import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CourierEditProfile } from '../models/courier-edit-profile';
import { CourierSearch } from '../models/courier-search';
import { CustomerSignUp } from '../models/customer-sign-up';
import { Package } from '../models/package';
import { PackageSearch } from '../models/package-search';
import { PackageStatus } from '../models/package-status';
import { Payment } from '../models/payment';
import { User } from '../models/user';
import { UserStatus } from '../models/user-status';
import { AlertService } from './alert.service';
import { ApiService } from './api.service';
import { ErrorHandlerService } from './error-handler.service';
import { LoadingService } from './loading.service';

@Injectable({
    providedIn: 'root'
}) export class AdminService {

    public orders$: BehaviorSubject<Package[] | undefined> = new BehaviorSubject<Package[] | undefined>(undefined);
    public couriers$: BehaviorSubject<User[] | undefined> = new BehaviorSubject<User[] | undefined>(undefined);
    private lastCourierSearch: CourierSearch | undefined;
    private lastPackageSearch: PackageSearch | undefined;

    constructor(
        private apiService: ApiService,
        private loadingService: LoadingService,
        private errorHandlerService: ErrorHandlerService,
        private alertService: AlertService,
    ) {

    }

    public async loadOrders(search: PackageSearch | undefined = this.lastPackageSearch): Promise<void> {
        if (!search) {
            return;
        }
        this.loadingService.loading$.next(true);
        this.lastPackageSearch = search;
        const result: Package[] = [];
        try {
            const { data, total } = await this.apiService.adminGetOrderList(search).toPromise();
            if (search.offset) {
                result.push(...(new Array(search.offset).fill({})));
            }
            result.push(...data);
            if (result.length < total) {
                result.push(...(new Array(total - result.length).fill({})));
            }
        } catch (err) {
            try {
                this.errorHandlerService.handleApiError(err);
            } catch (err) {
                this.alertService.presentAlert('Chyba', err.message);
            }
            return;
        } finally {
            this.loadingService.loading$.next(false);
        }
        this.orders$.next(result);
    }

    public async updateOrder(orderId: number, stub: Partial<Package>, throwOnError: boolean = false): Promise<void> {
        this.loadingService.loading$.next(true);
        try {
            await this.apiService.adminUpdateOrder(orderId, stub).toPromise();
        } catch (err) {
            try {
                this.errorHandlerService.handleApiError(err);
            } catch (err) {
                if (throwOnError) {
                    throw err;
                }
                this.alertService.presentAlert('Chyba', err.message);
                this.loadOrders(); // kompletni reload pri chybe
            }
            return;
        } finally {
            this.loadingService.loading$.next(false);
        }
        this.onOrderUpdate();
    }

    public async updateOrderStatus(
        orderId: number,
        status: PackageStatus,
        throwOnError: boolean = false,
        silentSuccess: boolean = false
    ): Promise<void> {
        this.loadingService.loading$.next(true);

        let apiFn: any;
        switch (status) {
            case PackageStatus.DECLINED:
            case PackageStatus.DECLINED_PROHIBITED:
            case PackageStatus.DECLINED_SENDER_NOT_PRESENT:
            case PackageStatus.DECLINED_OVERSIZED:
                apiFn = this.apiService.adminSetPackageDeclinedByCourier
                    .bind(this.apiService, orderId, status);
                break;
            case PackageStatus.COURIER_TAKEN:
                apiFn = this.apiService.adminSetPackageTakenByCourier
                    .bind(this.apiService, orderId);
                break;
            case PackageStatus.DEPO_TAKEN:
                apiFn = this.apiService.adminSetPackageAcceptedInDepo
                    .bind(this.apiService, orderId);
                break;
            case PackageStatus.EN_ROUTE_TO_CUSTOMER:
                apiFn = this.apiService.adminSetPackageEnRouteToCustomer
                    .bind(this.apiService, orderId);
                break;
            case PackageStatus.DELIVERED:
                apiFn = this.apiService.adminSetPackageDelivered
                    .bind(this.apiService, orderId);
                break;
            default:
                apiFn = this.apiService.adminUpdateOrder
                    .bind(this.apiService, orderId, { status });
                break;
        }

        try {
            await apiFn().toPromise();
        } catch (err) {
            try {
                this.errorHandlerService.handleApiError(err);
            } catch (err) {
                if (throwOnError) {
                    throw err;
                }
                this.alertService.presentAlert('Chyba', err.message);
                this.loadOrders(); // kompletni reload pri chybe
            }
            return;
        } finally {
            this.loadingService.loading$.next(false);
        }
        this.onOrderUpdate(silentSuccess);
    }

    public async loadCouriers(search: CourierSearch | undefined = this.lastCourierSearch): Promise<void> {
        if (!search) {
            return;
        }
        this.loadingService.loading$.next(true);
        this.lastCourierSearch = search;
        const result: User[] = [];
        try {
            const { data, total } = await this.apiService.adminGetCourierList(search).toPromise();
            if (search.offset) {
                result.push(...(new Array(search.offset).fill({})));
            }
            result.push(...data);
            if (result.length < total) {
                result.push(...(new Array(total - result.length).fill({})));
            }
        } catch (err) {
            try {
                this.errorHandlerService.handleApiError(err);
            } catch (err) {
                this.alertService.presentAlert('Chyba', err.message);
            }
            return;
        } finally {
            this.loadingService.loading$.next(false);
        }
        this.couriers$.next(result);
    }

    public async updateCourier(profileEdit: CourierEditProfile): Promise<void> {
        this.loadingService.loading$.next(true);
        try {
            await this.apiService.adminUpdateCourier(profileEdit).toPromise();
        } catch (err) {
            this.errorHandlerService.handleApiError(err);
            return;
        } finally {
            this.loadingService.loading$.next(false);
        }
        this.onCourierUpdate();
    }

    public async disableCourierAccount(courierId: string): Promise<void> {
        this.loadingService.loading$.next(true);
        try {
            await this.apiService.adminDisableCourierAccount(courierId).toPromise();
        } catch (err) {
            this.errorHandlerService.handleApiError(err);
            return;
        } finally {
            this.loadingService.loading$.next(false);
        }
        this.onCourierAccountDisable();
    }

    public async approveCourier(courierId: string): Promise<void> {
        this.loadingService.loading$.next(true);
        try {
            await this.apiService.adminApproveCourier(courierId).toPromise();
        } catch (err) {
            this.errorHandlerService.handleApiError(err);
            return;
        } finally {
            this.loadingService.loading$.next(false);
        }
        this.onCourierApprove();
    }

    public async declineCourier(courierId: string): Promise<void> {
        this.loadingService.loading$.next(true);
        try {
            await this.apiService.adminDeclineCourier(courierId).toPromise();
        } catch (err) {
            this.errorHandlerService.handleApiError(err);
            return;
        } finally {
            this.loadingService.loading$.next(false);
        }
        this.onCourierDecline();
    }

    public async addCourier(signUp: CustomerSignUp): Promise<void> {
        this.loadingService.loading$.next(true);
        try {
            await this.apiService.adminAddCourier(signUp).toPromise();
        } catch (err) {
            this.errorHandlerService.handleApiError(err);
            return;
        } finally {
            this.loadingService.loading$.next(false);
        }
        this.onCourierAdd();
    }

    public async getAllActiveCouriers(): Promise<User[]> {
        let result: User[] = [];
        const search: CourierSearch = {
            limit: 99999,
            offset: 0,
            userStatus: UserStatus.COURIER_ACTIVE,
            order: 'ASC',
            sort: 'username',
        };
        try {
            const { data } = await this.apiService.adminGetCourierList(search).toPromise();
            result = data;
        } catch (err) {
            try {
                this.errorHandlerService.handleApiError(err);
            } catch (err) {
                this.alertService.presentAlert('Chyba', err.message);
            }
        }
        return result;
    }

    public async deleteOrder(id: number): Promise<void> {
        this.loadingService.loading$.next(true);
        try {
            await this.apiService.adminDeleteOrder(id).toPromise();
        } catch (err) {
            this.errorHandlerService.handleApiError(err);
            return;
        } finally {
            this.loadingService.loading$.next(false);
        }
        this.onOrderDelete();
    }

    public async checkPaymentStatus(paymentId: string): Promise<Payment | undefined> {
        this.loadingService.loading$.next(true);
        let result: Payment;
        try {
            result = await this.apiService.checkPaymentStatus(paymentId).toPromise();
        } catch (err) {
            this.errorHandlerService.handleApiError(err);
            return;
        } finally {
            this.loadingService.loading$.next(false);
        }
        this.onPaymentCheckSuccess();
        return result;
    }

    public async capturePayment(paymentId: string, amount: string): Promise<Payment | undefined> {
        this.loadingService.loading$.next(true);
        let result: Payment;
        try {
            result = await this.apiService.adminCapturePayment(paymentId, amount).toPromise();
        } catch (err) {
            this.errorHandlerService.handleApiError(err);
            return;
        } finally {
            this.loadingService.loading$.next(false);
        }
        this.onCapturePaymentSuccess();
        return result;
    }

    private onOrderUpdate(silentSuccess: boolean = false): void {
        if (!silentSuccess) {
            this.alertService.presentToast('Zásilka byla upravena.');
        }
        this.loadOrders();
    }

    private onOrderDelete(): void {
        this.alertService.presentToast('Zásilka byla smazána.');
        this.loadOrders();
    }

    private onPaymentCheckSuccess(): void {
        this.alertService.presentToast('Aktualizováno');
        this.loadOrders();
    }

    private onCapturePaymentSuccess(): void {
        this.alertService.presentToast('Žádost o stržení platby byla uložena.');
        this.loadOrders();
    }

    private onCourierUpdate(): void {
        this.alertService.presentToast('Profil kurýra byl upraven.');
        this.loadCouriers();
    }

    private onCourierAccountDisable(): void {
        this.alertService.presentToast('Účet kurýra byl deaktivován.');
        this.loadCouriers();
    }

    private onCourierApprove(): void {
        this.alertService.presentToast('Registrace kurýra byla přijata.');
        this.loadCouriers();
    }

    private onCourierDecline(): void {
        this.alertService.presentToast('Registrace kurýra byla zamítnuta.');
        this.loadCouriers();
    }

    private onCourierAdd(): void {
        this.alertService.presentToast('Nový kurýr byl zaregistrován.');
        this.loadCouriers();
    }

}
