import { Component, Input } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { HelperService } from 'src/app/services/helper.service';
import { UserService } from 'src/app/services/user.service';
import { CustomerSignUpComponent } from '../customer-sign-up/customer-sign-up.component';
import { PasswordResetComponent } from '../password-reset/password-reset.component';

@Component({
    selector: 'app-customer-sign-in',
    templateUrl: './customer-sign-in.component.html',
    styleUrls: ['./customer-sign-in.component.scss']
})
export class CustomerSignInComponent {

    @Input() public note: string | undefined;

    public form: FormGroup = this.fb.group({
        email: ['', [Validators.required, this.helperService.emailValidator]],
        password: ['', [Validators.required]]
    });
    public submitted: boolean = false;
    public error: undefined | string;

    public get email(): AbstractControl { return this.form.get('email') as AbstractControl }
    public get password(): AbstractControl { return this.form.get('password') as AbstractControl }

    constructor(
        public userService: UserService,
        public modalRef: BsModalRef,
        private fb: FormBuilder,
        private bsModalService: BsModalService,
        private helperService: HelperService,
    ) {
    }

    public onSubmitClick(): void {
        this.error = undefined;
        this.submitted = true;
        if (!this.form.valid) {
            return;
        }
        this.signIn();
    }

    public openSignUpModal(): void {
        this.modalRef.hide();
        this.bsModalService.show(CustomerSignUpComponent);
    }

    public onForgotPasswordClick(): void {
        this.modalRef.hide();
        this.bsModalService.show(PasswordResetComponent);
    }

    private async signIn(): Promise<void> {
        this.submitted = false;
        try {
            await this.userService.customerSignIn(this.form.value);
        } catch (err) {
            this.error = err.message;
            return;
        }
        this.modalRef.hide();
    }
}
