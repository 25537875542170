import { UserRole } from './user-role'

export interface CustomerEditProfile {
    name: string;
    email: string;
    phone: string;
    street: string;
    city: string;
    zip: string;
    role: UserRole;
}

export const customerEditProfileToDto = (profile: CustomerEditProfile): any => {
    return {
        email: profile.email,
        username: profile.name,
        phone: profile.phone,
        address: profile.street,
        city: profile.city,
        zipCode: profile.zip,
        role: profile.role,
    }
}
