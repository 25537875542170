import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, Validators, AbstractControl, FormBuilder } from '@angular/forms';
import { DateTime } from 'luxon';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { GopayState } from 'src/app/models/gopay-state';
import { Package } from 'src/app/models/package';
import { PackageImage } from 'src/app/models/package-image';
import { PackageSizeEnum } from 'src/app/models/package-size';
import { PackageStatus } from 'src/app/models/package-status';
import { PackageWeightEnum } from 'src/app/models/package-weight';
import { User } from 'src/app/models/user';
import { AdminService } from 'src/app/services/admin.service';
import { AlertService } from 'src/app/services/alert.service';
import { STRINGS } from 'src/assets/strings';
import { AdminSetPriceComponent } from '../admin-set-price/admin-set-price.component';

@Component({
    selector: 'app-admin-order-edit',
    templateUrl: './admin-order-edit.component.html',
    styleUrls: ['./admin-order-edit.component.scss']
})
export class AdminOrderEditComponent implements OnInit {


    @Input() public order!: Package;
    @Input() public couriers!: User[];

    public editMode: boolean = false;
    public form: FormGroup = this.fb.group({
        title: ['', [Validators.required]],
        senderName: ['', [Validators.required]],
        senderPhone: ['', [Validators.required, Validators.pattern(/^\d{9}$/)]],
        receiverName: ['', Validators.required],
        receiverPhone: ['', [Validators.required, Validators.pattern(/^\d{9}$/)]],
        packageSize: [undefined, [Validators.required]],
        packageWeight: [undefined, [Validators.required]],
        addressFrom: ['', [Validators.required]],
        cityFrom: ['', [Validators.required]],
        zipCodeFrom: ['', [Validators.required, Validators.pattern(/^\d{5}$/)]],
        addressTo: ['', [Validators.required]],
        cityTo: ['', [Validators.required]],
        zipCodeTo: ['', [Validators.required, Validators.pattern(/^\d{5}$/)]],
        pickUpDate: [undefined, [Validators.required]],
        status: [undefined, [Validators.required]],
        courierId: [undefined],
    });
    public submitted: boolean = false;
    public error: undefined | string;
    public PackageWeight: typeof PackageWeightEnum = PackageWeightEnum;
    public PackageSize: typeof PackageSizeEnum = PackageSizeEnum;
    public PackageStatus: typeof PackageStatus = PackageStatus;
    public STRINGS: typeof STRINGS = STRINGS;
    public GopayState: typeof GopayState = GopayState;
    public bsDatepickerConfig: any = {
        dateInputFormat: 'D. M. YYYY'
    };

    public get title(): AbstractControl { return this.form.get('title') as AbstractControl }
    public get status(): AbstractControl { return this.form.get('status') as AbstractControl }
    public get pickUpDate(): AbstractControl { return this.form.get('pickUpDate') as AbstractControl }
    public get packageSize(): AbstractControl { return this.form.get('packageSize') as AbstractControl }
    public get packageWeight(): AbstractControl { return this.form.get('packageWeight') as AbstractControl }
    public get senderName(): AbstractControl { return this.form.get('senderName') as AbstractControl }
    public get senderPhone(): AbstractControl { return this.form.get('senderPhone') as AbstractControl }
    public get receiverName(): AbstractControl { return this.form.get('receiverName') as AbstractControl }
    public get receiverPhone(): AbstractControl { return this.form.get('receiverPhone') as AbstractControl }
    public get addressFrom(): AbstractControl { return this.form.get('addressFrom') as AbstractControl }
    public get cityFrom(): AbstractControl { return this.form.get('cityFrom') as AbstractControl }
    public get zipCodeFrom(): AbstractControl { return this.form.get('zipCodeFrom') as AbstractControl }
    public get addressTo(): AbstractControl { return this.form.get('addressTo') as AbstractControl }
    public get cityTo(): AbstractControl { return this.form.get('cityTo') as AbstractControl }
    public get zipCodeTo(): AbstractControl { return this.form.get('zipCodeTo') as AbstractControl }


    constructor(
        public adminService: AdminService,
        public modalRef: BsModalRef,
        private fb: FormBuilder,
        private alertService: AlertService,
        private bsModalService: BsModalService,
    ) {
    }

    public ngOnInit(): void {
        this.form.disable();
        this.form.patchValue({
            ...this.order,
            receiverPhone: this.order.receiverPhone ? this.order.receiverPhone.replace('+420', '') : '',
            senderPhone: this.order.senderPhone ? this.order.senderPhone.replace('+420', '') : '',
            pickUpDate: DateTime.fromISO(this.order.pickUpDate).toJSDate(),
        });
    }

    public onEditClick(): void {
        this.editMode = true;
        this.form.enable();
    }

    public onSubmitClick(): void {
        this.error = undefined;
        this.submitted = true;
        if (!this.form.valid) {
            return;
        }
        this.confirmOrderSave();
    }

    public onDeleteClick(): void {
        this.confirmOrderDelete();
    }

    public onPhotoClick(photo: PackageImage): void {
        window.open(photo.url, '_blank');
    }

    public async onCheckPaymentClick(): Promise<void> {
        this.error = undefined;
        try {
            const payment = await this.adminService.checkPaymentStatus(this.order.payment.id) as any;
            this.order.payment = payment;
        } catch (err) {
            this.error = err.message;
            return;
        }
    }

    public async onCapturePaymentClick(): Promise<void> {
        this.bsModalService.show(AdminSetPriceComponent, {
            initialState: {
                order: this.order
            },
        });
    }

    private async updateOrder(): Promise<void> {
        this.error = undefined;
        this.submitted = false;
        const newCourierId = this.form.value.courierId;
        const order: Package = {
            ...this.form.value,
            courierName: newCourierId ? this.couriers.find(it => it.id === newCourierId)?.username : undefined,
            receiverPhone: '+420' + this.form.value.receiverPhone,
            senderPhone: '+420' + this.form.value.senderPhone,
            pickUpDate: DateTime.fromJSDate(this.form.value.pickUpDate).toISO(),
        };
        try {
            if (this.order.status !== this.form.value.status) {
                await this.adminService.updateOrderStatus(this.order.id, this.form.value.status, true, true);
            }
            await this.adminService.updateOrder(this.order.id, order, true);
        } catch (err) {
            this.error = err.message;
            return;
        }
        this.modalRef.hide();
    }

    private confirmOrderSave(): void {
        this.alertService.presentConfirm({
            title: 'Úprava zásilky',
            message: `Opravdu si přejete upravit tuto zásilku?`,
            cancelText: 'Zpět',
            onConfirm: () => this.updateOrder()
        });
    }

    private confirmOrderDelete(): void {
        this.alertService.presentConfirm({
            title: 'Smazání zásilky',
            message: `Opravdu si přejete smazat tuto zásilku?`,
            cancelText: 'Zpět',
            onConfirm: () => this.deleteOrder()
        });
    }

    private async deleteOrder(): Promise<void> {
        this.error = undefined;
        this.submitted = false;
        try {
            await this.adminService.deleteOrder(this.order.id);
        } catch (err) {
            this.error = err.message;
            return;
        }
        this.modalRef.hide();
    }

}
