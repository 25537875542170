<div class='alerts-wrapper'>
    <alert *ngFor="let alert of alertService.alerts" [type]="alert.type" [dismissible]='true' [dismissOnTimeout]="alert.timeout">{{ alert.msg }}
    </alert>
</div>
<router-outlet></router-outlet>
<div *ngIf='loadingService.loading$ | async' class='loading-top'>
    <div class="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>
</div>
