import { Injectable } from '@angular/core';
import { StorageKeys } from '../models/storage-keys';
import { User } from '../models/user';
import { ApiService } from './api.service';
import { StorageService } from './storage.service';

@Injectable({
    providedIn: 'root'
}) export class InitService {

    public user: undefined | User;

    constructor(
        private storageService: StorageService,
        private apiService: ApiService
    ) {
    }

    public async initializeApp(): Promise<void> {
        const token = this.storageService.get(StorageKeys.API_TOKEN);
        if (!token) {
            return;
        }
        this.apiService.setTokenFromCache(token);
        try {
            this.user = await this.apiService.reloadUser().toPromise();
        } catch (err) {
            // token je neplatny
        }
    }

}
