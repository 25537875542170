<app-navbar></app-navbar>
<main>
    <section class="section section--top background-primary">
        <div class="container">
            <h2 class='font-normal'>Nastavení</h2>
            <p>&nbsp;</p>
            <img id='path-3' src='assets/images/path-3.png' />
        </div>
        <img id='bg-shape-1' src='assets/images/bg-shape-1.png' />
    </section>
    <section class="section section--content pb-0">
        <div class="container">
            <form action="">
                <div class="row">
                    <div class="col-lg-12">
                        <app-customer-profile-edit></app-customer-profile-edit>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-6">
                        <app-customer-password-change></app-customer-password-change>
                    </div>
                    <div class="col-lg-6">
                        <fieldset>
                            <img class='group-img group-img--info' src="assets/images/package-info.png" alt="">
                            <h5 class='primary-color font-semibold'>Další možnosti</h5>
                            <p><a (click)='onDeleteAccountClick()' class='underline grey-color'>Zrušit účet</a></p>
                        </fieldset>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <app-customer-packages></app-customer-packages>
                    </div>
                </div>
            </form>
        </div>
    </section>
</main>
<app-footer mode='lite'></app-footer>
