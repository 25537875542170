import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { AdminService } from './admin.service';

@Injectable({
    providedIn: 'root'
})
export class AdminContentResolverService implements Resolve<any> {

    constructor(
        private adminService: AdminService,
    ) { }

    public resolve(): Promise<void> {
        return this.adminService.loadCouriers();
    }
}

