import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Package } from 'src/app/models/package';
import { Payment } from 'src/app/models/payment';
import { AdminService } from 'src/app/services/admin.service';

@Component({
    selector: 'app-admin-set-price',
    templateUrl: './admin-set-price.component.html',
    styleUrls: ['./admin-set-price.component.scss']
})
export class AdminSetPriceComponent implements OnInit {

    @Input() public order!: Package;

    public submitted: boolean = false;
    public error: undefined | string;
    public form: FormGroup = this.fb.group({
        price: ['', [Validators.required]],
    });

    public get price(): AbstractControl { return this.form.get('price') as AbstractControl }

    constructor(
        public adminService: AdminService,
        public modalRef: BsModalRef,
        private fb: FormBuilder,
    ) {
    }

    public ngOnInit(): void {
        this.form.patchValue({
            price: this.order.finalDeliveryPrice,
        });
    }

    public onSubmitClick(): void {
        this.error = undefined;
        this.submitted = true;
        if (!this.form.valid) {
            return;
        }
        this.setPrice();
    }

    private async setPrice(): Promise<void> {
        this.submitted = false;
        const priceCZK = +this.form.controls.price.value;
        const priceCZKHalire = '' + (priceCZK * 100);
        let payment: Payment;
        try {
            payment = await this.adminService.capturePayment(this.order.payment.id, priceCZKHalire) as any;
        } catch (err) {
            this.error = err.message;
            return;
        }
        this.order.payment = payment;
        this.modalRef.hide();
    }
}
