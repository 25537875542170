import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import * as Sentry from '@sentry/angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NavbarComponent } from './components/navbar/navbar.component';
import { RootComponent } from './pages/root/root.component';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { Router } from '@angular/router';
import { PackageNewComponent } from './pages/package/package-new/package-new.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PackageDoneComponent } from './pages/package/package-done/package-done.component';
import { SettingsComponent } from './pages/user/settings/settings.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { PackageListComponent } from './pages/admin/package/package-list/package-list.component';
import { FooterComponent } from './components/footer/footer.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { csLocale } from 'ngx-bootstrap/locale';
import { AlertModule } from 'ngx-bootstrap/alert';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DeliverersComponent } from './pages/admin/deliverers/deliverers.component';
import { ApiService } from './services/api.service';
import { AdminSignInComponent } from './components/admin-sign-in/admin-sign-in.component';
import { CustomerSignInComponent } from './components/customer-sign-in/customer-sign-in.component';
import { CourierSignUpComponent } from './components/courier-sign-up/courier-sign-up.component';
import { CustomerSignUpComponent } from './components/customer-sign-up/customer-sign-up.component';
import { EmailVerificationComponent } from './components/email-verification/email-verification.component';
import { DeliverComponent } from './pages/deliver/deliver.component';
import { CustomerProfileEditComponent } from './components/customer-profile-edit/customer-profile-edit.component';
import { CustomerPasswordChangeComponent } from './components/customer-password-change/customer-password-change.component';
import { AlertModalComponent } from './components/alert-modal/alert-modal.component';
import { ConfirmModalComponent } from './components/confirm-modal/confirm-modal.component';
import { AdminOrderComponent } from './components/admin-order/admin-order.component';
import { AdminCourierActiveComponent } from './components/admin-courier-active/admin-courier-active.component';
import { AdminCourierEditComponent } from './components/admin-courier-edit/admin-courier-edit.component';
import { AdminCourierPendingComponent } from './components/admin-courier-pending/admin-courier-pending.component';
import { AdminCourierInactiveComponent } from './components/admin-courier-inactive/admin-courier-inactive.component';
import { PasswordResetComponent } from './components/password-reset/password-reset.component';
import { DataTableModule } from '@pascalhonegger/ng-datatable';
import { TableSorterComponent } from './components/table-sorter/table-sorter.component';
import { TermsContentComponent } from './components/terms-content/terms-content.component';
import { HttpClientModule } from '@angular/common/http';
import { InitService } from './services/init.service';
import { CustomerPackagesComponent } from './components/customer-packages/customer-packages.component';
import { AdminCourierAddComponent } from './components/admin-courier-add/admin-courier-add.component';
import { DepoOrderComponent } from './components/depo-order/depo-order.component';
import { AdminCourierDeclinedComponent } from './components/admin-courier-declined/admin-courier-declined.component';
import { PhonePipe } from './pipes/phone.pipe';
import { AdminSetPriceComponent } from './components/admin-set-price/admin-set-price.component';
import { TermsComponent } from './pages/terms/terms.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { ComplaintsComponent } from './pages/complaints/complaints.component';
import { AdminOrderEditComponent } from './components/admin-order-edit/admin-order-edit.component';
import { VerifyEmailComponent } from './pages/verify-email/verify-email.component';
import { NewPasswordComponent } from './pages/new-password/new-password.component';
import { LogoComponent } from './components/logo/logo.component';
import { CourierResendVerificationEmailComponent } from './components/courier-resend-verification-email/courier-resend-verification-email.component';
import { DatePipe } from '@angular/common';

defineLocale('cs', csLocale);

@NgModule({
    declarations: [
        AppComponent,
        PackageNewComponent,
        PackageDoneComponent,
        SettingsComponent,
        NavbarComponent,
        FooterComponent,
        RootComponent,
        DeliverComponent,
        PackageListComponent,
        DeliverersComponent,
        CourierSignUpComponent,
        CourierResendVerificationEmailComponent,
        AdminSignInComponent,
        CustomerSignInComponent,
        CustomerSignUpComponent,
        EmailVerificationComponent,
        CustomerProfileEditComponent,
        CustomerPasswordChangeComponent,
        AlertModalComponent,
        ConfirmModalComponent,
        AdminOrderComponent,
        DepoOrderComponent,
        AdminCourierActiveComponent,
        AdminCourierPendingComponent,
        AdminCourierInactiveComponent,
        AdminCourierDeclinedComponent,
        AdminCourierEditComponent,
        AdminCourierAddComponent,
        AdminSetPriceComponent,
        PasswordResetComponent,
        TableSorterComponent,
        TermsContentComponent,
        CustomerPackagesComponent,
        PhonePipe,
        TermsComponent,
        PrivacyPolicyComponent,
        ComplaintsComponent,
        AdminOrderEditComponent,
        VerifyEmailComponent,
        NewPasswordComponent,
        LogoComponent,
        CourierResendVerificationEmailComponent,
    ],
    imports: [
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        TabsModule.forRoot(),
        ModalModule.forRoot(),
        BsDropdownModule.forRoot(),
        BsDatepickerModule.forRoot(),
        AlertModule.forRoot(),
        DataTableModule,
        HttpClientModule,
    ],
    entryComponents: [
        AdminSignInComponent,
        CustomerSignInComponent,
        CustomerSignUpComponent,
        EmailVerificationComponent,
        AdminCourierEditComponent,
        AdminCourierAddComponent,
        AdminSetPriceComponent,
        AlertModalComponent,
        ConfirmModalComponent,
        PasswordResetComponent,
        TermsContentComponent,
        AdminOrderEditComponent,
    ],
    providers: [
        DatePipe,
        {
            provide: ApiService,
        },
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                showDialog: false
            })
        },
        {
            provide: Sentry.TraceService,
            deps: [Router]
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => {
            },
            deps: [Sentry.TraceService],
            multi: true
        },
        {
            provide: APP_INITIALIZER,
            // overeni platnosti tokenu a stazeni dat uzivatele
            useFactory: (initService: InitService) => () => initService.initializeApp(),
            deps: [InitService],
            multi: true
        },
        { provide: LOCALE_ID, useValue: 'cs' },
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
