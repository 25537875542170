<nav class="navbar fixed-top">
    <div class='container-fluid mr-4 ml-4'>
        <a routerLink='/' class="navbar-brand">
            <app-logo></app-logo>
        </a>
        <ul class="navbar-nav ml-auto">
            <li role="nav-item"><a (click)='onContinueToSiteClick()' class="btn btn--secondary">Pokračovat na web</a></li>
        </ul>
    </div>
</nav>
<main>
    <section class="section section--top background-primary">
        <div class="container-fluid ml-4">
            <h2 class="font-normal">Ověření e-mailové adresy</h2>
            <p>&nbsp;</p>
        </div>
    </section>
    <section class="section section--content">
        <div class="container">
            <p *ngIf='success' class='verify-email-success'>
                <ng-container *ngIf='app!=="COURIER"'>
                    Děkujeme. Ověření e-mailové adresy proběhlo úspěšně. Nyní můžete využívat všechny funkce aplikace.
                </ng-container>
                <ng-container *ngIf='app==="COURIER"'>
                    Děkujeme. Ověření e-mailové adresy proběhlo úspěšně. Nyní vyčkej do 2 pracovních dnů pro ověření účtu. Následně tě budeme informovat e-mailem. Stáhni si také aplikaci, kde bude probíhat vše důležité.
                </ng-container>
            </p>
            <p *ngIf='error' class='verify-email-error'>
                {{ error }}
            </p>
        </div>
    </section>
</main>
