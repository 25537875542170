import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular';
import { Integrations } from '@sentry/tracing';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
    enableProdMode();

    Sentry.init({
        dsn: 'https://2fa87dafac9947ffa21d0650d9ce96a6@o329963.ingest.sentry.io/5541401',
        integrations: [
            new Integrations.BrowserTracing({
                tracingOrigins: ['localhost', 'https://yourserver.io/api'], // TODO add origin
                routingInstrumentation: Sentry.routingInstrumentation,
            }),
        ],
        tracesSampleRate: 1.0,
    });
}

platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.error(err));
