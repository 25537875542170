<div class="modal-header">
    <h4 class="modal-title pull-left primary-color">Zapomenuté heslo</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
</div>
<div class="modal-body grey-color">
    <form (submit)='onSubmitClick()' [formGroup]='form' [class.submitted]='submitted' novalidate>
        <div class="form-group">
            <label>E-mail</label>
            <input formControlName='email' type="email" class="form-control" required>
            <div *ngIf='submitted && email.invalid' class="invalid-feedback">
                Zadejte prosím platný email.
            </div>
        </div>
        <div *ngIf='error' class='form-error-message'>
            {{error}}
        </div>
        <div *ngIf='success' class='form-success-message'>
            Vaše heslo bylo uspěšně obnoveno. Postupujte podle pokynů v emailu.
        </div>
        <div class="d-flex flex-row align-items-center">
            <input type="submit" class="btn btn--primary" value="Obnovit heslo" [disabled]='success'>
        </div>
    </form>
</div>
