<fieldset>
    <img class='group-img group-img--info' src="assets/images/package-info.png" alt="">
    <h5 class='primary-color font-semibold'>Moje zásilky</h5>
    <table [mfData]="items" [mfRowsOnPage]="10" #mf="mfDataTable" class="admin-table">
        <thead>
            <tr>
                <th>
                    ID
                    <!-- <app-table-sorter by="idSortable">ID</app-table-sorter> -->
                </th>
                <th>Zásilka</th>
                <th>
                    Název
                    <!-- <app-table-sorter by="titleSortable">Název</app-table-sorter> -->
                </th>
                <th>
                    Čas vytvoření
                    <!-- <app-table-sorter by="createdDateSortable">Čas vytvoření</app-table-sorter> -->
                </th>
                <th>
                    Odesílatel
                    <!-- <app-table-sorter by="senderSortable">Odesílatel</app-table-sorter> -->
                </th>
                <th>
                    Příjemce
                    <!-- <app-table-sorter by="recipientSortable">Příjemce</app-table-sorter> -->
                </th>
                <th>
                    Stav
                    <!-- <app-table-sorter by="stateSortable">Stav</app-table-sorter> -->
                </th>
                <th>
                    Platba
                    <!-- <app-table-sorter by="stateSortable">Stav</app-table-sorter> -->
                </th>
                <th>
                </th>
            </tr>
        </thead>
        <tbody>
            <tr *ngIf='items.length === 0'>
                <td colspan='8'>
                    <div *ngIf='items.length === 0' class='list-empty-message'>Žádné zásilky</div>
                </td>
            </tr>
            <tr *ngFor='let order of mf.data' app-customer-order>
                <td>
                    <span class="underline">{{order.packageTrackId}}</span>
                </td>
                <td>
                    <div class="img-wrapper">
                        <img *ngIf='order.files && order.files[0]' [src]='order.files[0].url' />
                        <i *ngIf='order.files && !order.files[0]' class="fas fa-image"></i>
                    </div>
                </td>
                <td>
                    {{order.title}}
                </td>
                <td>{{order.createdDate | date : 'd.M. H:mm yyyy'}}</td>
                <td>
                    <div class="table-user">
                        <div>{{order.senderName}}</div>
                        <div>{{order.addressFrom}}</div>
                    </div>
                </td>
                <td>
                    <div class="table-user">
                        <div>{{order.receiverName}}</div>
                        <div>{{order.addressTo}}</div>
                    </div>
                </td>
                <td>
                    {{$any(STRINGS.packageStatus)[order.status]}}
                </td>
                <td>
                    <ng-container *ngIf='order.payment'>
                        {{$any(STRINGS.paymentStatus)[order.payment.state]}}
                    </ng-container>
                    <ng-container *ngIf='!order.payment'>
                        Neznámý stav
                    </ng-container>
                </td>
                <td>
                    <ng-container *ngIf='!order.payment || (order.payment.state !== GopayState.PAID && order.payment.state !== GopayState.AUTHORIZED)'>
                        <button (click)='onPayClick(order)' type="button" class="btn btn--primary btn--small">
                            Zaplatit
                        </button>
                    </ng-container>
                </td>
            </tr>
        </tbody>
        <tfoot>
            <tr>
                <td colspan="9">
                    <mfBootstrapPaginator [rowsOnPageSet]="[5,10,25,50]"></mfBootstrapPaginator>
                </td>
            </tr>
        </tfoot>
    </table>
</fieldset>
