<div class="container p-tb-80 grey-color ">
    <div class="text-center">
        <h2 class='medium-darker-color font-normal mb-5'>Chcete se stát naším dopravcem?</h2>
        <p>
            Staňte se součástí Nebalik týmu. Staňte se naším kurýrem. Zaregistrujte se a přes aplikaci Nebalik dokončete registraci. Pak už jen stačí zadat si vlastní časový rozvrh práce a sledovat objednávky na přepravu.
        </p>
    </div>
    <form *ngIf='!success' (submit)='onSubmitClick()' [formGroup]='form' [class.submitted]='submitted' novalidate class="form">
        <div class="form-group">
            <label>Jméno a příjmení</label>
            <input formControlName='name' type="text" class="form-control">
            <div *ngIf='submitted && name.invalid' class="invalid-feedback">
                Zadejte prosím své jméno.
            </div>
        </div>
        <div class="form-group form-group--phone">
            <label>Telefon</label>
            <input formControlName='phone' type="number" class="form-control" pattern="[0-9]*">
            <div *ngIf='submitted && phone.invalid' class="invalid-feedback">
                Zadejte prosím platné telefonní číslo.
            </div>
        </div>
        <div class="form-group">
            <label>E-mail</label>
            <input formControlName='email' type="email" class="form-control" autocomplete="new-password">
            <div *ngIf='submitted && email.invalid' class="invalid-feedback">
                Zadejte prosím platný email.
            </div>
        </div>
        <div class="form-group">
            <label>Heslo</label>
            <input formControlName='password' type="password" class="form-control" autocomplete="new-password">
            <div *ngIf='submitted && password.invalid' class="invalid-feedback">
                Zadejte heslo o délce alespoň 6 znaků.
            </div>
        </div>
        <div class="form-group">
            <label>Heslo pro kontrolu</label>
            <input formControlName='passwordCheck' type="password" class="form-control" autocomplete='new-password' required>
            <div *ngIf='submitted && passwordCheck.invalid' class="invalid-feedback">
                Zadejte prosím heslo pro kontrolu.
            </div>
            <div *ngIf='submitted && password.valid && passwordCheck.valid && password.value !== passwordCheck.value' class="invalid-feedback">
                Hesla se neshodují.
            </div>
        </div>
        <div class="form-group">
            <label>Úložný prostor vozidla v litrech</label>
            <input formControlName='maxVolume' type="number" class="form-control" pattern="[0-9]*">
            <div *ngIf='submitted && maxVolume.invalid' class="invalid-feedback">
                Zadejte prosím úložný prostor vozidla.
            </div>
        </div>
        <div class="form-group">
            <label>Maximální váha nákladu v kg</label>
            <input formControlName='maxWeight' type="number" class="form-control" pattern="[0-9]*">
            <div *ngIf='submitted && maxWeight.invalid' class="invalid-feedback">
                Zadejte prosím maximální váhu nákladu.
            </div>
        </div>
        <div class="form-group">
            <label>Oblast rozvozu</label>
            <input formControlName='distributionAreas' type="text" class="form-control">
            <div *ngIf='submitted && distributionAreas.invalid' class="invalid-feedback">
                Zadejte prosím oblast rozvozu.
            </div>
        </div>
        <div class="form-group">
            <label>IČ</label>
            <input formControlName='ic' type="phone" class="form-control" pattern="[0-9]*">
            <div *ngIf='submitted && ic.invalid' class="invalid-feedback">
                Zadejte prosím IČ.
            </div>
        </div>
        <div class="form-group">
            <label>DIČ</label>
            <input formControlName='dic' type="text" class="form-control">
        </div>
        <div *ngIf='error' class='form-error-message'>
            {{error}}
        </div>
        <input type="submit" class="btn btn--primary" value="Chci být dopravcem" />
    </form>
    <div *ngIf='success' class='form-success-message mt-5'>
        Děkujeme, že se chceš stát součástí našeho týmu. Díky tobě bude přeprava zase o kus rychlejší.<br/><br/>
        Nyní prosím ověř svůj email klepnutím na odkaz, který jsme ti zaslali.<br/><br/>
        Poté vyčkej do 2 pracovních dnů pro ověření účtu. Následně tě budeme informovat e-mailem. Stáhni si také aplikaci, kde bude probíhat vše důležité.
    </div>
</div>
