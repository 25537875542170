export const FIREBASE_CONFIG = {
    apiKey: 'AIzaSyBEEFEiuCzNnmw9numHBlou07fi15SL26Y',
    authDomain: 'kuryr-8c111.firebaseapp.com',
    databaseURL: 'https://kuryr-8c111.firebaseio.com',
    projectId: 'kuryr-8c111',
    storageBucket: 'kuryr-8c111.appspot.com',
    messagingSenderId: '389398873228',
    appId: '1:389398873228:web:9db8db341c760232aae7a9',
    measurementId: 'G-0WFBMQ12R3'
};

// export const BASE_URL = 'http://p5012.dev.inited.cz/api'; // test na localhostu
export const BASE_URL = '/api'; // produkce
