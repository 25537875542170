import { Component, OnInit } from '@angular/core';
import { DataTable } from '@pascalhonegger/ng-datatable';
import { DateTime } from 'luxon';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { AdminCourierAddComponent } from 'src/app/components/admin-courier-add/admin-courier-add.component';
import { CourierSearch } from 'src/app/models/courier-search';
import { User } from 'src/app/models/user';
import { UserStatus } from 'src/app/models/user-status';
import { AdminService } from 'src/app/services/admin.service';

@Component({
    selector: 'app-deliverers',
    templateUrl: './deliverers.component.html',
    styleUrls: ['./deliverers.component.scss']
})
export class DeliverersComponent implements OnInit {

    public couriers$: Observable<User[] | undefined> = this.adminService.couriers$;
    public limit: number = 10;
    public page: number = 1;
    public sortBy: string = 'createdDate';
    public order: 'ASC' | 'DESC' = 'DESC';
    public selectedTab: UserStatus = UserStatus.COURIER_ACTIVE;
    public UserStatus: typeof UserStatus = UserStatus;

    public planPreviewDates: string[] = [
        DateTime.local().plus({ days: 1 }).toISO(),
        DateTime.local().plus({ days: 2 }).toISO(),
        DateTime.local().plus({ days: 3 }).toISO(),
        DateTime.local().plus({ days: 4 }).toISO(),
        DateTime.local().plus({ days: 5 }).toISO(),
    ];

    constructor(
        private adminService: AdminService,
        private bsModalService: BsModalService,
    ) {

    }

    public async ngOnInit(): Promise<void> {
        this.loadCouriers();
    }

    public onTabChange(tabId: string): void {
        this.page = 1;
        this.selectedTab = tabId as UserStatus;
        this.order = 'DESC';
        this.sortBy = 'createdDate';
        this.loadCouriers();
    }

    public onPageChange(table: DataTable): void {
        const activePage = table.activePage;
        const limit = table.rowsOnPage;
        if (this.page !== activePage || this.limit !== limit) {
            this.page = activePage;
            this.limit = limit;
            this.loadCouriers();
        }
    }

    public onSortChange(table: DataTable): void {
        const sortBy = (table.sortBy as string).substr(1);
        const order = table.sortOrder.toUpperCase() as 'ASC' | 'DESC';
        if (this.sortBy !== sortBy || this.order !== order) {
            this.sortBy = sortBy;
            this.order = order;
            this.loadCouriers();
        }
    }

    public onAddCourierClick(): void {
        this.bsModalService.show(AdminCourierAddComponent);
    }

    private loadCouriers(): void {
        const search: CourierSearch = {
            limit: this.limit,
            offset: (this.page - 1) * this.limit,
            userStatus: this.selectedTab,
            order: this.order,
            sort: this.sortBy,
        }
        this.adminService.loadCouriers(search);
    }
}
