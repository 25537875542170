import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { DataTable, PageEvent } from '@pascalhonegger/ng-datatable';
import { GopayState } from 'src/app/models/gopay-state';
import { Package } from 'src/app/models/package';
import { OrderService } from 'src/app/services/order.service';
import { STRINGS } from 'src/assets/strings';
@Component({
    selector: 'app-customer-packages',
    templateUrl: './customer-packages.component.html',
    styleUrls: ['./customer-packages.component.scss']
})
export class CustomerPackagesComponent implements AfterViewInit {

    @ViewChild('mf') public dataTable!: DataTable;

    public error: boolean = false;
    public loading: boolean = true;
    public items: Package[] = [];
    public STRINGS: typeof STRINGS = STRINGS;
    public GopayState: typeof GopayState = GopayState;
    public limit: number = 10;
    public page: number = 1;

    constructor(
        private orderService: OrderService,
    ) { }

    public async ngAfterViewInit(): Promise<void> {
        this.loadPackages();
        this.dataTable.onPageChange.subscribe((ev: PageEvent) => {
            const activePage = ev.activePage;
            const limit = ev.rowsOnPage;
            if (this.page !== activePage || this.limit !== limit) {
                this.page = activePage;
                this.limit = limit;
                this.loadPackages();
            }
        });
    }

    public onPayClick(order: Package): void {
        this.orderService.payOrder(order);
    }

    private async loadPackages(): Promise<void> {
        this.error = false;
        this.loading = true;
        try {
            const items = await this.orderService.getPackages(this.page, this.limit);
            this.items = items;
        } catch (err) {
            this.error = true;
        } finally {
            this.loading = false;
        }
    }

}
