<div class="container grey-color ">
    <div class="text-center">
        <p>
            Registraci už máte za sebou, ale ověřovací e-mail nemáte? Pošleme vám ho znovu.
        </p>
    </div>
    <form (submit)='onSubmitClick()' [formGroup]='form' [class.submitted]='submitted' novalidate class="form">
        <div class="form-group">
            <label>E-mail</label>
            <input formControlName='email' type="email" class="form-control" autocomplete="new-password">
            <div *ngIf='submitted && email.invalid' class="invalid-feedback">
                Zadejte prosím platný email.
            </div>
        </div>
        <div *ngIf='error' class='form-error-message'>
            {{error}}
        </div>
        <input type="submit" class="btn btn--primary" value="Znovu poslat ověřovací e-mail" />
    </form>
</div>
