import { Component, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { HelperService } from 'src/app/services/helper.service';
import { UserService } from 'src/app/services/user.service';

@Component({
    selector: 'app-deliver',
    templateUrl: './deliver.component.html',
    styleUrls: ['./deliver.component.scss']
})
export class DeliverComponent implements OnDestroy {

    private routerSub!: Subscription;

    constructor(
        public userService: UserService,
        private router: Router,
        private helperService: HelperService,
    ) {
        this.routerSub = this.router.events.subscribe(s => {
            if (s instanceof NavigationEnd) {
                const tree = this.router.parseUrl(this.router.url);
                if (tree.fragment) {
                    const element = document.querySelector(`#${tree.fragment}`);
                    if (element) {
                        window.scrollTo({ top: this.helperService.getElementOffset(element).top - 158, left: 0, behavior: 'smooth' });
                    }
                }
            }
        });
    }

    public ngOnDestroy(): void {
        this.routerSub.unsubscribe();
    }
    public scrollTo(el: HTMLElement): void {
        window.scrollTo({ top: this.helperService.getElementOffset(el).top - 158, left: 0, behavior: 'smooth' });
    }
}
