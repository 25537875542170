import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, Validators, AbstractControl, FormBuilder } from '@angular/forms';
import { DateTime } from 'luxon';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { CustomerSignInComponent } from 'src/app/components/customer-sign-in/customer-sign-in.component';
import { Order } from 'src/app/models/order';
import { PackageSizeEnum } from 'src/app/models/package-size';
import { PackageWeightEnum } from 'src/app/models/package-weight';
import { UserEvent } from 'src/app/models/user-event';
import { AlertService } from 'src/app/services/alert.service';
import { HelperService } from 'src/app/services/helper.service';
import { OrderService } from 'src/app/services/order.service';
import { UserService } from 'src/app/services/user.service';
import { STRINGS } from 'src/assets/strings';

@Component({
    selector: 'app-package-new',
    templateUrl: './package-new.component.html',
    styleUrls: ['./package-new.component.scss']
})
export class PackageNewComponent implements OnInit, OnDestroy {

    public form: FormGroup = this.fb.group({
        photo: [undefined, [Validators.required]],
        title: ['', [Validators.required]],
        pickUpDate: [undefined, [Validators.required]],
        weight: ['', [Validators.required]],
        dimensions: ['', [Validators.required]],
        senderEmail: ['', [Validators.required, this.helperService.emailValidator]],
        senderName: ['', [Validators.required]],
        senderPhone: ['', [Validators.required, Validators.pattern(/^\d{9}$/)]],
        senderStreet: ['', [Validators.required]],
        senderCity: ['', [Validators.required]],
        senderZip: ['', [Validators.required, Validators.pattern(/^\d{5}$/)]],
        recipientName: ['', [Validators.required]],
        recipientPhone: ['', [Validators.required, Validators.pattern(/^\d{9}$/)]],
        recipientStreet: ['', [Validators.required]],
        recipientCity: ['', [Validators.required]],
        recipientZip: ['', [Validators.required, Validators.pattern(/^\d{5}$/)]],
    });
    public submitted: boolean = false;
    public error: undefined | string;
    public photoPreviewDataURL: undefined | string;
    public estimatedPrice: undefined | string;
    public PackageWeight: typeof PackageWeightEnum = PackageWeightEnum;
    public PackageSize: typeof PackageSizeEnum = PackageSizeEnum;
    public STRINGS: typeof STRINGS = STRINGS;
    public availableDates: Date[] = [];

    private userSignInSub!: Subscription;
    private formChangesSub!: Subscription;
    private pickedPhotoFileRef: undefined | File;

    public get photo(): AbstractControl { return this.form.get('photo') as AbstractControl }
    public get title(): AbstractControl { return this.form.get('title') as AbstractControl }
    public get pickUpDate(): AbstractControl { return this.form.get('pickUpDate') as AbstractControl }
    public get weight(): AbstractControl { return this.form.get('weight') as AbstractControl }
    public get dimensions(): AbstractControl { return this.form.get('dimensions') as AbstractControl }
    public get senderEmail(): AbstractControl { return this.form.get('senderEmail') as AbstractControl }
    public get senderName(): AbstractControl { return this.form.get('senderName') as AbstractControl }
    public get senderPhone(): AbstractControl { return this.form.get('senderPhone') as AbstractControl }
    public get senderStreet(): AbstractControl { return this.form.get('senderStreet') as AbstractControl }
    public get senderCity(): AbstractControl { return this.form.get('senderCity') as AbstractControl }
    public get senderZip(): AbstractControl { return this.form.get('senderZip') as AbstractControl }
    public get recipientName(): AbstractControl { return this.form.get('recipientName') as AbstractControl }
    public get recipientPhone(): AbstractControl { return this.form.get('recipientPhone') as AbstractControl }
    public get recipientStreet(): AbstractControl { return this.form.get('recipientStreet') as AbstractControl }
    public get recipientCity(): AbstractControl { return this.form.get('recipientCity') as AbstractControl }
    public get recipientZip(): AbstractControl { return this.form.get('recipientZip') as AbstractControl }

    constructor(
        public userService: UserService,
        private orderService: OrderService,
        private bsModalService: BsModalService,
        private fb: FormBuilder,
        private helperService: HelperService,
        private alertService: AlertService,
    ) {
    }

    public ngOnInit(): void {
        this.availableDates = this.orderService.computeAvailableDates();
        if (!this.availableDates.length) {
            this.form.disable();
        }
        this.prefillDate();
        this.prefillSenderForm();
        this.userSignInSub = this.userService.userEvents$.subscribe(evt => {
            if (evt === UserEvent.SIGN_IN) {
                this.prefillSenderForm();
            }
        });
        this.formChangesSub = this.form.valueChanges.subscribe(() => {
            // pri editaci formulare resetuji vypocitanou cenu
            this.estimatedPrice = undefined;
        });
    }

    public ngOnDestroy(): void {
        this.userSignInSub.unsubscribe();
        this.formChangesSub.unsubscribe();
    }

    public onSignInClick(): void {
        this.bsModalService.show(CustomerSignInComponent);
    }

    public onPhotoFileInputChange(event: any): void {
        const file = event.target.files[0];
        this.pickedPhotoFileRef = file;
        const reader = new FileReader();
        reader.onload = () => this.photoPreviewDataURL = reader.result as string;
        reader.readAsDataURL(file);
    }


    public onEstimatePriceClick(): void {
        this.error = undefined;
        this.submitted = true;
        if (!this.form.valid) {
            return;
        }
        this.estimatePrice();
    }

    public onSubmitClick(): void {
        this.placeOrder();
    }

    public onDimensionsHelpClick(): void {
        this.alertService.presentAlert('Rozměry zásilky', 'Součet obvodové délky (1x délka + 2x šířka + 2x výška) musí být do 300 cm.');
    }

    private async estimatePrice(): Promise<void> {
        this.submitted = false;
        let result: string | undefined;
        const order = this.formToOrder(this.form.value);
        try {
            result = await this.orderService.estimatePrice(order);
        } catch (err) {
            this.error = err.message;
            return;
        }
        this.estimatedPrice = result;
    }

    private async placeOrder(): Promise<void> {
        if (!this.pickedPhotoFileRef) {
            return;
        }
        this.error = undefined;
        const order = this.formToOrder(this.form.value);
        try {
            await this.orderService.placeOrder(order, this.pickedPhotoFileRef);
        } catch (err) {
            this.error = err.message;
            return;
        }
    }

    private prefillSenderForm(): void {
        const user = this.userService.user;
        if (user) {
            this.form.patchValue({
                senderEmail: user.email,
                senderName: user.username,
                senderPhone: user.phone ? user.phone.replace('+420', '') : '',
                senderStreet: user.address.street,
                senderCity: user.address.city,
                senderZip: user.address.zip,
            });
        }
    }

    private formToOrder(formValue: any): Order {
        return {
            ...formValue,
            recipientPhone: '+420' + formValue.recipientPhone,
            senderPhone: '+420' + formValue.senderPhone,
            estimatedDeliveryPrice: this.estimatedPrice,
        };
    }

    private prefillDate(): void {
        if (this.availableDates.length) {
            this.form.patchValue({
                pickUpDate: this.availableDates[0],
            });
        }
    }
}
