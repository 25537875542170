<app-navbar></app-navbar>
<main>
    <section class="section section--top background-primary">
        <div class="container">
            <div class='row'>
                <div class='col'>
                    <h1 class='secondary-color mb-3 font-normal ff-bangers'>šetři čas na nápady</h1>
                    <h3 class='white-color mb-4 font-light'>Balení nech na nás</h3>
                    <p class='mb-5 medium-dark-color'>
                        Potřebujete poslat jakoukoliv věc dál? Ale jste otrávení jen z představy, že musíte najít vhodnou krabici, poté zabalit a dojít na poštu, kde strávíte spoustu drahocenného času? Máme pro vás řešení! Vše uděláme za vás!
                    </p>
                    <p><a routerLink="/send/package-new" class="btn btn--secondary">Poslat zásilku</a></p>
                </div>
            </div>
            <img id='banana' src='assets/images/banana.png' />
        </div>
        <img id='bg-shape-1' src='assets/images/bg-shape-1.png' />
        <img id='bg-shape-2' src='assets/images/bg-shape-2.png' />
    </section>
    <section class="section section--content">
        <div class="container">
            <div class='row'>
                <div class='col'>
                    <div class="text-center">
                        <h2 class='medium-darker-color font-normal'>Jak to funguje<span class='secondary-color h-enlarge ff-bangers'>?</span></h2>
                    </div>
                </div>
            </div>
            <p class='howto-note'>
                Zaslání zásilky nemůže být už jednodušší. Nemusíte nic balit. Vše potřebné zadáte pouze ve 3 krocích a pak jen čekáte na kurýra.
            </p>
            <div class="features row">
                <div class="col">
                    <div class="feature">
                        <img class='feature__img' src='assets/images/package-take-photo.png' />
                        <h5>Vyfoťte a zjistěte cenu</h5>
                        <p>Předmět, který chcete odeslat, vyfoťte a pak už jen zvolte datum vyzvednutí a kurýra.</p>
                    </div>
                    <img class='feature__path' src='assets/images/path-h-1.png' />
                </div>
                <div class="col">
                    <div class="feature">
                        <img class='feature__img' src='assets/images/package-pick-up.png' />
                        <h5>Vyzvedneme, zabalíme</h5>
                        <p>V termín, který vám zašleme v sms a emailem, si kurýr vyzvedne zásilku. A to ostatní už obstaráme my.</p>
                    </div>
                    <img class='feature__path' src='assets/images/path-h-2.png' />
                </div>
                <div class="col">
                    <div class="feature">
                        <img class='feature__img' src='assets/images/package-delivery.png' />
                        <h5>Doručíme</h5>
                        <p>Předmět důkladně zabalíme a bezpečně přepravíme na požadovanou adresu.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section id='app' class="section section--content background-primary">
        <div class="container">
            <img src='assets/images/mobile-app.png' />
            <div class="row">
                <div class="col">
                    <h2 class='secondary-color font-normal'>Mobilní aplikace</h2>
                    <p class="m-tb-40 medium-dark-color">Mobilní aplikace Nebalik je ještě praktičtější. Žádné kabely, žádné notebooky a foťáky. Stačí pár kroků přes mobil a kurýr je na cestě. Zároveň máte s aplikací Nebalík kontrolu zásilek vždy po ruce.</p>
                    <div class="badges">
                        <a href='https://itunes.apple.com/app/id1550790547' target='_blank'>
                            <img src="assets/images/button-app-store.png" alt="">
                        </a>
                        <a href='https://play.google.com/store/apps/details?id=cz.nebalik.zakaznik' target='_blank'>
                            <img src="assets/images/button-google-play.png" alt="">
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- <section id='social' class="section section--content">
        <div class="container">
            <div class="row">
                <div class="col">
                    <p class='grey-color'>
                        She packed her seven versalia, put her initial into<br/> the belt and made herself on the way Far far away,<br/> behind the word mountains, far from the countries Vokalia
                    </p>
                    <p class="m-t-40"><a>Learn more <i class="fas fa-angle-right"></i></a></p>
                </div>
                <div class="col">
                    <div class="logos">
                        <img src="assets/images/netflix-logo.png" alt="">
                        <img src="assets/images/vine-logo.png" alt="">
                        <img src="assets/images/mashable-logo.png" alt="">
                        <img src="assets/images/adult-swim-logo.png" alt="">
                    </div>
                </div>
            </div>
        </div>
    </section> -->
    <section class="section section--content ">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6">
                    <div class="img-wrapper">
                        <img id='map-img' src="assets/images/map.png" alt="">
                    </div>
                </div>
                <div class="col-lg-5 offset-lg-1">
                    <h2 class='medium-darker-color font-normal'>Kde můžete službu využít?</h2>
                    <p class="m-t-40 grey-color">V rámci startovního provozu můžete službu<br/> prozatím využít pouze na území Prahy.<br/>
                        <strong>Doručujeme však po celé České Republice.</strong>
                    </p>
                </div>
            </div>
        </div>
    </section>
    <section class='section section--content background-primary'>
        <div class="container">
            <div class='row'>
                <div class='col'>
                    <div class="p-tb-80">
                        <div class="text-center">
                            <h2 class="mb-5 secondary-color font-normal">
                                Pošlete to s nebalík.cz
                            </h2>
                            <p class="m-0"><a routerLink="/send/package-new" class="btn btn--secondary">Poslat zásilku
                        </a></p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <div class="text-center">
                        <h5 class='medium-dark-color font-light'>
                            Jste dopravce a chcete s námi spolupracovat?<br/> Pokračujte do sekce <a routerLink='/deliver' class='underline link--alt'>Pro Dopravce</a>
                        </h5>
                    </div>
                </div>
            </div>
        </div>
    </section>
</main>
<app-footer></app-footer>
