import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GopayState } from 'src/app/models/gopay-state';
import { Package } from 'src/app/models/package';
import { OrderService } from 'src/app/services/order.service';
import { STRINGS } from 'src/assets/strings';

@Component({
    selector: 'app-package-done',
    templateUrl: './package-done.component.html',
    styleUrls: ['./package-done.component.scss']
})
export class PackageDoneComponent implements OnInit {

    public paymentId: string = this.route.snapshot.queryParams.id;
    public packageId: string = this.route.snapshot.queryParams.packageId;
    public success: boolean = false;
    public error: string = '';
    public pkg: Package | undefined;

    constructor(
        private route: ActivatedRoute,
        private orderService: OrderService,
        private router: Router,
    ) {
    }

    public async ngOnInit(): Promise<void> {
        try {
            this.pkg = await this.orderService.getPackage(+this.packageId);
            const paymentResult = await this.orderService.checkPaymentStatus(this.paymentId);
            if (!paymentResult || !this.pkg) {
                this.error = 'Nepodařilo se načíst stav platby.';
                return;
            }
            if (paymentResult.state === GopayState.AUTHORIZED
                || paymentResult.state === GopayState.PAID) {
                this.success = true;
                return;
            }
            this.error = `Platbu se nepodařilo autorizovat.\n\nStav platby: ${STRINGS.paymentStatus[paymentResult.state]}`;
        } catch (err) {
            this.error = `Nepodařilo se načíst stav platby.\n\n${err.message}`;
        }
    }

    public onMyOrdersClick(): void {
        this.router.navigateByUrl('/send/settings');
    }

    public onPayClick(): void {
        if (!this.pkg) {
            return;
        }
        this.orderService.payOrder(this.pkg)
    }
}
