import { PackageSizeEnum } from 'src/app/models/package-size';
import { PackageStatus } from 'src/app/models/package-status';
import { PackageWeightEnum } from 'src/app/models/package-weight';
import { GopayState } from 'src/app/models/gopay-state';

export const STRINGS = {
    error: {
        connection: 'Ověřte připojení k internetu.',
        unauthorized: 'Uživatel byl odhlášen. Přihlaste se prosím znovu.',
        default: 'Neznámá chyba',
    },
    packageStatus: {
        [PackageStatus.WAITING]: 'Nová zásilka',
        [PackageStatus.COURIER_PLANNED]: 'Čeká na vyzvednutí',
        [PackageStatus.COURIER_TAKEN]: 'Převzato kurýrem',
        [PackageStatus.DELIVERED]: 'Doručeno',
        [PackageStatus.DEPO_TAKEN]: 'Předáno do depa',
        [PackageStatus.EN_ROUTE_TO_CUSTOMER]: 'Na cestě k zákazníkovi',
        [PackageStatus.DECLINED]: 'Zamítnuto - jiné',
        [PackageStatus.DECLINED_OVERSIZED]: 'Zamítnuto - velký rozměr',
        [PackageStatus.DECLINED_PROHIBITED]: 'Zamítnuto - zakázané zboží',
        [PackageStatus.DECLINED_SENDER_NOT_PRESENT]: 'Zamítnuto - odesílatel nezastižen',
        [PackageStatus.CUSTOMER_DECLINED]: 'Odmítnuto zákazníkem',
    },
    packageWeight: {
        [PackageWeightEnum.SMALL]: '0 - 10 kg',
        [PackageWeightEnum.LARGE]: '10 - 30 kg',
    },
    packageSize: {
        [PackageSizeEnum.SMALL]: 'max 100x50x50 cm',
        [PackageSizeEnum.LARGE]: 'více než 100x50x50 cm',
    },
    paymentStatus: {
        [GopayState.AUTHORIZED]: 'Rezervováno',
        [GopayState.CANCELED]: 'Zamítnuto',
        [GopayState.CREATED]: 'Nezaplaceno',
        [GopayState.PAID]: 'Zaplaceno',
        [GopayState.PARTIALLY_REFUNDED]: 'Částečně vráceno',
        [GopayState.PAYMENT_METHOD_CHOSEN]: 'Nezaplaceno',
        [GopayState.REFUNDED]: 'Vráceno',
        [GopayState.TIMEOUTED]: 'Zamítnuto',
    },
};
