import { Component } from '@angular/core';
import { FormGroup, Validators, AbstractControl, FormBuilder } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { HelperService } from 'src/app/services/helper.service';
import { UserService } from 'src/app/services/user.service';
import { PasswordResetComponent } from '../password-reset/password-reset.component';

@Component({
    selector: 'app-admin-sign-in',
    templateUrl: './admin-sign-in.component.html',
    styleUrls: ['./admin-sign-in.component.scss']
})
export class AdminSignInComponent {

    public form: FormGroup = this.fb.group({
        email: ['', [Validators.required, this.helperService.emailValidator]],
        password: ['', [Validators.required]]
    });
    public submitted: boolean = false;
    public error: undefined | string;

    public get email(): AbstractControl { return this.form.get('email') as AbstractControl }
    public get password(): AbstractControl { return this.form.get('password') as AbstractControl }

    constructor(
        public userService: UserService,
        public modalRef: BsModalRef,
        private bsModalService: BsModalService,
        private fb: FormBuilder,
        private helperService: HelperService,
    ) {
    }

    public onSubmitClick(): void {
        this.error = undefined;
        this.submitted = true;
        if (!this.form.valid) {
            return;
        }
        this.signIn();
    }

    public onForgotPasswordClick(): void {
        this.modalRef.hide();
        this.bsModalService.show(PasswordResetComponent);
    }

    private async signIn(): Promise<void> {
        this.submitted = false;
        try {
            await this.userService.adminSignIn(this.form.value);
        } catch (err) {
            this.error = err.message;
            return;
        }
        this.modalRef.hide();
    }
}
