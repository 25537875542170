<nav class="navbar fixed-top">
    <div class='container-fluid mr-4 ml-4'>
        <a routerLink='/' class="navbar-brand">
            <app-logo></app-logo>
        </a>
        <ul class="navbar-nav ml-auto">
            <li role="nav-item"><a class="btn btn--secondary" routerLink="/">Zpět na web</a></li>
        </ul>
    </div>
</nav>
<main>
    <section class="section section--top background-primary">
        <div class="container-fluid ml-4">
            <h2 class="font-normal">Zásady ochrany osobních údajů</h2>
            <p>&nbsp;</p>
        </div>
    </section>
    <div class="c13">
        <ol class="c1 lst-kix_list_1-0 start" start="1">
            <li class="c16 li-bullet-0"><span class="c10">Z&aacute;kladn&iacute; ustanoven&iacute;</span></li>
        </ol>
        <ol class="c1 lst-kix_list_1-1 start" start="1">
            <li class="c4 li-bullet-0"><span class="c8">Spr&aacute;vcem osobn&iacute;ch &uacute;daj&#367; podle &#269;l. 4 bod 7 na&#345;&iacute;zen&iacute; Evropsk&eacute;ho parlamentu a Rady (EU) 2016/679 o ochran&#283; fyzick&yacute;ch osob v souvislosti se zpracov&aacute;n&iacute;m osobn&iacute;ch &uacute;daj&#367; a o voln&eacute;m pohybu t&#283;chto &uacute;daj&#367; (d&aacute;le jen:&nbsp;</span>
                <span class="c6">&bdquo;GDPR&rdquo;</span><span class="c2">) je&nbsp;Evolution Concept s.r.o se s&iacute;dlem Jugosl&aacute;vsk&aacute; 659/11, I&#268; 05760402, DI&#268; CZ05760402, zaps&aacute;na v obchodn&iacute;m rejst&#345;&iacute;ku m&#283;stsk&eacute;ho soudu v Praze, v odd&iacute;le C, vlo&#382;ce 270281 (d&aacute;le jen: &bdquo;Spr&aacute;vce&ldquo;).</span></li>
            <li class="c4 li-bullet-0"><span class="c2">Kontaktn&iacute; &uacute;daje Spr&aacute;vce jsou<br>adresa:&nbsp;Jugosl&aacute;vsk&aacute; 659/11<br>email: info@nebalik.cz<br>telefon:&nbsp;+420&nbsp;728 611 618</span></li>
            <li class="c4 li-bullet-0"><span class="c2">Spr&aacute;vce nejmenoval pov&#283;&#345;ence pro ochranu osobn&iacute;ch &uacute;daj&#367;.</span></li>
        </ol>
        <ol class="c1 lst-kix_list_1-0" start="2">
            <li class="c16 li-bullet-0"><span class="c10">Zdroje a kategorie zpracov&aacute;van&yacute;ch osobn&iacute;ch &uacute;daj&#367;</span></li>
        </ol>
        <ol class="c1 lst-kix_list_1-1 start" start="1">
            <li class="c4 li-bullet-0"><span class="c8">Spr&aacute;vce zpracov&aacute;v&aacute; osobn&iacute; &uacute;daje, kter&eacute; jste mu poskytl/a nebo osobn&iacute; &uacute;daje, kter&eacute; Spr&aacute;vce z&iacute;skal na z&aacute;klad&#283; obstar&aacute;n&iacute; p&#345;epravy z&aacute;silek a proveden&iacute; dal&scaron;&iacute;ch &uacute;kon&#367; s p&#345;epravou souvisej&iacute;c&iacute;ch (d&aacute;le jen&nbsp;</span>
                <span class="c6">&bdquo;Slu&#382;ba&ldquo;</span><span class="c8">) nebo registrac&iacute; na webov&yacute;ch str&aacute;nk&aacute;ch Spr&aacute;vce (d&aacute;le jen&nbsp;</span><span class="c6">&bdquo;Objedn&aacute;vkov&yacute; syst&eacute;m&ldquo;</span>
                <span class="c2">).</span>
            </li>
            <li class="c4 li-bullet-0"><span class="c2">Jedn&aacute; se p&#345;edev&scaron;&iacute;m o:</span></li>
        </ol>
        <ul class="c1 lst-kix_list_1-2 start">
            <li class="c0 li-bullet-0"><span class="c2">jm&eacute;no a p&#345;&iacute;jmen&iacute;</span></li>
            <li class="c0 li-bullet-0"><span class="c2">n&aacute;zev spole&#269;nosti (subjektu), I&#268;O a DI&#268; a kontaktn&iacute; osoba u spole&#269;nost&iacute;</span></li>
            <li class="c0 li-bullet-0"><span class="c2">kontaktn&iacute; &uacute;daje</span></li>
            <li class="c0 li-bullet-0"><span class="c3">adresa</span></li>
            <li class="c0 li-bullet-0"><span class="c3">e-mailov&aacute; adresa</span></li>
            <li class="c0 li-bullet-0"><span class="c3">telefonn&iacute; &#269;&iacute;slo</span></li>
            <li class="c0 li-bullet-0"><span class="c3">lokaliza&#269;n&iacute; &uacute;daje (Adresa, PS&#268;, m&#283;sto, st&aacute;t, zem&#283;pisn&aacute; d&eacute;lka, zem&#283;pisn&aacute; &scaron;&iacute;&#345;ka)</span></li>
            <li class="c0 li-bullet-0"><span class="c3">&uacute;daje o objedn&aacute;vk&aacute;ch (historie objedn&aacute;vek, p&#345;ipom&iacute;nky, recenze, dodac&iacute; adresa, &uacute;sp&#283;&scaron;n&eacute; i zru&scaron;en&iacute; objedn&aacute;vky) </span></li>
            <li class="c0 li-bullet-0"><span class="c5">bankovn&iacute; spojen&iacute; (&#269;&iacute;slo bankovn&iacute;ho &uacute;&#269;tu pro &uacute;&#269;ely</span><span class="c2">&nbsp;vypl&aacute;cen&iacute; dob&iacute;rky)</span></li>
            <li class="c0 li-bullet-0"><span class="c8">(d&aacute;le jen&nbsp;</span><span class="c6">&bdquo;Osobn&iacute; &uacute;daje&ldquo;</span><span class="c2">)</span></li>
        </ul>
        <ol class="c1 lst-kix_list_1-0" start="3">
            <li class="c16 li-bullet-0"><span class="c10">Z&aacute;konn&yacute; d&#367;vod a &uacute;&#269;el zpracov&aacute;n&iacute; Osobn&iacute;ch &uacute;daj&#367;</span></li>
        </ol>
        <ol class="c1 lst-kix_list_1-1 start" start="1">
            <li class="c4 li-bullet-0"><span class="c2">Z&aacute;konn&yacute;m d&#367;vodem zpracov&aacute;n&iacute; Osobn&iacute;ch &uacute;daj&#367; je</span></li>
        </ol>
        <ol class="c1 lst-kix_list_2-2 start" start="1">
            <li class="c7 li-bullet-0"><span class="c2">pln&#283;n&iacute; smlouvy mezi V&aacute;mi a Spr&aacute;vcem podle &#269;l. 6 odst. 1 p&iacute;sm. b) GDPR,</span></li>
            <li class="c0 li-bullet-0"><span class="c8">opr&aacute;vn&#283;n&yacute; z&aacute;jem Spr&aacute;vce na poskytnut&iacute; p&#345;&iacute;m&eacute; marketingov&eacute; &#269;innosti (prim&aacute;rn&#283; </span><span class="c3">zas&iacute;l&aacute;n&iacute; obchodn&iacute;ch sd&#283;len&iacute; a newsletr&#367;) podle &#269;l. 6 odst. 1 p&iacute;sm. f) GDPR,</span></li>
        </ol>
        <ol class="c1 lst-kix_list_2-1 start" start="1">
            <li class="c4 li-bullet-0"><span class="c3">&Uacute;&#269;elem zpracov&aacute;n&iacute; Osobn&iacute;ch &uacute;daj&#367; je</span></li>
        </ol>
        <ol class="c1 lst-kix_list_2-2 start" start="1">
            <li class="c0 li-bullet-0"><span class="c3">vy&#345;&iacute;zen&iacute;, zru&scaron;en&iacute; nebo reklamace objedn&aacute;vky a v&yacute;kon pr&aacute;v a povinnost&iacute; vypl&yacute;vaj&iacute;c&iacute;ch ze smluvn&iacute;ho vztahu mezi V&aacute;mi a Spr&aacute;vcem; jsou vy&#382;adov&aacute;ny Osobn&iacute; &uacute;daje, kter&eacute; jsou nutn&eacute; pro &uacute;sp&#283;&scaron;n&eacute; vy&#345;&iacute;zen&iacute; t&eacute;to smlouvy (jm&eacute;no a adresa, kontakt), poskytnut&iacute; Osobn&iacute;ch &uacute;daj&#367; je nutn&yacute;m po&#382;adavkem pro uzav&#345;en&iacute; a pln&#283;n&iacute; smlouvy, bez poskytnut&iacute; Osobn&iacute;ch &uacute;daj&#367; nen&iacute; mo&#382;n&eacute; smlouvu uzav&#345;&iacute;t &#269;i j&iacute; ze strany Spr&aacute;vce plnit,</span></li>
            <li class="c0 li-bullet-0"><span class="c3">zji&scaron;t&#283;n&iacute; zp&#283;tn&eacute; vazby t&yacute;kaj&iacute;c&iacute; se Va&scaron;eho n&aacute;kupu, a to prost&#345;ednictv&iacute;m emailov&yacute;ch a webov&yacute;ch pr&#367;zkum&#367;.</span></li>
            <li class="c0 li-bullet-0"><span class="c2">zas&iacute;l&aacute;n&iacute; obchodn&iacute;ch sd&#283;len&iacute; a prov&aacute;d&#283;n&iacute; dal&scaron;&iacute;ch marketingov&yacute;ch aktivit.</span></li>
        </ol>
        <ol class="c1 lst-kix_list_2-0 start" start="1">
            <li class="c16 li-bullet-0"><span class="c10">Doba uchov&aacute;v&aacute;n&iacute; &uacute;daj&#367;</span></li>
        </ol>
        <ol class="c1 lst-kix_list_2-1 start" start="1">
            <li class="c4 li-bullet-0"><span class="c2">Spr&aacute;vce uchov&aacute;v&aacute; Osobn&iacute; &uacute;daje</span></li>
        </ol>
        <p class="c18"><span class="c2">po dobu nezbytn&#283; nutnou k v&yacute;konu pr&aacute;v a povinnost&iacute; vypl&yacute;vaj&iacute;c&iacute;ch ze smluvn&iacute;ho vztahu mezi V&aacute;mi a Spr&aacute;vcem a uplat&#328;ov&aacute;n&iacute; n&aacute;rok&#367; z t&#283;chto smluvn&iacute;ch vztah&#367; (po dobu 15 let od ukon&#269;en&iacute; smluvn&iacute;ho vztahu).</span></p>
        <p class="c14"><span class="c2">po dobu, ne&#382; odvol&aacute;te V&aacute;&scaron; souhlas se zpracov&aacute;n&iacute;m Osobn&iacute;ch &uacute;daj&#367; pro &uacute;&#269;ely marketingu, nejd&eacute;le 5 let od uzav&#345;en&iacute; posledn&iacute; smlouvy.</span></p>
        <ol class="c1 lst-kix_list_2-1" start="2">
            <li class="c15 li-bullet-0"><span class="c2">Po uplynut&iacute; doby uchov&aacute;v&aacute;n&iacute; Osobn&iacute;ch &uacute;daj&#367; Spr&aacute;vce Osobn&iacute; &uacute;daje vyma&#382;e.</span></li>
        </ol>
        <ol class="c1 lst-kix_list_2-0" start="2">
            <li class="c16 li-bullet-0"><span class="c10">P&#345;&iacute;jemci Osobn&iacute;ch &uacute;daj&#367; (subdodavatel&eacute; Spr&aacute;vce)</span></li>
        </ol>
        <ol class="c1 lst-kix_list_2-1 start" start="1">
            <li class="c4 li-bullet-0"><span class="c8">Zpracov&aacute;n&iacute; Osobn&iacute;ch &uacute;daj&#367; je prov&aacute;d&#283;no Spr&aacute;vcem, Osobn&iacute; &uacute;daje v&scaron;ak pro </span><span class="c3">Spr&aacute;vce mohou &#269;&aacute;ste&#269;n&#283; nebo &uacute;pln&#283; zpracov&aacute;vat tito zpracovatel&eacute;:</span></li>
        </ol>
        <ul class="c1 lst-kix_list_3-2 start">
            <li class="c7 li-bullet-0"><span class="c3">dopravci pod&iacute;lej&iacute;c&iacute; se na realizaci p&#345;epravy a balen&iacute; Z&aacute;silek,</span></li>
            <li class="c0 li-bullet-0"><span class="c3">soukrom&eacute; osoby (Kur&yacute;&#345;i) pod&iacute;lej&iacute;c&iacute; se na realizaci p&#345;epravy a balen&iacute; Z&aacute;silek,</span></li>
            <li class="c0 li-bullet-0"><span class="c3">soukrom&eacute; nebo pr&aacute;vnick&eacute; osoby objedn&aacute;vaj&iacute;c&iacute; Slu&#382;bu,</span></li>
            <li class="c0 li-bullet-0"><span class="c3">subjekty zaji&scaron;&#357;uj&iacute;c&iacute; realizaci plateb na z&aacute;klad&#283; smlouvy, slu&#382;by provozov&aacute;n&iacute; webov&eacute; aplikace a dal&scaron;&iacute; slu&#382;by v souvislosti s &#269;innost&iacute; spole&#269;nosti,</span></li>
            <li class="c0 li-bullet-0"><span class="c3">v&yacute;dejn&iacute; m&iacute;sta a jejich provozovatel&eacute;, jejich&#382; slu&#382;by jsou pot&#345;ebn&eacute; pro poskytov&aacute;n&iacute; Slu&#382;eb,</span></li>
            <li class="c0 li-bullet-0"><span class="c2">subjekty zaji&scaron;tuj&iacute;c&iacute; prodejn&iacute; kan&aacute;l, marketingov&eacute; a emailingov&eacute; slu&#382;by,</span></li>
            <li class="c0 li-bullet-0"><span class="c2">subjekty zaji&scaron;tuj&iacute;c&iacute; zp&#283;tnou vazbu a dotazn&iacute;kov&eacute; &scaron;et&#345;en&iacute; v p&#345;&iacute;pad&#283; souhlasu.</span></li>
        </ul>
        <ol class="c1 lst-kix_list_3-1 start" start="1">
            <li class="c12"><span class="c3">Jsou to p&#345;edev&scaron;&iacute;m: GOPAY s.r.o., PayPal Inc., General Logistics Systems Czech Republic s.r.o., Direct Parcel Distribution CZ s. r. o., TOPTRANS EU, a.s., UNITED PARCEL SERVICE CZECH REPUBLIC, s. r. o., SendGrid, Inc., Facebook, Google LLC (Google Adwords, Google Analytics, Google Disk), Amazon Web Services, Inc., ComVisionSp. z o.o., tawk.to inc., Hotjar Limited, TheRocket Science Group a Seznam.cz, a.s., p&#345;&iacute;padn&#283; dal&scaron;&iacute; poskytovatel&eacute; zpracovatelsk&yacute;ch softwar&#367;, slu&#382;eb a aplikac&iacute;, kter&eacute; v&scaron;ak v sou&#269;asn&eacute; dob&#283; spole&#269;nost nevyu&#382;&iacute;v&aacute;.</span></li>
            <li class="c12"><span class="c5">Spr&aacute;vce m&aacute; v &uacute;myslu p&#345;edat Osobn&iacute; &uacute;daje do t&#345;et&iacute; zem&#283; (do zem&#283; mimo EU) nebo mezin&aacute;rodn&iacute; organizaci. Ve t&#345;et&iacute;ch zem&iacute;ch jsou p&#345;&iacute;jemci Osobn&iacute;ch &uacute;daj&#367; prim&aacute;rn&#283;</span>
                <span class="c2">&nbsp;poskytovatel&eacute; mailingov&yacute;ch a cloudov&yacute;ch slu&#382;eb. M&aacute;-li Z&aacute;silka m&iacute;sto doru&#269;en&iacute; mimo &uacute;zem&iacute; EU, dojde k p&#345;ed&aacute;n&iacute; Osobn&iacute;ch &uacute;daj&#367;
                            Zpracovateli se s&iacute;dlem mimo &uacute;zem&iacute; EU.</span>
            </li>
        </ol>
        <ol class="c1 lst-kix_list_3-0 start" start="1">
            <li class="c12"><span class="c10">Va&scaron;e pr&aacute;va</span></li>
        </ol>
        <ol class="c1 lst-kix_list_3-1 start" start="1">
            <li class="c12"><span class="c2">Za podm&iacute;nek stanoven&yacute;ch v GDPR m&aacute;te</span></li>
        </ol>
        <ul class="c1 lst-kix_list_3-2 start">
            <li class="c0 li-bullet-0"><span class="c2">pr&aacute;vo na p&#345;&iacute;stup ke sv&yacute;m Osobn&iacute;m &uacute;daj&#367;m dle &#269;l. 15 GDPR,</span></li>
            <li class="c0 li-bullet-0"><span class="c2">pr&aacute;vo na zm&#283;nu Osobn&iacute;ch &uacute;daj&#367; dle &#269;l. 16 GDPR, pop&#345;&iacute;pad&#283; omezen&iacute; zpracov&aacute;n&iacute; dle &#269;l. 18 GDPR.</span></li>
            <li class="c0 li-bullet-0"><span class="c2">pr&aacute;vo na smaz&aacute;n&iacute; Osobn&iacute;ch &uacute;daj&#367; dle &#269;l. 17 GDPR.</span></li>
            <li class="c0 li-bullet-0"><span class="c2">pr&aacute;vo vzn&eacute;st n&aacute;mitku proti zpracov&aacute;n&iacute; dle &#269;l. 21 GDPR a</span></li>
            <li class="c0 li-bullet-0"><span class="c2">pr&aacute;vo na p&#345;enositelnost &uacute;daj&#367; dle &#269;l. 20 GDPR.</span></li>
            <li class="c0 li-bullet-0"><span class="c2">pr&aacute;vo odvolat souhlas se zpracov&aacute;n&iacute;m p&iacute;semn&#283; nebo elektronicky na adresu nebo email Spr&aacute;vce uveden&yacute; v &#269;l. III t&#283;chto podm&iacute;nek.</span></li>
            <li class="c0 li-bullet-0"><span class="c2">v p&#345;&iacute;pad&#283; pochybnost&iacute; o dodr&#382;ov&aacute;n&iacute; povinnost&iacute; souvisej&iacute;c&iacute;ch se zpracov&aacute;n&iacute;m Osobn&iacute;ch &uacute;daj&#367; obr&aacute;tit se na Spr&aacute;vce nebo na &Uacute;&#345;ad pro ochranu Osobn&iacute;ch &uacute;daj&#367;.</span></li>
        </ul>
        <ol class="c1 lst-kix_list_3-0" start="2">
            <li class="c12"><span class="c10">Podm&iacute;nky zabezpe&#269;en&iacute; Osobn&iacute;ch &uacute;daj&#367;</span></li>
        </ol>
        <ol class="c1 lst-kix_list_3-1 start" start="1">
            <li class="c12"><span class="c2">Spr&aacute;vce prohla&scaron;uje, &#382;e p&#345;ijal ve&scaron;ker&aacute; p&#345;im&#283;&#345;en&aacute; bezpe&#269;nostn&iacute;, technick&aacute;, fyzick&aacute; a administrativn&iacute; opat&#345;en&iacute; k ochran&#283; bezpe&#269;nosti Osobn&iacute;ch &uacute;daj&#367;.</span></li>
            <li class="c12"><span class="c8">Spr&aacute;vce prohla&scaron;uje, &#382;e k Osobn&iacute;m &uacute;daj&#367;m </span><span class="c19">maj&iacute; p&#345;&iacute;stup pouze uveden&eacute; osoby &ndash; P&#345;&iacute;jemci Osobn&iacute;ch &uacute;daj&#367;, zam&#283;stnanci, dodavatel&eacute; a z&aacute;stupci. </span>
                <span class="c2">Osoby, kter&eacute; maj&iacute; p&#345;&iacute;stup k Osobn&iacute;m &uacute;daj&#367;m, jsou povinni udr&#382;ovat tyto informace d&#367;v&#283;rn&eacute; a nesm&iacute; je pou&#382;&iacute;vat k jin&yacute;m &uacute;&#269;el&#367;m,
                            ne&#382; kter&eacute; jsou uvedeny v&yacute;&scaron;e v&nbsp;tomto dokumentu.</span>
            </li>
        </ol>
        <ol class="c1 lst-kix_list_3-0" start="3">
            <li class="c11"><span class="c10">Z&aacute;v&#283;re&#269;n&aacute; ustanoven&iacute;</span></li>
        </ol>
        <ol class="c1 lst-kix_list_3-1 start" start="1">
            <li class="c11"><span class="c2">Odesl&aacute;n&iacute;m objedn&aacute;vky z Objedn&aacute;vkov&eacute;ho syst&eacute;mu potvrzujete, &#382;e jste sezn&aacute;men/a s podm&iacute;nkami ochrany Osobn&iacute;ch &uacute;daj&#367; a &#382;e je v cel&eacute;m rozsahu p&#345;ij&iacute;m&aacute;te.</span></li>
            <li class="c11"><span class="c19 c21">P&#345;ihl&aacute;&scaron;en&iacute;m do syst&eacute;mu pro p&#345;&iacute;jem zak&aacute;zek a spr&aacute;vu potvrzujete, &#382;e jste sezn&aacute;men/a s podm&iacute;nkami ochrany Osobn&iacute;ch &uacute;daj&#367; a &#382;e je v cel&eacute;m rozsahu p&#345;ij&iacute;m&aacute;te.</span></li>
            <li class="c11"><span class="c3">Odesl&aacute;n&iacute;m objedn&aacute;vky z Objedn&aacute;vkov&eacute;ho syst&eacute;mu potvrzujete, &#382;e jste sezn&aacute;men/a s podm&iacute;nkami ochrany Osobn&iacute;ch &uacute;daj&#367;, a &#382;e je v cel&eacute;m rozsahu p&#345;ij&iacute;m&aacute;te.</span></li>
            <li class="c11"><span class="c3">Potvrzen&iacute;m objedn&aacute;vky z Objedn&aacute;vkov&eacute;ho syst&eacute;mu potvrzujete, &#382;e jste sezn&aacute;men/a s podm&iacute;nkami ochrany Osobn&iacute;ch &uacute;daj&#367; a &#382;e je v cel&eacute;m rozsahu p&#345;ij&iacute;m&aacute;te.</span></li>
            <li class="c11"><span class="c3">Pr&aacute;va a povinnosti smluvn&iacute;ch stran p&#345;i zpracov&aacute;n&iacute; Osobn&iacute;ch &uacute;daj&#367; P&#345;&iacute;jemc&#367;, Odes&iacute;latel&#367; a Kur&yacute;r&#367; Z&aacute;silek jsou upraveny Smlouvou o zpravov&aacute;n&iacute; osobn&iacute;ch &uacute;daj&#367;, kter&aacute; je p&#345;&iacute;lohou &#269;. 2 t&#283;chto Obchodn&iacute;ch podm&iacute;nek.</span></li>
            <li class="c11"><span class="c3">Pokud je jak&eacute;koli ustanoven&iacute; podm&iacute;nek nebo jej&iacute; &#269;&aacute;st pova&#382;ov&aacute;na z jak&eacute;hokoli d&#367;vodu za neplatnou, bude pro dan&yacute; &uacute;&#269;el pova&#382;ov&aacute;na za vypu&scaron;t&#283;nou. Tato skute&#269;nost nem&aacute; vliv na platnost zb&yacute;vaj&iacute;c&iacute;ch &#269;&aacute;st&iacute; podm&iacute;nek.</span></li>
            <li class="c11"><span class="c3">Spr&aacute;vce je opr&aacute;vn&#283;n tyto podm&iacute;nky zm&#283;nit. Novou verzi podm&iacute;nek ochrany Osobn&iacute;ch &uacute;daj&#367; zve&#345;ejn&iacute; na sv&yacute;ch internetov&yacute;ch str&aacute;nk&aacute;ch.</span></li>
        </ol>
        <p class="c22" id="h.gjdgxs"><span class="c20">Tyto podm&iacute;nky nab&yacute;vaj&iacute; &uacute;&#269;innosti dnem 31.1.2021</span></p>
        <p class="c17"><span class="c9"></span></p>
    </div>
</main>
<app-footer mode='lite'></app-footer>
