<div class="modal-header">
    <h4 class="modal-title pull-left primary-color">{{courier.username}}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body grey-color">
    <form (submit)='onSubmitClick()' [formGroup]='form' [class.submitted]='submitted' novalidate class="form">
        <div class="form-group">
            <label>E-mail</label>
            <input formControlName='email' type="email" class="form-control">
            <div *ngIf='submitted && email.invalid' class="invalid-feedback">
                Zadejte prosím platný email.
            </div>
        </div>
        <div class="form-group">
            <label>Jméno a příjmení</label>
            <input formControlName='username' type="text" class="form-control">
            <div *ngIf='submitted && username.invalid' class="invalid-feedback">
                Zadejte prosím jméno a příjmení.
            </div>
        </div>
        <div class="form-group form-group--phone">
            <label>Telefon</label>
            <input formControlName='phone' type="number" class="form-control">
            <div *ngIf='submitted && phone.invalid' class="invalid-feedback">
                Zadejte prosím platné telefonní číslo.
            </div>
        </div>
        <div class="form-group">
            <label>Úložný prostor vozidla v litrech</label>
            <input formControlName='maxVolume' type="number" class="form-control" pattern="[0-9]*">
            <div *ngIf='submitted && maxVolume.invalid' class="invalid-feedback">
                Zadejte prosím úložný prostor vozidla.
            </div>
        </div>
        <div class="form-group">
            <label>Maximální váha nákladu v kg</label>
            <input formControlName='maxWeight' type="number" class="form-control" pattern="[0-9]*">
            <div *ngIf='submitted && maxWeight.invalid' class="invalid-feedback">
                Zadejte prosím maximální váhu nákladu.
            </div>
        </div>
        <div class="form-group">
            <label>Oblast rozvozu</label>
            <input formControlName='distributionAreas' type="text" class="form-control">
            <div *ngIf='submitted && distributionAreas.invalid' class="invalid-feedback">
                Zadejte prosím oblast rozvozu.
            </div>
        </div>
        <div class="form-group">
            <label>IČ</label>
            <input formControlName='ic' type="number" class="form-control" pattern="[0-9]*">
            <div *ngIf='submitted && ic.invalid' class="invalid-feedback">
                Zadejte prosím IČ.
            </div>
        </div>
        <div class="form-group">
            <label>DIČ</label>
            <input formControlName='dic' type="text" class="form-control">
        </div>
        <div class="form-group">
            <label>Firma</label>
            <input formControlName='company' type="text" class="form-control">
        </div>
        <div class="form-group">
            <label>Bankovní účet</label>
            <input formControlName='bankAccount' type="text" class="form-control">
        </div>
        <div class="form-group">
            <label>Ulice a číslo domu</label>
            <input formControlName='address' type="text" class="form-control">
        </div>
        <div class="form-group">
            <label>Město</label>
            <input formControlName='city' type="text" class="form-control">
        </div>
        <div class="form-group">
            <label>PSČ</label>
            <input formControlName='zipCode' type="number" class="form-control">
            <div *ngIf='submitted && zipCode.invalid' class="invalid-feedback">
                Zadejte prosím platné PSČ.
            </div>
        </div>
        <div *ngIf='error' class='form-error-message'>
            {{error}}
        </div>
        <div class="d-flex">
            <input type="submit" class="btn btn--primary" value="Uložit" />
        </div>
    </form>
</div>
