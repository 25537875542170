<div class="modal-header">
    <h4 class="modal-title pull-left primary-color">Zásilka {{order.packageTrackId}}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body grey-color">
    <div class='row'>
        <div class='col-8'>
            <p><strong>{{order.userEmail}}</strong><br/><small>Vytvořeno {{order.createdDate | date: "d.M.yyyy H:mm"}}, poslední změna {{order.lastModifiedDate | date: "d.M.yyyy H:mm"}}</small></p>
        </div>
        <div class='col-4 text-right'>
            <button *ngIf='editMode' (click)='onSubmitClick()' class="btn btn--primary btn--small">Uložit</button>
            <button *ngIf='!editMode' (click)='onEditClick()' class="btn btn--primary btn--small">Upravit</button>
            <button *ngIf='!editMode' (click)='onDeleteClick()' class="btn btn--danger btn--small ml-3">Smazat</button>
        </div>
    </div>
    <form (submit)='onSubmitClick()' [formGroup]='form' [class.submitted]='submitted' novalidate class="form">
        <div class="row payment-info">
            <div class="col-6">
                <h6>Stav platby:&nbsp;{{order.payment ? $any(STRINGS.paymentStatus)[order.payment.state] : 'Neznámý'}}
                    <ng-container *ngIf='order.finalDeliveryPrice'>
                        <br/>Konečná cena: {{order.finalDeliveryPrice}}&nbsp;Kč
                    </ng-container>
                </h6>
            </div>
            <div class="col-6 text-right">
                <a class="underline" *ngIf='order.payment' (click)='onCheckPaymentClick()'>Zkontrolovat stav</a>
                <a class="underline" *ngIf='order.payment && order.payment.state === GopayState.AUTHORIZED' (click)='onCapturePaymentClick()'>Strhnout platbu</a>
            </div>
        </div>
        <div class='row'>
            <div class="col-12">
                <div class="form-group">
                    <label>Pojmenování zásilky</label>
                    <input formControlName='title' type="text" class="form-control" required>
                    <div *ngIf='submitted && title.invalid' class="invalid-feedback">
                        Zadejte prosím pojmenování zásilky.
                    </div>
                </div>
            </div>
        </div>
        <div class='row'>
            <div class="col-6">
                <div class="form-group">
                    <label>Datum převzetí</label>
                    <input formControlName='pickUpDate' type="text" class="form-control" bsDatepicker [bsConfig]="bsDatepickerConfig" required>
                    <div *ngIf='submitted && pickUpDate.invalid' class="invalid-feedback">
                        Zadejte prosím datum převzetí.
                    </div>
                </div>
                <div class="form-group">
                    <label>Dopravce</label>
                    <select formControlName='courierId' class="form-control">
                            <option *ngFor='let courier of couriers' [value]="courier.id">{{courier.username}}</option>
                    </select>
                </div>
                <div class="form-group">
                    <label>Váha zásilky</label>
                    <select formControlName='packageWeight' class="form-control" required>
                            <option [value]="PackageWeight.SMALL">{{STRINGS.packageWeight[PackageWeight.SMALL]}}</option>
                            <option [value]="PackageWeight.LARGE">{{STRINGS.packageWeight[PackageWeight.LARGE]}}</option>
                        </select>
                    <div *ngIf='submitted && packageWeight.invalid' class="invalid-feedback">
                        Zadejte prosím váhu zásilky.
                    </div>
                </div>
            </div>
            <div class="col-6">
                <div class="form-group">
                    <label>Stav</label>
                    <select formControlName='status' class="form-control">
                                <option [value]="PackageStatus.WAITING">{{STRINGS.packageStatus[PackageStatus.WAITING]}}</option>
                                <option [value]="PackageStatus.COURIER_PLANNED">{{STRINGS.packageStatus[PackageStatus.COURIER_PLANNED]}}</option>
                                <option [value]="PackageStatus.COURIER_TAKEN">{{STRINGS.packageStatus[PackageStatus.COURIER_TAKEN]}}</option>
                                <option [value]="PackageStatus.DEPO_TAKEN">{{STRINGS.packageStatus[PackageStatus.DEPO_TAKEN]}}</option>
                                <option [value]="PackageStatus.EN_ROUTE_TO_CUSTOMER">{{STRINGS.packageStatus[PackageStatus.EN_ROUTE_TO_CUSTOMER]}}</option>
                                <option [value]="PackageStatus.DELIVERED">{{STRINGS.packageStatus[PackageStatus.DELIVERED]}}</option>
                                <option [value]="PackageStatus.DECLINED_OVERSIZED">{{STRINGS.packageStatus[PackageStatus.DECLINED_OVERSIZED]}}</option>
                                <option [value]="PackageStatus.DECLINED_PROHIBITED">{{STRINGS.packageStatus[PackageStatus.DECLINED_PROHIBITED]}}</option>
                                <option [value]="PackageStatus.DECLINED_SENDER_NOT_PRESENT">{{STRINGS.packageStatus[PackageStatus.DECLINED_SENDER_NOT_PRESENT]}}</option>
                                <option [value]="PackageStatus.DECLINED">{{STRINGS.packageStatus[PackageStatus.DECLINED]}}</option>
                                <option [value]="PackageStatus.CUSTOMER_DECLINED">{{STRINGS.packageStatus[PackageStatus.CUSTOMER_DECLINED]}}</option>
                        </select>
                </div>
                <div class="form-group">
                    <label>Rozměry zásilky</label>
                    <select formControlName='packageSize' class="form-control" required>
                            <option [value]="PackageSize.SMALL">{{STRINGS.packageSize[PackageSize.SMALL]}}</option>
                            <option [value]="PackageSize.LARGE">{{STRINGS.packageSize[PackageSize.LARGE]}}</option>
                        </select>
                    <div *ngIf='submitted && packageSize.invalid' class="invalid-feedback">
                        Zadejte prosím rozměry zásilky.
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col-6">
                <h5>Odesílatel</h5>
                <div class="form-group">
                    <input formControlName='senderName' type="text" class="form-control" required>
                    <div *ngIf='submitted && senderName.invalid' class="invalid-feedback">
                        Zadejte prosím jméno a příjmení.
                    </div>
                </div>
                <div class="form-group form-group--phone">
                    <label>Telefon</label>
                    <input formControlName='senderPhone' type="number" class="form-control" required>
                    <div *ngIf='submitted && senderPhone.invalid' class="invalid-feedback">
                        Zadejte prosím platné telefonní číslo.
                    </div>
                </div>
                <div class="form-group">
                    <label>Ulice a číslo domu</label>
                    <input formControlName='addressFrom' type="text" class="form-control" required>
                    <div *ngIf='submitted && addressFrom.invalid' class="invalid-feedback">
                        Zadejte prosím ulici a číslo domu.
                    </div>
                </div>
                <div class="form-group">
                    <label>Město</label>
                    <input formControlName='cityFrom' type="text" class="form-control" required>
                    <div *ngIf='submitted && cityFrom.invalid' class="invalid-feedback">
                        Zadejte prosím město.
                    </div>
                </div>
                <div class="form-group">
                    <label>PSČ</label>
                    <input formControlName='zipCodeFrom' type="number" class="form-control" required>
                    <div *ngIf='submitted && zipCodeFrom.invalid' class="invalid-feedback">
                        Zadejte prosím platné PSČ.
                    </div>
                </div>
            </div>
            <div class="col-6">
                <h5> Příjemce </h5>
                <div class="form-group">
                    <input formControlName='receiverName' type="text" class="form-control" required>
                    <div *ngIf='submitted && receiverName.invalid' class="invalid-feedback">
                        Zadejte prosím jméno a příjmení.
                    </div>
                </div>
                <div class="form-group form-group--phone">
                    <label>Telefon</label>
                    <input formControlName='receiverPhone' type="number" class="form-control" required>
                    <div *ngIf='submitted && receiverPhone.invalid' class="invalid-feedback">
                        Zadejte prosím platné telefonní číslo.
                    </div>
                </div>
                <div class="form-group">
                    <label>Ulice a číslo domu</label>
                    <input formControlName='addressTo' type="text" class="form-control" required>
                    <div *ngIf='submitted && addressTo.invalid' class="invalid-feedback">
                        Zadejte prosím ulici a číslo domu.
                    </div>
                </div>
                <div class="form-group">
                    <label>Město</label>
                    <input formControlName='cityTo' type="text" class="form-control" required>
                    <div *ngIf='submitted && cityTo.invalid' class="invalid-feedback">
                        Zadejte prosím město.
                    </div>
                </div>
                <div class="form-group">
                    <label>PSČ</label>
                    <input formControlName='zipCodeTo' type="number" class="form-control" required>
                    <div *ngIf='submitted && zipCodeTo.invalid' class="invalid-feedback">
                        Zadejte prosím platné PSČ.
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col-12">
                <h5>Fotografie</h5>
                <div class='admin-order-photos'>
                    <div (click)='onPhotoClick(photo)' *ngFor='let photo of order.files'>
                        <img [src]='photo.url' />
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf='error' class='form-error-message'>
            {{error}}
        </div>
    </form>
</div>
