import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PackageNewComponent } from './pages/package/package-new/package-new.component';
import { RootComponent } from './pages/root/root.component';
import { PackageDoneComponent } from './pages/package/package-done/package-done.component';
import { SettingsComponent } from './pages/user/settings/settings.component';
import { PackageListComponent } from './pages/admin/package/package-list/package-list.component';
import { DeliverersComponent } from './pages/admin/deliverers/deliverers.component';
import { NotAdminGuard } from './guards/not-admin.guard';
import { CustomerGuard } from './guards/customer.guard';
import { AdminGuard } from './guards/admin.guard';
import { AdminContentResolverService } from './services/admin-content-resolver.service';
import { DeliverComponent } from './pages/deliver/deliver.component';
import { TermsComponent } from './pages/terms/terms.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { ComplaintsComponent } from './pages/complaints/complaints.component';
import { ActiveCustomerGuard } from './guards/active-customer.guard';
import { VerifyEmailComponent } from './pages/verify-email/verify-email.component';
import { NewPasswordComponent } from './pages/new-password/new-password.component';
import { OrderAvailableGuard } from './guards/order-available.guard';

const routes: Routes = [
    {
        path: 'send',
        canActivate: [NotAdminGuard],
        children: [
            { path: '', component: RootComponent },
            { path: 'package-new', component: PackageNewComponent, canActivate: [CustomerGuard, ActiveCustomerGuard, OrderAvailableGuard] },
            { path: 'package-done', component: PackageDoneComponent, canActivate: [CustomerGuard, ActiveCustomerGuard] },
            { path: 'settings', component: SettingsComponent, canActivate: [CustomerGuard] },
            { path: '**', redirectTo: '/send' },
        ]
    },
    { path: 'deliver', component: DeliverComponent, canActivate: [NotAdminGuard] },
    {
        path: 'admin',
        canActivate: [AdminGuard],
        resolve: [AdminContentResolverService],
        children: [
            { path: 'packages', component: PackageListComponent },
            { path: 'deliverers', component: DeliverersComponent },
            { path: '**', redirectTo: '/admin/packages' },
        ]
    },
    { path: 'terms', component: TermsComponent },
    { path: 'privacy-policy', component: PrivacyPolicyComponent },
    { path: 'complaints', component: ComplaintsComponent },
    { path: 'validate-email-token', component: VerifyEmailComponent },
    { path: 'new-password', component: NewPasswordComponent },
    { path: '**', redirectTo: '/send' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes,
        { scrollPositionRestoration: 'enabled' }
    )],
    exports: [RouterModule]
})
export class AppRoutingModule {

}
