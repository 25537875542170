import { Component } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { HelperService } from 'src/app/services/helper.service';
import { UserService } from 'src/app/services/user.service';

@Component({
    selector: 'app-customer-sign-up',
    templateUrl: './customer-sign-up.component.html',
    styleUrls: ['./customer-sign-up.component.scss']
})
export class CustomerSignUpComponent {

    public form: FormGroup = this.fb.group({
        name: ['', [Validators.required]],
        email: ['', [Validators.required, this.helperService.emailValidator]],
        phone: ['', [Validators.required, Validators.pattern(/^\d{9}$/)]],
        password: ['', [Validators.required, Validators.minLength(6)]],
        passwordCheck: ['', [Validators.required]],
    });
    public submitted: boolean = false;
    public error: undefined | string;

    public get name(): AbstractControl { return this.form.get('name') as AbstractControl }
    public get email(): AbstractControl { return this.form.get('email') as AbstractControl }
    public get phone(): AbstractControl { return this.form.get('phone') as AbstractControl }
    public get password(): AbstractControl { return this.form.get('password') as AbstractControl }
    public get passwordCheck(): AbstractControl { return this.form.get('passwordCheck') as AbstractControl }

    constructor(
        public userService: UserService,
        public modalRef: BsModalRef,
        private fb: FormBuilder,
        private helperService: HelperService,
    ) {
    }

    public onSubmitClick(): void {
        this.error = undefined;
        this.submitted = true;
        if (!this.form.valid || this.form.value.password !== this.form.value.passwordCheck) {
            return
        }
        this.signUp();
    }

    private async signUp(): Promise<void> {
        this.submitted = false;
        try {
            await this.userService.customerSignUp({
                ...this.form.value,
                phone: '+420' + this.form.value.phone,
            });
        } catch (err) {
            this.error = err.message;
            return;
        }
        this.modalRef.hide();
    }
}
