import { Injectable } from '@angular/core';
import { Order } from '../models/order';
import { Package } from '../models/package';
import { ApiService } from './api.service';
import { ErrorHandlerService } from './error-handler.service';
import { LoadingService } from './loading.service';
import { UserService } from './user.service';
import { Payment } from '../models/payment';
import { GopayState } from '../models/gopay-state';
import { Router } from '@angular/router';
import { AlertService } from './alert.service';
import { DateTime } from 'luxon';
import { HelperService } from './helper.service';

@Injectable({
    providedIn: 'root'
}) export class OrderService {


    constructor(
        private apiService: ApiService,
        private loadingService: LoadingService,
        private errorHandlerService: ErrorHandlerService,
        private userService: UserService,
        private router: Router,
        private alertService: AlertService,
        private helperService: HelperService,
    ) {
    }

    public async getPackage(id: number): Promise<Package | undefined> {
        this.loadingService.loading$.next(true);
        let resp: Package;
        try {
            resp = await this.apiService.customerGetPackage(id).toPromise();
        } catch (err) {
            this.errorHandlerService.handleApiError(err);
            return;
        } finally {
            this.loadingService.loading$.next(false);
        }
        return resp;
    }

    public async getPackages(page: number, limit: number): Promise<Package[]> {
        this.loadingService.loading$.next(true);
        const offset = (page - 1) * limit;
        const result: Package[] = [];
        try {
            const { data, total } = await this.apiService.customerGetPackages(offset, limit).toPromise();
            if (offset) {
                result.push(...(new Array(offset).fill({})));
            }
            result.push(...data);
            if (result.length < total) {
                result.push(...(new Array(total - result.length).fill({})));
            }
        } catch (err) {
            this.errorHandlerService.handleApiError(err);
        } finally {
            this.loadingService.loading$.next(false);
        }
        return result;
    }

    public async placeOrder(order: Order, file: File): Promise<void> {
        const user = this.userService.user;
        if (!user) {
            return;
        }
        this.loadingService.loading$.next(true);
        let pkg: Package;
        try {
            pkg = await this.apiService.customerPlaceOrder(user, order, file).toPromise();
        } catch (err) {
            this.errorHandlerService.handleApiError(err);
            return;
        } finally {
            this.loadingService.loading$.next(false);
        }
        this.payOrder(pkg)
    }

    public async payOrder(pkg: Package): Promise<void> {
        this.loadingService.loading$.next(true);
        try {
            let updatedPayment: Payment;
            if (pkg.payment) {
                updatedPayment = await this.apiService.checkPaymentStatus(pkg.payment.id).toPromise();
            } else {
                updatedPayment = await this.apiService.customerCreatePayment(pkg.id).toPromise();
            }
            if (updatedPayment.state === GopayState.CANCELED
                || updatedPayment.state === GopayState.TIMEOUTED) {
                updatedPayment = await this.apiService.customerRecreatePayment(pkg.id).toPromise();
            }
            if (updatedPayment.state === GopayState.AUTHORIZED
                || updatedPayment.state === GopayState.PAID) {
                this.router.navigateByUrl(`/send/package-done?id=${updatedPayment.id}&packageId=${pkg.id}`)
                return;
            }
            this.openPaygate(updatedPayment.gw_url);
        } catch (err) {
            try {
                this.errorHandlerService.handleApiError(err);
            } catch (err) {
                this.alertService.presentToast(err.message, 'danger');
            }
        } finally {
            this.loadingService.loading$.next(false);
        }
    }

    public async checkPaymentStatus(paymentId: string): Promise<Payment | undefined> {
        this.loadingService.loading$.next(true);
        let result: Payment;
        try {
            result = await this.apiService.checkPaymentStatus(paymentId).toPromise();
        } catch (err) {
            this.errorHandlerService.handleApiError(err);
            return;
        } finally {
            this.loadingService.loading$.next(false);
        }
        return result;
    }

    public async estimatePrice(order: Order): Promise<string | undefined> {
        const user = this.userService.user;
        if (!user) {
            return;
        }
        this.loadingService.loading$.next(true);
        let result: string;
        try {
            result = await this.apiService.customerGetEstimatedPrice(user.id, order).toPromise();
        } catch (err) {
            this.errorHandlerService.handleApiError(err);
            return;
        } finally {
            this.loadingService.loading$.next(false);
        }
        return result;
    }

    public computeAvailableDates(fromDate: DateTime = DateTime.local().startOf('day')): Date[] {
        const availableDatetimes: DateTime[] = [];
        // objednavky na max +3 dny
        availableDatetimes.push(fromDate.plus({ days: 1 }));
        availableDatetimes.push(fromDate.plus({ days: 2 }));
        availableDatetimes.push(fromDate.plus({ days: 3 }));
        const availableDates = availableDatetimes
            .filter(datetime => this.helperService.isBussinessDay(datetime))
            .map(datetime => datetime.toJSDate());
        return availableDates;
    }

    public computeNextAvailableOrderDate(): string {
        let current = DateTime.local().startOf('day');
        let availabeDates: Date[] = [];
        while (availabeDates.length === 0) {
            current = current.plus({ days: 1 });
            availabeDates = this.computeAvailableDates(current);
        }
        return current.toISO();
    }

    private openPaygate(gatewayUrl: string): void {
        window.location.href = gatewayUrl;
    }
}
