<div class="modal-header">
    <h4 class="modal-title pull-left primary-color">Vytvořit účet</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body  grey-color">
    <form (submit)='onSubmitClick()' [formGroup]='form' novalidate autocomplete="off" [class.submitted]='submitted'>
        <div class="form-group">
            <label>Celé jméno</label>
            <input formControlName='name' type="text" class="form-control" required>
            <div *ngIf='submitted && name.invalid' class="invalid-feedback">
                Zadejte prosím své jméno.
            </div>
        </div>
        <div class="form-group">
            <label>E-mail</label>
            <input formControlName='email' type="email" class="form-control" required>
            <div *ngIf='submitted && email.invalid' class="invalid-feedback">
                Zadejte prosím platný email.
            </div>
        </div>
        <div class="form-group form-group--phone">
            <label>Telefon</label>
            <input formControlName='phone' type="number" class="form-control" required>
            <div *ngIf='submitted && phone.invalid' class="invalid-feedback">
                Zadejte prosím platné telefonní číslo.
            </div>
        </div>
        <div class="form-group">
            <label>Heslo</label>
            <input formControlName='password' type="password" class="form-control" autocomplete='new-password' required>
            <div *ngIf='submitted && password.invalid' class="invalid-feedback">
                Zadejte prosím heslo o alespoň 6 znacích.
            </div>
        </div>
        <div class="form-group">
            <label>Heslo pro kontrolu</label>
            <input formControlName='passwordCheck' type="password" class="form-control" autocomplete='new-password' required>
            <div *ngIf='submitted && passwordCheck.invalid' class="invalid-feedback">
                Zadejte prosím heslo pro kontrolu.
            </div>
            <div *ngIf='submitted && password.valid && passwordCheck.valid && password.value !== passwordCheck.value' class="invalid-feedback">
                Hesla se neshodují.
            </div>
        </div>
        <div *ngIf='error' class='form-error-message'>
            {{error}}
        </div>
        <div class="d-flex">
            <input type="submit" class="btn btn--primary" value='Vytvořit účet' />
        </div>
    </form>
</div>
