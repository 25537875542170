<td>{{courier.username}}</td>
<td class="text-center">{{ courier.nextFiveDaysPackageCount && courier.nextFiveDaysPackageCount[0] }}</td>
<td class="text-center">{{ courier.nextFiveDaysPackageCount && courier.nextFiveDaysPackageCount[1] }}</td>
<td class="text-center">{{ courier.nextFiveDaysPackageCount && courier.nextFiveDaysPackageCount[2] }}</td>
<td class="text-center">{{ courier.nextFiveDaysPackageCount && courier.nextFiveDaysPackageCount[3] }}</td>
<td class="text-center">{{ courier.nextFiveDaysPackageCount && courier.nextFiveDaysPackageCount[4] }}</td>
<!-- <td><a (click)='onShowTimetableClick()' class='underline'>Zobrazit harmonogram</a></td> -->
<td>{{courier.phone | phone}}</td>
<td>{{courier.email}}</td>
<td>{{courier.createdDate | date:'d.M.yyyy'}}</td>
<td class="text-center">
    <div class="btn-group" dropdown>
        <button id="button-basic" dropdownToggle type="button" class="btn btn--primary dropdown-toggle" aria-controls="dropdown-basic">
                <i class="fas fa-ellipsis-h"></i>
            </button>
        <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
            <li role="menuitem"><a (click)='onEditProfileClick()' class="dropdown-item">Upravit údaje</a></li>
            <li role="menuitem"><a (click)='onDisableAccountClick()' class="dropdown-item">Deaktivovat účet</a></li>
        </ul>
    </div>
</td>
