import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Theme } from 'src/app/models/theme';
import { AlertService } from 'src/app/services/alert.service';
import { UserService } from 'src/app/services/user.service';

@Component({
    selector: 'app-verify-email',
    templateUrl: './verify-email.component.html',
    styleUrls: ['./verify-email.component.scss']
})
export class VerifyEmailComponent implements OnInit {

    public error: string = '';
    public success: boolean = false;
    public app: string = '';

    constructor(
        private activatedRoute: ActivatedRoute,
        private userService: UserService,
        private router: Router,
        private alertService: AlertService,
    ) { }

    public async ngOnInit(): Promise<void> {
        const token = this.activatedRoute.snapshot.queryParams.token;
        this.app = this.activatedRoute.snapshot.queryParams.app;
        if (this.app === 'COURIER') {
            document.body.classList.add(Theme.DELIVERER);
        }
        try {
            await this.userService.userVerifyEmail(token);
        } catch (err) {
            console.log('error', err);
            this.error = err.message;
            return;
        }
        this.success = true;
    }

    public async onContinueToSiteClick(): Promise<void> {
        document.body.classList.remove(Theme.DELIVERER);
        if (this.success && this.userService.user && this.app !== 'COURIER') {
            try {
                await this.userService.customerCheckEmailVerified();
                this.alertService.presentToast('Děkujeme! Registrace proběhla úspěšně.');
            } catch (err) {
            }
        }
        this.router.navigateByUrl('/');
    }

}
