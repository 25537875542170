import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { Theme } from './models/theme';
import { AlertService } from './services/alert.service';
import { AnalyticsService } from './services/analytics.service';
import { HelperService } from './services/helper.service';
import { LoadingService } from './services/loading.service';
import { UserService } from './services/user.service';
import localeCs from '@angular/common/locales/cs';
import { registerLocaleData } from '@angular/common';

const PAGE_THEME: { [page: string]: Theme } = {
    deliver: Theme.DELIVERER,
    default: Theme.CUSTOMER,
};
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {

    private themeClass: Theme = Theme.CUSTOMER;

    constructor(
        public loadingService: LoadingService,
        public alertService: AlertService,
        private userService: UserService,
        private analyticsService: AnalyticsService,
        private localeService: BsLocaleService,
        private router: Router,
        private helperService: HelperService,
    ) {
        this.analyticsService.initialize();

        registerLocaleData(localeCs, 'cs');
        this.localeService.use('cs');

        this.userService.initialize();

        this.router.events.subscribe(async (evt) => {
            if (evt instanceof NavigationEnd) {
                const bodyElement = document.body;
                if (bodyElement) {
                    bodyElement.classList.remove(this.themeClass);
                    const page = this.helperService.getPageFromUrl(evt.url);
                    this.themeClass = PAGE_THEME[page] || PAGE_THEME.default;
                    bodyElement.classList.add(this.themeClass);
                }
            }
        });

        this.alertService.presentAlert(
            'Nebalík.cz',
            'Připravujeme nové propojení s dopravním partnerem. Projekt bude spuštěný znovu na jaře 2022.',
            false,
        )
    }
}
