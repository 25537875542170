<td>{{courier.username}}</td>
<td>{{courier.createdDate | date:'d.M.yyyy H:mm'}}</td>
<!-- <td><a (click)='onShowTimetableClick()' class='underline'>Zobrazit harmonogram</a></td> -->
<td>{{courier.phone | phone}}</td>
<td>{{courier.email}}</td>
<td class="buttons">
    <button (click)='onApproveClick()' type="button" class="btn btn--primary">
            Aktivovat účet
        </button>
    <button (click)='onDeclineClick()' type="button" class="btn btn--grey">
            Zamítnout
    </button>
</td>
