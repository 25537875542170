import { Component } from '@angular/core';
import { FormGroup, Validators, AbstractControl, FormBuilder } from '@angular/forms';
import { HelperService } from 'src/app/services/helper.service';
import { UserService } from 'src/app/services/user.service';

@Component({
    selector: 'app-courier-resend-verification-email',
    templateUrl: './courier-resend-verification-email.component.html',
    styleUrls: ['./courier-resend-verification-email.component.scss']
})
export class CourierResendVerificationEmailComponent {

    public form: FormGroup = this.fb.group({
        email: ['', [Validators.required, this.helperService.emailValidator]],
    });
    public submitted: boolean = false;
    public error: undefined | string;

    public get email(): AbstractControl { return this.form.get('email') as AbstractControl }

    constructor(
        public userService: UserService,
        private fb: FormBuilder,
        private helperService: HelperService,
    ) {
    }

    public onSubmitClick(): void {
        this.error = undefined;
        this.submitted = true;
        if (!this.form.valid) {
            return;
        }
        this.resendVerificationEmail();
    }

    private async resendVerificationEmail(): Promise<void> {
        this.submitted = false;
        try {
            await this.userService.userResendVerificationEmail(this.form.value.email, 'COURIER');
        } catch (err) {
            this.error = err.message;
            return;
        }
        this.form.reset();
    }
}
