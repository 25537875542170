import { Component, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
    selector: 'app-terms-content',
    templateUrl: './terms-content.component.html',
    styleUrls: ['./terms-content.component.scss']
})
export class TermsContentComponent {

    @Input() public title!: string;
    @Input() public html!: string;

    constructor(
        public modalRef: BsModalRef,
    ) {
    }
}
