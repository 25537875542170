import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, AbstractControl, FormBuilder } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AdminService } from 'src/app/services/admin.service';
import { HelperService } from 'src/app/services/helper.service';

@Component({
    selector: 'app-admin-courier-add',
    templateUrl: './admin-courier-add.component.html',
    styleUrls: ['./admin-courier-add.component.scss']
})
export class AdminCourierAddComponent implements OnInit {

    public form: FormGroup = this.fb.group({
        name: ['', [Validators.required]],
        email: ['', [Validators.required, this.helperService.emailValidator]],
        phone: ['', [Validators.required, Validators.pattern(/^\d{9}$/)]],
        password: ['', [Validators.required, Validators.minLength(6)]],
    });
    public submitted: boolean = false;
    public error: undefined | string;

    public get name(): AbstractControl { return this.form.get('name') as AbstractControl }
    public get email(): AbstractControl { return this.form.get('email') as AbstractControl }
    public get phone(): AbstractControl { return this.form.get('phone') as AbstractControl }
    public get password(): AbstractControl { return this.form.get('password') as AbstractControl }

    constructor(
        public adminService: AdminService,
        public modalRef: BsModalRef,
        private fb: FormBuilder,
        private helperService: HelperService,
    ) {
    }

    public ngOnInit(): void {
    }

    public onSubmitClick(): void {
        this.error = undefined;
        this.submitted = true;
        if (!this.form.valid) {
            return;
        }
        this.updateCourier();
    }

    private async updateCourier(): Promise<void> {
        this.submitted = false;
        try {
            await this.adminService.addCourier(this.form.value);
        } catch (err) {
            this.error = err.message;
            return;
        }
        this.modalRef.hide();
    }
}
