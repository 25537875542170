import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { CustomerSignInComponent } from '../components/customer-sign-in/customer-sign-in.component';
import { UserRole } from '../models/user-role';
import { UserService } from '../services/user.service';

@Injectable({
    providedIn: 'root'
}) export class CustomerGuard implements CanActivate {


    constructor(
        public router: Router,
        private userService: UserService,
        private bsModalService: BsModalService,
    ) {

    }

    public canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const user = this.userService.user;
        if (!user && state.url.includes('package-new')) {
            this.bsModalService.show(CustomerSignInComponent, {
                initialState: {
                    note: 'Před zadáním objednávky se prosím nejprve přihlaste.'
                }
            });
            this.router.navigateByUrl('/send');
            return false;
        }
        if (user?.role !== UserRole.CUSTOMER) {
            this.router.navigateByUrl('/send');
            return false;
        }
        return true;
    }
}
