import { UserRole } from './user-role';
import { UserStatus } from './user-status';

export interface User {
    id: string;
    username: string;
    phone: string;
    email: string;
    createdDate: string;
    status: UserStatus;
    role: UserRole;
    address: {
        street: string;
        city: string;
        zip: string;
    };
    nextFiveDaysPackageCount: number[];
    maxVolume: number;
    maxWeight: number;
    distributionAreas: number[];
    ic: string;
    dic: string;
    bankAccount: string;
    company: string;
    verified: boolean;
}


export const dtoToUser = (dto: any): User => {
    return {
        id: dto.id,
        username: dto.username,
        email: dto.email,
        phone: dto.phone,
        address: {
            city: dto.city,
            street: dto.address,
            zip: dto.zipCode,
        },
        createdDate: dto.createdDate,
        role: dto.role,
        status: dto.status,
        nextFiveDaysPackageCount: dto.nextFiveDaysPackageCount,
        maxVolume: dto.maxVolume,
        maxWeight: dto.maxWeight,
        distributionAreas: dto.distributionAreas,
        ic: dto.ic,
        dic: dto.dic,
        bankAccount: dto.bankAccount,
        company: dto.company,
        verified: dto.isEmailVerified,
    }
}
