<div class="modal-header">
    <h4 class="modal-title pull-left primary-color">Business přihlášení</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body  grey-color">
    <p>
        Business přihlášení je určené výhradně pro dispečery. <br/>
        Pokud jste kurýr, použijte, prosím, <a (click)='modalRef.hide()' routerLink='/deliver' fragment='app' class='underline'><strong>Mobilní aplikaci</strong></a>.
    </p>
    <form (submit)='onSubmitClick()' [formGroup]='form' [class.submitted]='submitted' novalidate>
        <div class="form-group">
            <label>E-mail</label>
            <input formControlName='email' type="email" class="form-control" required>
            <div *ngIf='submitted && email.invalid' class="invalid-feedback">
                Zadejte prosím platný email.
            </div>
        </div>
        <div class="form-group">
            <label>Heslo 
               <!--  <span><a (click)='onForgotPasswordClick()' class='underline'>Zapomenuté heslo</a></span> -->
            </label>
            <input formControlName='password' type="password" class="form-control" required>
            <div *ngIf='submitted && password.invalid' class="invalid-feedback">
                Zadejte prosím heslo.
            </div>
        </div>
        <div *ngIf='error' class='form-error-message'>
            {{error}}
        </div>
        <div class="d-flex">
            <input type="submit" class="btn btn--primary" value="Přihlásit se">
        </div>
    </form>
</div>
