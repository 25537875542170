<footer [class.footer--full]='mode === "full"' [class.footer--lite]='mode === "lite"'>
    <div *ngIf='mode === "full"' class="container grey-color">
        <ng-container *ngIf="userIsCustomerOrAnonymous | async">
            <div class="row">
                <div class="col-lg-3">
                    <h5>Užitečné odkazy</h5>
                    <p>
                        <a routerLink='/send/package-new' class='underline'>Poslat zásilku</a><br/>
                        <a routerLink='/deliver' class='underline'>Pro Dopravce</a><br/>
                        <a href='/static/Kompletni-cenik.pdf' class='underline' target='_blank'>Kompletní ceník</a><br/>
                        <a routerLink='/complaints' class='underline'>Reklamační řád</a><br/>
                        <a (click)='onAboutUsClick()' class='underline'>O nás</a>
                    </p>
                </div>
                <div class="col-lg-3">
                    <h5>Rychlý Kontakt</h5>
                    <p>
                        <a href="tel:+420728611618">+420 728 611 618</a><br/>
                        <a href="mailto:info@nebalik.cz" target='_blank'>info@nebalik.cz</a>
                    </p>
                    <!-- <p>
                        <a class='underline'>Další kontakty</a>
                    </p> -->
                </div>
                <div class="col-lg-3">
                    <h5>Provozovatel</h5>
                    <p>
                        Evolution Concept s.r.o<br/> Jugoslávská 659/11<br/> Praha 2, 120 00<br/> IČ: 05760402<br/> DIČ: CZ05760402
                    </p>
                </div>
                <div class="col-lg-3">
                    <h5>Sociální sítě</h5>
                    <p class="socials">
                        <a>
                            <i class="fab fa-facebook-f"></i>
                        </a>
                        <a>
                            <i class="fab fa-twitter"></i>
                        </a>
                        <a>
                            <i class="fab fa-linkedin-in"></i>
                        </a>
                        <a>
                            <i class="fab fa-medium"></i>
                        </a>
                    </p>
                </div>
            </div>
        </ng-container>
    </div>
    <nav class="navbar background-primary">
        <div [class.container]="userIsCustomerOrAnonymous | async" [class.container-fluid]="userIsAdmin | async">
            <div class='footer-attribution'>
                <p *ngIf='attribution==="customer"'>V roce 2020 získala firma Evolution Concept s.r.o. podporu z Evropských strukturálních a investičních fondů, realizovanou skrz Operační program Praha - pól růstu ČR a čerpanou přes druhou výzvu Specializovaných voucherů (reg.č. CZ.07.1.02/0.0/0.0/16_027/0000607).
                    Podpora byla mířena na účast firmy Evolution Concept s.r.o. na projekt s názvem „Webová stránka pro přepravu zásilek“ na adrese „Jugoslávská 659/11, Praha 2, 120 00“ za účelem vytvoření webové stránky. Očekávaným výstupem je webová
                    stránka včetně online objednání zásilky, back-endové rozhraní s databází a určitý počet registrací a objednávek přepravy zásilek. Projekt „Nebalík“ je spolufinancován Evropskou unií.</p>
                <p *ngIf='attribution==="courier"'>V roce 2020 získala firma Evolution Concept s.r.o. podporu z Evropských strukturálních a investičních fondů, realizovanou skrz Operační program Praha - pól růstu ČR a čerpanou přes druhou výzvu Specializovaných voucherů (reg.č. CZ.07.1.02/0.0/0.0/16_027/0000607).
                    Podpora byla mířena na účast firmy Evolution Concept s.r.o. na projekt s názvem „Webová stránka pro síť kurýrů“ na adrese „Jugoslávská 659/11, Praha 2, 120 00“ za účelem vytvoření webové stránky. Očekávaným výstupem je webová stránka
                    s funkčním procesem registrace, administrační sekcí pro dispečery, zabezpečenou sekcí pro přihlášení kurýrů a určitým počtem registrací a návštěv. Projekt „Nebalík“ je spolufinancován Evropskou unií.</p>
                <img src='assets/images/logo_eu.jpg'>
            </div>
            <p>© 2021 Evolution Concept s.r.o Všechna práva vyhrazena</p>
            <ul *ngIf="(userIsCustomerOrAnonymous | async) else admin">
                <li><a routerLink='/terms' class='link--alt'>Všeobecné obchodní podmínky</a></li>
                <li><a routerLink='/privacy-policy' class='link--alt'>Zásady ochrany osobních údajů</a></li>
            </ul>
            <ng-template #admin>
                <ul>
                    <li><a (click)='onSupportLinkClick()' class='link--alt'>Technická podpora</a></li>
                    <li><a (click)="onSignOutClick()" class='link--alt'>Pokračovat zpět na web</a></li>
                </ul>
            </ng-template>
        </div>
    </nav>
</footer>
