import { Component, Input } from '@angular/core';
import { User } from 'src/app/models/user';
import { AdminService } from 'src/app/services/admin.service';

@Component({
    selector: '[app-admin-courier-declined]',
    templateUrl: './admin-courier-declined.component.html',
    styleUrls: ['./admin-courier-declined.component.scss']
})
export class AdminCourierDeclinedComponent {

    @Input() public courier!: User;

    constructor(
        private adminService: AdminService,
    ) {
    }

    public onActivateClick(): void {
        this.adminService.approveCourier(this.courier.id);
    }
}
