export enum PackageStatus {
    WAITING = 'WAITING',
    COURIER_PLANNED = 'COURIER_PLANNED',
    COURIER_TAKEN = 'COURIER_TAKEN',
    DEPO_TAKEN = 'DEPO_TAKEN',
    EN_ROUTE_TO_CUSTOMER = 'DEPO_ON_THE_WAY',
    DELIVERED = 'DELIVERED',
    DECLINED_SENDER_NOT_PRESENT = 'DECLINED_SENDER_NOT_PRESENT',
    DECLINED_OVERSIZED = 'DECLINED_OVERSIZED',
    DECLINED_PROHIBITED = 'DECLINED_PROHIBITED',
    CUSTOMER_DECLINED = 'DECLINED_BY_CUSTOMER',
    DECLINED = 'DECLINED',
}
