import { Component, Input, OnInit } from '@angular/core';
import { Package } from 'src/app/models/package';
import { PackageStatus } from 'src/app/models/package-status';
import { AdminService } from 'src/app/services/admin.service';
import { STRINGS } from 'src/assets/strings';
import { User } from 'src/app/models/user';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AdminSetPriceComponent } from '../admin-set-price/admin-set-price.component';
import { AdminOrderEditComponent } from '../admin-order-edit/admin-order-edit.component';
import { GopayState } from 'src/app/models/gopay-state';
import { AlertService } from 'src/app/services/alert.service';

@Component({
    selector: '[app-admin-order]',
    templateUrl: './admin-order.component.html',
    styleUrls: ['./admin-order.component.scss']
})
export class AdminOrderComponent implements OnInit {

    @Input() public order!: Package;
    @Input() public couriers!: User[];

    public initialState!: Package;
    public PackageStatus: typeof PackageStatus = PackageStatus;
    public STRINGS: typeof STRINGS = STRINGS;
    public GopayState: typeof GopayState = GopayState;
    public courierName: string = '';

    constructor(
        private adminService: AdminService,
        private bsModalService: BsModalService,
        private alertService: AlertService,
    ) {
    }

    public ngOnInit(): void {
        if (!this.order.id) {
            return;
        }
        this.courierName = this.order.courierName || 'Nepřiřazen';
        this.initialState = JSON.parse(JSON.stringify(this.order));
    }

    public onPackageStatusChange(status: PackageStatus): void {
        this.confirmStatusChange(
            () => this.adminService.updateOrderStatus(this.order.id, status),
            () => this.order = JSON.parse(JSON.stringify(this.initialState))
        );
    }

    public onCourierChange(newCourierId: string): void {
        this.adminService.updateOrder(this.order.id, {
            courierId: newCourierId,
            courierName: this.couriers.find(it => it.id === newCourierId)?.username,
        });
    }

    public onSetFinalPriceClick(): void {
        this.bsModalService.show(AdminSetPriceComponent, {
            initialState: {
                order: this.order
            },
        });
    }

    public onDetailClick(): void {
        this.bsModalService.show(AdminOrderEditComponent, {
            initialState: {
                order: this.order,
                couriers: this.couriers,
            },
            class: 'modal-lg',
        });
    }

    private confirmStatusChange(onConfirm: () => void, onCancel: () => void): void {
        this.alertService.presentConfirm({
            title: 'Změna stavu',
            message: `Opravdu si přejete změnit stav zásilky?`,
            cancelText: 'Zpět',
            onConfirm,
            onCancel,
        });
    }
}
