<nav class="navbar fixed-top">
    <div class='container-fluid mr-4 ml-4'>
        <a routerLink='/' class="navbar-brand">
            <app-logo></app-logo>
        </a>
        <ul class="navbar-nav ml-auto">
            <li role="nav-item"><a class="btn btn--secondary" routerLink="/">Zpět na web</a></li>
        </ul>
    </div>
</nav>
<main>
    <section class="section section--top background-primary">
        <div class="container-fluid ml-4">
            <h2 class="font-normal">Reklamační řád služby Nebalík.cz</h2>
            <p>&nbsp;</p>
        </div>
    </section>
    <div class="c27">
        <p class="c14"><span class="c2"></span></p>
        <p class="c21"><span class="c8">Na&scaron;&iacute;m c&iacute;lem je zprost&#345;edkovat v&#382;dy perfektn&iacute; p&#345;epravn&iacute; slu&#382;by. Pro mal&eacute; procento objedn&aacute;vek, kdy se tak nestalo, je tady na&scaron;e&nbsp;</span><span class="c20"><a class="c15" href="mailto:info@nebalik.cz" target='_system'>z&aacute;kaznick&aacute; podpora</a></span>
            <span
                class="c8">, kter&aacute; v&aacute;s bude zastupovat v &#345;&iacute;zen&iacute; s dan&yacute;m P&#345;epravcem. Stoj&iacute;me na va&scaron;i stran&#283; a pro co nejrychlej&scaron;&iacute; a &uacute;sp&#283;&scaron;nou reklamaci se pros&iacute;m &#345;i&#271;te
                t&iacute;mto &#345;&aacute;dem. Tento&nbsp;</span><span class="c19">Reklama&#269;n&iacute; &#345;&aacute;d</span><span class="c8">&nbsp;dopl&#328;uje&nbsp;</span><span class="c20"><a class="c15" routerLink="/terms">V&scaron;eobecn&eacute; obchodn&iacute; podm&iacute;nky</a></span>
                <span
                    class="c8">&nbsp;slu&#382;by Nebal&iacute;k.cz, provozovan&eacute; spole&#269;nost&iacute;&nbsp;</span><span class="c19">Evolution Concept s.r.o.</span><span class="c2">&nbsp;(I&#268; 05760402) a ve&scaron;ker&eacute; v&yacute;razy uveden&eacute; v tomto Reklama&#269;n&iacute;m &#345;&aacute;du s velk&yacute;m po&#269;&aacute;te&#269;n&iacute;m p&iacute;smenem maj&iacute; v&yacute;znam uveden&yacute; v Podm&iacute;nk&aacute;ch.</span></p>
        <hr>
        <ol class="c4 lst-kix_list_1-0 start" start="1">
            <li class="c23 li-bullet-0"><span class="c12">D&#367;le&#382;it&aacute; ustanoven&iacute;</span></li>
        </ol>
        <ul class="c4 lst-kix_list_1-1 start">
            <li class="c7 li-bullet-0"><span class="c0">N&aacute;hrada &scaron;kody nen&iacute; mo&#382;n&aacute; v p&#345;&iacute;pad&#283; po&scaron;kozen&iacute; smaltovan&yacute;ch, &#269;i lakovan&yacute;ch povrch&#367; (odloupnut&iacute;, &scaron;kr&aacute;bance, od&#345;eniny). U z&aacute;silek s k&#345;ehk&yacute;m obsahem (sklo, porcel&aacute;n, l&aacute;hve, injekce, infuze atd.), pokud nedo&scaron;lo k po&scaron;kozen&iacute; obalu, nezodpov&iacute;d&aacute; P&#345;epravce za &scaron;kodu a to ani v p&#345;&iacute;pad&#283;, &#382;e t&iacute;m do&scaron;lo k &#269;&aacute;ste&#269;n&eacute;mu po&scaron;kozen&iacute; obsahu z&aacute;silky (nap&#345;. vylit&iacute;m &#269;&aacute;sti obsahu z&aacute;silky).</span></li>
            <li
                class="c7 li-bullet-0"><span class="c3">P&#345;epravci p&#345;ij&iacute;maj&iacute; odpov&#283;dnost pouze za skute&#269;nou p&#345;&iacute;mou &scaron;kodu. Nevztahuje se tedy na jak&eacute;koliv n&aacute;sledn&eacute; &scaron;kody. Odpov&#283;dnost za &scaron;kodu se&nbsp;</span>
                <span
                    class="c1">nevztahuje na &bdquo;u&scaron;l&yacute; zisk&ldquo;</span><span class="c0">.</span></li>
                    <li class="c7 li-bullet-0"><span class="c0">V p&#345;&iacute;pad&#283; hrub&eacute;ho poru&scaron;en&iacute; Obchodn&iacute;ch podm&iacute;nek, jako je nap&#345;&iacute;klad zas&iacute;l&aacute;n&iacute; zbo&#382;&iacute; nebezpe&#269;n&eacute; a n&aacute;sledn&yacute;m po&scaron;kozen&iacute;m jin&yacute;ch z&aacute;silek, &#269;i majetku Poskytovatele nebo P&#345;epravce, Poskytovatel si vyhrazuje pr&aacute;vo na n&aacute;hradu vznikl&eacute; &scaron;kody Objednavatelem, p&#345;&iacute;kazcem, z&aacute;kazn&iacute;kem.</span></li>
                    <li
                        class="c7 li-bullet-0"><span class="c3">Nelze reklamovat opo&#382;d&#283;n&eacute; vyzvednut&iacute; nebo opo&#382;d&#283;n&eacute; doru&#269;en&iacute; z&aacute;silky. Nelze reklamovat p&#345;epravu v p&#345;&iacute;pad&#283;, &#382;e p&#345;&iacute;jemce&nbsp;</span>
                        <span
                            class="c1">odm&iacute;tne z&aacute;silku p&#345;evz&iacute;t</span><span class="c3">&nbsp;nebo kv&#367;li&nbsp;</span><span class="c1">nezasti&#382;en&iacute; p&#345;&iacute;jemce</span><span class="c0">&nbsp;na jeho adrese.</span></li>
        </ul>
        <ol class="c4 lst-kix_list_1-0" start="2">
            <li class="c11 li-bullet-0"><span class="c12">Proces reklamace</span></li>
        </ol>
        <ul class="c4 lst-kix_list_1-1 start">
            <li class="c7 li-bullet-0"><span class="c3">Reklama&#269;n&iacute; proces prob&iacute;h&aacute; na z&aacute;klad&#283;&nbsp;</span><span class="c9"><a class="c15" routerLink="/terms">V&scaron;eobecn&yacute;ch obchodn&iacute;ch podm&iacute;nek</a></span>
                <span
                    class="c0">&nbsp;slu&#382;by Nebal&iacute;k.cz.</span>
            </li>
            <li class="c7 li-bullet-0"><span class="c3">Reklamaci p&#345;epravy&nbsp;</span><span class="c1">uplat&#328;uje v&yacute;hradn&#283; P&#345;&iacute;kazce</span><span class="c0">&nbsp;(objednatel a pl&aacute;tce p&#345;epravy), a to pomoc&iacute;&nbsp;formul&aacute;&#345;e, kter&yacute; obdr&#382;&iacute;te po kontaktov&aacute;n&iacute; z&aacute;kaznick&eacute; podpory na adrese info@nebalik.cz.</span></li>
            <li
                class="c7 li-bullet-0"><span class="c0">Po nahl&aacute;&scaron;en&iacute; p&iacute;semn&eacute; reklamace potvrd&iacute;me jej&iacute; p&#345;ijet&iacute;, a po&#382;&aacute;d&aacute;me o pot&#345;ebn&eacute; podklady nutn&eacute; k jej&iacute;mu vy&#345;&iacute;zen&iacute;. Vyj&aacute;d&#345;en&iacute; s v&yacute;sledkem reklamace pos&iacute;l&aacute;me na emailovou adresu P&#345;&iacute;kazce.</span></li>
                <li
                    class="c7 li-bullet-0"><span class="c3">Zjevn&eacute; poru&scaron;en&iacute; nebo po&scaron;kozen&iacute; obalu m&#367;&#382;e b&yacute;t rozporov&aacute;no nejpozd&#283;ji p&#345;i doru&#269;en&iacute; z&aacute;silky. O rozsahu po&scaron;kozen&iacute; nebo &#269;&aacute;ste&#269;n&eacute; ztr&aacute;t&#283; obsahu z&aacute;silky mus&iacute; b&yacute;t na m&iacute;st&#283; seps&aacute;n&nbsp;</span>
                    <span
                        class="c1">Z&aacute;pis o &scaron;kod&#283; na formul&aacute;&#345;i p&#345;epravce</span><span class="c0">.</span></li>
                        <li class="c7 li-bullet-0"><span class="c0">Z&aacute;pis o &scaron;kod&#283; nen&iacute; uplatn&#283;n&iacute;m n&aacute;roku na n&aacute;hradu &scaron;kody.</span></li>
                        <li class="c7 li-bullet-0"><span class="c3">Reklamaci &scaron;kody, kter&aacute; nen&iacute; p&#345;i doru&#269;en&iacute; zjevn&aacute;, je t&#345;eba vzn&eacute;st&nbsp;</span><span class="c1">do 3 pracovn&iacute;ch dn&#367;</span><span class="c0">&nbsp;po doru&#269;en&iacute; bal&iacute;ku p&#345;&iacute;jemci, jinak n&aacute;rok na n&aacute;hradu &scaron;kody zanik&aacute;.</span></li>
                        <li
                            class="c7 li-bullet-0"><span class="c3">V p&#345;&iacute;pad&#283; uplatn&#283;n&iacute; n&aacute;roku na n&aacute;hradu &scaron;kody za&nbsp;</span><span class="c1">ztracenou Z&aacute;silku</span><span class="c3">&nbsp;je P&#345;&iacute;kazce povinen informovat o ztr&aacute;t&#283; Z&aacute;silky Poskytovatele nejpozd&#283;ji&nbsp;</span>
                            <span
                                class="c1">do 10 kalend&aacute;&#345;n&iacute;ch dn&#367; od p&#345;edpokl&aacute;dan&eacute;ho term&iacute;nu doru&#269;en&iacute;</span><span class="c0">&nbsp;dle aktu&aacute;ln&#283; platn&yacute;ch cen&iacute;k&#367;.</span></li>
                                <li
                                    class="c7 li-bullet-0"><span class="c3">Ostatn&iacute; reklamace podl&eacute;haj&iacute;&nbsp;</span><span class="c1">30 denn&iacute; proml&#269;ec&iacute; lh&#367;t&#283;</span><span class="c0">.</span></li>
                                    <li class="c7 li-bullet-0"><span class="c1">Reklamace, u kter&yacute;ch do 10 pracovn&iacute;ch dn&#367; od pod&aacute;n&iacute; reklamace neobdr&#382;&iacute;me kompletn&iacute; dokumentaci, jsou zastaveny a reklamaci nelze znovu obnovit.</span></li>
                                    <li
                                        class="c7 li-bullet-0"><span class="c0">Reklamaci s p&#345;epravcem vy&#345;e&scaron;&iacute;me v nejbli&#382;&scaron;&iacute; mo&#382;n&eacute; dob&#283;, nejpozd&#283;ji v&scaron;ak do 30 dn&#367; od obdr&#382;en&iacute; ve&scaron;ker&yacute;ch pot&#345;ebn&yacute;ch podklad&#367;.</span></li>
                                        <li
                                            class="c7 li-bullet-0"><span class="c0">V p&#345;&iacute;pad&#283;, &#382;e podkladem pro stanoven&iacute; hodnoty po&scaron;kozen&eacute;ho &#269;i poh&#345;e&scaron;ovan&eacute;ho obsahu Z&aacute;silky je faktura v zahrani&#269;n&iacute; m&#283;n&#283;, p&#345;epo&#269;&iacute;t&aacute; se &#269;&aacute;stka na t&eacute;to faktu&#345;e uveden&aacute; dle sm&#283;nn&eacute;ho kurzu vyhl&aacute;&scaron;en&eacute;ho &#268;eskou n&aacute;rodn&iacute; bankou platn&eacute;ho k prvn&iacute;mu pracovn&iacute;mu dni m&#283;s&iacute;ce, kdy byla Z&aacute;silka p&#345;evzata k p&#345;eprav&#283;.</span></li>
                                            <li
                                                class="c7 li-bullet-0"><span class="c0">Proti zam&iacute;tnut&iacute; reklamace je mo&#382;no vzn&eacute;st p&iacute;semn&eacute; odvol&aacute;n&iacute;, kter&eacute; bude p&#345;ezkoum&aacute;no ve lh&#367;t&#283; 30 dn&#367;.</span></li>
        </ul>
        <ol class="c4 lst-kix_list_1-0" start="3">
            <li class="c11 li-bullet-0"><span class="c12">Doklady pot&#345;ebn&eacute; pro uplatn&#283;n&iacute; reklamace</span></li>
        </ol>
        <ol class="c4 lst-kix_list_2-1 start" start="1">
            <li class="c18 li-bullet-0"><span class="c1">Po&scaron;kozen&aacute; z&aacute;silka nebo &#269;&aacute;ste&#269;n&#283; ztracen&aacute;</span></li>
        </ol>
        <ol class="c4 lst-kix_list_2-2 start" start="1">
            <li class="c5 li-bullet-0"><span class="c0">Vypln&#283;n&yacute; formul&aacute;&#345; N&aacute;rok na n&aacute;hradu &scaron;kody, kter&yacute; P&#345;&iacute;kazce obdr&#382;&iacute; od Z&aacute;kaznick&eacute; podpory.</span></li>
            <li class="c5 li-bullet-0"><span class="c0">Pr&#367;vodn&iacute; doklad Z&aacute;silky (faktura, dodac&iacute; list) s vyzna&#269;en&iacute;m po&scaron;kozen&yacute;ch polo&#382;ek a po&#269;tu po&scaron;kozen&yacute;ch kus&#367;.</span></li>
            <li class="c5 li-bullet-0"><span class="c1">Doklad o po&#345;izovac&iacute; cen&#283;</span><span class="c0">&nbsp;(n&aacute;kupn&iacute; faktura, p&#345;&iacute;padn&#283; &#269;estn&eacute; prohl&aacute;&scaron;en&iacute; s vy&#269;&iacute;slen&iacute;m v&yacute;robn&iacute;ch &#269;i po&#345;izovac&iacute;ch n&aacute;klad&#367;) bez ziskov&eacute; mar&#382;e a bez DPH, v p&#345;&iacute;pad&#283; po&scaron;kozen&iacute; tak&eacute; n&aacute;klady na opravu nebo vy&#269;&iacute;slen&aacute; sleva z prodejn&iacute; ceny.</span></li>
            <li
                class="c5 li-bullet-0"><span class="c0">Fotodokumentace pou&#382;it&eacute;ho p&#345;epravn&iacute;ho obalu, vyst&yacute;lky, po&scaron;kozen&eacute;ho obsahu a &scaron;t&iacute;tku.</span></li>
                <li class="c5 li-bullet-0"><span class="c0">Kopie Z&aacute;pisu o &scaron;kod&#283;, pokud byl vystaven a pokud je zde potvrzeno, &#382;e bal&iacute;k nesl p&#345;i doru&#269;en&iacute; zn&aacute;mky vn&#283;j&scaron;&iacute;ho po&scaron;kozen&iacute;.</span></li>
                <li
                    class="c5 li-bullet-0"><span class="c0">Posudek servisu &#269;i jin&eacute; osoby, kter&aacute; provedla opravu Z&aacute;silky, pokud byla Z&aacute;silka po &scaron;kodn&iacute; ud&aacute;losti opravov&aacute;na, nebo vy&#382;aduj&iacute;-li to okolnosti p&#345;&iacute;padu.</span></li>
        </ol>
        <ol class="c4 lst-kix_list_2-1" start="2">
            <li class="c7 li-bullet-0"><span class="c1">Ztracen&aacute; z&aacute;silka</span></li>
        </ol>
        <ol class="c4 lst-kix_list_2-2 start" start="1">
            <li class="c5 li-bullet-0"><span class="c0">Vypln&#283;n&yacute; formul&aacute;&#345; N&aacute;rok na n&aacute;hradu &scaron;kody, kter&yacute; P&#345;&iacute;kazce obdr&#382;&iacute; od Z&aacute;kaznick&eacute; podpory.</span></li>
            <li class="c5 li-bullet-0"><span class="c0">Pr&#367;vodn&iacute; doklad Z&aacute;silky (faktura, dodac&iacute; list).</span></li>
            <li class="c5 li-bullet-0"><span class="c1">Doklad o po&#345;izovac&iacute; cen&#283;</span><span class="c0">&nbsp;(n&aacute;kupn&iacute; faktura, p&#345;&iacute;padn&#283; &#269;estn&eacute; prohl&aacute;&scaron;en&iacute; s vy&#269;&iacute;slen&iacute;m v&yacute;robn&iacute;ch &#269;i po&#345;izovac&iacute;ch n&aacute;klad&#367;) bez ziskov&eacute; mar&#382;e a bez DPH.</span></li>
            <li
                class="c5 li-bullet-0"><span class="c3">V p&#345;&iacute;pad&#283;, &#382;e z&aacute;silka v&#367;bec nevstoupila do syst&eacute;mu P&#345;epravce &ndash; kopii&nbsp;</span><span class="c1">Potvrzen&iacute; o p&#345;evzet&iacute; z&aacute;silky</span><span class="c0">&nbsp;vystavenou kur&yacute;rem p&#345;i p&#345;evzet&iacute;.</span></li>
                <li
                    class="c5 li-bullet-0"><span class="c3">V p&#345;&iacute;pad&#283;&nbsp;</span><span class="c1">doru&#269;en&iacute; bal&iacute;ku na chybnou adresu nebo nedoru&#269;en&iacute;</span><span class="c0">&nbsp;&ndash; &#269;estn&yacute;m prohl&aacute;&scaron;en&iacute;m P&#345;&iacute;jemce Z&aacute;silky, kde P&#345;&iacute;jemce sv&yacute;m podpisem potvrd&iacute;, &#382;e mu p&#345;&iacute;slu&scaron;n&aacute; Z&aacute;silka nebyla doru&#269;ena. &#268;estn&eacute; prohl&aacute;&scaron;en&iacute; mus&iacute; obsahovat zejm&eacute;na &uacute;daje o P&#345;&iacute;jemci Z&aacute;silky (jm&eacute;no, p&#345;&iacute;jmen&iacute;, adresu doru&#269;en&iacute;) a &#269;&iacute;slo Z&aacute;silky, kter&aacute; nebyla doru&#269;ena.</span></li>
        </ol>
        <ol class="c4 lst-kix_list_2-0 start" start="1">
            <li class="c10 li-bullet-0"><span class="c12">Uznan&aacute; reklamace</span></li>
        </ol>
        <ul class="c4 lst-kix_list_3-1 start">
            <li class="c13 li-bullet-0"><span class="c3">P&#345;&iacute;kazce, kter&eacute;mu byla uzn&aacute;na reklamace,&nbsp;</span><span class="c1">vystav&iacute; Poskytovateli &Scaron;kodovou fakturu ve v&yacute;&scaron;i uznan&eacute; reklamace</span><span class="c0">.</span></li>
            <li
                class="c16 li-bullet-0"><span class="c3">Objednavatel (P&#345;&iacute;kazce) je jedin&yacute;m legitimn&iacute;m vlastn&iacute;kem z&aacute;silky, proto eventu&aacute;ln&iacute; n&aacute;hrady budou pouk&aacute;z&aacute;ny&nbsp;</span><span class="c1">na jeho bankovn&iacute; &uacute;&#269;et</span>
                <span
                    class="c0">&nbsp;a ve v&#283;cech realizace reklamac&iacute; je Poskytovatel odpov&#283;dn&yacute; pouze Objednavateli.</span>
                    </li>
                    <li class="c16 li-bullet-0"><span class="c0">V p&#345;&iacute;pad&#283; uznan&eacute; reklamace, u kter&eacute; P&#345;&iacute;kazce nedodal doklad o po&#345;izovac&iacute; cen&#283;, kompenzuje P&#345;epravce maxim&aacute;ln&#283; 80% z prodejn&iacute; hodnoty zbo&#382;&iacute; bez DPH.</span></li>
                    <li
                        class="c16 li-bullet-0"><span class="c1">Pro podnikatele pl&aacute;tce DPH u &scaron;kodov&eacute; faktury plat&iacute;</span><span class="c0">:</span></li>
        </ul>
        <ul class="c4 lst-kix_list_4-2 start">
            <li class="c26 li-bullet-0"><span class="c1">Po&scaron;kozen&aacute; z&aacute;silka</span><span class="c3">&nbsp;- vyplacen&aacute; n&aacute;hrada &scaron;kody za po&scaron;kozenou z&aacute;silku&nbsp;</span><span class="c1">je p&#345;edm&#283;tem dan&#283;</span>
                <span
                    class="c0">&nbsp;ve smyslu z&aacute;kona o dani z p&#345;idan&eacute; hodnoty, do&scaron;lo ke zdaniteln&eacute;mu pln&#283;n&iacute;. V p&#345;&iacute;pad&#283; po&scaron;kozen&iacute; je &scaron;koda vy&#269;&iacute;slena s DPH a dokl&aacute;d&aacute;
                    se faktura za uveden&iacute; zbo&#382;&iacute; do p&#367;vodn&iacute;ho stavu od autorizovan&eacute;ho servisu, opravny apod.</span>
            </li>
            <li class="c28 li-bullet-0"><span class="c1">Ztracen&aacute; z&aacute;silka</span><span class="c3">&nbsp;- vyplacen&aacute; n&aacute;hrada &scaron;kody za ztracenou z&aacute;silku&nbsp;</span><span class="c1">nen&iacute; p&#345;edm&#283;tem dan&#283;</span><span class="c0">&nbsp;ve smyslu z&aacute;kona o dani z p&#345;idan&eacute; hodnoty. Nedo&scaron;lo k &#382;&aacute;dn&eacute;mu dod&aacute;n&iacute; zbo&#382;&iacute;, nebylo uskute&#269;n&#283;no zdaniteln&eacute; pln&#283;n&iacute;, a proto fakturace n&aacute;hrady &scaron;kody bude bez DPH (0% DPH).</span></li>
        </ul>
        <p class="c25"><span class="c24"></span></p>
    </div>
</main>
<app-footer mode='lite'></app-footer>
