<app-navbar></app-navbar>
<main>
    <ng-container *ngIf='error'>
        <section class="section section--top background-primary">
            <div class="container">
                <h2 class="font-normal">Nastala chyba při platbě</h2>
            </div>
            <img id='bg-shape-1' src='assets/images/bg-shape-1.png' />
        </section>
        <section class="section section--content">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <p class='error-message mb-5'>{{error}}</p>
                        <div>
                            <button (click)='onMyOrdersClick()' type="button" class="btn btn--primary btn--small mr-5">
                                Moje objednávky
                            </button>
                            <button *ngIf='pkg' (click)='onPayClick()' type="button" class="btn btn--primary btn--small">
                                Zaplatit
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </ng-container>
    <ng-container *ngIf='success'>
        <section class="section section--top background-primary">
            <div class="container">
                <h2 class="font-normal">Dokončená objednávka</h2>
                <p>Tak a to je vše. Nyní jen vyčkejte na kurýra. O jeho příjezdu vás budeme informovat.</p>
            </div>
            <img id='bg-shape-1' src='assets/images/bg-shape-1.png' />
        </section>
        <section class="section section--content">
            <div class="container">
                <div class="row">
                    <div class="col-lg-7">
                        <div class="d-flex flex-row">
                            <i class="far fa-paper-plane"></i>
                            <ul>
                                <li class="grey-color">Vaši zásilku {{ pkg ? 'ID ' + pkg.packageTrackId : ''}} vyzvedneme ve vámi zvolený den {{ pkg ? (pkg.pickUpDate | date:'d.M.yyyy') : '' }}.</li>
                                <li class="grey-color">O příjezdu kurýra vás budeme informovat e-mailem nebo notifikacemi.</li>
                                <li class="grey-color">V případě změn v objednávce nás kontaktujte na mailu <a href='mailto:info@nebalik.cz' target='_blank'>info@nebalik.cz</a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-5">
                        <div class="app-box">
                            <img src="assets/images/mockup-full.png" alt="">
                            <div class="content">
                                <p>Pro snažší posílání můžete využít naši <strong>mobilní aplikaci</strong></p>
                                <div class='badges'>
                                    <a href='https://itunes.apple.com/app/id1550790547' target='_blank'>
                                        <img src="assets/images/button-app-store.png" alt="">
                                    </a>
                                    <a href='https://play.google.com/store/apps/details?id=cz.nebalik.zakaznik' target='_blank'>
                                        <img src="assets/images/button-google-play.png" alt="">
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="section m-tb-40">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-3">
                        <div class="feature">
                            <div class="left"><span class="far fa-check-circle"></span></div>
                            <div class="right">
                                <h3>Rychle
                                </h3>
                                <p>Pokud stihnete zadat objednávku do 12:00, naši kurýři si u vás vyzvednou zásilku již následující den.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3">
                        <div class="feature">
                            <div class="left"><span class="far fa-check-circle"></span></div>
                            <div class="right">
                                <h3>Pohodlně
                                </h3>
                                <p>Nemusíte nikam chodit ani čekat ve frontách na pobočkách nebo přepážkách. Kurýr se staví na vámi uvedenou adresu.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3">
                        <div class="feature">
                            <div class="left"><span class="far fa-check-circle"></span></div>
                            <div class="right">
                                <h3>Bez starostí
                                </h3>
                                <p>O balení se postaráme my tak, aby vaše zásilky byly v bezpečí. Po odeslání pak můžete sledovat stav své zásilky a mít tak dokonalý přehled.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </ng-container>
</main>
<app-footer mode='lite'></app-footer>
