<nav class="navbar fixed-top">
    <div class='container-fluid mr-4 ml-4'>
        <a routerLink='/' class="navbar-brand">
            <app-logo></app-logo>
        </a>
        <ul class="navbar-nav ml-auto">
            <li role="nav-item"><a (click)='onContinueToSiteClick()' class="btn btn--secondary">Pokračovat na web</a></li>
        </ul>
    </div>
</nav>
<main>
    <section class="section section--top background-primary">
        <div class="container-fluid ml-4">
            <h2 class="font-normal">Nastavení nového hesla</h2>
            <p>&nbsp;</p>
        </div>
    </section>
    <section class="section section--content">
        <div class="container">
            <ng-container *ngIf='!success'>
                <p>
                    Zvolte si nové heslo zadáním níže.
                </p>
                <form (submit)='onSubmitClick()' [formGroup]='form' [class.submitted]='submitted' novalidate>
                    <div class="form-group grey-color">
                        <label>Nové heslo</label>
                        <input formControlName='password' type="password" class="form-control" autocomplete='new-password' required>
                        <div *ngIf='submitted && password.invalid' class="invalid-feedback">
                            Zadejte prosím nové heslo o alespoň 6 znacích.
                        </div>
                    </div>
                    <div class="form-group grey-color">
                        <label>Nové heslo pro kontrolu</label>
                        <input formControlName='passwordCheck' type="password" class="form-control" autocomplete='new-password' required>
                        <div *ngIf='submitted && passwordCheck.invalid' class="invalid-feedback">
                            Zadejte prosím nové heslo pro kontrolu.
                        </div>
                        <div *ngIf='submitted && password.valid && passwordCheck.valid && passwordCheck.value !== password.value' class="invalid-feedback">
                            Hesla se neshodují.
                        </div>
                    </div>
                    <div class="d-flex pt-3">
                        <input type="submit" class="btn btn--primary" value="Uložit">
                    </div>
                </form>
            </ng-container>
            <ng-container *ngIf='success'>
                <p class='mt-4'>
                    Nastavení hesla proběhlo úspěšně. Nyní se můžete přihlásit.
                </p>
            </ng-container>
            <p *ngIf='error' class='verify-email-error mt-4'>
                {{ error }}
            </p>
        </div>
    </section>
</main>
