import { Component, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
    selector: 'app-confirm-modal',
    templateUrl: './confirm-modal.component.html',
    styleUrls: ['./confirm-modal.component.scss']
})
export class ConfirmModalComponent {

    @Input() public title!: string;
    @Input() public message!: string;
    @Input() public cancelText: string = 'Zrušit';
    @Input() public onSubmit!: () => void;
    @Input() public onCancel: undefined | (() => void);


    constructor(
        public modalRef: BsModalRef,
    ) {
    }

    public onCancelClick(): void {
        if (this.onCancel) {
            this.onCancel();
        }
        this.modalRef.hide();
    }

    public onConfirmClick(): void {
        this.onSubmit();
        this.modalRef.hide();
    }
}
