<form (submit)='onSubmitClick()' [formGroup]='form' [class.submitted]='submitted' novalidate>
    <fieldset>
        <img class='group-img group-img--info' src="assets/images/package-info.png" alt="">
        <h5 class='primary-color font-semibold'>Osobní údaje</h5>
        <div class="form-group">
            <label>
                <span>E-mail</span>
                <div class='form-label-error' *ngIf='userService.user && !userService.user.verified'><span>Neověřený účet</span>&nbsp;&nbsp;<a (click)='onVerifyEmailClick()'>Ověřit</a></div>
            </label>
            <input formControlName='email' type="email" class="form-control" required>
            <div *ngIf='submitted && email.invalid' class="invalid-feedback">
                Zadejte prosím platný email.
            </div>
        </div>
        <div class="form-group">
            <label>Jméno a příjmení</label>
            <input formControlName='name' type="text" class="form-control" required>
            <div *ngIf='submitted && name.invalid' class="invalid-feedback">
                Zadejte prosím jméno a příjmení.
            </div>
        </div>
        <div class="form-group form-group--phone">
            <label>Kontaktní telefon pro přepravce</label>
            <input formControlName='phone' type="number" class="form-control" required>
            <div *ngIf='submitted && phone.invalid' class="invalid-feedback">
                Zadejte prosím platné telefonní číslo.
            </div>
        </div>
        <div class="form-group">
            <label>Ulice a číslo domu</label>
            <input formControlName='street' type="text" class="form-control" required>
            <div *ngIf='submitted && street.invalid' class="invalid-feedback">
                Zadejte prosím ulici a číslo domu.
            </div>
        </div>
        <div class="row">
            <div class="col-lg-9">
                <div class="form-group">
                    <label>Město</label>
                    <input formControlName='city' type="text" class="form-control" required>
                    <div *ngIf='submitted && city.invalid' class="invalid-feedback">
                        Zadejte prosím město.
                    </div>
                </div>
            </div>
            <div class="col-lg-3">
                <div class="form-group">
                    <label>PSČ</label>
                    <input formControlName='zip' type="number" class="form-control" required>
                    <div *ngIf='submitted && zip.invalid' class="invalid-feedback">
                        Zadejte prosím platné PSČ.
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf='error' class='form-error-message'>
            {{error}}
        </div>
        <button class="btn btn--primary" type="submit">Uložit změny</button>
    </fieldset>
</form>
