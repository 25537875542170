import { Component, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { HelperService } from 'src/app/services/helper.service';

@Component({
    selector: 'app-root-root',
    templateUrl: './root.component.html',
    styleUrls: ['./root.component.scss']
})
export class RootComponent implements OnDestroy {
    private routerSub!: Subscription;

    constructor(
        private router: Router,
        private helperService: HelperService
    ) {
        this.routerSub = this.router.events.subscribe(s => {
            if (s instanceof NavigationEnd) {
                const tree = this.router.parseUrl(this.router.url);
                if (tree.fragment) {
                    const element = document.querySelector(`#${tree.fragment}`);
                    if (element) {
                        window.scrollTo({ top: this.helperService.getElementOffset(element).top - 158, left: 0, behavior: 'smooth' });
                        setTimeout(() => window.scrollTo({ top: this.helperService.getElementOffset(element).top - 158, left: 0 }), 500);
                    }
                }
            }
        });
    }

    public ngOnDestroy(): void {
        this.routerSub?.unsubscribe();
    }
}
