import { Component } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { AlertService } from 'src/app/services/alert.service';

@Component({
    selector: 'app-settings',
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.scss']
})
export class SettingsComponent {


    constructor(
        private userService: UserService,
        private alertService: AlertService,
    ) {
    }

    public onDeleteAccountClick(): void {
        this.alertService.presentConfirm({
            title: 'Zrušení účtu',
            message: 'Opravdu si přejete zrušit účet?',
            cancelText: 'Zpět',
            onConfirm: () => this.deleteAccount()
        })
    }

    private async deleteAccount(): Promise<void> {
        try {
            await this.userService.customerDeleteAccount();
        } catch (err) {
            this.alertService.presentAlert(
                'Chyba',
                err.message
            );
        }
    }

}
