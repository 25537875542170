import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, AbstractControl, FormBuilder } from '@angular/forms';
import { BsModalService } from 'ngx-bootstrap/modal';
import { User } from 'src/app/models/user';
import { HelperService } from 'src/app/services/helper.service';
import { UserService } from 'src/app/services/user.service';
import { EmailVerificationComponent } from '../email-verification/email-verification.component';

@Component({
    selector: 'app-customer-profile-edit',
    templateUrl: './customer-profile-edit.component.html',
    styleUrls: ['./customer-profile-edit.component.scss']
})
export class CustomerProfileEditComponent implements OnInit {

    public form: FormGroup = this.fb.group({
        name: ['', [Validators.required]],
        email: [{ value: '', disabled: true }, [Validators.required, this.helperService.emailValidator]],
        phone: ['', [Validators.required, Validators.pattern(/^\d{9}$/)]],
        street: ['', [Validators.required]],
        city: ['', [Validators.required]],
        zip: ['', [Validators.required, Validators.pattern(/^\d{5}$/)]],
    });
    public submitted: boolean = false;
    public error: undefined | string;

    public get name(): AbstractControl { return this.form.get('name') as AbstractControl }
    public get email(): AbstractControl { return this.form.get('email') as AbstractControl }
    public get phone(): AbstractControl { return this.form.get('phone') as AbstractControl }
    public get street(): AbstractControl { return this.form.get('street') as AbstractControl }
    public get city(): AbstractControl { return this.form.get('city') as AbstractControl }
    public get zip(): AbstractControl { return this.form.get('zip') as AbstractControl }

    constructor(
        public userService: UserService,
        private fb: FormBuilder,
        private helperService: HelperService,
        private bsModalService: BsModalService,
    ) {
    }

    public ngOnInit(): void {
        const user = this.userService.user as User;
        this.form.patchValue({
            name: user.username,
            email: user.email,
            phone: user.phone ? user.phone.replace('+420', '') : '',
            street: user.address.street,
            city: user.address.city,
            zip: user.address.zip,
        });
    }

    public onSubmitClick(): void {
        this.error = undefined;
        this.submitted = true;
        if (!this.form.valid) {
            return;
        }
        this.submit();
    }

    public onVerifyEmailClick(): void {
        this.bsModalService.show(EmailVerificationComponent);
    }

    private async submit(): Promise<void> {
        this.submitted = false;
        try {
            await this.userService.customerEditProfile({
                ...this.form.value,
                email: this.userService.user?.email,
                role: this.userService.user?.role,
                phone: '+420' + this.form.value.phone,
            });
        } catch (err) {
            this.error = err.message;
            return;
        }
    }
}
