import { Component, Input } from '@angular/core';
import { User } from 'src/app/models/user';
import { AdminService } from 'src/app/services/admin.service';

@Component({
    selector: '[app-admin-courier-pending]',
    templateUrl: './admin-courier-pending.component.html',
    styleUrls: ['./admin-courier-pending.component.scss']
})
export class AdminCourierPendingComponent {

    @Input() public courier!: User;

    constructor(
        private adminService: AdminService,
    ) {
    }

    public onShowTimetableClick(): void {

    }

    public onApproveClick(): void {
        this.adminService.approveCourier(this.courier.id);
    }

    public onDeclineClick(): void {
        this.adminService.declineCourier(this.courier.id);
    }
}
