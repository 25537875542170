import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
import { SignIn } from '../models/sign-in';
import { CustomerSignUp } from '../models/customer-sign-up';
import { StorageKeys } from '../models/storage-keys';
import { User } from '../models/user';
import { UserEvent } from '../models/user-event';
import { CustomerEditProfile } from '../models/customer-edit-profile';
import { AlertService } from './alert.service';
import { ApiService } from './api.service';
import { ErrorHandlerService } from './error-handler.service';
import { LoadingService } from './loading.service';
import { StorageService } from './storage.service';
import { CustomerChangePassword } from '../models/customer-change-password';
import { UserRole } from '../models/user-role';
import { InitService } from './init.service';
import { CourierSignUp } from '../models/courier-sign-up';
import { EmailVerificationComponent } from '../components/email-verification/email-verification.component';
import { BsModalService } from 'ngx-bootstrap/modal';

@Injectable({
    providedIn: 'root'
}) export class UserService {

    public user: User | undefined;
    public user$: BehaviorSubject<User | undefined> = new BehaviorSubject<User | undefined>(undefined);
    public userEvents$: Subject<UserEvent> = new Subject<UserEvent>();

    constructor(
        private router: Router,
        private storage: StorageService,
        private apiService: ApiService,
        private loadingService: LoadingService,
        private errorHandlerService: ErrorHandlerService,
        private alertService: AlertService,
        private initService: InitService,
        private bsModalService: BsModalService,
    ) {
    }

    public initialize(): void {
        this.errorHandlerService.unauthorizedError$.subscribe({
            next: this.signOut.bind(this)
        });
        if (this.initService.user) {
            this.onSilentSignIn(this.initService.user);
        }
    }

    public async customerSignIn(input: SignIn): Promise<void> {
        this.loadingService.loading$.next(true);
        let user: User;
        try {
            user = await this.apiService.customerSignIn(input).toPromise();
        } catch (err) {
            this.errorHandlerService.handleApiError(err, true);
            return;
        } finally {
            this.loadingService.loading$.next(false);
        }
        this.onSignIn(user);
    }

    public async adminSignIn(input: SignIn): Promise<void> {
        this.loadingService.loading$.next(true);
        let user: User;
        try {
            user = await this.apiService.adminSignIn(input).toPromise();
        } catch (err) {
            this.errorHandlerService.handleApiError(err, true);
            return;
        } finally {
            this.loadingService.loading$.next(false);
        }
        this.onSignIn(user);
    }

    public async customerSignUp(input: CustomerSignUp): Promise<void> {
        this.loadingService.loading$.next(true);
        let user: User;
        try {
            user = await this.apiService.customerSignUp(input).toPromise();
        } catch (err) {
            this.errorHandlerService.handleApiError(err, true);
            return;
        } finally {
            this.loadingService.loading$.next(false);
        }
        this.onSignUp(user);
    }

    public async courierSignUp(input: CourierSignUp): Promise<void> {
        this.loadingService.loading$.next(true);
        try {
            await this.apiService.courierSignUp(input).toPromise();
        } catch (err) {
            this.errorHandlerService.handleApiError(err, true);
            return;
        } finally {
            this.loadingService.loading$.next(false);
        }
    }

    public async userResetPassword(email: string): Promise<void> {
        this.loadingService.loading$.next(true);
        try {
            await this.apiService.userResetPassword(email).toPromise();
        } catch (err) {
            this.errorHandlerService.handleApiError(err, true);
            return;
        } finally {
            this.loadingService.loading$.next(false);
        }
    }

    public async userSetPassword(resetToken: string, newPassword: string): Promise<void> {
        this.loadingService.loading$.next(true);
        try {
            await this.apiService.userSetPassword(resetToken, newPassword).toPromise();
        } catch (err) {
            this.errorHandlerService.handleApiError(err, true);
            return;
        } finally {
            this.loadingService.loading$.next(false);
        }
    }

    public async customerEditProfile(input: CustomerEditProfile): Promise<void> {
        if (!this.user) {
            return;
        }
        this.loadingService.loading$.next(true);
        let user: User;
        try {
            user = await this.apiService.customerEditProfile(this.user.id, input).toPromise();
        } catch (err) {
            this.errorHandlerService.handleApiError(err);
            return;
        } finally {
            this.loadingService.loading$.next(false);
        }
        this.onProfileUpdate(user);
    }

    public async customerChangePassword(input: CustomerChangePassword): Promise<void> {
        this.loadingService.loading$.next(true);
        try {
            await this.apiService.customerChangePassword(input).toPromise();
        } catch (err) {
            this.errorHandlerService.handleApiError(err, true);
            return;
        } finally {
            this.loadingService.loading$.next(false);
        }
        this.onPasswordChange();
    }

    public async customerDeleteAccount(): Promise<void> {
        this.loadingService.loading$.next(true);
        try {
            await this.apiService.customerDeleteAccount().toPromise();
        } catch (err) {
            this.errorHandlerService.handleApiError(err);
            return;
        } finally {
            this.loadingService.loading$.next(false);
        }
        this.onAccountDelete();
    }

    public async userResendVerificationEmail(email: string, app: string): Promise<void> {
        this.loadingService.loading$.next(true);
        try {
            await this.apiService.userResendVerificationEmail(email, app).toPromise();
        } catch (err) {
            this.errorHandlerService.handleApiError(err);
            return;
        } finally {
            this.loadingService.loading$.next(false);
        }
        this.onVerificationEmailResendSuccess(email, app);
    }

    public async customerCheckEmailVerified(): Promise<void> {
        this.loadingService.loading$.next(true);
        let user: User;
        try {
            user = await this.apiService.reloadUser().toPromise();
        } catch (err) {
            this.errorHandlerService.handleApiError(err, true);
            return;
        } finally {
            this.loadingService.loading$.next(false);
        }
        this.onCustomerEmailVerificationCheckSuccess(user);
    }

    public async userVerifyEmail(verificationToken: string): Promise<void> {
        this.loadingService.loading$.next(true);
        try {
            await this.apiService.userVerifyEmail(verificationToken).toPromise();
        } catch (err) {
            this.errorHandlerService.handleApiError(err);
            return;
        } finally {
            this.loadingService.loading$.next(false);
        }
    }


    public signOut(): void {
        this.user = undefined;
        this.storage.clear();
        this.userEvents$.next(UserEvent.SIGN_OUT);
        this.user$.next(undefined);
        this.alertService.presentToast('Uživatel byl odhlášen.');
        this.router.navigateByUrl('/');
    }

    private onSilentSignIn(user: User): void {
        this.user = user;
        this.user$.next(user);
    }

    private onSignIn(user: User): void {
        this.user = user;
        this.userEvents$.next(UserEvent.SIGN_IN);
        this.user$.next(user);
        this.alertService.presentToast('Přihlášení proběhlo úspěšně.');
        if (this.user.role === UserRole.ADMIN || this.user.role === UserRole.DEPO) {
            this.router.navigateByUrl('/admin');
        }
    }

    private onSignUp(user: User): void {
        this.user = user;
        this.userEvents$.next(UserEvent.SIGN_IN);
        this.user$.next(user);
        if (user.verified) {
            this.alertService.presentToast('Registrace proběhla úspěšně.');
        } else {
            this.bsModalService.show(EmailVerificationComponent);
        }
    }

    private onProfileUpdate(user: User): void {
        this.user = user;
        this.userEvents$.next(UserEvent.PROFILE_UPDATE);
        this.user$.next(user);
        this.alertService.presentToast('Nastavení profilu bylo uloženo.');
    }

    private onPasswordChange(): void {
        this.alertService.presentAlert('Změna hesla', 'Nové heslo bylo nastaveno.');
    }

    private onVerificationEmailResendSuccess(email: string, app: string): void {
        if (app === 'CUSTOMER') {
            this.alertService.presentToast(`Ověřovací e-mail byl odeslán na vaši adresu ${email}.`);
        } else {
            this.alertService.presentAlert('Ověření e-mailu', `Ověřovací e-mail byl odeslán na vaši adresu ${email}.`);
        }
    }

    private onAccountDelete(): void {
        this.alertService.presentAlert('Zrušení účtu', 'Váš účet byl úspěšně zrušen.');
        this.user = undefined;
        this.storage.clear();
        this.userEvents$.next(UserEvent.SIGN_OUT);
        this.user$.next(undefined);
        this.router.navigateByUrl('/');
    }

    private onCustomerEmailVerificationCheckSuccess(user: User): void {
        this.user = user;
        this.user$.next(user);
    }

}
