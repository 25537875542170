import { DateTime } from 'luxon';

export interface Order {
    photo: File;
    title: string;
    weight: string;
    pickUpDate: string,
    dimensions: string;
    senderEmail: string;
    senderName: string;
    senderPhone: string;
    senderStreet: string;
    senderCity: string;
    senderZip: string;
    recipientName: string;
    recipientPhone: string;
    recipientStreet: string;
    recipientCity: string;
    recipientZip: string;
    estimatedDeliveryPrice: string;
}

export const orderToDTO = (order: Order): any => {
    return {
        title: order.title,
        packageSize: order.dimensions,
        packageWeight: order.weight,
        senderName: order.senderName,
        senderPhone: order.senderPhone,
        addressFrom: order.senderStreet,
        cityFrom: order.senderCity,
        zipCodeFrom: order.senderZip,
        receiverName: order.recipientName,
        receiverPhone: order.recipientPhone,
        addressTo: order.recipientStreet,
        cityTo: order.recipientCity,
        zipCodeTo: order.recipientZip,
        estimatedDeliveryPrice: order.estimatedDeliveryPrice,
        pickUpDate: order.pickUpDate,
    };
}
