import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { DateTime } from 'luxon';
@Injectable({
    providedIn: 'root'
}) export class HelperService {

    constructor() {

    }

    public normalizeString(input: string): string {
        if (typeof input !== 'string') {
            return input;
        }
        return input.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();
    }

    public getPageFromUrl(url: string): string {
        let result = url.substring(1, url.indexOf(';') > -1 ? url.indexOf(';') : url.length);
        result = result.substring(0, result.indexOf('#') > -1 ? result.indexOf('#') : result.length);
        return result;
    }

    public emailValidator(control: AbstractControl): { [key: string]: any } | null {
        const re = /\S+@\S+\.\S+/;
        const passed = re.test(control.value.toLowerCase());
        return passed ? null : { emailValidator: { value: control.value } };
    }

    public getElementOffset(el: Element): { top: number, left: number } {
        const rect = el.getBoundingClientRect();
        return {
            left: rect.left + window.scrollX,
            top: rect.top + window.scrollY
        };
    }

    public isBussinessDay(input: DateTime): boolean {
        const isPublicHoliday = (datetime: DateTime): boolean => {
            const easter = (Y: number): DateTime => {
                const C = Math.floor(Y / 100);
                const N = Y - 19 * Math.floor(Y / 19);
                const K = Math.floor((C - 17) / 25);
                let I = C - Math.floor(C / 4) - Math.floor((C - K) / 3) + 19 * N + 15;
                I = I - 30 * Math.floor((I / 30));
                I = I - Math.floor(I / 28) * (1 - Math.floor(I / 28) * Math.floor(29 / (I + 1)) * Math.floor((21 - N) / 11));
                let J = Y + Math.floor(Y / 4) + I + 2 - C + Math.floor(C / 4);
                J = J - 7 * Math.floor(J / 7);
                const L = I - J;
                const M = 3 + Math.floor((L + 40) / 44);
                const D = L + 28 - 31 * Math.floor(M / 4);
                return DateTime.local(Y, M, D, 0, 0, 0);
            }
            const dt = datetime.startOf('day');
            const dm = dt.toFormat('d.M.');
            const y = dt.toFormat('yyyy');
            const staticPublicHolidays = [
                '1.1.',
                '1.5.',
                '8.5.',
                '5.7.',
                '6.7.',
                '28.9.',
                '28.10.',
                '17.11.',
                '24.12.',
                '25.12.',
                '26.12.'
            ];
            if (staticPublicHolidays.includes(dm)) {
                return true;
            } else {
                const dynamicPublicHolidays = [];
                const easterSunday = easter(+y).startOf('day');
                const easterMonday = easterSunday.plus({ days: 1 });
                const goodFriday = easterSunday.minus({ days: 2 });
                dynamicPublicHolidays.push(easterMonday);
                dynamicPublicHolidays.push(goodFriday);
                if (dynamicPublicHolidays.find(holiday => holiday.toMillis() === dt.toMillis())) {
                    return true;
                }
            }
            return false;
        }
        const isWeekend = (datetime: DateTime): boolean => {
            return datetime.weekday === 6 || datetime.weekday === 7;
        }

        return !isPublicHoliday(input) && !isWeekend(input);
    }
}
