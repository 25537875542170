<nav class="navbar fixed-top">
    <div class='container-fluid mr-4 ml-4'>
        <a routerLink='/' class="navbar-brand">
            <app-logo></app-logo>
        </a>
        <ul class="navbar-nav ml-auto">
            <li role="nav-item"><a class="btn btn--secondary" routerLink="/">Zpět na web</a></li>
        </ul>
    </div>
</nav>
<main>
    <section class="section section--top background-primary">
        <div class="container-fluid ml-4">
            <h2 class="font-normal">Všeobecné obchodní podmínky</h2>
            <p>&nbsp;</p>
        </div>
    </section>
    <div class="c18">
        <ol class="c12 lst-kix_list_1-0 start" start="1">
            <li class="c11 li-bullet-0"><span class="c13 c9">&Uacute;vodn&iacute; ustanoven&iacute;</span></li>
        </ol>
        <ol class="c12 lst-kix_list_1-1 start" start="1">
            <li class="c7 li-bullet-0"><span class="c14">Tyto v&scaron;eobecn&eacute; obchodn&iacute; podm&iacute;nky (d&aacute;le jen &bdquo;</span><span class="c4">Obchodn&iacute; podm&iacute;nky</span><span class="c14">&ldquo;) bl&iacute;&#382;e vymezuj&iacute; a up&#345;es&#328;uj&iacute; obchodn&iacute; a pr&aacute;vn&iacute; vztahy mezi poskytovatelem slu&#382;by, kter&yacute;m je spole&#269;nost Evolution Concept s.r.o se s&iacute;dlem Jugosl&aacute;vsk&aacute; 659/11, I&#268; 05760402, DI&#268; CZ05760402, zaps&aacute;na v obchodn&iacute;m rejst&#345;&iacute;ku m&#283;stsk&eacute;ho soudu v Praze, v odd&iacute;le C, vlo&#382;ce 270281 (d&aacute;le tak&eacute; &quot;</span>
                <span class="c4">Poskytovatel</span><span class="c14">&quot;) a objedn&aacute;vaj&iacute;c&iacute;m (d&aacute;le tak&eacute; &quot;</span><span class="c4">Objednatel</span><span class="c0">&quot;). </span></li>
            <li class="c7 li-bullet-0"><span class="c14">Poskytovatel je majitelem str&aacute;nky www.nebalik.cz (d&aacute;le jen &bdquo;</span><span class="c4">Webov&aacute; str&aacute;nka</span><span class="c0">&ldquo;) a v&scaron;ech autorsk&yacute;ch a p&#345;&iacute;buzn&yacute;ch pr&aacute;v spojen&yacute;ch se jm&eacute;nem a zna&#269;kou Nebal&iacute;k. </span></li>
            <li class="c7 li-bullet-0"><span class="c14">Zas&iacute;latelskou smlouvu lze uzav&#345;&iacute;t p&iacute;semn&#283; nebo ji lze pova&#382;ovat za platnou okam&#382;ikem, kdy Poskytovatel akceptuje po&#382;adavek Objednatele (pomoc&iacute; webov&eacute; str&aacute;nky, e-mailem, prost&#345;ednictv&iacute;m mobiln&iacute; aplikace nebo telefonicky) k proveden&iacute; p&#345;epravy z&aacute;silky za podm&iacute;nek ur&#269;en&yacute;ch Objednatelem. Poskytovatel si vyhrazuje, &#382;e ka&#382;d&aacute; objedn&aacute;vka slu&#382;eb Objednatelem je z&aacute;rove&#328; vyj&aacute;d&#345;en&yacute;m souhlasem s Obchodn&iacute;mi </span>
                <span class="c1 c9">podm&iacute;nkami a p&#345;edpokl&aacute;d&aacute; respektov&aacute;n&iacute; a dodr&#382;ov&aacute;n&iacute; ve&scaron;ker&yacute;ch ustanoven&iacute; v nich obsa&#382;en&yacute;ch.</span>
            </li>
            <li class="c7 li-bullet-0"><span class="c1">Zas&iacute;latelskou smlouvou pov&#283;&#345;uje Objednatel spole&#269;nost Evolution Concept s.r.o uzav&#345;en&iacute;m Zas&iacute;latelsk&eacute; smlouvy s P&#345;epravcem, kter&yacute; spl&#328;uje podm&iacute;nky pro</span>
                <span class="c14">&nbsp;realizaci slu&#382;by. Smlouvu uzav&iacute;r&aacute; Poskytovatel vlastn&iacute;m jm&eacute;nem na &uacute;&#269;et Objednatele.&nbsp;</span><span class="c4">Poskytovatel neprov&aacute;d&iacute; p&#345;epravu z&aacute;silek.</span>
                <span class="c0">&nbsp;Poskytovatel obstar&aacute;v&aacute; P&#345;epravn&iacute; slu&#382;by P&#345;epravce, tak, aby co nejl&eacute;pe vyhovovaly z&aacute;jm&#367;m Objednatele. Poskytovatel neodpov&iacute;d&aacute; za uskute&#269;n&#283;n&iacute;
                            p&#345;epravy z&aacute;silky, kterou obstaral. P&#345;evzet&iacute;, p&#345;epravu a doru&#269;en&iacute; z&aacute;silky a zaji&scaron;t&#283;n&iacute; organizace p&#345;epravy prov&aacute;d&iacute; P&#345;epravce vybran&yacute;
                            Poskytovatelem a zodpov&iacute;d&aacute; za kompletn&iacute; proveden&iacute; P&#345;epravy.</span>
            </li>
            <li class="c7 li-bullet-0"><span class="c0">Parametry z&aacute;silek a p&#345;epravn&iacute; podm&iacute;nky se &#345;&iacute;d&iacute; Obchodn&iacute;mi podm&iacute;nkami vybran&eacute;ho P&#345;epravce. </span></li>
            <li class="c7 li-bullet-0"><span class="c0">Reklamace se &#345;&iacute;d&iacute; v&yacute;hradn&#283; reklama&#269;n&iacute;m &#345;&aacute;dem konkr&eacute;tn&iacute;ho P&#345;epravce, v jeho&#382; &uacute;seku p&#345;epravy do&scaron;lo k domn&#283;l&eacute; &scaron;kodn&eacute; ud&aacute;losti.</span></li>
            <li class="c7 li-bullet-0"><span class="c0">Ve&scaron;ker&eacute; smluvn&iacute; vztahy mezi Poskytovatelem a Objednatelem jsou uzav&#345;eny v souladu s pr&aacute;vn&iacute;m &#345;&aacute;dem &#268;esk&eacute; republiky. Pr&aacute;vn&iacute; vztahy neupraven&eacute; Smlouvou nebo t&#283;mito Podm&iacute;nkami se &#345;&iacute;d&iacute; Ob&#269;ansk&yacute;m z&aacute;kon&iacute;kem (&#269;. 89/2012 Sb.), ve zn&#283;n&iacute; pozd&#283;j&scaron;&iacute;ch novel.</span></li>
            <li class="c7 li-bullet-0"><span class="c0">Obchodn&iacute; podm&iacute;nky zvolen&eacute;ho P&#345;epravce jsou p&#345;ilo&#382;en&eacute; k Zas&iacute;latelsk&eacute; smlouv&#283; a st&aacute;vaj&iacute; se sou&#269;&aacute;st&iacute; podm&iacute;nek Poskytovatele. Objednatel automaticky spotvrzen&iacute;m objedn&aacute;vky vyjad&#345;uje souhlas s&nbsp;obchodn&iacute;mi podm&iacute;nkami Poskytovatele.</span></li>
        </ol>
        <ol class="c12 lst-kix_list_1-0" start="2">
            <li class="c11 li-bullet-0"><span class="c9 c13">Pojmy</span></li>
        </ol>
        <ol class="c12 lst-kix_list_1-1 start" start="1">
            <li class="c7 li-bullet-0"><span class="c0">Poskytovatel (Zas&iacute;latel) &ndash; obstar&aacute;v&aacute; Z&aacute;kazn&iacute;kovi vlastn&iacute;m jm&eacute;nem na jeho &uacute;&#269;et p&#345;epravu Z&aacute;silky.</span></li>
            <li class="c7 li-bullet-0"><span class="c0">Objednatel (Z&aacute;kazn&iacute;k) &ndash; fyzick&aacute;, nebo pr&aacute;vnick&aacute; osoba objedn&aacute;vaj&iacute;c&iacute; slu&#382;by u Poskytovatele.</span></li>
            <li class="c7 li-bullet-0"><span class="c14">P&#345;epravce (Smluvn&iacute; p&#345;epravce, Dopravce) &ndash; druh specializovan&eacute; extern&iacute; p&#345;epravn&iacute; slu&#382;by, nebo kur&yacute;rn&iacute; firma, kter&aacute; fyzicky p&#345;epravuje z&aacute;silku a je zprost&#345;edkovan&aacute; Poskytovatelem, subjekt odli&scaron;n&yacute; od Provozovatele</span>
                <span class="c1 c9">. P&#345;epravce d&aacute;le zodpov&iacute;d&aacute; za balen&iacute; z&aacute;silky a jej&iacute; ochranu p&#345;ed po&scaron;kozen&iacute;m. </span>
            </li>
            <li class="c7 li-bullet-0"><span class="c1">Vlastn&iacute; p&#345;epravce - smluvn&iacute; partner Provozovatele a Objednatele, jemu&#382; Provozovatel na z&aacute;klad&#283; D&iacute;l&#269;&iacute; smlouvy o p&#345;ed&aacute;n&iacute; dat p&#345;ed&aacute; data tvo&#345;&iacute;c&iacute; Z&aacute;silku Objednatele. Provozovatel u Vlastn&iacute;ho p&#345;epravce nezprost&#345;edkov&aacute;v&aacute; P&#345;epravce,</span>
                <span class="c0">&nbsp;jenom p&#345;ed&aacute;v&aacute; data Objednavatelem nasmlouvan&eacute;mu p&#345;epravci. Vlastn&iacute; p&#345;epravce je subjekt odli&scaron;n&yacute; od Provozovatele,</span>
            </li>
            <li class="c7 li-bullet-0"><span class="c0">Odes&iacute;latel - fyzick&aacute; osoba p&#345;ed&aacute;vaj&iacute;c&iacute; z&aacute;silku kur&yacute;rovi.</span></li>
            <li class="c7 li-bullet-0"><span class="c0">P&#345;&iacute;jemce (Adres&aacute;t) - subjekt ozna&#269;en&yacute; na z&aacute;silce jako P&#345;&iacute;jemce. Osoba, kter&eacute; m&aacute; b&yacute;t z&aacute;silka ur&#269;ena.</span></li>
            <li class="c7 li-bullet-0"><span class="c0">Srovn&aacute;vac&iacute; a Objedn&aacute;vkov&yacute; Syst&eacute;m (Objedn&aacute;vkov&yacute; syst&eacute;m) &ndash; online rezerva&#269;n&iacute; slu&#382;ba umo&#382;&#328;uj&iacute;c&iacute; srovnat a objednat p&#345;epravn&iacute; slu&#382;by poskytovan&eacute; partnery Poskytovatele (P&#345;epravci), spr&aacute;vu a sledov&aacute;n&iacute; z&aacute;silek, platby za slu&#382;by Objednatelem. Slou&#382;&iacute;c&iacute; k zaznamen&aacute;v&aacute;n&iacute; dat t&yacute;kaj&iacute;c&iacute;ch se obsahu, hmotnosti, rozm&#283;r&#367;, hodnoty z&aacute;silek a adresn&iacute;ch &uacute;daj&#367;. Na z&aacute;klad&#283; t&#283;chto informac&iacute; vypo&#269;&iacute;t&aacute;v&aacute; cenu p&#345;epravn&eacute;ho, jen&#382; je pro Poskytovatele z&aacute;vazn&aacute; p&#345;i sjedn&aacute;v&aacute;n&iacute; slu&#382;eb. Syst&eacute;m je k dispozici na webov&yacute;ch str&aacute;nk&aacute;ch Poskytovatele. Za objedn&aacute;vkov&yacute; syst&eacute;m se pova&#382;uje i rozhran&iacute; mobiln&iacute; aplikac&iacute; Poskytovatele, zprost&#345;edkuj&iacute;c&iacute; slu&#382;by Nebal&iacute;k.cz.</span></li>
            <li class="c7 li-bullet-0"><span class="c0">U&#382;ivatelsk&yacute; &uacute;&#269;et &ndash; &#269;&aacute;st webov&eacute;ho rozhran&iacute; na str&aacute;nk&aacute;ch Poskytovatele, p&#345;&iacute;stupn&eacute;ho v&yacute;hradn&#283; Objednateli po zad&aacute;n&iacute; p&#345;&iacute;stupov&yacute;ch &uacute;daj&#367;, slou&#382;&iacute;c&iacute; pro obsluhu Objedn&aacute;vkov&eacute;ho syst&eacute;mu.</span></li>
            <li class="c7 li-bullet-0"><span class="c0">Z&aacute;silka - ob&aacute;lky, bal&iacute;ky a dal&scaron;&iacute; p&#345;edm&#283;ty p&#345;ipraven&eacute; Objednatelem k vyzvednut&iacute; kur&yacute;rem v&nbsp;&#345;&aacute;du stanoven&eacute;m stavu. </span></li>
            <li class="c7 li-bullet-0"><span class="c14">Bal&iacute;k - z&aacute;silka odeslan&aacute; P&#345;&iacute;jemci na z&aacute;klad&#283; jednoho n&aacute;kladov&eacute;ho </span><span class="c1 c9">listu. Jde o z&aacute;silku zabalenou P&#345;epravcem (Kur&yacute;rem). Jedna z&aacute;silka m&#367;&#382;e b&yacute;t tvo&#345;ena v&iacute;ce bal&iacute;ky.</span></li>
            <li class="c7 li-bullet-0"><span class="c0">V&iacute;cekusov&aacute; z&aacute;silka - z&aacute;silka skl&aacute;daj&iacute;c&iacute; se z n&#283;kolika bal&iacute;k&#367; zas&iacute;lan&yacute;ch jednomu p&#345;&iacute;jemci. Po&#269;et bal&iacute;k&#367; v takov&eacute; z&aacute;silce mus&iacute; souhlasit s&nbsp;po&#269;tem n&aacute;kladn&iacute;ch list&#367;.</span></li>
            <li class="c7 li-bullet-0"><span class="c0">Bal&iacute;kov&aacute; z&aacute;silka &ndash; z&aacute;silka nep&#345;esahuj&iacute;c&iacute; parametry Nadrozm&#283;rn&eacute; z&aacute;silky dle bodu 20.</span></li>
            <li class="c7 li-bullet-0"><span class="c0">Kusov&aacute; z&aacute;silka &ndash; z&aacute;silka jin&aacute; ne&#382; listov&aacute; nebo bal&iacute;kov&aacute;, s parametry (pokud nen&iacute; v podm&iacute;nk&aacute;ch vybran&eacute;ho P&#345;epravce uvedeno jinak):</span></li>
        </ol>
        <ol class="c12 lst-kix_list_1-2 start" start="1">
            <li class="c2 li-bullet-0"><span class="c0">hmotnost jednoho kusu nesm&iacute; p&#345;ekro&#269;it 1500 kg,</span></li>
            <li class="c2 li-bullet-0"><span class="c0">celkov&aacute; hmotnost z&aacute;silky nesm&iacute; p&#345;ekro&#269;it 2000 kg,</span></li>
            <li class="c2 li-bullet-0"><span class="c0">rozm&#283;ry nesm&#283;j&iacute; b&yacute;t v&#283;t&scaron;&iacute; ne&#382;: celkov&aacute; v&yacute;&scaron;ka 2,2 metru (v&#269;etn&#283; palety, podstavce), &scaron;&iacute;&#345;ka 2,0 metry, d&eacute;lka 4,0 metry,</span></li>
            <li class="c2 li-bullet-0"><span class="c0">objem m&#367;&#382;e b&yacute;t maxim&aacute;ln&#283; 13,3 m3,</span></li>
            <li class="c2 li-bullet-0"><span class="c0">celkov&aacute; hmotnost jedn&eacute; z&aacute;silky nesm&iacute; p&#345;ekro&#269;it 2000kg, nad 2001kg &#345;e&scaron;eno individu&aacute;ln&#283;,</span></li>
            <li class="c2 li-bullet-0"><span class="c0">po&#269;et kus&#367; v jedn&eacute; z&aacute;silce pro jednoho p&#345;&iacute;jemce m&#367;&#382;e b&yacute;t max. 10 kus&#367;, p&#345;itom sou&#269;et v&scaron;ech kus&#367; dan&eacute; z&aacute;silky mus&iacute; spl&#328;ovat limity podle bod&#367; a-d.</span></li>
        </ol>
        <ol class="c12 lst-kix_list_1-1" start="14">
            <li class="c7 li-bullet-0"><span class="c0">Identifika&#269;n&iacute; &#269;&iacute;slo &ndash; unik&aacute;tn&iacute; k&oacute;d generovan&yacute; Objedn&aacute;vkov&yacute;m syst&eacute;mem pro ka&#382;dou objedn&aacute;vku.</span></li>
            <li class="c7 li-bullet-0"><span class="c0">Doru&#269;en&iacute; z&aacute;silky - p&#345;ed&aacute;n&iacute; z&aacute;silky ur&#269;en&eacute;mu P&#345;&iacute;jemci u prvn&iacute;ch uzamykateln&yacute;ch dve&#345;&iacute; objektu na adrese udan&eacute; Objednatelem nebo osobn&iacute; odb&#283;r na v&yacute;dejn&iacute;ch m&iacute;stech.</span></li>
            <li class="c7 li-bullet-0"><span class="c0">V&yacute;dejn&iacute; a Sb&#283;rn&eacute; m&iacute;sto - v&yacute;dejn&iacute; a p&#345;&iacute;jmov&eacute; m&iacute;sto Poskytovatele nebo vybran&eacute; v&yacute;dejn&iacute; a p&#345;&iacute;jmov&eacute; m&iacute;sto smluvn&iacute;ho Dopravce. Aktu&aacute;ln&iacute; v&yacute;&#269;et V&yacute;dejn&iacute;ch a Sb&#283;rn&yacute;ch m&iacute;st je dostupn&yacute; v&nbsp;Objedn&aacute;vkov&eacute;m procesu. </span></li>
            <li class="c7 li-bullet-0"><span class="c0">Dob&iacute;rka - z&aacute;silka, oproti jej&iacute;mu&#382; fyzick&eacute;mu vyd&aacute;n&iacute; p&#345;&iacute;jemci je zas&iacute;latel povinen na z&aacute;klad&#283; pokynu p&#345;&iacute;kazce vybrat od p&#345;&iacute;jemce pen&#283;&#382;n&iacute; dob&iacute;rkovou &#269;&aacute;stku ve v&yacute;&scaron;i ur&#269;en&eacute; p&#345;&iacute;kazcem a n&aacute;sledn&#283; ji pouk&aacute;zat na &uacute;&#269;et specifikovan&yacute; p&#345;&iacute;kazcem.</span></li>
            <li class="c7 li-bullet-0"><span class="c0">Re&aacute;ln&aacute; hmotnost - celkov&aacute; hmotnost z&aacute;silky, kterou zjist&iacute; Poskytovatel nebo P&#345;epravce.</span></li>
            <li class="c7 li-bullet-0"><span class="c0">Obvodov&aacute; d&eacute;lka - sou&#269;et d&eacute;lky a obvodu z&aacute;silky. Obvodov&aacute; d&eacute;lka = 1xd&eacute;lka+2x&scaron;&iacute;&#345;ka+2xv&yacute;&scaron;ka.</span></li>
            <li class="c7 li-bullet-0"><span class="c0">Nadrozm&#283;rn&aacute; z&aacute;silka - sv&yacute;mi rozm&#283;ry, tvarem neodpov&iacute;d&aacute; n&aacute;sleduj&iacute;c&iacute;m parametr&#367;m (pokud nen&iacute; v podm&iacute;nk&aacute;ch vybran&eacute;ho p&#345;epravce uvedeno jinak):</span></li>
        </ol>
        <ol class="c12 lst-kix_list_1-2 start" start="1">
            <li class="c2 li-bullet-0"><span class="c0">maxim&aacute;ln&iacute; hmotnost 30 kg,</span></li>
            <li class="c2 li-bullet-0"><span class="c0">maxim&aacute;ln&iacute; d&eacute;lka 175 cm,</span></li>
            <li class="c2 li-bullet-0"><span class="c0">maxim&aacute;ln&iacute; obvodov&aacute; d&eacute;lka 300 cm.</span></li>
        </ol>
        <ol class="c12 lst-kix_list_1-1" start="21">
            <li class="c7 li-bullet-0"><span class="c0">Dopl&#328;kov&eacute; slu&#382;by &ndash; slu&#382;by mimo odesl&aacute;n&iacute; a zabalen&iacute; Z&aacute;silky. </span></li>
            <li class="c7 li-bullet-0"><span class="c0">D&iacute;l&#269;&iacute; smlouva o p&#345;ed&aacute;n&iacute; dat - smlouvu uzav&#345;enou mezi Objednatelem a Provozovatelem. Provozovatel se zavazuje p&#345;edat data ur&#269;en&eacute;mu Vlastn&iacute;mu p&#345;epravci o uzav&#345;en&eacute; Z&aacute;silce, kterou si Objednavatel zadal prost&#345;ednictv&iacute;m Objedn&aacute;vkov&eacute;ho syst&eacute;mu.</span></li>
            <li class="c7 li-bullet-0"><span class="c14">P&#345;epravn&iacute; &Scaron;t&iacute;tek (&Scaron;t&iacute;tek) &ndash; doklad vyti&scaron;t&#283;n&yacute; k z&aacute;silce (bal&iacute;ku) Objednatelem, </span><span class="c1 c9">povinn&eacute; dle podm&iacute;nek p&#345;epravce a jeho slu&#382;by.</span></li>
            <li class="c7 li-bullet-0"><span class="c1 c9">Balen&iacute; &ndash; slu&#382;ba, kterou poskytuje dopravce. To znamen&aacute; zabalen&iacute; z&aacute;silky a jej&iacute; ochranu p&#345;ed zni&#269;en&iacute;m b&#283;hem p&#345;epravy. </span></li>
            <li class="c7 li-bullet-0"><span class="c1 c9">P&#345;eprava (Spedice, P&#345;epravn&iacute; slu&#382;ba) &ndash; slu&#382;ba, kterou poskytuje dopravce. To znamen&aacute; p&#345;&iacute;jem, zabalen&iacute;, p&#345;epravu a doru&#269;en&iacute; z&aacute;silky.</span></li>
            <li class="c7 li-bullet-0"><span class="c1 c9">Kur&yacute;r (&#344;idi&#269;) - pracovn&iacute;k P&#345;epravce, nebo soukrom&aacute; osoba smluvn&#283; zav&aacute;zan&aacute; k&nbsp;p&#345;eprav&#283;. Doru&#269;uje z&aacute;silku na depo.</span></li>
            <li class="c7 li-bullet-0"><span class="c1 c9">Vnitrost&aacute;tn&iacute; z&aacute;silky - z&aacute;silky, jejich&#382; p&#345;eprava prob&iacute;h&aacute; v&yacute;hradn&#283; na &uacute;zem&iacute; &#268;esk&eacute; republiky.</span></li>
            <li class="c7 li-bullet-0"><span class="c1">Mezin&aacute;rodn&iacute; z&aacute;silky - z&aacute;silky,</span><span class="c0">&nbsp;jejich&#382; p&#345;eprava prob&iacute;h&aacute; tak&eacute; mimo &uacute;zem&iacute; &#268;esk&eacute; republiky.</span></li>
            <li class="c7 li-bullet-0"><span class="c0">P.O.BOX - zamykateln&aacute; po&scaron;tovn&iacute; p&#345;ihr&aacute;dka um&iacute;st&#283;n&aacute; na po&scaron;t&#283;.</span></li>
            <li class="c7 li-bullet-0"><span class="c0">Pracovn&iacute; den &ndash; ka&#382;d&yacute; den krom&#283; soboty, ned&#283;le, st&aacute;tn&iacute;ho sv&aacute;tku &#269;i jin&eacute;ho dne pracovn&iacute;ho klidu v zemi, kde se bal&iacute;k p&#345;eb&iacute;r&aacute; &#269;i doru&#269;uje, nebo p&#345;es kterou se p&#345;epravuje.</span></li>
        </ol>
        <ol class="c12 lst-kix_list_1-0" start="3">
            <li class="c11 li-bullet-0"><span class="c3">Povinnosti Objednatele</span></li>
        </ol>
        <ol class="c12 lst-kix_list_1-1 start" start="1">
            <li class="c7 li-bullet-0"><span class="c0">Objednatel se zavazuje, &#382;e se p&#345;ed odesl&aacute;n&iacute;m ka&#382;d&eacute; Z&aacute;silky d&#367;kladn&#283; sezn&aacute;m&iacute; s P&#345;epravn&iacute;mi podm&iacute;nkami ka&#382;d&eacute;ho P&#345;epravce, zvolen&eacute;ho pro p&#345;epravu bal&iacute;k&#367;. Objednatel se zejm&eacute;na zavazuje, &#382;e nezad&aacute; k p&#345;eprav&#283; takov&yacute; bal&iacute;k, kter&yacute; by u dan&eacute;ho P&#345;epravce dle jeho P&#345;epravn&iacute;ch podm&iacute;nek mohl z p&#345;epravy vylou&#269;en a &#382;e ka&#382;d&yacute; bal&iacute;k bude &#345;&aacute;dn&#283; p&#345;ipraven k p&#345;eprav&#283; tak, jak budou vy&#382;adovat P&#345;epravn&iacute; podm&iacute;nky dan&eacute;ho P&#345;epravce. Objednatel bere na v&#283;dom&iacute;, &#382;e P&#345;epravci jsou opr&aacute;vn&#283;n&iacute; sv&eacute; P&#345;epravn&iacute; podm&iacute;nky m&#283;nit a aktualizovat.</span></li>
            <li class="c7 li-bullet-0"><span class="c5">Objednatel se zad&aacute;n&iacute;m objedn&aacute;vky zavazuje, &#382;e z&aacute;silka bude v&nbsp;den p&#345;&iacute;jezdu p&#345;ipravena k bezpe&#269;n&eacute;mu p&#345;ed&aacute;n&iacute; kur&yacute;rovi, kter&yacute; n&aacute;sledn&#283; z&aacute;silku vyzvedne.</span></li>
            <li class="c7 li-bullet-0"><span class="c5">Objednatel prohla&scaron;uje, &#382;e z&aacute;silka bude v&nbsp;takov&eacute;m stavu, aby nezp&#367;sobila kur&yacute;rovi &uacute;jmu na majetku, zdrav&iacute; a neohrozila jeho bezpe&#269;nost. </span></li>
            <li class="c7 li-bullet-0"><span class="c1">Objednatel prohla&scaron;uje, &#382;e &uacute;daje j&iacute;m zadan&eacute; na webov&yacute;ch str&aacute;nk&aacute;ch</span><span class="c0">&nbsp;Poskytovatele jsou pravdiv&eacute; a odpov&iacute;daj&iacute; skute&#269;nosti. V p&#345;&iacute;pad&#283; v&#283;t&scaron;&iacute;ch skute&#269;n&yacute;ch rozm&#283;r&#367; nebo v&#283;t&scaron;&iacute; skute&#269;n&eacute; hmotnosti oproti zadan&yacute;m &uacute;daj&#367;m nemus&iacute; b&yacute;t z&aacute;silka p&#345;ijata k p&#345;eprav&#283; a jedn&aacute; se o d&#367;vod pro odstoupen&iacute; od p&#345;epravn&iacute; smlouvy. Odstoupen&iacute; od smlouvy o p&#345;eprav&#283; z tohoto d&#367;vodu se nedot&yacute;k&aacute; n&aacute;roku na sjednanou odm&#283;nu za obstar&aacute;n&iacute; p&#345;epravy. V p&#345;&iacute;pad&#283;, &#382;e z&aacute;silka bude p&#345;esto k p&#345;eprav&#283; p&#345;ijata, zavazuje se Objednatel k doplacen&iacute; rozd&iacute;lov&eacute; &#269;&aacute;stky dle nov&eacute; kalkulace ceny se zji&scaron;t&#283;n&yacute;mi parametry. Poskytovatel nem&aacute; povinnost upozornit na ne&uacute;plnost nebo nespr&aacute;vnost vlo&#382;en&yacute;ch &uacute;daj&#367;. </span></li>
            <li class="c7 li-bullet-0"><span class="c0">N&aacute;klady nav&iacute;c vznikl&eacute; v d&#367;sledku dodate&#269;n&yacute;ch po&#382;adavk&#367; Objednatele na vybran&eacute;ho P&#345;epravce jdou k t&iacute;&#382;i Objednatele.</span></li>
            <li class="c7 li-bullet-0"><span class="c0">Nen&iacute; mo&#382;no p&#345;epravit bal&iacute;k, ze kter&eacute;ho vy&#269;n&iacute;vaj&iacute; dlouh&eacute; a ostr&eacute; &#269;&aacute;sti zbo&#382;&iacute;.</span></li>
            <li class="c7 li-bullet-0"><span class="c0">K&#345;ehk&eacute; zbo&#382;&iacute; by m&#283;lo b&yacute;t &#345;&aacute;dn&#283; ozna&#269;eno &scaron;t&iacute;tkem, nap&#345;&iacute;klad:&bdquo;Pozor sklo&ldquo; nebo &bdquo;K&#345;ehk&eacute;&ldquo;. </span></li>
            <li class="c7 li-bullet-0"><span class="c0">Ka&#382;d&aacute; z&aacute;silka mus&iacute; b&yacute;t p&#345;ipraven&aacute; k p&#345;eprav&#283; dle Obchodn&iacute;ch podm&iacute;nek. Pokud nebude zabalen&aacute; v dostate&#269;n&eacute; kvalit&#283;, vyhrazujeme si pr&aacute;vo p&#345;epravu odm&iacute;tnout, p&#345;&iacute;padn&#283; neposkytnout reklama&#269;n&iacute; &#345;&iacute;zen&iacute;. </span></li>
            <li class="c7 li-bullet-0"><span class="c0">V p&#345;&iacute;pad&#283; hrub&eacute;ho poru&scaron;en&iacute; Obchodn&iacute;ch podm&iacute;nek, jako je nap&#345;&iacute;klad zas&iacute;l&aacute;n&iacute; zbo&#382;&iacute; uveden&eacute;ho v&nbsp;seznamu Zak&aacute;zan&yacute;ch v&#283;c&iacute; s n&aacute;sledn&yacute;m po&scaron;kozen&iacute;m jin&yacute;ch z&aacute;silek, &#269;i majetku Poskytovatele nebo P&#345;epravce, si Poskytovatel vyhrazuje pr&aacute;vo na n&aacute;hradu vznikl&eacute; &scaron;kody Objednatelem.</span></li>
            <li class="c7 li-bullet-0"><span class="c0">Objednatel se zavazuje informovat P&#345;&iacute;jemce o tom, &#382;e v p&#345;&iacute;pad&#283; ztr&aacute;ty &#269;&aacute;sti, nebo po&scaron;kozen&iacute; z&aacute;silky je P&#345;&iacute;jemce povinen bezodkladn&#283; vyhotovit z&aacute;pis s kur&yacute;rem, kter&yacute; bude obsahovat zp&#367;sob a rozsah po&scaron;kozen&iacute; nebo &#269;&aacute;ste&#269;n&eacute; ztr&aacute;ty. Protokol o po&scaron;kozen&iacute; mus&iacute; b&yacute;t sepsan&yacute; v p&#345;&iacute;tomnosti z&aacute;stupce kur&yacute;ra (po&#382;adovan&yacute; protokol mus&iacute; b&yacute;t &#269;iteln&#283; podepsan&yacute; p&#345;&iacute;jemcem a z&aacute;stupcem kur&yacute;ra doru&#269;uj&iacute;c&iacute;ho z&aacute;silku).</span></li>
            <li class="c7 li-bullet-0"><span class="c0">Objednatel mus&iacute; zajistit, &#382;e z&aacute;silka bude p&#345;ijata v c&iacute;lov&eacute;m m&iacute;st&#283;. Objednatel je povinen informovat Odes&iacute;latele o uzav&#345;en&eacute; smlouv&#283; s P&#345;epravcem.</span></li>
            <li class="c7 li-bullet-0"><span class="c0">P&#345;epravce a Poskytovatel m&aacute; pr&aacute;vo od Objednatele po&#382;adovat poskytnut&iacute; &uacute;daj&#367; o obsahu a povaze Z&aacute;silky. Objednatel je povinen t&eacute;to &#382;&aacute;dosti o poskytnut&iacute; &uacute;daj&#367; vyhov&#283;t.</span></li>
            <li class="c7 li-bullet-0"><span class="c0">Ve&scaron;ker&eacute; objedn&aacute;vky u&#269;in&#283;n&eacute; prost&#345;ednictv&iacute;m u&#382;ivatelsk&eacute;ho jm&eacute;na a hesla Objednatele (nezbytn&eacute;ho k pou&#382;&iacute;v&aacute;n&iacute; ve&scaron;ker&yacute;ch slu&#382;eb popsan&yacute;ch v syst&eacute;mu) se pova&#382;uj&iacute; za objedn&aacute;vku u&#269;in&#283;nou Objednatelem.</span></li>
            <li class="c7 li-bullet-0"><span class="c0">V p&#345;&iacute;pad&#283;, kdy jsou p&#345;edm&#283;tem slu&#382;by obzvl&aacute;&scaron;t&#283; cenn&eacute; z&aacute;silky, je Objednatel povinen ozn&aacute;mit Poskytovateli jejich skute&#269;nou hodnotu bu&#271; pro ka&#382;d&yacute; kus odd&#283;len&#283;, anebo pro jeden bal&iacute;k, p&#345;i&#269;em&#382; za obzvl&aacute;&scaron;t&#283; cennou z&aacute;silku se pova&#382;uje ka&#382;d&aacute; v&#283;c v hodnot&#283; vy&scaron;&scaron;&iacute; ne&#382; 25 000 K&#269; nebo bal&iacute;k v hodnot&#283; p&#345;evy&scaron;uj&iacute;c&iacute; tuto &#269;&aacute;stku. Pokud Objednatel nep&#345;iznal hodnotu z&aacute;silky v&yacute;&scaron;e popsan&yacute;m zp&#367;sobem, bude p&#345;&iacute;padn&eacute; pln&#283;n&iacute; z pojistn&eacute; ud&aacute;losti limitov&aacute;no jako v p&#345;&iacute;pad&#283; z&aacute;silky v hodnot&#283; do 25 000 K&#269;.</span></li>
            <li class="c7 li-bullet-0"><span class="c0">Objednatel bere na v&#283;dom&iacute;, &#382;e Objednatel a P&#345;epravce v p&#345;&iacute;padech, kdy existuje d&#367;vodn&eacute; podez&#345;en&iacute;, &#382;e z&aacute;silka Objednatele poru&scaron;uje platn&eacute; pr&aacute;vn&iacute; p&#345;edpisy, takovou z&aacute;silku neprodlen&#283; ozn&aacute;m&iacute; p&#345;&iacute;slu&scaron;n&eacute; instituci a zastav&iacute; p&#345;epravu a zajist&iacute; z&aacute;silky do doby inspekce proveden&eacute; p&#345;&iacute;slu&scaron;n&yacute;m org&aacute;nem.</span></li>
            <li class="c7 li-bullet-0"><span class="c14">Objednatel bere na v&#283;dom&iacute;, &#382;e Poskytovatel m&#367;&#382;e po&#382;adovat uhrazen&iacute; ceny za dodate&#269;n&eacute; slu&#382;by nesjednan&eacute; p&#345;i uzav&#345;en&iacute; Zas&iacute;latelsk&eacute; smlouvy, kter&eacute; Objednatel, Odes&iacute;latel nebo P&#345;&iacute;jemce od P&#345;epravce dodate&#269;n&#283; vy&#382;aduje a tak&eacute; pokuty &uacute;&#269;tovan&eacute; P&#345;epravcem za nedodr&#382;en&iacute; </span>
                <span class="c1 c9">p&#345;epravn&iacute;ch nebo Obchodn&iacute;ch podm&iacute;nek Objednatelem.</span>
            </li>
            <li class="c7 li-bullet-0"><span class="c1">Odesl&aacute;n&iacute;m objedn&aacute;vky se Objednatel zavazuje, &#382;e Z&aacute;silka bude pro P&#345;epravce p&#345;ipravena k p&#345;evzet&iacute; na adrese vyzvednut&iacute; v dob&#283; mezi 9 - 17:00 dne, kter&yacute; si ur&#269;&iacute; Objednatel. V p&#345;&iacute;pad&#283; nep&#345;edv&iacute;dan&yacute;ch okolnost&iacute; na stran&#283; P&#345;epravce m&#367;&#382;e b&yacute;t p&#345;evzet&iacute; ve vybran&yacute; den provedeno i d&#345;&iacute;ve nebo pozd&#283;ji. V&yacute;jime&#269;n&#283; m&#367;&#382;e doj&iacute;t k tomu, &#382;e v d&#367;sledku nep&#345;edv&iacute;dateln&yacute;ch okolnost&iacute; na stran&#283; P&#345;epravce</span>
                <span class="c0">&nbsp;bude vyzvednut&iacute; provedeno a&#382; dal&scaron;&iacute; pracovn&iacute; den.</span>
            </li>
        </ol>
        <ol class="c12 lst-kix_list_1-0" start="4">
            <li class="c11 li-bullet-0"><span class="c3">Povinnosti a z&aacute;vazky Poskytovatele</span></li>
        </ol>
        <ol class="c12 lst-kix_list_1-1 start" start="1">
            <li class="c7 li-bullet-0"><span class="c0">V&scaron;echny ceny uveden&eacute; na webov&yacute;ch str&aacute;nk&aacute;ch a ve webov&eacute; aplikaci Poskytovatele jsou v&#269;etn&#283; DPH, pokud nen&iacute; uvedeno jinak.</span></li>
            <li class="c7 li-bullet-0"><span class="c0">Poskytovatel si vyhrazuje pr&aacute;vo na zm&#283;nu cen&iacute;ku. D&aacute;le si vyhrazuje pr&aacute;vo na zm&#283;ny v zav&aacute;d&#283;n&iacute; nov&yacute;ch slu&#382;eb, &uacute;pravu, nebo zru&scaron;en&iacute; platn&yacute;ch slu&#382;eb, zav&aacute;d&#283;n&iacute; a ru&scaron;en&iacute; propaga&#269;n&iacute;ch akc&iacute; nebo prov&aacute;d&#283;n&iacute; zm&#283;n v nich.</span></li>
            <li class="c7 li-bullet-0"><span class="c0">Poskytovatel se skrze Objedn&aacute;vkov&yacute; syst&eacute;m zavazuje obstarat Objednateli P&#345;epravn&iacute; slu&#382;by v&#269;etn&#283; zabalen&iacute; z&aacute;silky, kter&eacute; poskytuj&iacute; smluvn&iacute; partne&#345;i &ndash; P&#345;epravci. Poskytovateli za zm&iacute;n&#283;n&eacute; slu&#382;by n&aacute;le&#382;&iacute; odm&#283;na uveden&aacute; p&#345;i v&yacute;po&#269;tu ceny p&#345;epravy v Objedn&aacute;vkov&eacute;m syst&eacute;mu nebo sd&#283;len&aacute; telefonicky.</span></li>
            <li class="c7 li-bullet-0"><span class="c0">Informace spojen&eacute; se z&aacute;silkou, v&#269;etn&#283; dokladu o doru&#269;en&iacute;, je mo&#382;no po dobu 1 roku zp&#283;tn&#283; vyhledat na z&aacute;klad&#283; p&iacute;semn&eacute; &#382;&aacute;dosti.</span></li>
            <li class="c7 li-bullet-0"><span class="c0">P&#345;i zm&#283;n&#283; adresy z&aacute;silky v pr&#367;b&#283;hu p&#345;epravy, provede Poskytovatel nebo P&#345;epravce up&#345;esn&#283;n&iacute; a n&aacute;sledn&eacute; doru&#269;en&iacute; z&aacute;silky na spr&aacute;vnou adresu. N&aacute;sledn&eacute; doru&#269;en&iacute; po prvn&iacute;m ne&uacute;sp&#283;&scaron;n&eacute;m pokusu o doru&#269;en&iacute; z&aacute;silky m&#367;&#382;e b&yacute;t zpoplatn&#283;no, dle podm&iacute;nek vybran&eacute;ho P&#345;epravce a jeho slu&#382;by.</span></li>
            <li class="c7 li-bullet-0"><span class="c0">Poskytovatel se zavazuje informovat Objednatele o jak&eacute;koliv informaci, kterou obdr&#382;&iacute; od P&#345;epravce, t&yacute;kaj&iacute;c&iacute; se dopravy Z&aacute;silky Objednatele</span></li>
            <li class="c7 li-bullet-0"><span class="c0">Obecn&aacute; proml&#269;ec&iacute; doba pr&aacute;v ze zas&iacute;latelsk&eacute; smlouvy &#269;in&iacute; 3 roky. Pr&aacute;va vznikl&aacute; z celkov&eacute;ho zni&#269;en&iacute; nebo ztr&aacute;ty z&aacute;silky &#269;i z jej&iacute;ho po&scaron;kozen&iacute; nebo opo&#382;d&#283;n&eacute;ho doru&#269;en&iacute; se proml&#269;uj&iacute; v 1 roce. Proml&#269;ec&iacute; lh&#367;ta za&#269;&iacute;n&aacute; b&#283;&#382;et ode dne, kdy byla z&aacute;silka doru&#269;ena, p&#345;&iacute;padn&#283;, kdy m&#283;la b&yacute;t doru&#269;ena. Jednoro&#269;n&iacute; proml&#269;ec&iacute; lh&#367;ta neplat&iacute; u pr&aacute;va vznikl&eacute;ho z &uacute;mysln&eacute;ho po&scaron;kozen&iacute; povinnosti nebo je-li jednoro&#269;n&iacute; proml&#269;ec&iacute; lh&#367;ta ujedn&aacute;na v neprosp&#283;ch slab&scaron;&iacute; strany (&sect;630 odst. 2 NOZ). Ostatn&iacute; pr&aacute;va ze zas&iacute;latelsk&eacute; smlouvy se proml&#269;uj&iacute; ve 3 letech.</span></li>
            <li class="c7 li-bullet-0"><span class="c1 c9">Objednatel m&#367;&#382;e objednat slu&#382;bu Poskytovatele 7 dn&iacute; v t&yacute;dnu, v&nbsp;jak&yacute;koliv &#269;as. Vnitrost&aacute;tn&iacute; nebo exportn&iacute; Objedn&aacute;vky na z&aacute;silky, kter&eacute; Poskytovatel p&#345;ijme po 17:45, importn&iacute; po 14:30 a d&aacute;le v sobotu, ned&#283;li &#269;i ve st&aacute;tn&iacute; sv&aacute;tek mohou b&yacute;t realizov&aacute;ny a&#382; n&aacute;sleduj&iacute;c&iacute; pracovn&iacute; den.</span></li>
            <li class="c7 li-bullet-0"><span class="c1 c9">Poskytovatel neposkytuje p&#345;epravu z&aacute;silek do P.O.BOX.</span></li>
            <li class="c7 li-bullet-0"><span class="c4">Garance vyzvednut&iacute; a doru&#269;en&iacute;:</span></li>
        </ol>
        <ol class="c12 lst-kix_list_1-2 start" start="1">
            <li class="c2 li-bullet-0"><span class="c0">Dodac&iacute; lh&#367;ty a term&iacute;n vyzvednut&iacute; se v&#382;dy uv&aacute;d&iacute; odhadem a jsou pouze p&#345;ibli&#382;n&eacute;, nikoli zaru&#269;en&eacute;.</span></li>
            <li class="c2 li-bullet-0"><span class="c0">Poskytovatel neposkytuje garanci doru&#269;en&iacute;, pokud tak nen&iacute; p&#345;&iacute;mo uvedeno u popisu slu&#382;by, ve&scaron;ker&eacute; tyto garance p&#345;eb&iacute;r&aacute; zvolen&yacute; P&#345;epravce.</span></li>
            <li class="c2 li-bullet-0"><span class="c0">Do p&#345;epravn&iacute;ch &#269;as&#367; p&#345;epravn&iacute;ch slu&#382;eb se nepo&#269;&iacute;t&aacute; den p&#345;evzet&iacute; z&aacute;silky p&#345;epravcem, pokud nen&iacute; uvedeno jinak, doba pot&#345;ebn&aacute; pro celn&iacute; kontroly, kontroly p&#345;&iacute;slu&scaron;n&yacute;ch st&aacute;tn&iacute;ch org&aacute;nu, opravu chybn&yacute;ch celn&iacute;ch dokumentu apod., prodlen&iacute; zp&#367;soben&eacute; vy&scaron;&scaron;&iacute; moc&iacute; nebo okolnostmi vypl&yacute;vaj&iacute;c&iacute;mi z provozu, jako&#382; i okolnostmi, kter&eacute; nemohl p&#345;epravce odvr&aacute;tit nebo p&#345;edv&iacute;dat.</span></li>
            <li class="c2 li-bullet-0"><span class="c0">Z&aacute;kladn&iacute; poplatek nezahrnuje dodate&#269;n&eacute; poplatky za slu&#382;by pou&#382;it&eacute; p&#345;i procesu objedn&aacute;v&aacute;n&iacute;, nap&#345;. dob&iacute;rku, dopl&#328;kov&eacute; poji&scaron;t&#283;n&iacute;, vr&aacute;cen&iacute; dokument&#367;, atd.</span></li>
            <li class="c2 li-bullet-0"><span class="c0">P&#345;i osobn&iacute;m odb&#283;ru na V&yacute;dejn&iacute;ch m&iacute;stech se za okam&#382;ik doru&#269;en&iacute; pova&#382;uje vyzvednut&iacute; p&#345;edm&#283;tn&eacute; z&aacute;silky.</span></li>
            <li class="c2 li-bullet-0"><span class="c0">Doru&#269;en&iacute; z&aacute;silky v sobotu nebo v ned&#283;li je mo&#382;n&eacute; pouze takto ozna&#269;en&yacute;ch slu&#382;eb a v&yacute;hradn&#283; v p&#345;&iacute;pad&#283; objedn&aacute;n&iacute; resp. jej&iacute;ho vyzvednut&iacute; P&#345;epravcem v bezprost&#345;edn&#283; p&#345;edch&aacute;zej&iacute;c&iacute; p&aacute;tek.</span></li>
        </ol>
        <ol class="c12 lst-kix_list_1-0" start="5">
            <li class="c11 li-bullet-0"><span class="c3">Omezen&iacute; odpov&#283;dnosti poskytovatele</span></li>
        </ol>
        <ol class="c12 lst-kix_list_1-1 start" start="1">
            <li class="c7 li-bullet-0"><span class="c14">Poskytovatel nenese nebezpe&#269;&iacute; vzniku &scaron;kody na z&aacute;silce v pr&#367;b&#283;hu p&#345;epravy, pokud s&aacute;m neprov&aacute;d&iacute; tuto p&#345;epravu. Poskytovatel odpov&iacute;d&aacute; pouze ze &scaron;kodu na p&#345;evzat&eacute; z&aacute;silce vznikl&eacute; v dob&#283; p&#345;i obstar&aacute;v&aacute;n&iacute; p&#345;epravy. Poskytovatel neodpov&iacute;d&aacute; za poru&scaron;en&iacute; smluv sjednan&yacute;ch s P&#345;epravci, neodpov&iacute;d&aacute; tedy Objednateli za &scaron;kody, kter&eacute; mu vzniknou poru&scaron;en&iacute;m p&#345;epravn&iacute; smlouvy ze strany </span>
                <span class="c1 c9">P&#345;epravce.</span>
            </li>
            <li class="c7 li-bullet-0"><span class="c1 c9">Poskytovatel nenese zodpov&#283;dnost za zp&#367;sob balen&iacute; zvolen&yacute;m P&#345;epravcem nebo Kur&yacute;rem. Odpov&#283;dnost za zvolen&iacute; vhodn&eacute;ho Kur&yacute;ra, P&#345;epravn&iacute; spole&#269;nosti a zp&#367;sobu balen&iacute; nese Objednatel.</span></li>
            <li class="c7 li-bullet-0"><span class="c1">Poskytovatel neodpov&iacute;d&aacute; za p&#345;&iacute;padn&eacute; nedoru&#269;en&iacute;</span><span class="c0">&nbsp;z&aacute;silky. Nezodpov&iacute;d&aacute; za jakoukoli jinou, i nep&#345;&iacute;mou, &scaron;kodu, neodpov&iacute;d&aacute; za u&scaron;l&yacute; zisk plynouc&iacute; z pou&#382;&iacute;v&aacute;n&iacute; servisu Nebal&iacute;ku spojen&yacute; se ztr&aacute;tou, po&scaron;kozen&iacute;m nebo zpo&#382;d&#283;n&iacute;m z&aacute;silky. Poskytovatel neodpov&iacute;d&aacute; za objedn&aacute;vky nespln&#283;n&eacute; z technick&yacute;ch d&#367;vod&#367;, jako jsou nap&#345;. v&yacute;padku nebo poruchy webov&yacute;ch str&aacute;nek Poskytovatele. Poskytovatel neodpov&iacute;d&aacute; za zm&#283;ny a transakce Objednatele. Skute&#269;nou &scaron;kodou se rozum&iacute; &#269;&aacute;stka, o kterou se zmen&scaron;ila hodnota Z&aacute;silky.</span></li>
            <li class="c7 li-bullet-0"><span class="c0">Objednatel je povinen zjistit si v&scaron;echny pot&#345;ebn&eacute; informace o mo&#382;nosti p&#345;epravy zbo&#382;&iacute; do zvolen&eacute; destinace. Poskytovatel nenese &#382;&aacute;dnou zodpov&#283;dnost za vr&aacute;cen&iacute; zbo&#382;&iacute; p&#345;epravcem, nebo celn&iacute;m &uacute;&#345;adem z d&#367;vodu nespln&#283;n&iacute; t&#283;chto podm&iacute;nek. Bere na z&#345;etel r&#367;zn&eacute; pokyny a podm&iacute;nky pro doru&#269;ov&aacute;n&iacute; pro r&#367;zn&eacute; p&#345;epravn&iacute; spole&#269;nosti</span></li>
            <li class="c7 li-bullet-0"><span class="c0">Poskytne-li Objednatel nespr&aacute;vn&eacute; &uacute;daje o obsahu z&aacute;silky nebo zabezpe&#269;&iacute;-li tuto &scaron;patn&#283;, jde o d&#367;vod zpro&scaron;t&#283;n&iacute; se odpov&#283;dnosti za po&scaron;kozen&iacute; nebo nedoru&#269;en&iacute; z&aacute;silky.</span></li>
            <li class="c7 li-bullet-0"><span class="c0">V p&#345;&iacute;pad&#283; ztr&aacute;ty nebo zni&#269;en&iacute; &#269;&aacute;sti z&aacute;silky se v&yacute;&scaron;e od&scaron;kodn&#283;n&iacute; po&#269;&iacute;t&aacute; ve vztahu k velikosti &#269;&aacute;sti z&aacute;silky, kter&eacute; se t&yacute;k&aacute; &scaron;koda.</span></li>
            <li class="c7 li-bullet-0"><span class="c14">Poskytovatel nenese odpov&#283;dnost za chybn&eacute; doru&#269;en&iacute; &#269;i nedoru&#269;en&iacute; Z&aacute;silky v p&#345;&iacute;pad&#283;, pokud byla &scaron;patn&#283; nebo nejednozna&#269;n&#283; adresov&aacute;na &#269;i ozna&#269;ena. Pot&#345;ebn&yacute; &#269;as na celn&iacute; odbaven&iacute; se m&#367;&#382;e li&scaron;it podle m&iacute;stn&iacute;ch p&#345;edpis&#367; a souvisej&iacute;c&iacute; v&yacute;daje nese objednatel nebo adres&aacute;t. V p&#345;&iacute;pad&#283; bal&iacute;k&#367; ur&#269;en&yacute;ch na export</span>
                <span class="c1 c9">, jestli&#382;e P&#345;&iacute;jemce nespln&iacute; svoje z&aacute;vazky k uhrazen&iacute; v&yacute;daj&#367; souvisej&iacute;c&iacute;ch s celn&iacute;m odbaven&iacute;m, jdou tyto v&yacute;daje k t&iacute;&#382;i Objednatele.</span>
            </li>
            <li class="c7 li-bullet-0"><span class="c1 c9">Poskytovatel nenese odpov&#283;dnost za nedostate&#269;n&eacute; zabalen&iacute; z&aacute;silky ze strany Kur&yacute;ra nebo P&#345;epravn&iacute; spole&#269;nosti. </span></li>
            <li class="c7 li-bullet-0"><span class="c1 c9">Ve v&#283;cech realizace objedn&aacute;vek a reklamac&iacute; je Objednatel jedin&yacute;m legitimn&iacute;m vlastn&iacute;kem z&aacute;silky. Mo&#382;n&eacute; n&aacute;hrady budou pouk&aacute;z&aacute;ny na jeho bankovn&iacute; &uacute;&#269;et.</span></li>
            <li class="c7 li-bullet-0"><span class="c1">Pouze Objednatel je u Poskytovatele opr&aacute;vn&#283;n&yacute;</span><span class="c0">&nbsp;podat reklamaci.</span></li>
            <li class="c7 li-bullet-0"><span class="c0">Objednatel je povinen ozn&aacute;men&iacute; o zp&#367;soben&iacute; &scaron;kody doru&#269;it Poskytovateli ve lh&#367;t&#283; 7 dn&#367; od vzniku &scaron;kody. Marn&yacute;m uplynut&iacute;m t&eacute;to lh&#367;ty jeho n&aacute;rok na n&aacute;hradu mo&#382;n&eacute; &scaron;kody zanik&aacute;. V p&#345;&iacute;pad&#283;, &#382;e opo&#382;d&#283;n&iacute; &#269;i nedoru&#269;en&iacute; z&aacute;silky P&#345;&iacute;jemci nebo neproveden&iacute; sjednan&eacute; objedn&aacute;vky zap&#345;&iacute;&#269;in&iacute; st&aacute;vka, pracovn&iacute; spory, blok&aacute;da, &scaron;patn&eacute; po&#269;as&iacute;, siln&yacute; provoz, mechanick&eacute; po&scaron;kozen&iacute; nebo p&#345;et&iacute;&#382;en&iacute; silnic a d&aacute;lnic &#269;i jak&aacute;koliv jin&aacute; p&#345;&iacute;&#269;ina vymykaj&iacute;c&iacute; se kontrole Poskytovatele nebo P&#345;epravce, jde o d&#367;vod zpro&scaron;t&#283;n&iacute; se odpov&#283;dnosti.</span></li>
            <li class="c7 li-bullet-0"><span class="c0">Poskytovatel nenese odpov&#283;dnost, jedn&aacute;-li se o p&#345;&iacute;pady s vylou&#269;en&iacute;m odpov&#283;dnosti dopravce podle ustanoven&iacute; mezin&aacute;rodn&iacute;ch &uacute;mluv nebo n&aacute;rodn&iacute;ho pr&aacute;va.</span></li>
            <li class="c7 li-bullet-0"><span class="c0">Poskytovatel nenese odpov&#283;dnost, jestli&#382;e nespln&iacute; v&#367;&#269;i Odes&iacute;lateli jak&eacute;koli z&aacute;vazky, pokud tyto z&aacute;vazky vypl&yacute;vaj&iacute; z:</span></li>
        </ol>
        <ol class="c12 lst-kix_list_1-2 start" start="1">
            <li class="c2 li-bullet-0"><span class="c0">okolnost&iacute; mimo kontrolu poskytovatele nebo p&#345;epravce jako bou&#345;ek, povodn&iacute;, po&#382;&aacute;r&#367;, chorob, mlhy, sn&#283;hu nebo mrazu,&#382;iveln&yacute;ch nebezpe&#269;&iacute;.</span></li>
            <li class="c2 li-bullet-0"><span class="c0">z&aacute;sah&#367; vy&scaron;&scaron;&iacute; moci, zejm&eacute;na: v&aacute;lek, nehod, &#269;in&#367; ve&#345;ejn&yacute;ch nep&#345;&aacute;tel, st&aacute;vek, embarg, ohro&#382;en&iacute; ze vzduchu, m&iacute;stn&iacute;ch spor&#367; nebo ob&#269;ansk&yacute;ch nepokoj&#367;,</span></li>
            <li class="c2 li-bullet-0"><span class="c0">celost&aacute;tn&iacute;ch nebo m&iacute;stn&iacute;ch naru&scaron;en&iacute; provozn&#283;-technick&eacute;ho zaji&scaron;t&#283;n&iacute; leteck&yacute;ch nebo pozemn&iacute;ch dopravn&iacute;ch s&iacute;t&iacute; a technicko-technologick&yacute;ch probl&eacute;m&#367; dopravn&iacute;ch nebo p&#345;epravn&iacute;ch prost&#345;edk&#367; nebo obdobn&yacute;ch za&#345;&iacute;zen&iacute;,</span></li>
            <li class="c2 li-bullet-0"><span class="c0">vnit&#345;n&iacute; vady z&aacute;silky nebo skryt&eacute; vady,</span></li>
            <li class="c2 li-bullet-0"><span class="c0">trestn&eacute; &#269;iny t&#345;et&iacute;ch osob jako nap&#345;. loupe&#382;,</span></li>
            <li class="c2 li-bullet-0"><span class="c0">poru&scaron;en&iacute; (nebo poru&scaron;en&iacute; jin&eacute; strany, kter&aacute; m&aacute; z&aacute;jem na z&aacute;silce a zp&#367;sobila va&scaron;e poru&scaron;en&iacute;) povinnost&iacute; podle t&#283;chto Podm&iacute;nek,</span></li>
            <li class="c2 li-bullet-0"><span class="c0">toho, &#382;e hmotnost nebo rozm&#283;ry z&aacute;silky nesouhlas&iacute; s deklarovanou hmotnost&iacute; p&#345;i jej&iacute;m odes&iacute;l&aacute;n&iacute;,</span></li>
            <li class="c2 li-bullet-0"><span class="c0">toho, &#382;e obsah z&aacute;silky zahrnuje zak&aacute;zan&yacute; druh zbo&#382;&iacute;, i p&#345;epravce takovou z&aacute;silku p&#345;ijal,</span></li>
            <li class="c2 li-bullet-0"><span class="c0">p&#345;&iacute;jemce odm&iacute;tne nap&#345;&iacute;klad zaplatit &#269;&aacute;stku za dob&iacute;rku, nebo P&#345;&iacute;jemce odm&iacute;tne z&aacute;silku bez ud&aacute;n&iacute; v&aacute;&#382;n&eacute;ho d&#367;vodu,</span></li>
            <li class="c2 li-bullet-0"><span class="c0">&#269;inu nebo zanedb&aacute;n&iacute; kter&eacute;hokoli celn&iacute;ho, bezpe&#269;nostn&iacute;ho, st&aacute;tn&iacute;ho nebo obdobn&eacute;ho &uacute;&#345;edn&iacute;ka nebo &#269;initele leteck&eacute; spole&#269;nosti &#269;i leti&scaron;t&#283;,</span></li>
            <li class="c2 li-bullet-0"><span class="c0">odm&iacute;tnut&iacute; Poskytovatele prov&eacute;st jak&eacute;koliv nez&aacute;konn&eacute; platby v zastoupen&iacute; Objednatele.</span></li>
        </ol>
        <ol class="c12 lst-kix_list_1-1" start="14">
            <li class="c7 li-bullet-0"><span class="c0">V&nbsp;p&#345;&iacute;pad&#283;, &#382;e dojde k&nbsp;jak&eacute;mukoliv poru&scaron;en&iacute; ustanoven&iacute; p&#345;epravn&iacute;ho &#345;&aacute;du vybran&eacute;ho p&#345;epravce nebo Zas&iacute;latelsk&eacute; smlouvy Objednatelem, m&#367;&#382;e Poskytovatel zablokovat nebo odstranit &uacute;&#269;et Objednatele, a tak&eacute; ihned odstoupit od Zas&iacute;latelsk&eacute; smlouvy bez p&iacute;semn&eacute;ho od&#367;vodn&#283;n&iacute;.</span></li>
        </ol>
        <ol class="c12 lst-kix_list_1-0" start="6">
            <li class="c11 li-bullet-0"><span class="c3">Balen&iacute;</span></li>
        </ol>
        <ol class="c12 lst-kix_list_1-1 start" start="1">
            <li class="c7 li-bullet-0"><span class="c0">Z&aacute;silku p&#345;ed&aacute;v&aacute; Objednatel vybran&eacute;mu Kur&yacute;rovi nezabalenou. Kur&yacute;r doru&#269;&iacute; z&aacute;silku na spedi&#269;n&iacute; depo, kde dojde k zabalen&iacute; z&aacute;silky a jej&iacute; p&#345;eprav&#283; na m&iacute;sto doru&#269;en&iacute;. </span></li>
            <li class="c7 li-bullet-0"><span class="c0">Spedi&#269;n&iacute; depo tak&eacute; vypo&#269;&iacute;t&aacute; a up&#345;esn&iacute; skute&#269;n&eacute; n&aacute;klady, pot&#345;ebn&eacute; k zabalen&iacute; z&aacute;silky. Cena se odv&iacute;j&iacute; dle rozm&#283;r&#367; z&aacute;silky, v&aacute;hy, tvaru a ceny a tedy i spot&#345;ebovan&eacute;ho bal&iacute;c&iacute;ho materi&aacute;lu.</span></li>
            <li class="c7 li-bullet-0"><span class="c0">Rozd&iacute;l mezi skute&#269;n&yacute;mi n&aacute;klady a &#269;&aacute;stkou, zablokovanou na kart&#283; Objednavatele se vrac&iacute; na platebn&iacute; kartu Objednavatele, zadanou v objedn&aacute;vac&iacute;m procesu, do 5 pracovn&iacute;ch dn&iacute;, od data zji&scaron;t&#283;n&iacute; skute&#269;n&yacute;ch n&aacute;klad&#367;.</span></li>
        </ol>
        <p class="c21 c24"><span class="c0"></span></p>
        <ol class="c12 lst-kix_list_1-0" start="7">
            <li class="c19 li-bullet-0"><span class="c3">Doru&#269;en&iacute;</span></li>
        </ol>
        <ol class="c12 lst-kix_list_1-1 start" start="1">
            <li class="c7 li-bullet-0"><span class="c0">K p&#345;ed&aacute;n&iacute; z&aacute;silky ur&#269;en&eacute;mu P&#345;&iacute;jemci doch&aacute;z&iacute; u prvn&iacute;ch uzamykateln&yacute;ch dve&#345;&iacute; objektu (jestli&#382;e nen&iacute; vyu&#382;ita p&#345;&iacute;platkov&aacute; slu&#382;ba) na adrese udan&eacute; Objednatelem nebo osobn&iacute;m odb&#283;rem na V&yacute;dejn&iacute;ch m&iacute;stech, jestli&#382;e to zvolen&aacute; slu&#382;ba podporuje.</span></li>
            <li class="c7 li-bullet-0"><span class="c0">P&#345;i doru&#269;en&iacute; p&#345;ed&aacute;v&aacute; Kur&yacute;r P&#345;&iacute;jemci pe&#269;liv&#283; zabalen&yacute;, p&#345;esn&#283; a &#269;iteln&#283; adresovan&yacute; bal&iacute;k. Kur&yacute;ra nem&#367;&#382;e p&#345;i doru&#269;ov&aacute;n&iacute; &#269;ekat; v p&#345;&iacute;pad&#283;, &#382;e nen&iacute; mo&#382;n&eacute; z&aacute;silku ihned p&#345;edat p&#345;&iacute;jemci, m&#367;&#382;e b&yacute;t doru&#269;ov&aacute;n&iacute; p&#345;eru&scaron;eno.</span></li>
            <li class="c7 li-bullet-0"><span class="c0">Z&aacute;silka bude vyd&aacute;na proti podpisu P&#345;&iacute;jemci nebo osob&aacute;m, o nich&#382; lze podle okolnost&iacute; p&#345;edpokl&aacute;dat, &#382;e jsou opr&aacute;vn&#283;ny z&aacute;silku p&#345;evz&iacute;t. Jde o osoby p&#345;&iacute;tomn&eacute; v m&iacute;st&#283; adres&aacute;ta a osoby, kter&eacute; disponuj&iacute; plnou moc&iacute; a to i mimo m&iacute;sta doru&#269;en&iacute;. Doru&#269;en&iacute; z&aacute;silky P&#345;&iacute;jemce, p&#345;&iacute;padn&#283; jin&aacute; osoba dle prvn&iacute; a druh&eacute; v&#283;ty tohoto ustanoven&iacute;, potvrzuje sv&yacute;m podpisem na formul&aacute;&#345;i P&#345;epravce. Kur&yacute;r nen&iacute; povinen ov&#283;&#345;ovat toto&#382;nost, pokud tak nen&iacute; uvedeno u zvolen&eacute; slu&#382;by. Doru&#269;en&iacute; se pova&#382;uje za uskute&#269;n&#283;n&eacute; i v p&#345;&iacute;pad&#283;, &#382;e z&aacute;silku na doru&#269;ovac&iacute; adrese p&#345;evezme osoba k p&#345;evzet&iacute; z&aacute;silky neopr&aacute;vn&#283;n&aacute;, nemohl-li na tuto skute&#269;nost kur&yacute;r z jemu dostupn&yacute;ch informac&iacute; usuzovat.</span></li>
            <li class="c7 li-bullet-0"><span class="c0">Kur&yacute;r p&#345;i podpisu po&#382;aduje i k&#345;estn&iacute; jm&eacute;no p&#345;ej&iacute;maj&iacute;c&iacute;ho. Jedn&aacute;-li se o doru&#269;en&iacute; na dob&iacute;rku, tak plat&iacute; &#382;e a&#382; po vyplacen&iacute; z&aacute;silky a podeps&aacute;n&iacute; dokladu o doru&#269;en&iacute;, m&#367;&#382;e P&#345;&iacute;jemce krabici otev&#345;&iacute;t, resp. zkontrolovat jej&iacute; obsah.</span></li>
            <li class="c7 li-bullet-0"><span class="c0">Nedojde-li k doru&#269;en&iacute; v pl&aacute;novan&yacute; den z d&#367;vodu, kter&yacute; nen&iacute; na stran&#283; Kur&yacute;ra (P&#345;&iacute;jemce je nep&#345;&iacute;tomen), prob&#283;hne dal&scaron;&iacute; pokus o doru&#269;en&iacute; v n&#283;kter&eacute;m z dal&scaron;&iacute;ch pracovn&iacute;ch dn&#367;. V p&#345;&iacute;pad&#283; nep&#345;&iacute;tomnosti P&#345;&iacute;jemce je Kur&yacute;r opr&aacute;vn&#283;n se rozhodnout, &#382;e bal&iacute;k doru&#269;&iacute; na alternativn&iacute; adresu (sousedn&iacute; adresa nebo m&iacute;stn&iacute; po&scaron;ta &#269;i doru&#269;ovac&iacute; m&iacute;sto). V tom p&#345;&iacute;pad&#283; zanech&aacute; Kur&yacute;r na prvn&iacute; adrese, v&nbsp;mobiln&iacute; aplikaci nebo skrze webov&eacute; rozhran&iacute; Objednateli a P&#345;&iacute;jemci vzkaz s informac&iacute; o tom, kam bude Z&aacute;silka doru&#269;ena.</span></li>
            <li class="c7 li-bullet-0"><span class="c0">K&nbsp;p&#345;evzet&iacute; bal&iacute;ku na alternativn&iacute; adrese m&aacute; P&#345;&iacute;jemce 7 dn&iacute; od prvn&iacute;ho pokusu o doru&#269;en&iacute;. Pokud z&aacute;silka nen&iacute; vyzvednuta, vyhrazuje si Poskytovatel pr&aacute;vo Z&aacute;silku na n&aacute;klady Objednatele vr&aacute;tit, a to na adresu Odes&iacute;latele. Vznikl&eacute; n&aacute;klady nav&iacute;c budou v souladu s t&#283;mito V&scaron;eobecn&yacute;mi podm&iacute;nkami na&uacute;&#269;tov&aacute;ny Objednateli.</span></li>
            <li class="c7 li-bullet-0"><span class="c0">Slu&#382;ba se pova&#382;uje za neprovedenou bez n&aacute;roku na refundaci, pokud:</span></li>
        </ol>
        <ol class="c12 lst-kix_list_1-2 start" start="1">
            <li class="c2 li-bullet-0"><span class="c0">nastanou p&#345;&iacute;pady uveden&eacute; v odstavci E, bod 15, v odstavci C bod 8 a v odstavci H obchodn&iacute;ch podm&iacute;nek,</span></li>
            <li class="c2 li-bullet-0"><span class="c0">z&aacute;silka nebyla zaplacena nebo byla uhrazena &#269;&aacute;stka ni&#382;&scaron;&iacute;, ne&#382; bylo stanoveno a Objednatel nebo P&#345;&iacute;jemce odm&iacute;tne zaplatit poplatek nebo rozd&iacute;l v poplatc&iacute;ch,</span></li>
            <li class="c2 li-bullet-0"><span class="c0">z&aacute;silku nelze doru&#269;it P&#345;&iacute;jemci ani vr&aacute;tit Objednateli z d&#367;vodu chyb&#283;j&iacute;c&iacute; nebo nespr&aacute;vn&eacute; adresy Objednatele.</span></li>
        </ol>
        <ol class="c12 lst-kix_list_1-1" start="8">
            <li class="c7 li-bullet-0"><span class="c0">V p&#345;&iacute;pad&#283; prok&aacute;zan&eacute;ho odm&iacute;tnut&iacute; p&#345;ijet&iacute; z&aacute;silky P&#345;&iacute;jemcem, je z&aacute;silka vr&aacute;cena Objednateli na jeho n&aacute;klady. Jestli&#382;e to bude mo&#382;n&eacute;, bude &#269;&aacute;stka ode&#269;tena z P&#345;&iacute;kazcova pre-Paid &uacute;&#269;tu nebo bude Objednatel zasl&aacute;na v&yacute;zva k zaplacen&iacute;.</span></li>
            <li class="c7 li-bullet-0"><span class="c0">P&#345;&iacute;jemce je povinen ponechat si Protokol o p&#345;evzet&iacute; z&aacute;silky a uk&aacute;zat jeho origin&aacute;l v p&#345;&iacute;pad&#283; reklamace.</span></li>
        </ol>
        <ol class="c12 lst-kix_list_1-0" start="8">
            <li class="c11 li-bullet-0"><span class="c3">Reklamace</span></li>
        </ol>
        <ol class="c12 lst-kix_list_1-1 start" start="1">
            <li class="c7 li-bullet-0"><span class="c0">Poskytovatel - provozovatel online syst&eacute;mu Nebal&iacute;k.cz nenese odpov&#283;dnost za p&#345;&iacute;padnou ztr&aacute;tu &#269;i po&scaron;kozen&iacute; Z&aacute;silky nebo jeho obsahu. Zodpov&iacute;d&aacute; pouze za v&yacute;b&#283;r P&#345;epravn&iacute; slu&#382;by &ndash; P&#345;epravce.</span></li>
            <li class="c7 li-bullet-0"><span class="c0">Poskytovatel neodpov&iacute;d&aacute; za pln&#283;n&iacute; na stran&#283; P&#345;epravce. P&#345;&iacute;padn&eacute; n&aacute;roky Objednatele v&#367;&#269;i P&#345;epravci vypl&yacute;vaj&iacute;c&iacute; z&nbsp;p&#345;epravn&iacute; smlouvy uplat&#328;uje Poskytovatel vlastn&iacute;m jm&eacute;nem na &uacute;&#269;et Objednatele. Objednatel je povinen poskytnout Poskytovateli pot&#345;ebnou sou&#269;innost, zejm&eacute;na doklady t&yacute;kaj&iacute;c&iacute; se z&aacute;silky, jej&iacute; hodnoty apod. Uplat&#328;ov&aacute;n&iacute; n&aacute;rok&#367; Poskytovatelem dle p&#345;edchoz&iacute; v&#283;ty kon&#269;&iacute; vy&#345;&iacute;zen&iacute;m reklamace P&#345;epravcem v souladu s &#269;l. G. 18.</span></li>
            <li class="c7 li-bullet-0"><span class="c0">Na &#382;&aacute;dost Objednatele postoup&iacute; Poskytovatel ve&scaron;ker&eacute; n&aacute;roky a pr&aacute;va v&#367;&#269;i P&#345;epravci a poskytne Objednateli pot&#345;ebnou sou&#269;innost. Objednatel je pak povinen vym&aacute;hat n&aacute;roky a pr&aacute;va na P&#345;epravci s&aacute;m.</span></li>
            <li class="c7 li-bullet-0"><span class="c0">Reklamace u Smluvn&iacute;ho p&#345;epravce podl&eacute;h&aacute; lh&#367;t&aacute;m a Obchodn&iacute;m podm&iacute;nk&aacute;m dan&eacute;ho P&#345;epravce. Poskytovatel uplatn&iacute; sv&yacute;m jm&eacute;nem na &uacute;&#269;et Objednatele u P&#345;epravce n&aacute;roky v souladu s podm&iacute;nkami upraven&yacute;mi v &#269;l. G. 5 a&#382; G. 16.</span></li>
            <li class="c7 li-bullet-0"><span class="c0">Poskytovatel ani P&#345;epravci nenese odpov&#283;dnost za neproveden&iacute; nebo nespr&aacute;vn&eacute; proveden&iacute; p&#345;epravn&iacute;ch slu&#382;eb zejm&eacute;na pokud:</span></li>
        </ol>
        <ol class="c12 lst-kix_list_1-2 start" start="1">
            <li class="c2 li-bullet-0"><span class="c0">z&aacute;silka byla ozna&#269;ena &scaron;patnou adresou (nap&#345;. chyba v p&#345;&iacute;jmen&iacute;, ulici, &#269;&iacute;sle nebo v m&#283;st&#283; P&#345;&iacute;jemce, n&aacute;zvu spole&#269;nosti, apod.),</span></li>
            <li class="c2 li-bullet-0"><span class="c0">p&#345;&iacute;jemce zm&#283;nil bydli&scaron;t&#283; nebo m&iacute;sto, v n&#283;m&#382; se nach&aacute;z&iacute; s&iacute;dlo (v p&#345;&iacute;pad&#283; firmy),</span></li>
            <li class="c2 li-bullet-0"><span class="c0">skute&#269;n&yacute; obsah z&aacute;silky nesouhlas&iacute; s uveden&yacute;m obsahem p&#345;i odes&iacute;l&aacute;n&iacute;,</span></li>
            <li class="c2 li-bullet-0"><span class="c0">hmotnost z&aacute;silky nesouhlas&iacute; s deklarovanou hmotnost&iacute; p&#345;i odes&iacute;l&aacute;n&iacute;,</span></li>
            <li class="c2 li-bullet-0"><span class="c0">p&#345;&iacute;jemce odm&iacute;tne splnit podm&iacute;nky doru&#269;en&iacute; z&aacute;silky (nap&#345;&iacute;klad odm&iacute;tne p&#345;ebrat z&aacute;silku, nebo zaplatit dob&iacute;rku),</span></li>
            <li class="c2 li-bullet-0"><span class="c0">se jedn&aacute; o p&#345;edm&#283;ty vylou&#269;en&eacute; z p&#345;epravy,</span></li>
            <li class="c2 li-bullet-0"><span class="c0">dojde ke vzniku mimo&#345;&aacute;dn&yacute;ch ud&aacute;lost&iacute;, nap&#345;&iacute;klad: &scaron;patn&eacute; pov&#283;trnostn&iacute; podm&iacute;nky, mlha, n&iacute;zk&eacute; teploty, napadnut&iacute; velk&eacute;ho mno&#382;stv&iacute; sn&#283;hu, n&aacute;led&iacute;, st&aacute;vky, povodn&#283;, zem&#283;t&#345;esen&iacute;, po&#382;&aacute;ry, apod.</span></li>
        </ol>
        <ol class="c12 lst-kix_list_1-1" start="6">
            <li class="c7 li-bullet-0"><span class="c0">Po&scaron;kozen&iacute;m se rozum&iacute; zm&#283;na stavu, tj. zm&#283;na jakosti, rozm&#283;r&#367;, struktury, st&aacute;losti, slo&#382;en&iacute; v&#283;c&iacute; tvo&#345;&iacute;c&iacute;ch z&aacute;silku, kterou je mo&#382;no odstranit opravou, nebo takov&aacute; zm&#283;na stavu, kterou sice nelze odstranit opravou, ale p&#345;esto je v&#283;c pou&#382;iteln&aacute; k p&#367;vodn&iacute;mu &uacute;&#269;elu.</span></li>
            <li class="c7 li-bullet-0"><span class="c0">Zni&#269;en&iacute;m se rozum&iacute; takov&aacute; zm&#283;na stavu v&#283;c&iacute; tvo&#345;&iacute;c&iacute;ch z&aacute;silku, kterou nelze odstranit opravou a v&#283;c nelze d&aacute;le pou&#382;&iacute;vat k p&#367;vodn&iacute;mu &uacute;&#269;elu.</span></li>
            <li class="c7 li-bullet-0"><span class="c0">V p&#345;&iacute;pad&#283; po&scaron;kozen&iacute; z&aacute;silky p&#345;im&#283;&#345;en&eacute; n&aacute;klady na opravu, kter&eacute; jsou nutn&eacute; pro uveden&iacute; z&aacute;silky do p&#367;vodn&iacute;ho stavu, v&#269;etn&#283; n&aacute;klad&#367; na demont&aacute;&#382; a mont&aacute;&#382; v&#283;ci. Od t&#283;chto n&aacute;klad&#367; se ode&#269;te cena zpen&#283;&#382;iteln&yacute;ch a vyu&#382;iteln&yacute;ch zbytk&#367; nahrazovan&yacute;ch &#269;&aacute;st&iacute;. Pokud se po&scaron;kozen&aacute; z&aacute;silka neoprav&iacute; a je pou&#382;iteln&aacute; k p&#367;vodn&iacute;mu &uacute;&#269;elu i s po&scaron;kozen&iacute;m, potom m&aacute; Objednatel n&aacute;rok na n&aacute;hradu &scaron;kody ve v&yacute;&scaron;i rozd&iacute;lu cen z&aacute;silky p&#345;ed po&scaron;kozen&iacute;m a po vzniku &scaron;kody. Hodnota po&scaron;kozen&eacute; z&aacute;silky je &#269;&aacute;stka stanoven&aacute; odborn&yacute;m posudkem, p&#345;&iacute;padn&#283; &#269;ist&yacute; v&yacute;t&#283;&#382;ek z prodeje po&scaron;kozen&eacute; z&aacute;silky, pokud Objednatel s prodejem souhlas&iacute;.</span></li>
            <li class="c7 li-bullet-0"><span class="c0">V p&#345;&iacute;pad&#283; zni&#269;en&iacute; nebo ztr&aacute;ty z&aacute;silky skute&#269;nou hodnotu z&aacute;silky v dob&#283; p&#345;evzet&iacute; z&aacute;silky P&#345;epravcem, p&#345;&iacute;padn&#283; jej&iacute; &#269;&aacute;sti, pokud se ztrat&iacute; nebo zni&#269;&iacute; jen jej&iacute; &#269;&aacute;st. Hodnota z&aacute;silky je udan&aacute; ve smlouv&#283; mezi Poskytovatelem a Objednatelem.</span></li>
            <li class="c7 li-bullet-0"><span class="c0">U po&scaron;kozen&yacute;ch z&aacute;silek je Objednatel, P&#345;&iacute;jemce i Poskytovatel povinen umo&#382;nit ohled&aacute;n&iacute; z&aacute;silky tak&eacute; z&aacute;stupci poji&scaron;&#357;ovny P&#345;epravce.</span></li>
            <li class="c7 li-bullet-0"><span class="c0">V p&#345;&iacute;pad&#283;, &#382;e podkladem pro stanoven&iacute; hodnoty po&scaron;kozen&eacute;ho &#269;i poh&#345;e&scaron;ovan&eacute;ho obsahu z&aacute;silky je da&#328;ov&yacute; doklad v zahrani&#269;n&iacute; m&#283;n&#283;, p&#345;epo&#269;&iacute;t&aacute; se &#269;&aacute;stka na t&eacute;to faktu&#345;e uveden&aacute; dle sm&#283;nn&eacute;ho kurzu vyhl&aacute;&scaron;en&eacute;ho &#268;eskou n&aacute;rodn&iacute; bankou platn&eacute;ho ke dni p&#345;evzet&iacute; z&aacute;silky ke slu&#382;b&#283;.</span></li>
            <li class="c7 li-bullet-0"><span class="c0">Z&aacute;silka se pova&#382;uje za ztracenou, jestli&#382;e nedoraz&iacute; do m&iacute;sta ur&#269;en&iacute; uveden&eacute;ho v n&aacute;kladn&iacute;m listu do 14 dn&#367; od p&#345;edpokl&aacute;dan&eacute;ho &#269;asu doru&#269;en&iacute; v p&#345;&iacute;pad&#283; vnitrost&aacute;tn&iacute; z&aacute;silky a 30 dn&#367; v p&#345;&iacute;pad&#283; mezin&aacute;rodn&iacute; z&aacute;silky.</span></li>
            <li class="c7 li-bullet-0"><span class="c14">Zjevn&eacute; poru&scaron;en&iacute; nebo po&scaron;kozen&iacute; obalu a zji&scaron;t&#283;n&yacute;ch ztr&aacute;t m&#367;&#382;e b&yacute;t rozporov&aacute;no nejpozd&#283;ji p&#345;i doru&#269;en&iacute; Z&aacute;silky&nbsp;za p&#345;&iacute;tomnosti Kur&yacute;ra seps&aacute;n ve dni doru&#269;en&iacute; z&aacute;silky. O rozsahu po&scaron;kozen&iacute; nebo &#269;&aacute;ste&#269;n&eacute; ztr&aacute;t&#283; obsahu z&aacute;silky mus&iacute; b&yacute;t na m&iacute;st&#283; seps&aacute;n&nbsp;</span>
                <span class="c4">Protokol o po&scaron;kozen&iacute; (Protokol o &scaron;kod&#283;)</span><span class="c0">.</span></li>
            <li class="c7 li-bullet-0"><span class="c0">V p&#345;&iacute;pad&#283; &scaron;kody, kter&aacute; nen&iacute; p&#345;i doru&#269;en&iacute; zjevn&aacute;, vznikne opr&aacute;vn&#283;n&yacute; n&aacute;rok na n&aacute;hradu pouze v p&#345;&iacute;pad&#283;, je-li tento n&aacute;rok p&#345;edlo&#382;en Objednatelem do 3 pracovn&iacute;ch dn&#367; od doru&#269;en&iacute; nebo vr&aacute;cen&iacute; z&aacute;silky a pokud byly p&#345;&iacute;&#269;iny po&scaron;kozen&iacute; prokazateln&#283; na stran&#283; P&#345;epravce. P&#345;i neporu&scaron;en&eacute;m balen&iacute; z&aacute;silky nemus&iacute; P&#345;epravce odpov&iacute;dat za mechanick&eacute;, elektrick&eacute;, nebo elektronick&eacute; po&scaron;kozen&iacute; obsahu, i kdy&#382; vnit&#345;n&iacute; balen&iacute; z&aacute;silky bylo dostate&#269;n&eacute;.</span></li>
            <li class="c7 li-bullet-0"><span class="c4">Reklamace t&yacute;kaj&iacute;c&iacute; se ztr&aacute;ty, po&scaron;kozen&iacute;, zni&#269;en&iacute; nebo ztr&aacute;ty obsahu z&aacute;silky mus&iacute; obsahovat</span><span class="c0">:</span></li>
        </ol>
        <ol class="c12 lst-kix_list_1-2 start" start="1">
            <li class="c2 li-bullet-0"><span class="c0">n&aacute;zev nebo jm&eacute;no a adresu &#382;adatele,</span></li>
            <li class="c2 li-bullet-0"><span class="c0">p&#367;vodn&iacute; reklama&#269;n&iacute; st&iacute;&#382;nost nebo vyti&scaron;t&#283;n&yacute; reklama&#269;n&iacute; formul&aacute;&#345;,</span></li>
            <li class="c2 li-bullet-0"><span class="c0">identifika&#269;n&iacute; &#269;&iacute;slo (ID) z&aacute;silky v r&aacute;mci syst&eacute;mu Nebal&iacute;k.cz,</span></li>
            <li class="c2 li-bullet-0"><span class="c0">popis obsahu z&aacute;silky v&#269;etn&#283; uveden&iacute; ceny jednotliv&yacute;ch polo&#382;ek,</span></li>
            <li class="c2 li-bullet-0"><span class="c0">&#269;&iacute;slo bankovn&iacute;ho &uacute;&#269;tu,</span></li>
            <li class="c2 li-bullet-0"><span class="c0">origin&aacute;l n&aacute;kladn&iacute;ho listu (kopie ponech&aacute;na kur&yacute;rem), kopii dokladu (ov&#283;&#345;enou podle origin&aacute;lu) potvrzuj&iacute;c&iacute; v&yacute;&scaron;i &scaron;kody v z&aacute;vislosti na jej&iacute;m typu (n&aacute;kupn&iacute; fakturu, paragon, pokladn&iacute; doklad / dohodu o n&aacute;kupu / prodeji, origin&aacute;l v&yacute;po&#269;tu n&aacute;klad&#367; na v&yacute;robu produktu vystaven&yacute; v&yacute;robcem nebo &uacute;&#269;et za opravu / kalkulaci n&aacute;klad&#367; na opravu / fakturu za opravu) a p&#367;vodn&iacute; Protokol o &scaron;kod&#283; (v&yacute;tisk pat&#345;&iacute;c&iacute; po&scaron;kozen&eacute;mu),</span></li>
            <li class="c2 li-bullet-0"><span class="c0">posudek servisu, oprav&aacute;&#345;e &#269;i jin&eacute; osoby, kter&aacute; provedla opravu z&aacute;silky, pokud byla z&aacute;silka po &scaron;kodn&iacute; ud&aacute;losti opravov&aacute;na, nebo vy&#382;aduj&iacute;-li to okolnosti p&#345;&iacute;padu,</span></li>
            <li class="c2 li-bullet-0"><span class="c0">fotodokumentaci u z&aacute;silek s &#269;&aacute;ste&#269;nou ztr&aacute;tou nebo po&scaron;kozen&iacute;m. Fotodokumentace p&#345;edlo&#382;en&aacute; Poskytovateli mus&iacute; obsahovat i fotografie obalu, p&#345;epravn&iacute;ho &scaron;t&iacute;tku, jako&#382; i obsahu z&aacute;silky tak, aby se dalo posoudit, zda &scaron;koda na z&aacute;silce je v p&#345;&iacute;&#269;inn&eacute; souvislosti s po&scaron;kozen&iacute;m obalu,</span></li>
            <li class="c2 li-bullet-0"><span class="c0">v p&#345;&iacute;pad&#283; nedoru&#269;en&iacute; z&aacute;silky &#269;estn&eacute; prohl&aacute;&scaron;en&iacute; P&#345;&iacute;jemce, &#382;e dot&#269;enou Z&aacute;silku nep&#345;evzal,</span></li>
            <li class="c2 li-bullet-0"><span class="c0">podpis &#382;adatele,</span></li>
            <li class="c2 li-bullet-0"><span class="c0">doklad prokazuj&iacute;c&iacute; opr&aacute;vn&#283;n&iacute; k reklamaci, jde-li o st&iacute;&#382;nost z&aacute;stupce &#382;adatele.</span></li>
        </ol>
        <ol class="c12 lst-kix_list_1-1" start="16">
            <li class="c7 li-bullet-0"><span class="c0">N&aacute;roky plynouc&iacute; z nespr&aacute;vn&eacute;ho proveden&iacute; slu&#382;eb, pozb&yacute;vaj&iacute; platnost v p&#345;&iacute;pad&#283; p&#345;ijet&iacute; z&aacute;silky bez v&yacute;hrad.</span></li>
            <li class="c7 li-bullet-0"><span class="c0">Pr&aacute;vo k uplatn&#283;n&iacute; n&aacute;rok&#367; vypl&yacute;vaj&iacute;c&iacute;ch z reklamace m&#367;&#382;e b&yacute;t Objednateli pozastaveno do doby, dokud tento Objednatel nevyrovn&aacute; v&scaron;echny svoje &uacute;hrady, poplatky, n&aacute;klady a dal&scaron;&iacute; v&yacute;daje.</span></li>
            <li class="c7 li-bullet-0"><span class="c0">Na reklamace ohl&aacute;&scaron;en&eacute; po lh&#367;t&#283; uveden&eacute; v bod&#283; 13, reklamace nedostate&#269;n&#283; p&#345;esn&eacute; nebo ne&uacute;pln&eacute;, reklamace nahl&aacute;&scaron;en&eacute; po uplynut&iacute; lh&#367;ty pro pod&aacute;n&iacute; st&iacute;&#382;nosti, nebo reklamace podan&eacute; neopr&aacute;vn&#283;nou osobou nebude br&aacute;n z&#345;etel.</span></li>
            <li class="c7 li-bullet-0"><span class="c0">Po prozkoum&aacute;n&iacute; st&iacute;&#382;nosti Pojistitelem P&#345;epravce, sd&#283;l&iacute; Poskytovatel, P&#345;epravce nebo Pojistitel P&#345;epravce do 30 dn&#367; od p&#345;ijet&iacute; &uacute;pln&eacute; dokumentace reklamace p&iacute;semn&#283; &#382;adateli, zda jeho reklamace byla uzn&aacute;na, &#269;&aacute;ste&#269;n&#283; uzn&aacute;na nebo zam&iacute;tnuta. Za sezn&aacute;men&iacute; s rozhodnut&iacute;m lze pova&#382;ovat i odesl&aacute;n&iacute; rozhodnut&iacute; o reklamaci na emailovou adresu Objednatele.</span></li>
            <li class="c7 li-bullet-0"><span class="c0">Reklamace se pova&#382;uje za uzav&#345;enou, pokud P&#345;epravce nebo Pojistitel jm&eacute;nem P&#345;epravce odm&iacute;tne uznat reklamaci a tak&eacute; pokud nezaplat&iacute; vym&aacute;hanou pohled&aacute;vku v term&iacute;nu do 6 m&#283;s&iacute;c&#367; ode dne p&#345;ijet&iacute; pln&eacute; dokumentace reklamace. Rozhodnut&iacute; P&#345;epravce o vy&#345;&iacute;zen&iacute; reklamace Z&aacute;silky z konkr&eacute;tn&iacute;ho reklama&#269;n&iacute;ho d&#367;vodu je kone&#269;n&eacute; a nem&#283;nn&eacute;. K dal&scaron;&iacute;m reklamac&iacute;m t&eacute;&#382;e Z&aacute;silky z t&eacute;ho&#382; reklama&#269;n&iacute;ho d&#367;vodu nebude P&#345;epravce p&#345;ihl&iacute;&#382;et.</span></li>
            <li class="c7 li-bullet-0"><span class="c14">Objednatel, kter&eacute;mu byla uzn&aacute;na reklamace, m&#367;&#382;e b&yacute;t vyzv&aacute;n k vystaven&iacute; </span><span class="c1 c9">&Scaron;kodov&eacute; faktury ve v&yacute;&scaron;i uznan&eacute; reklamace.</span></li>
            <li class="c7 li-bullet-0"><span class="c1 c9">Objednatel je povinen se &#345;&iacute;dit Reklama&#269;n&iacute;m &#345;&aacute;dem uveden&yacute;m na internetov&eacute; adrese www.nebalik.cz</span></li>
            <li class="c7 li-bullet-0"><span class="c1">Pokud Poskytovatel uzn&aacute; uplatn&#283;nou</span><span class="c0">&nbsp;&scaron;kodu v pln&eacute; v&yacute;&scaron;i, tj. ve v&yacute;&scaron;i hodnoty p&#345;epravovan&eacute; Z&aacute;silky a Objednateli sd&#283;l&iacute;, &#382;e &scaron;kodu uhrad&iacute;, je povinen Objednatel zajistit vyd&aacute;n&iacute; po&scaron;kozen&eacute; nebo zni&#269;en&eacute; z&aacute;silky Poskytovateli.</span></li>
            <li class="c7 li-bullet-0"><span class="c0">Pokud dojde k po&scaron;kozen&iacute;, zni&#269;en&iacute; nebo ztr&aacute;t&#283; p&#345;epravovan&eacute; Z&aacute;silky a Objednatel uplatn&iacute; u Poskytovatele n&aacute;rok na n&aacute;hradu &scaron;kody ve v&yacute;&scaron;i ceny z&aacute;silky a Poskytovatel p&#345;&iacute;kazci celou cenu z&aacute;silky uhrad&iacute;, p&#345;ech&aacute;z&iacute; vlastnick&eacute; pr&aacute;vo k Z&aacute;silce z Objednatele na Poskytovatele.</span></li>
        </ol>
        <ol class="c12 lst-kix_list_1-0" start="9">
            <li class="c11 li-bullet-0"><span class="c13 c9">P&#345;edm&#283;ty vylou&#269;en&eacute; z p&#345;epravy</span></li>
        </ol>
        <ol class="c12 lst-kix_list_1-1 start" start="1">
            <li class="c7 li-bullet-0"><span class="c0">Objednatel je povinen o Podm&iacute;nk&aacute;ch a o parametrech stanoven&yacute;ch Podm&iacute;nkami informovat t&#345;et&iacute; osoby, kter&eacute; budou p&#345;ed&aacute;vat P&#345;epravci z&aacute;silky k p&#345;eprav&#283;, a zajistit, aby z&aacute;silky p&#345;ed&aacute;van&eacute; k p&#345;eprav&#283; na z&aacute;klad&#283; jeho objedn&aacute;vky vyhovovaly t&#283;mto Podm&iacute;nk&aacute;m. Za spln&#283;n&iacute; t&#283;chto po&#382;adavk&#367; Objednatel odpov&iacute;d&aacute; stejn&#283;, jako by z&aacute;silku k p&#345;eprav&#283; p&#345;ed&aacute;val s&aacute;m.</span></li>
            <li class="c7 li-bullet-0"><span class="c4 c9">Poskytovatel si vymi&#328;uje, aby obsahem p&#345;epravovan&eacute; Z&aacute;silky nebylo n&aacute;sleduj&iacute;c&iacute; i &#269;&aacute;sti (pokud tak nedovoluj&iacute; p&#345;epravn&iacute; podm&iacute;nky zvolen&eacute;ho P&#345;epravce):</span></li>
        </ol>
        <ol class="c12 lst-kix_list_1-2 start" start="1">
            <li class="c2 li-bullet-0"><span class="c0">z&aacute;silka tvo&#345;en&aacute; n&#283;kolika pospojovan&yacute;mi krabicemi,</span></li>
            <li class="c2 li-bullet-0"><span class="c0">zbo&#382;&iacute; podl&eacute;haj&iacute;c&iacute; zvl&aacute;&scaron;tn&iacute;mu re&#382;imu (nebezpe&#269;&iacute; n&aacute;kazy), naka&#382;liv&eacute; a zap&aacute;chaj&iacute;c&iacute; zbo&#382;&iacute;,</span></li>
            <li class="c2 li-bullet-0"><span class="c0">obligace, zneu&#382;iteln&eacute; &scaron;eky,</span></li>
            <li class="c2 li-bullet-0"><span class="c0">sypk&eacute; zbo&#382;&iacute; v s&aacute;&#269;c&iacute;ch, nebo pytl&iacute;ch,</span></li>
            <li class="c2 li-bullet-0"><span class="c0">losy z vylosovan&yacute;ch loteri&iacute;, prav&eacute; perly,</span></li>
            <li class="c2 li-bullet-0"><span class="c0">drah&eacute; kovy a kameny, zlato, finan&#269;n&iacute; hotovost, kreditn&iacute; karty, doklady, ceniny apod.,</span></li>
            <li class="c2 li-bullet-0"><span class="c0">z&aacute;silky, u nich&#382; chyb&iacute; pot&#345;ebn&eacute; &uacute;daje o odes&iacute;lateli a p&#345;&iacute;jemci,</span></li>
            <li class="c2 li-bullet-0"><span class="c0">z&aacute;silky, jejich&#382; obsah by mohl zp&#367;sobit &scaron;kodu na majetku, nebo zdrav&iacute; (zejm&eacute;na: v&yacute;bu&scaron;niny, ho&#345;laviny, radioaktivn&iacute; l&aacute;tky, &#382;&iacute;raviny nebo zap&aacute;chaj&iacute;c&iacute; l&aacute;tky, zbran&#283; a munici, a dal&scaron;&iacute; l&aacute;tky),</span></li>
            <li class="c2 li-bullet-0"><span class="c0">&#382;iv&iacute; &#382;ivo&#269;ichov&eacute; a rostliny,</span></li>
            <li class="c2 li-bullet-0"><span class="c0">&scaron;perky a hodinky (vyjma bi&#382;uterie a b&#283;&#382;n&yacute;ch hodinek), jejich&#382; hodnota p&#345;esahuje 12tis K&#269; nebo ekvivalent t&eacute;to &#269;&aacute;stky v m&iacute;stn&iacute; m&#283;n&#283; za bal&iacute;k,</span></li>
            <li class="c2 li-bullet-0"><span class="c0">um&#283;leck&aacute; d&iacute;la, antikvari&aacute;tn&iacute; p&#345;edm&#283;ty,</span></li>
            <li class="c2 li-bullet-0"><span class="c0">z&aacute;silky s tepeln&#283; nestabiln&iacute;m obsahem,</span></li>
            <li class="c2 li-bullet-0"><span class="c0">z&aacute;silky adresovan&eacute; jen sm&#283;rovac&iacute;m &#269;&iacute;slem (bez ud&aacute;n&iacute; pln&eacute; a platn&eacute; adresy),</span></li>
            <li class="c2 li-bullet-0"><span class="c4">z&aacute;silky, kter&eacute; nejsou k&nbsp;odesl&aacute;n&iacute; p&#345;ipraveny zp&#367;sobem odpov&iacute;daj&iacute;c&iacute;m jejich v&aacute;ze, tvaru a povaze obsahu, nebo po&#382;adavk&#367;m P&#345;epravce,</span></li>
            <li class="c2 li-bullet-0"><span class="c0">z&aacute;silka obsahuj&iacute;c&iacute; drogy nebo l&eacute;ky a psychotropn&iacute; l&aacute;tky, s v&yacute;jimkou p&#345;edm&#283;t&#367;, je&#382; zas&iacute;laj&iacute; z&aacute;konn&#283; k tomu opr&aacute;vn&#283;n&eacute; instituce pro v&#283;deck&eacute; nebo zdravotnick&eacute; &uacute;&#269;ely,</span></li>
            <li class="c2 li-bullet-0"><span class="c0">ostatky lid&iacute; a &#382;ivo&#269;ich&#367;,</span></li>
            <li class="c2 li-bullet-0"><span class="c0">z&aacute;silky, jejich&#382; obsah nen&iacute; zbaven provozn&iacute;ch kapalin &ndash; oleje, benz&iacute;nu apod.,</span></li>
            <li class="c2 li-bullet-0"><span class="c0">vitr&aacute;&#382;e a jin&eacute; zbo&#382;&iacute; s&nbsp;rozm&#283;rn&yacute;mi sklen&#283;n&yacute;mi v&yacute;pln&#283;mi,</span></li>
            <li class="c2 li-bullet-0"><span class="c0">mezin&aacute;rodn&iacute; z&aacute;silky obsahuj&iacute;c&iacute; ve&scaron;ker&eacute; alkoholick&eacute; n&aacute;poje, tab&aacute;kov&eacute; v&yacute;robky a zbo&#382;&iacute; podl&eacute;haj&iacute;c&iacute; spot&#345;ebn&iacute; dani,</span></li>
            <li class="c2 li-bullet-0"><span class="c0">z&aacute;silka pro leteckou p&#345;epravu obsahuj&iacute;c&iacute;: n&aacute;doby pod tlakem (nap&#345;. oby&#269;ejn&eacute; spreje), v&yacute;bu&scaron;n&eacute; l&aacute;tky a p&#345;edm&#283;ty, plyny a zkapaln&#283;n&eacute; podchlazen&eacute; plyny, l&aacute;tky n&aacute;chyln&eacute; k samovzn&iacute;cen&iacute;, l&aacute;tky podporuj&iacute;c&iacute; ho&#345;en&iacute;, okysli&#269;uj&iacute;c&iacute; l&aacute;tky, korozivn&iacute; l&aacute;tky, a dal&scaron;&iacute; komodity uveden&eacute; na seznamu DGR - Pravidel pro p&#345;epravu nebezpe&#269;n&eacute;ho zbo&#382;&iacute; vydan&yacute;mi Mezin&aacute;rodn&iacute; asociac&iacute; leteck&yacute;ch p&#345;epravc&#367; (IATA),</span></li>
            <li class="c2 li-bullet-0"><span class="c0">exportn&iacute; z&aacute;silky na dob&iacute;rku a na celn&iacute; z&aacute;znam,</span></li>
            <li class="c2 li-bullet-0"><span class="c0">z&aacute;silky s obsahem snadno podl&eacute;haj&iacute;c&iacute;m zk&aacute;ze, nebo obsahem snadno po&scaron;koditeln&yacute;m a d&aacute;le z&aacute;silky s obsahem, kter&yacute; je nutno zvl&aacute;&scaron;tn&iacute;m zp&#367;sobem chr&aacute;nit, nebo je s nimi nutn&aacute; zvl&aacute;&scaron;tn&iacute; manipulace,</span></li>
            <li class="c2 li-bullet-0"><span class="c0">zavazadla (kufry, ta&scaron;ky, batohy, aktovky) bez doprovodu, pokud to nedovoluje vybran&yacute; p&#345;epravce,</span></li>
            <li class="c2 li-bullet-0"><span class="c0">z&aacute;silky zbo&#382;&iacute; spadaj&iacute;c&iacute;ho pod re&#382;im ADR (nebezpe&#269;n&eacute; zbo&#382;&iacute;),</span></li>
            <li class="c2 li-bullet-0"><span class="c0">z&aacute;silka, kter&aacute; podle pr&aacute;vn&iacute;ch p&#345;edpis&#367; nem&#367;&#382;e b&yacute;t p&#345;epravov&aacute;na,</span></li>
            <li class="c2 li-bullet-0"><span class="c0">l&aacute;hve a sklenice, kter&eacute; nejsou zabaleny v certifikovan&yacute;ch obalech ur&#269;en&yacute;ch k bal&iacute;kov&eacute; p&#345;eprav&#283;,</span></li>
            <li class="c2 li-bullet-0"><span class="c0">po&scaron;kozen&eacute; p&#345;edm&#283;ty,</span></li>
            <li class="c2 li-bullet-0"><span class="c0">pneumatiky či ráfky kol s pneumatikami.</span></li>
        </ol>
        <ol class="c12 lst-kix_list_1-1" start="3">
            <li class="c7 li-bullet-0"><span class="c0">V p&#345;&iacute;pad&#283;, &#382;e Objednatel p&#345;ed&aacute; P&#345;epravci k p&#345;eprav&#283; Z&aacute;silku vylou&#269;enou z p&#345;epravy, a to bez jeho v&#283;dom&iacute; a jeho v&yacute;slovn&eacute;ho souhlasu, Poskytovatel nenese &#382;&aacute;dnou odpov&#283;dnost za jak&eacute;koliv &scaron;kody vznikl&eacute; v souvislosti s p&#345;ed&aacute;n&iacute;m Z&aacute;silky a dal&scaron;&iacute;m nakl&aacute;d&aacute;n&iacute;m s n&iacute;. V takov&eacute;m p&#345;&iacute;pad&#283; je Objednatel povinen uhradit Poskytovateli ve&scaron;ker&eacute; n&aacute;klady s t&iacute;mto spojen&eacute; a ve&scaron;kerou jemu vzniklou &scaron;kodu a to jak u vnitrost&aacute;tn&iacute;ch, tak mezin&aacute;rodn&iacute;ch z&aacute;silek.</span></li>
            <li class="c7 li-bullet-0"><span class="c0">Pro p&#345;&iacute;pad poru&scaron;en&iacute; t&eacute;to povinnosti sjedn&aacute;vaj&iacute; smluvn&iacute; strany smluvn&iacute; pokutu ve v&yacute;&scaron;i rozd&iacute;lu skute&#269;n&eacute; ceny takto protipr&aacute;vn&#283; odeslan&eacute;ho zbo&#382;&iacute; a &#269;&aacute;stky 6.000 K&#269;, kterou je Objednatel povinen zaplatit Poskytovateli.</span></li>
        </ol>
        <ol class="c12 lst-kix_list_1-0" start="10">
            <li class="c11 li-bullet-0"><span class="c3">&#344;e&scaron;en&iacute; spor&#367;</span></li>
        </ol>
        <ol class="c12 lst-kix_list_1-1 start" start="1">
            <li class="c7 li-bullet-0"><span class="c0">Vz&aacute;jemn&eacute; spory mezi prod&aacute;vaj&iacute;c&iacute;m a kupuj&iacute;c&iacute;m &#345;e&scaron;&iacute; obecn&eacute; soudy. Kupuj&iacute;c&iacute;, kter&yacute; je spot&#345;ebitelem, m&aacute; podle z&aacute;kona &#269;. 634/1992 Sb., o ochran&#283; spot&#345;ebitele, v platn&eacute;m zn&#283;n&iacute;, pr&aacute;vo na mimosoudn&iacute; &#345;e&scaron;en&iacute; spot&#345;ebitelsk&eacute;ho sporu z kupn&iacute; smlouvy nebo ze smlouvy o poskytov&aacute;n&iacute; slu&#382;eb. Subjektem, kter&yacute; je opr&aacute;vn&#283;n mimosoudn&iacute; &#345;e&scaron;en&iacute; sporu prov&aacute;d&#283;t, je &#268;esk&aacute; obchodn&iacute; inspekce. Bli&#382;&scaron;&iacute; informace jsou dostupn&eacute; na webov&yacute;ch str&aacute;nk&aacute;ch www.coi.cz.</span></li>
            <li class="c7 li-bullet-0"><span class="c0">Mimosoudn&iacute; &#345;e&scaron;en&iacute; spot&#345;ebitelsk&eacute;ho sporu se zahajuje v&yacute;lu&#269;n&#283; na n&aacute;vrh spot&#345;ebitele, a to pouze v p&#345;&iacute;pad&#283;, &#382;e se spor nepoda&#345;ilo s prod&aacute;vaj&iacute;c&iacute;m vy&#345;e&scaron;it p&#345;&iacute;mo. N&aacute;vrh lze podat nejpozd&#283;ji do 1 roku ode dne, kdy spot&#345;ebitel uplatnil sv&eacute; pr&aacute;vo, kter&eacute; je p&#345;edm&#283;tem sporu, u prod&aacute;vaj&iacute;c&iacute;ho poprv&eacute;.</span></li>
            <li class="c7 li-bullet-0"><span class="c14">Spot&#345;ebitel m&aacute; pr&aacute;vo zah&aacute;jit mimosoudn&iacute; &#345;e&scaron;en&iacute; sporu online prost&#345;ednictv&iacute;m platformy ODR dostupn&eacute; na webov&eacute; str&aacute;nce&nbsp;</span><span class="c17"><a class="c15" href="https://ec.europa.eu/consumers/odr/">https://ec.europa.eu/consumers/odr/</a></span>
                <span class="c0">.</span>
            </li>
            <li class="c7 li-bullet-0"><span class="c0">Po dobu trv&aacute;n&iacute; jedn&aacute;n&iacute; o mimosoudn&iacute;m urovn&aacute;n&iacute; sporu neb&#283;&#382;&iacute; ani neza&#269;nou b&#283;&#382;et proml&#269;ec&iacute; a prekluzivn&iacute; lh&#367;ty podle ob&#269;ansk&eacute;ho z&aacute;kon&iacute;ku, dokud jedna ze stran sporu v&yacute;slovn&#283; neodm&iacute;tne v jedn&aacute;n&iacute; pokra&#269;ovat.</span></li>
            <li class="c7 li-bullet-0"><span class="c14">Dozor nad dodr&#382;ov&aacute;n&iacute;m povinnost&iacute; podle z&aacute;kona &#269;. 634/1992 Sb., o ochran&#283; spot&#345;ebitele, v platn&eacute;m zn&#283;n&iacute;, vykon&aacute;v&aacute; &#268;esk&aacute; obchodn&iacute; inspekce (</span>
                <span class="c17"><a class="c15" href="http://www.coi.cz/">www.coi.cz</a></span><span class="c0">).</span></li>
        </ol>
        <ol class="c12 lst-kix_list_1-0" start="11">
            <li class="c11 li-bullet-0"><span class="c3">Z&aacute;silky na dob&iacute;rku</span></li>
        </ol>
        <ol class="c12 lst-kix_list_1-1 start" start="1">
            <li class="c7 li-bullet-0"><span class="c0">U z&aacute;silek, kter&eacute; se zas&iacute;laj&iacute; na dob&iacute;rku, prov&aacute;d&iacute; Poskytovatel nebo P&#345;epravce u P&#345;&iacute;jemce Z&aacute;silky inkaso &#269;&aacute;stky ur&#269;en&eacute; Objednatelem (maxim&aacute;ln&#283; do v&yacute;&scaron;e 85 000 K&#269; nebo 3 300 EUR) a pouk&aacute;&#382;e tuto &#269;&aacute;stku na &uacute;&#269;et p&#345;&iacute;kazce v souladu s t&#283;mito podm&iacute;nkami. Dob&iacute;rkov&eacute; &#269;&aacute;stky budou zasl&aacute;ny na &uacute;&#269;et ur&#269;en&yacute; Objednatelem do 7 pracovn&iacute;ch dn&#367; po jejich vybr&aacute;n&iacute; u CZK dob&iacute;rek a 10 pracovn&iacute;ch dn&#367; po jejich vybr&aacute;n&iacute; u EUR dob&iacute;rek. Zasl&aacute;n&iacute;m se rozum&iacute; den, kdy budou odeps&aacute;ny z &uacute;&#269;tu Poskytovatele.</span></li>
            <li class="c7 li-bullet-0"><span class="c0">Objednatel je povinen data o dob&iacute;rce zadat on-line prost&#345;ednictv&iacute;m Webov&eacute; str&aacute;nky, pomoc&iacute; datov&eacute; v&#283;ty (API), telefonicky, nebo pomoc&iacute; mobiln&iacute; aplikace.</span></li>
            <li class="c7 li-bullet-0"><span class="c0">Zas&iacute;lat na dob&iacute;rku Poskytovatel umo&#382;&#328;uje do n&aacute;sleduj&iacute;c&iacute;ch zem&iacute;:</span></li>
        </ol>
        <ol class="c12 lst-kix_list_1-2 start" start="1">
            <li class="c2 li-bullet-0"><span class="c0">&#268;esk&aacute; republika &ndash; vnitrost&aacute;tn&iacute; z&aacute;silka,</span></li>
        </ol>
        <ol class="c12 lst-kix_list_1-1" start="4">
            <li class="c7 li-bullet-0"><span class="c0">V p&#345;&iacute;pad&#283;, &#382;e je slu&#382;ba Poskytovatele vnitrost&aacute;tn&iacute;, je Objednatel povinen ve smlouv&#283; vyzna&#269;it &#269;&aacute;stku dob&iacute;rky v &#269;esk&eacute; m&#283;n&#283; (K&#269;). Ozna&#269;en&aacute; &#269;&aacute;stka bude vybr&aacute;na od P&#345;&iacute;jemce p&#345;i p&#345;evzet&iacute; z&aacute;silky.</span></li>
            <li class="c7 li-bullet-0"><span class="c0">Bankovn&iacute; &uacute;&#269;et ur&#269;en&yacute; Objednatelem, kam m&aacute; b&yacute;t Poskytovatelem pouk&aacute;z&aacute;na dob&iacute;rkov&aacute; &#269;&aacute;stka za z&aacute;silku, mus&iacute; b&yacute;t veden v &#269;esk&eacute; m&#283;n&#283; (K&#269;) u bankovn&iacute;ho &uacute;stavu se s&iacute;dlem v &#268;esk&eacute; republice, pokud se jedn&aacute; o Vnitrost&aacute;tn&iacute; z&aacute;silku. </span></li>
            <li class="c7 li-bullet-0"><span class="c0">Ve&scaron;ker&eacute; n&aacute;klady spojen&eacute; s bankovn&iacute; transakc&iacute; a p&#345;evodem finan&#269;n&iacute;ch prost&#345;edk&#367; hrad&iacute; Objednatel. Pokud by Objednatel ozna&#269;il &uacute;&#269;et u jin&eacute;ho bankovn&iacute;ho &uacute;stavu, kam m&aacute; b&yacute;t pouk&aacute;z&aacute;na dob&iacute;rkov&aacute; &#269;&aacute;stka za z&aacute;silku, kter&yacute; nem&aacute; s&iacute;dlo v &#268;esk&eacute; republice, je Objednatel povinen sd&#283;lit Poskytovateli ve lh&#367;t&#283; do 7 kalend&aacute;&#345;n&iacute;ch dn&iacute; jin&yacute; zp&#367;sob pouk&aacute;z&aacute;n&iacute; dob&iacute;rkov&eacute; &#269;&aacute;stky (nap&#345;. jin&yacute; bankovn&iacute; &uacute;stav se s&iacute;dlem v &#268;esk&eacute; republice, respektive v &#268;esk&eacute; republice nebo na &uacute;zem&iacute; Slovensk&eacute; republiky, &#269;i zasl&aacute;n&iacute; formou po&scaron;tovn&iacute; pouk&aacute;zky na adresu Klienta). Poskytovatel nen&iacute; opr&aacute;vn&#283;n pouk&aacute;zat dob&iacute;rkovou &#269;&aacute;stku na &uacute;&#269;et veden&yacute; u bankovn&iacute;ho &uacute;stavu se s&iacute;dlem jin&yacute;m, ne&#382; jsou v&yacute;&scaron;e uvedeny a pro p&#345;&iacute;pad prodlen&iacute; s pouk&aacute;z&aacute;n&iacute;m dob&iacute;rkov&eacute; &#269;&aacute;stky v tomto p&#345;&iacute;pad&#283; nenese &#382;&aacute;dnou odpov&#283;dnost.</span></li>
            <li class="c7 li-bullet-0"><span class="c0">Pokud Objednatel dod&aacute; chybn&aacute; dob&iacute;rkov&aacute; data, zejm&eacute;na pokud je na stran&#283; zas&iacute;latele nutn&eacute; zpracov&aacute;vat dob&iacute;rkov&aacute; data p&#345;&iacute;kazce manu&aacute;ln&#283; (nap&#345;. v p&#345;&iacute;pad&#283; neplatn&eacute;ho k&oacute;du banky), je zas&iacute;latel opr&aacute;vn&#283;n &uacute;&#269;tovat p&#345;&iacute;kazci administrativn&iacute; poplatek za ka&#382;dou z&aacute;silku, ke kter&eacute; musela b&yacute;t dob&iacute;rkov&aacute; data zpracov&aacute;na manu&aacute;ln&#283;. Objednatel odpov&iacute;d&aacute; za &scaron;kody zp&#367;soben&eacute; Poskytovateli chybn&yacute;m zad&aacute;n&iacute;m dob&iacute;rkov&yacute;ch dat &#269;i jinou chybou vzniklou v d&#367;sledku nedod&aacute;n&iacute; dob&iacute;rkov&yacute;ch dat &#345;&aacute;dn&#283; &#269;i v&#269;as Objednatelem.</span></li>
            <li class="c7 li-bullet-0"><span class="c0">P&#345;evodn&iacute; kurz EUR/CZK, kter&yacute; je v&nbsp;p&#345;&iacute;pad&#283; pot&#345;eby aplikov&aacute;n p&#345;i vypl&aacute;cen&iacute; dob&#283;re&#269;n&eacute; &#269;&aacute;stky P&#345;&iacute;kazci, vych&aacute;z&iacute; ze st&#345;edn&iacute; hodnoty &#268;NB v den objedn&aacute;n&iacute;, upraven&eacute; o 2,5% ve prosp&#283;ch Poskytovatele.</span></li>
        </ol>
        <ol class="c12 lst-kix_list_1-0" start="12">
            <li class="c11 li-bullet-0"><span class="c3">Odm&#283;na za slu&#382;by a platebn&iacute; podm&iacute;nky</span></li>
        </ol>
        <ol class="c12 lst-kix_list_1-1 start" start="1">
            <li class="c7 li-bullet-0"><span class="c0">Za v&scaron;echny realizovan&eacute; slu&#382;by objednan&eacute; Objednatelem &uacute;&#269;tuje Poskytovatel poplatky podle aktu&aacute;ln&iacute; kalkulace ceny na Webov&yacute;ch str&aacute;nk&aacute;ch nebo v&nbsp;mobiln&iacute; aplikaci, v&#269;etn&#283; dopl&#328;kov&yacute;ch slu&#382;eb, p&#345;&iacute;platk&#367;, nadstandardn&iacute;ch &uacute;kon&#367; a dal&scaron;&iacute;ch slu&#382;eb.</span></li>
            <li class="c7 li-bullet-0"><span class="c0">Poskytovatel p&#345;epravuje a bal&iacute; jen bal&iacute;ky, jejich&#382; p&#345;epravn&iacute; n&aacute;klady hrad&iacute; Objednatel, bal&iacute;ky pod&aacute;van&eacute; bezplatn&#283; nep&#345;epravuje.</span></li>
            <li class="c7 li-bullet-0"><span class="c0">Poskytovatel a P&#345;epravce je opr&aacute;vn&#283;n upravit &uacute;daje o parametrech z&aacute;silky p&#367;vodn&#283; uveden&yacute;ch Objednatelem a n&aacute;sledn&#283; p&#345;i&#345;adit odpov&iacute;daj&iacute;c&iacute; slu&#382;bu v p&#345;&iacute;pad&#283;, &#382;e zjist&iacute; rozd&iacute;l mezi &uacute;dajem uveden&yacute;m v objedn&aacute;vce p&#345;epravy a skute&#269;n&yacute;mi parametry z&aacute;silky. V takov&eacute;m p&#345;&iacute;pad&#283; je pro &uacute;&#269;ely v&yacute;po&#269;tu v&yacute;&scaron;e odm&#283;ny zas&iacute;latele rozhoduj&iacute;c&iacute; &uacute;daj zji&scaron;t&#283;n&yacute; zas&iacute;latelem.</span></li>
            <li class="c7 li-bullet-0"><span class="c0">N&aacute;klady nav&iacute;c vznikl&eacute; z d&#367;vodu celn&iacute;ho odbavov&aacute;n&iacute; &#269;i jin&yacute;ch &#345;&iacute;zen&iacute; jdou k t&iacute;&#382;i Objednatele.</span></li>
            <li class="c7 li-bullet-0"><span class="c0">Poskytovatel je opr&aacute;vn&#283;n sjednat s Objednatelem na z&aacute;klad&#283; dlouhodob&eacute; spolupr&aacute;ce vlastn&iacute; platebn&iacute; podm&iacute;nky s mo&#382;nost&iacute; &uacute;hrady kumulativn&iacute; fakturou za sjednan&eacute; obdob&iacute;. Objednatel je povinen uhradit fakturu nejpozd&#283;ji v den jej&iacute; splatnosti. </span></li>
            <li class="c7 li-bullet-0"><span class="c0">V p&#345;&iacute;pad&#283; prodlen&iacute; &uacute;hrady vystaven&eacute; faktury je Poskytovatel opr&aacute;vn&#283;n &uacute;&#269;tovat pen&aacute;le z prodlen&iacute; spole&#269;n&#283; s &#345;&aacute;dn&#283; vyfakturovanou dlu&#382;nou &#269;&aacute;stkou ve v&yacute;&scaron;i 0,05 % z dlu&#382;n&eacute; &#269;&aacute;stky za ka&#382;d&yacute; den prodlen&iacute;.</span></li>
            <li class="c7 li-bullet-0"><span class="c0">Poskytovatel je opr&aacute;vn&#283;n sjednat s Objednatelem na z&aacute;klad&#283; dlouhodob&eacute; spolupr&aacute;ce vlastn&iacute; cenov&eacute; podm&iacute;nky, kter&eacute; jsou pot&eacute; sou&#269;&aacute;st&iacute; jedine&#269;n&eacute; smlouvy uzav&#345;en&eacute; mezi Poskytovatelem a Objednatelem. Takov&eacute;to cenov&eacute; podm&iacute;nky jsou platn&eacute; pouze po dobu platnosti smlouvy, p&#345;i&#269;em&#382; rozhodn&yacute; den pro platnost takov&yacute;chto cenov&yacute;ch podm&iacute;nek se rozum&iacute; den, kdy byla uzav&#345;ena konkr&eacute;tn&iacute; p&iacute;semn&aacute; smlouva mezi Poskytovatelem a Objednatelem o poskytov&aacute;n&iacute; slu&#382;eb pro doru&#269;ov&aacute;n&iacute; slu&#382;eb Nebal&iacute;k.cz. V&nbsp;p&#345;&iacute;pad&#283; poskytov&aacute;n&iacute; slu&#382;eb na dob&iacute;rku, je Poskytovatel opr&aacute;vn&#283;n si za&uacute;&#269;tovat sv&eacute; splatn&eacute; pohled&aacute;vky za Objednatelem na dob&iacute;rkov&eacute; &#269;&aacute;stky vybran&eacute; p&#345;i doru&#269;en&iacute; dob&iacute;rkov&yacute;ch Z&aacute;silek od P&#345;&iacute;jemc&#367;, pokud nen&iacute; ve smlouv&#283; uvedeno jinak.</span></li>
            <li class="c7 li-bullet-0"><span class="c14">Platby se prov&aacute;d&#283;j&iacute; p&#345;edem prost&#345;ednictv&iacute;m bankovn&iacute;ch p&#345;evod&#367;, elektronickou platebn&iacute; br&aacute;nou GoPay.cz, ComGate, nebo na fakturu se splatnost&iacute; u individu&aacute;ln&#283; sjednan&yacute;ch platebn&iacute;ch </span>
                <span class="c1 c9">podm&iacute;nk&aacute;ch. Bankovn&iacute; &uacute;&#269;et Poskytovatele pro platbu p&#345;evodem je veden ve Fio banka, a.s., 2201188125/2010</span>
            </li>
            <li class="c7 li-bullet-0"><span class="c1 c9">Poskytovatel nenese &#382;&aacute;dnou odpov&#283;dnost za transakce proveden&eacute; p&#345;es platebn&iacute; br&aacute;nu.</span></li>
            <li class="c7 li-bullet-0"><span class="c1">V p&#345;&iacute;pad&#283;, &#382;e Poskytovatel nespr&aacute;vn&#283; za&uacute;&#269;tuje Objednateli slu&#382;by Nebal&iacute;k.cz na z&aacute;klad&#283; da&#328;ov&eacute;ho dokladu, je Objednatel opr&aacute;vn&#283;n p&iacute;semn&#283; na tuto skute&#269;nost Poskytovatele upozornit, vyzna&#269;it konkr&eacute;tn&iacute; nespr&aacute;vnosti a po&#382;&aacute;dat</span>
                <span class="c0">&nbsp;Poskytovatele o vystaven&iacute; nov&eacute;ho spr&aacute;vn&eacute;ho da&#328;ov&eacute;ho dokladu, a to ve lh&#367;t&#283; do 30 kalend&aacute;&#345;n&iacute;ch dn&iacute; ode dne vystaven&iacute; da&#328;ov&eacute;ho
                                        dokladu a pokud tak Objednatel neu&#269;in&iacute;, m&aacute; se za to, &#382;e vy&uacute;&#269;tov&aacute;n&iacute; uveden&eacute; v da&#328;ov&eacute;m dokladu je bezvadn&eacute; a Poskytovateli vznikl n&aacute;rok
                                        na zaplacen&iacute; ceny za slu&#382;by dle zaslan&eacute;ho da&#328;ov&eacute;ho dokladu. Bylo-li vy&uacute;&#269;tov&aacute;n&iacute; uveden&eacute; v da&#328;ov&eacute;m dokladu nespr&aacute;vn&eacute;, Poskytovatel
                                        ve lh&#367;t&#283; 30 dn&iacute; po doru&#269;en&iacute; upozorn&#283;n&iacute; Objednatele na nespr&aacute;vn&eacute; vy&uacute;&#269;tov&aacute;n&iacute;, za&scaron;le Objednateli nov&yacute; a spr&aacute;vn&yacute;
                                        da&#328;ov&yacute; doklad se spr&aacute;vn&yacute;m vy&uacute;&#269;tov&aacute;n&iacute;m slu&#382;eb a provede storno vadn&eacute;ho da&#328;ov&eacute;ho dokladu. </span>
            </li>
        </ol>
        <ol class="c12 lst-kix_list_1-0" start="13">
            <li class="c11 li-bullet-0"><span class="c3">Ochrana osobn&iacute;ch &uacute;daj&#367; GDPR</span></li>
        </ol>
        <ol class="c12 lst-kix_list_1-1 start" start="1">
            <li class="c7 li-bullet-0"><span class="c14">Poskytovatel je&nbsp;</span><span class="c4">Spr&aacute;vcem</span><span class="c14">&nbsp;osobn&iacute;ch &uacute;daj&#367; podle &#269;l. 4 bod 7 na&#345;&iacute;zen&iacute; Evropsk&eacute;ho parlamentu a Rady (EU) 2016/679 (d&aacute;le tak&eacute; na&#345;&iacute;zen&iacute; &bdquo;</span>
                <span class="c4">GDPR</span><span class="c14">&ldquo; o ochran&#283; fyzick&yacute;ch osob v souvislosti se zpracov&aacute;n&iacute;m osobn&iacute;ch &uacute;daj&#367; a o voln&eacute;m pohybu t&#283;chto &uacute;daj&#367; ve vztahu k Objednateli a&nbsp;</span>
                <span class="c4">Zpracovatelem</span><span class="c0">&nbsp;ve vztahu k Odes&iacute;lateli a P&#345;&iacute;jemci, jestli se nejedn&aacute; o tent&yacute;&#382; subjekt.</span></li>
            <li class="c7 li-bullet-0"><span class="c0">Objednatel souhlas&iacute; s t&iacute;m, aby Poskytovatel zpracov&aacute;val, shroma&#382;&#271;oval a uchov&aacute;val osobn&iacute; &uacute;daje Objednatele uveden&eacute; v&nbsp;uzav&#345;en&eacute; smlouv&#283;, p&#345;&iacute;padn&#283; z&iacute;skan&eacute; z jin&yacute;ch zdroj&#367; souvisej&iacute;c&iacute;ch se vz&aacute;jemnou spoluprac&iacute;, a dal&scaron;&iacute; osobn&iacute; &uacute;daje nezbytn&eacute; pro poskytov&aacute;n&iacute; zas&iacute;latelsk&yacute;ch slu&#382;eb. Tyto osobn&iacute; &uacute;daje budou Poskytovatelem zpracov&aacute;v&aacute;ny a uchov&aacute;v&aacute;ny pro &uacute;&#269;ely pln&#283;n&iacute; jeho z&aacute;vazk&#367; vypl&yacute;vaj&iacute;c&iacute;ch ze zas&iacute;latelsk&eacute; smlouvy a eviden&#269;n&iacute; &uacute;&#269;ely. Osobn&iacute;mi &uacute;daji se rozum&iacute;: </span></li>
        </ol>
        <ul class="c12 lst-kix_list_2-2 start">
            <li class="c22 li-bullet-0"><span class="c0">v p&#345;&iacute;pad&#283; fyzick&yacute;ch osob: jm&eacute;no a p&#345;&iacute;jmen&iacute;, adresu bydli&scaron;t&#283;, &#269;&iacute;slo bankovn&iacute;ho &uacute;&#269;tu, e-mailovou adresu, telefonn&iacute; &#269;&iacute;slo,</span></li>
            <li class="c2 li-bullet-0"><span class="c0">v p&#345;&iacute;pad&#283; spole&#269;nost&iacute;: jm&eacute;no a p&#345;&iacute;jmen&iacute; kontaktn&iacute;ch osob, telefonn&iacute; &#269;&iacute;slo kontaktn&iacute;ch osob, e-mailovou adresu kontaktn&iacute;ch osob, n&aacute;zev spole&#269;nosti, faktura&#269;n&iacute; adresu, I&#268;, DI&#268;, &#269;&iacute;slo bankovn&iacute;ho &uacute;&#269;tu.</span></li>
        </ul>
        <ol class="c12 lst-kix_list_2-1 start" start="1">
            <li class="c6"><span class="c0">Objednatel se zavazuje zajistit souhlas v&scaron;ech z&uacute;&#269;astn&#283;n&yacute;ch osob na obchodn&iacute;m vztahu mezi Poskytovatelem a Objednatelem, zejm&eacute;na Odes&iacute;latele a P&#345;&iacute;jemce, se zpracov&aacute;n&iacute;m osobn&iacute;ch &uacute;daj&#367;.</span></li>
            <li class="c6"><span class="c14">Objednatel souhlas&iacute; s poskytnut&iacute;m osobn&iacute;ch &uacute;daj&#367; t&#345;et&iacute;m stran&aacute;m. Osobn&iacute; &uacute;daje budou zpracov&aacute;v&aacute;ny v elektronick&eacute; podob&#283; automatizovan&yacute;m </span>
                <span class="c1 c9">zp&#367;sobem nebo v ti&scaron;t&#283;n&eacute; podob&#283;.</span>
            </li>
            <li class="c6"><span class="c1">Kompletn&iacute;&nbsp;</span><span class="c23">Podm&iacute;nky ochrany osobn&iacute;ch &uacute;daj&#367;</span><span class="c1 c9">&nbsp;jsou p&#345;&iacute;lohou &#269;. 1 t&#283;chto </span></li>
            <li class="c6"><span class="c1">Pr&aacute;va a povinnosti smluvn&iacute;ch stran p&#345;i zpracov&aacute;n&iacute; osobn&iacute;ch &uacute;daj&#367; Odes&iacute;latel&#367; a P&#345;&iacute;jemc&#367; jsou upraveny&nbsp;</span><span class="c23">Smlouvou o zpravov&aacute;n&iacute; osobn&iacute;ch &uacute;daj&#367;</span>
                <span class="c1 c9">, kter&aacute; je p&#345;&iacute;lohou &#269;. 2 t&#283;chto Obchodn&iacute;ch podm&iacute;nek. </span>
            </li>
            <li class="c6"><span class="c1">Osoby, kter&eacute; s&nbsp;Poskytovatelem pl&aacute;nuj&iacute; uzav&#345;&iacute;t smlouvu, poskytuj&iacute;</span><span class="c0">&nbsp;sv&eacute; osobn&iacute; informace dobrovoln&#283;. Nicm&eacute;n&#283; ze strany Poskytovatele je jejich poskytnut&iacute; podm&iacute;nkou uzav&#345;en&iacute; smluvn&iacute;ho vztahu a pln&#283;n&iacute; z&aacute;vazk&#367; Poskytovatele z tohoto smluvn&iacute;ho vztahu.</span></li>
            <li class="c6"><span class="c0">Objednatel bere na v&#283;dom&iacute;, &#382;e je povinen sv&eacute; osobn&iacute; &uacute;daje (p&#345;i registraci, ve sv&eacute;m u&#382;ivatelsk&eacute;m &uacute;&#269;tu, p&#345;i objedn&aacute;vce proveden&eacute; z webov&eacute;ho rozhran&iacute; obchodu, v&nbsp;mobiln&iacute; aplikaci) uv&aacute;d&#283;t spr&aacute;vn&#283; a pravdiv&#283;. Bez zbyte&#269;n&eacute;ho odkladu je povinen informovat prod&aacute;vaj&iacute;c&iacute;ho o zm&#283;n&#283; ve sv&yacute;ch osobn&iacute;ch &uacute;daj&iacute;ch. Objednatel potvrzuje, &#382;e se jedn&aacute; o dobrovoln&eacute; poskytnut&iacute; osobn&iacute;ch &uacute;daj&#367; a &#382;e poskytnut&eacute; osobn&iacute; &uacute;daje jsou p&#345;esn&eacute;. </span></li>
            <li class="c6"><span class="c0">Poskytovatel nenese odpov&#283;dnost za spr&aacute;vnost &#269;i pravdivost informac&iacute;, kter&eacute; registrovan&iacute; u&#382;ivatel&eacute; uv&aacute;d&#283;j&iacute; na Webov&yacute;ch str&aacute;nk&aacute;ch. Rovn&#283;&#382; Poskytovatel nenese odpov&#283;dnost za spr&aacute;vnost &#269;i pravdivost informac&iacute; poskytovan&yacute;ch webov&yacute;mi port&aacute;ly, na nich&#382; se nach&aacute;z&iacute; odkazy na webov&eacute;m port&aacute;lu Poskytovatele.</span></li>
            <li class="c6"><span class="c0">Objednatel je zodpov&#283;dn&yacute; za n&aacute;sledky a &scaron;kody, kter&eacute; vznikly v d&#367;sledku zneu&#382;it&iacute; p&#345;&iacute;stupov&yacute;ch opr&aacute;vn&#283;n&iacute; a hesel do Objedn&aacute;vkov&eacute;ho syst&eacute;mu. Poskytovatel tyto p&#345;&iacute;stupy z&#345;izuje pro osoby opr&aacute;vn&#283;n&eacute; dle Smlouvy, p&#345;&iacute;padn&#283; pro Objednatele zplnomocn&#283;n&eacute;. Objednatel je povinen u&#269;init na sv&eacute; stran&#283; nezbytn&aacute; opat&#345;en&iacute; proti jejich zneu&#382;it&iacute;.</span></li>
            <li class="c6"><span class="c0">Objednatel souhlas&iacute; s t&iacute;m, aby Poskytovatel pro &uacute;&#269;ely zlep&scaron;en&iacute; kvality poskytovan&yacute;ch slu&#382;eb, p&#345;&iacute;padn&#283; pro &uacute;&#269;ely budouc&iacute;ho dolo&#382;en&iacute; obsahu telefonick&yacute;ch objedn&aacute;vek p&#345;epravy, nahr&aacute;val hovory uskute&#269;n&#283;n&eacute; na z&aacute;kaznickou linku Poskytovatele.</span></li>
            <li class="c6"><span class="c0">Objednatel souhlas&iacute; s t&iacute;m, aby zas&iacute;latel, ve smyslu z&aacute;kona &#269;. 101/2000 Sb. o ochran&#283; osobn&iacute;ch &uacute;daj&#367; a o zm&#283;n&#283; n&#283;kter&yacute;ch z&aacute;konu, ve zn&#283;n&iacute; pozd&#283;j&scaron;&iacute;ch p&#345;edpis&#367; (d&aacute;le jen &bdquo;z&aacute;kon o ochran&#283; osobn&iacute;ch &uacute;daj&#367;&rdquo;), zpracov&aacute;val, shroma&#382;&#271;oval a uchov&aacute;val osobn&iacute; &uacute;daje Objednatele uveden&eacute; ve smlouv&#283;, p&#345;&iacute;padn&#283; z&iacute;skan&eacute; z jin&yacute;ch zdroj&#367; souvisej&iacute;c&iacute;ch se vz&aacute;jemnou spoluprac&iacute;, a dal&scaron;&iacute; osobn&iacute; &uacute;daje nezbytn&eacute; pro poskytov&aacute;n&iacute; zas&iacute;latelsk&yacute;ch slu&#382;eb. Tyto osobn&iacute; &uacute;daje budou Poskytovatelem zpracov&aacute;v&aacute;ny a uchov&aacute;v&aacute;ny v z&aacute;kaznick&eacute; datab&aacute;zi Poskytovatele pro &uacute;&#269;ely pln&#283;n&iacute; jeho z&aacute;vazk&#367; vypl&yacute;vaj&iacute;c&iacute;ch ze zas&iacute;latelsk&eacute; smlouvy a eviden&#269;n&iacute; &uacute;&#269;ely.</span></li>
            <li class="c6"><span class="c0">Objednatel souhlas&iacute; s poskytnut&iacute;m t&#283;chto osobn&iacute;ch &uacute;daj&#367; t&#345;et&iacute;m stran&aacute;m &ndash; P&#345;epravc&#367;m a to v&yacute;hradn&#283; za &uacute;&#269;elem pln&#283;n&iacute; zas&iacute;latelsk&eacute; smlouvy. Krom&#283; osob dopravuj&iacute;c&iacute;ch zbo&#382;&iacute; nebudou osobn&iacute; &uacute;daje Objednatele bez p&#345;edchoz&iacute;ho souhlasu Objednatele p&#345;ed&aacute;v&aacute;ny jin&yacute;m t&#345;et&iacute;m stran&aacute;m. Osobn&iacute; &uacute;daje budou zpracov&aacute;v&aacute;ny po dobu neur&#269;itou. Osobn&iacute; &uacute;daje budou zpracov&aacute;v&aacute;ny v elektronick&eacute; podob&#283; automatizovan&yacute;m zp&#367;sobem nebo v ti&scaron;t&#283;n&eacute; podob&#283; neautomatizovan&yacute;m zp&#367;sobem.</span></li>
            <li class="c6"><span class="c0">Poskytnut&iacute; osobn&iacute;ch &uacute;daj&#367; osob, kter&eacute; s Poskytovatelem hodlaj&iacute; uzav&#345;&iacute;t smlouvu je dobrovoln&eacute;, nicm&eacute;n&#283; ze strany Poskytovatele je jejich poskytnut&iacute; podm&iacute;nkou uzav&#345;en&iacute; smluvn&iacute;ho vztahu a pln&#283;n&iacute; z&aacute;vazk&#367; Poskytovatele z tohoto smluvn&iacute;ho vztahu.</span></li>
            <li class="c6"><span class="c0">Objedn&aacute;vaj&iacute;c&iacute; objedn&aacute;n&iacute;m p&#345;epravy Z&aacute;silky je povinen zajistit souhlas P&#345;&iacute;jemce Z&aacute;silky se zpracov&aacute;n&iacute;m osobn&iacute;ch &uacute;daj&#367; ve smyslu z&aacute;kona o ochran&#283; osobn&iacute;ch &uacute;daj&#367; a spln&#283;n&iacute; informa&#269;n&iacute; povinnosti dle ustanoven&iacute; &sect; 11 z&aacute;kona o ochran&#283; osobn&iacute;ch &uacute;daj&#367; v takov&eacute;m rozsahu, aby Poskytovatel mohl &#345;&aacute;dn&#283; plnit povinnosti vypl&yacute;vaj&iacute;c&iacute; ze smlouvy. Pro tento p&#345;&iacute;pad se Objedn&aacute;vaj&iacute;c&iacute; ve smyslu &sect; 2890 a n&aacute;sl. ob&#269;ansk&eacute;ho z&aacute;kon&iacute;ku d&aacute;le zavazuje uhradit Poskytovateli jakoukoliv &scaron;kodu, kter&aacute; mu v souvislosti se zpracov&aacute;n&iacute;m osobn&iacute;ch &uacute;daj&#367; P&#345;&iacute;jemc&#367; vznikne.</span></li>
            <li class="c6"><span class="c0">Objednatel souhlas&iacute; s t&iacute;m, &#382;e osobn&iacute; &uacute;daje Objednatele mohou b&yacute;t pou&#382;ity v souladu se z&aacute;konem &#269;. 480/2004 Sb. Objednatel souhlas&iacute; s p&#345;ij&iacute;m&aacute;n&iacute;m obchodn&iacute;ch sd&#283;len&iacute; od Poskytovatele. Obsahem sd&#283;len&iacute; jsou informace o novink&aacute;ch, slev&aacute;ch a nab&iacute;dk&aacute;ch Poskytovatele, nezvol&iacute;-li Objednatel jinou mo&#382;nost.</span></li>
            <li class="c6"><span class="c0">Objednatel bere na v&#283;dom&iacute;, &#382;e je povinen sv&eacute; osobn&iacute; &uacute;daje (p&#345;i registraci, ve sv&eacute;m u&#382;ivatelsk&eacute;m &uacute;&#269;tu, p&#345;i objedn&aacute;vce proveden&eacute; z webov&eacute;ho rozhran&iacute; obchodu) uv&aacute;d&#283;t spr&aacute;vn&#283; a pravdiv&#283; a &#382;e je povinen bez zbyte&#269;n&eacute;ho odkladu informovat prod&aacute;vaj&iacute;c&iacute;ho o zm&#283;n&#283; ve sv&yacute;ch osobn&iacute;ch &uacute;daj&iacute;ch. Objednatel potvrzuje, &#382;e poskytnut&eacute; osobn&iacute; &uacute;daje jsou p&#345;esn&eacute; a &#382;e byl pou&#269;en o tom, &#382;e se jedn&aacute; o dobrovoln&eacute; poskytnut&iacute; osobn&iacute;ch &uacute;daj&#367;.</span></li>
            <li class="c6"><span class="c0">Objednatel souhlas&iacute; s t&iacute;m, aby Poskytovatel pro &uacute;&#269;ely zlep&scaron;en&iacute; kvality poskytovan&yacute;ch slu&#382;eb, p&#345;&iacute;padn&#283; pro &uacute;&#269;ely budouc&iacute;ho dolo&#382;en&iacute; obsahu telefonick&yacute;ch objedn&aacute;vek p&#345;epravy, nahr&aacute;val hovory uskute&#269;n&#283;n&eacute; na z&aacute;kaznickou linku Poskytovatele.</span></li>
            <li class="c6"><span class="c0">V p&#345;&iacute;pad&#283;, &#382;e by se Objedn&aacute;vaj&iacute;c&iacute; nebo P&#345;&iacute;jemce domn&iacute;val, &#382;e Poskytovatel prov&aacute;d&iacute; zpracov&aacute;n&iacute; jeho osobn&iacute;ch &uacute;daj&#367;, kter&eacute; je v rozporu s ochranou soukrom&eacute;ho a osobn&iacute;ho &#382;ivota kupuj&iacute;c&iacute;ho nebo v rozporu se z&aacute;konem, zejm&eacute;na jsou-li osobn&iacute; &uacute;daje nep&#345;esn&eacute; s ohledem na &uacute;&#269;el jejich zpracov&aacute;n&iacute;, m&#367;&#382;e po&#382;&aacute;dat Poskytovatele nebo zpracovatele o vysv&#283;tlen&iacute; nebo po&#382;adovat, aby Poskytovatel nebo zpracovatel odstranil takto vznikl&yacute; stav. Tyto osobn&iacute; &uacute;daje je mo&#382;no na p&iacute;semnou &#382;&aacute;dost Objedn&aacute;vaj&iacute;c&iacute;ho nebo P&#345;&iacute;jemce vymazat z datab&aacute;ze. Spr&aacute;vce osobn&iacute;ch &uacute;daj&#367; pot&eacute; nesm&iacute; d&aacute;le uveden&eacute; &uacute;daje zpracov&aacute;vat.</span></li>
            <li class="c6"><span class="c0">Po&#382;&aacute;d&aacute;-li Objedn&aacute;vaj&iacute;c&iacute; nebo P&#345;&iacute;jemce o pod&aacute;n&iacute; informac&iacute; o zp&#367;sobu zpracov&aacute;n&iacute; sv&yacute;ch osobn&iacute;ch &uacute;daj&#367;, je mu prod&aacute;vaj&iacute;c&iacute; povinen tuto informaci p&#345;edat. Prod&aacute;vaj&iacute;c&iacute; m&aacute; pr&aacute;vo za poskytnut&iacute; informace podle p&#345;edchoz&iacute; v&#283;ty po&#382;adovat p&#345;im&#283;&#345;enou &uacute;hradu nep&#345;evy&scaron;uj&iacute;c&iacute; n&aacute;klady nezbytn&eacute; na poskytnut&iacute; informace, a pokud Objedn&aacute;vaj&iacute;c&iacute; zjist&iacute; nep&#345;esnosti v &uacute;daj&iacute;ch zpracov&aacute;van&yacute;ch Poskytovatelem, je opr&aacute;vn&#283;n &#382;&aacute;dat, aby Poskytovatel provedl opravu.</span></li>
            <li class="c6"><span class="c0">Poskytovatel nenese odpov&#283;dnost za spr&aacute;vnost &#269;i pravdivost informac&iacute;, kter&eacute; registrovan&iacute; u&#382;ivatel&eacute; uv&aacute;d&#283;j&iacute; na Webov&yacute;ch str&aacute;nk&aacute;ch nebo v&nbsp;Mobiln&iacute; aplikaci. Rovn&#283;&#382; Poskytovatel nenese odpov&#283;dnost za spr&aacute;vnost &#269;i pravdivost informac&iacute; poskytovan&yacute;ch webov&yacute;mi port&aacute;ly, na nich&#382; se nach&aacute;z&iacute; odkazy na webov&eacute;m port&aacute;lu Poskytovatele.</span></li>
        </ol>
        <ol class="c12 lst-kix_list_2-0 start" start="1">
            <li class="c6 c21"><span class="c3"></span></li>
            <li class="c6"><span class="c3">Ukl&aacute;d&aacute;n&iacute; cookies, monitorov&aacute;n&iacute; n&aacute;v&scaron;t&#283;vnosti a u&#382;ivatelsk&eacute; &uacute;&#269;ty</span></li>
        </ol>
        <ol class="c12 lst-kix_list_2-1 start" start="1">
            <li class="c6"><span class="c0">Objedn&aacute;vaj&iacute;c&iacute; souhlas&iacute; s ukl&aacute;d&aacute;n&iacute;m tzv. cookies na jeho po&#269;&iacute;ta&#269; a mobiln&iacute;m za&#345;&iacute;zen&iacute;. Ulo&#382;en&iacute;m cookies nedoch&aacute;z&iacute; k osobn&iacute; identifikaci. Objedn&aacute;vaj&iacute;c&iacute; m&#367;&#382;e ukl&aacute;d&aacute;n&iacute; cookies vylou&#269;it ve sv&eacute;m internetov&eacute;m prohl&iacute;&#382;e&#269;i. Dojde t&iacute;m v&scaron;ak k omezen&eacute; funk&#269;nosti webov&eacute;ho port&aacute;lu Poskytovatele.</span></li>
            <li class="c6"><span class="c0">Webov&eacute; str&aacute;nky Poskytovatele u&#382;&iacute;vaj&iacute; r&#367;zn&eacute; analytick&eacute; n&aacute;stroje pro zji&scaron;t&#283;n&iacute; a anal&yacute;zu dat. M&#367;&#382;e j&iacute;t nap&#345;&iacute;klad o aplikaci Google Analytics, webovou analytickou slu&#382;bu od spole&#269;nosti Google. Tento program slou&#382;&iacute; k monitorov&aacute;n&iacute; a vytv&aacute;&#345;en&iacute; statistiky n&aacute;v&scaron;t&#283;vnosti webov&yacute;ch str&aacute;nek Poskytovatele. Google Analytics funguje na principu ukl&aacute;d&aacute;n&iacute; cookies. Pomoc&iacute; cookies jsou ukl&aacute;d&aacute;na data t&yacute;kaj&iacute;c&iacute; se nav&scaron;t&iacute;ven&iacute; a u&#382;&iacute;v&aacute;n&iacute; webov&eacute; str&aacute;nky (a to v&#269;etn&#283; IP adresy). Tyto data slou&#382;&iacute; ke zkvalitn&#283;n&iacute; slu&#382;eb Poskytovatele.</span></li>
            <li class="c6"><span class="c0">Webov&eacute; str&aacute;nky d&aacute;le pou&#382;&iacute;vaj&iacute; cookies vyu&#382;&iacute;van&eacute; pro identifikaci u&#382;ivatele v partnersk&eacute;m (affiliate) programu Poskytovatele.</span></li>
            <li class="c6"><span class="c0">Z&aacute;lohov&aacute;n&iacute; u&#382;ivatelsk&yacute;ch &uacute;daj&#367; (nap&#345;. p&#345;ihla&scaron;ovac&iacute;ho hesla) je nutn&eacute; pro spr&aacute;vn&eacute; u&#382;&iacute;v&aacute;n&iacute; webov&yacute;ch str&aacute;nek Poskytovatele. K u&#382;ivatelsk&yacute;m informac&iacute;m pat&#345;&iacute; i p&#345;&iacute;stupov&eacute; &uacute;daje (IP adresa, datum, &#269;as, prohl&iacute;&#382;en&aacute; str&aacute;nka), kter&eacute; se ukl&aacute;daj&iacute;, jakmile u&#382;ivatel nav&scaron;t&iacute;v&iacute; webov&yacute; port&aacute;l. Tato data z&#367;stanou anonymn&iacute; a vyhodnot&iacute; se &#269;ist&#283; za &uacute;&#269;elem statistiky. Tato statistika slou&#382;&iacute; ke zkvalitn&#283;n&iacute; slu&#382;eb. Tato data nejsou p&#345;ed&aacute;v&aacute;na t&#345;et&iacute; stran&#283; za &uacute;&#269;elem komer&#269;n&iacute;m &#269;i nekomer&#269;n&iacute;m. U&#382;ivatelsk&aacute; data jsou z&aacute;lohov&aacute;na po dobu 12 m&#283;s&iacute;c&#367;.</span></li>
        </ol>
        <ol class="c12 lst-kix_list_2-0" start="3">
            <li class="c8"><span class="c3"></span></li>
            <li class="c10"><span class="c3">Z&aacute;v&#283;re&#269;n&aacute; ustanoven&iacute;</span></li>
        </ol>
        <ol class="c12 lst-kix_list_2-1 start" start="1">
            <li class="c10"><span class="c0">Tyto obchodn&iacute; podm&iacute;nky jsou platn&eacute; v&nbsp;aktu&aacute;ln&iacute;m zn&#283;n&iacute; uveden&eacute;m na webov&yacute;ch str&aacute;nk&aacute;ch Poskytovatele, a to v den potvrzen&iacute; po&#382;adavku Objednatele Poskytovatelem. Odesl&aacute;n&iacute;m objedn&aacute;vky Objednatel p&#345;ij&iacute;m&aacute; ve&scaron;ker&aacute; ustanoven&iacute; Obchodn&iacute;ch podm&iacute;nek ve zn&#283;n&iacute; platn&eacute;m v den odesl&aacute;n&iacute; objedn&aacute;vky, t&eacute;&#382; platnou cenu objednan&yacute;ch slu&#382;eb, nebylo-li v konkr&eacute;tn&iacute;m p&#345;&iacute;pad&#283; prokazateln&#283; dohodnuto jinak.</span></li>
            <li class="c10"><span class="c0">Webov&eacute; str&aacute;nky Poskytovatele, jejich vyobrazen&iacute; a informace na nich uveden&eacute; jsou chr&aacute;n&#283;ny autorsk&yacute;m z&aacute;konem. N&aacute;zvy a ozna&#269;en&iacute; slu&#382;eb Poskytovatele jsou chr&aacute;n&#283;ny registrovanou ochrannou zn&aacute;mkou. N&aacute;zvy nab&iacute;zen&yacute;ch P&#345;epravc&#367; a jejich slu&#382;eb jsou majetkem opr&aacute;vn&#283;n&iacute;m majitel&#367;, m&#367;&#382;ou b&yacute;t jimi chr&aacute;n&#283;n&eacute; a nejsou nijak spojen&eacute; s Poskytovatelem.</span></li>
            <li class="c10"><span class="c0">Jak&eacute;koliv spory, neshody nebo n&aacute;roky vypl&yacute;vaj&iacute;c&iacute; ze smluvn&iacute;ho vztahu Poskytovatele a Objednatele budou &#345;e&scaron;eny v&#382;dy prim&aacute;rn&#283; sm&iacute;rnou cestou a dohodou. Nebude-li mo&#382;n&eacute; tyto spory vy&#345;e&scaron;it sm&iacute;rnou cestou, budou &#345;e&scaron;eny soudem m&iacute;stn&#283; p&#345;&iacute;slu&scaron;n&eacute;m Poskytovateli.</span></li>
            <li class="c10"><span class="c0">Uzav&iacute;r&aacute; se smlouva na jeden objednan&yacute; obchod, p&#345;&iacute;padn&#283; na dobu uvedenou ve smlouv&#283;. Kter&aacute;koliv ze smluvn&iacute;ch stran je opr&aacute;vn&#283;na smlouvu na dobu neur&#269;itou vypov&#283;d&#283;t bez uveden&iacute; d&#367;vodu. V&yacute;pov&#283;dn&iacute; doba v tomto p&#345;&iacute;pad&#283; &#269;in&iacute; jeden m&#283;s&iacute;ce a po&#269;&iacute;n&aacute; b&#283;&#382;et prvn&iacute;m dnem kalend&aacute;&#345;n&iacute;ho m&#283;s&iacute;ce n&aacute;sleduj&iacute;c&iacute;ho po doru&#269;en&iacute; v&yacute;pov&#283;di druh&eacute; smluvn&iacute; stran&#283;. V&yacute;pov&#283;dn&iacute; doba se nevztahuje na jednor&aacute;zov&eacute; obchody.</span></li>
            <li class="c10"><span class="c0">Kter&aacute;koliv ze smluvn&iacute;ch stran je opr&aacute;vn&#283;na okam&#382;it&#283; odstoupit od smlouvy z&nbsp;d&#367;vodu podstatn&eacute;ho poru&scaron;en&iacute; z&aacute;vazk&#367; druh&eacute; strany. Pro vylou&#269;en&iacute; pochybnost&iacute; se za podstatn&eacute; poru&scaron;en&iacute; povinnost&iacute; Objednatele pro &uacute;&#269;ely t&eacute;to smlouvy pova&#382;uje zejm&eacute;na prodlen&iacute; Objednatele s pln&#283;n&iacute;m ceny uveden&eacute; v objedn&aacute;vce a poru&scaron;en&iacute; povinnost&iacute; sjednan&yacute;ch t&#283;mito Podm&iacute;nkami. U fyzick&yacute;ch osob nepodnikatel&#367; plat&iacute; pro odstoupen&iacute; od smlouvy ust. O spot&#345;ebitelsk&yacute;ch smlouv&aacute;ch dle Ob&#269;ansk&eacute;ho z&aacute;kon&iacute;ku.</span></li>
            <li class="c10"><span class="c0">V&yacute;zvy, odstoupen&iacute; a dal&scaron;&iacute; jednostrann&eacute; &uacute;kony proveden&eacute; jednou ze stran v&#367;&#269;i stran&#283; druh&eacute; se, pokud nedojde k jejich doru&#269;en&iacute;, pova&#382;uj&iacute; za doru&#269;en&eacute; nejpozd&#283;ji p&aacute;t&yacute;m dnem pot&eacute;, co strana &uacute;kon &#269;in&iacute;c&iacute; jej podala ve form&#283; doporu&#269;en&eacute; listovn&iacute; z&aacute;silky k po&scaron;tovn&iacute; p&#345;eprav&#283; provozovateli po&scaron;tovn&iacute;ch slu&#382;eb.</span></li>
            <li class="c10"><span class="c0">Pokud vztah zalo&#382;en&yacute; Zas&iacute;latelskou smlouvou obsahuje mezin&aacute;rodn&iacute; (zahrani&#269;n&iacute;) prvek, pak strany sjedn&aacute;vaj&iacute;, &#382;e vztah se &#345;&iacute;d&iacute; pr&aacute;vn&iacute;m &#345;&aacute;dem &#268;esk&eacute; republiky. Poskytovatel je opr&aacute;vn&#283;n kdykoliv jednostrann&#283; m&#283;nit tyto Obchodn&iacute; podm&iacute;nky nebo aktu&aacute;ln&iacute; cen&iacute;ky slu&#382;eb a to i bez p&#345;edchoz&iacute;ho upozorn&#283;n&iacute; objednatele. Objednatel je povinen dostate&#269;n&#283; &#269;asto sledovat uveden&eacute; Webov&eacute; str&aacute;nky Poskytovatele, seznamovat se s uveden&yacute;mi zm&#283;nami, a pokud s n&#283;kterou ze zve&#345;ejn&#283;n&yacute;ch zm&#283;n nesouhlas&iacute;, je opr&aacute;vn&#283;n smlouvu uzav&#345;enou s Poskytovatelem p&#345;ed datem &uacute;&#269;innosti zm&#283;ny p&iacute;semn&#283; vypov&#283;d&#283;t v&yacute;&scaron;e uveden&yacute;m zp&#367;sobem, v opa&#269;n&eacute;m p&#345;&iacute;pad&#283; se m&aacute; za to, &#382;e se s uveden&yacute;mi zm&#283;nami sezn&aacute;mil a souhlas&iacute; s nimi a je jimi ode dne jejich &uacute;&#269;innosti v&aacute;z&aacute;n.</span></li>
            <li class="c10"><span class="c0">Pokud je jak&eacute;koli ustanoven&iacute; podm&iacute;nek nebo jej&iacute; &#269;&aacute;st pova&#382;ov&aacute;na z jak&eacute;hokoli d&#367;vodu za neplatnou, bude pro dan&yacute; &uacute;&#269;el pova&#382;ov&aacute;na za vypu&scaron;t&#283;nou. Tato skute&#269;nost nem&aacute; vliv na platnost zb&yacute;vaj&iacute;c&iacute;ch &#269;&aacute;st&iacute; podm&iacute;nek.</span></li>
            <li class="c10" id="h.gjdgxs"><span class="c0">Tyto Obchodn&iacute; podm&iacute;nky jsou aktualizovan&yacute;m vyd&aacute;n&iacute;m Obchodn&iacute;ch podm&iacute;nek vydan&yacute;ch dne 31.1.2021</span></li>
        </ol>
        <p class="c20"><span class="c9 c16"></span></p>
    </div>
</main>
<app-footer mode='lite'></app-footer>
