import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { UserRole } from '../models/user-role';
import { UserService } from '../services/user.service';

@Injectable({
    providedIn: 'root'
}) export class NotAdminGuard implements CanActivate {


    constructor(
        private userService: UserService,
        public router: Router,
    ) {

    }

    public canActivate(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): boolean {
        const user = this.userService.user;
        if (user?.role === UserRole.ADMIN || user?.role === UserRole.DEPO) {
            this.router.navigateByUrl('/admin');
            return false;
        }
        return true;
    }
}
