<td>
    <span class="underline">{{order.packageTrackId}}</span>
</td>
<td>
    {{ order.pickUpDate | date: "d.M. yyyy"}}
</td>
<td>{{order.createdDate | date : 'd.M. H:mm yyyy'}}</td>
<td>
    <div class="table-user">
        <div>{{order.senderName}} <i class="fas fa-info-circle"></i></div>
        <div>{{order.addressFrom}}</div>
    </div>
</td>
<td>
    <div class="table-user">
        <div>{{order.receiverName}} <i class="fas fa-info-circle"></i></div>
        <div>{{order.addressTo}}</div>
    </div>
</td>
<td>
    <div class="img-wrapper">
        <img (click)='onPhotoClick(order.files[0])' *ngIf='order.files && order.files[0]' [src]='order.files[0].url' />
        <i *ngIf='!order.files || !order.files[0]' class="fas fa-image"></i>
    </div>
</td>
<td>
    <div class="img-wrapper">
        <img (click)='onPhotoClick(order.files[1])' *ngIf='order.files && order.files[1]' [src]='order.files[1].url' />
        <i *ngIf='!order.files || !order.files[1]' class="fas fa-image"></i>
    </div>
</td>
<td>
    {{order.title}}
</td>
<td>
    {{STRINGS.packageWeight[order.packageWeight]}}
</td>
<td>
    {{STRINGS.packageSize[order.packageSize]}}
</td>
<td>
    <ng-container *ngIf='!order.payment || order.payment.state !== GopayState.AUTHORIZED'>
        {{ order.finalDeliveryPrice ? order.finalDeliveryPrice + ' Kč' : 'Nezadáno' }}
    </ng-container>
    <ng-container *ngIf='order.payment && order.payment.state === GopayState.AUTHORIZED'>
        <div class='invisible-select__content'>
            <a (click)='onSetFinalPriceClick()' class="underline">{{ order.finalDeliveryPrice ? order.finalDeliveryPrice + ' Kč' : 'Nezadáno' }}</a>&nbsp;
            <i class="fas fa-pencil-alt"></i>
        </div>
    </ng-container>
</td>
<td>
    {{courierName}}
</td>
<td>
    {{ STRINGS.packageStatus[order.status] }}
</td>
<td>
    <ng-container *ngIf='order.payment'>
        {{$any(STRINGS.paymentStatus)[order.payment.state]}}
    </ng-container>
    <ng-container *ngIf='!order.payment'>
        Neznámý stav
    </ng-container>
</td>
<td>
    <button *ngIf='order.status === PackageStatus.COURIER_TAKEN' (click)='onAcceptInDepoClick()' type="button" class="btn btn--primary btn--small">
        Převzato do depa
    </button>
    <button *ngIf='order.status === PackageStatus.DEPO_TAKEN' (click)='onEnRouteToCustomerClick()' type="button" class="btn btn--primary btn--small">
        Odesláno zákazníkovi
    </button>
    <button *ngIf='order.status === PackageStatus.EN_ROUTE_TO_CUSTOMER' (click)='onDeliveredClick()' type="button" class="btn btn--primary btn--small">
        Doručeno
    </button>
</td>
