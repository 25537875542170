import { Component, Input } from '@angular/core';
import { User } from 'src/app/models/user';
import { AdminService } from 'src/app/services/admin.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AdminCourierEditComponent } from '../admin-courier-edit/admin-courier-edit.component';
import { AlertService } from 'src/app/services/alert.service';

@Component({
    selector: '[app-admin-courier-active]',
    templateUrl: './admin-courier-active.component.html',
    styleUrls: ['./admin-courier-active.component.scss']
})
export class AdminCourierActiveComponent {

    @Input() public courier!: User;

    constructor(
        private alertService: AlertService,
        private adminService: AdminService,
        private bsModalService: BsModalService,
    ) {
    }

    public onShowTimetableClick(): void {

    }

    public onEditProfileClick(): void {
        this.bsModalService.show(AdminCourierEditComponent, {
            initialState: { courier: this.courier }
        });
    }

    public onDisableAccountClick(): void {
        this.alertService.presentConfirm({
            title: 'Deaktivace účtu',
            message: `Opravdu si přejete deaktivovat účet kurýra ${this.courier.username}?`,
            cancelText: 'Zpět',
            onConfirm: () => this.disableAccount()
        })
    }

    private async disableAccount(): Promise<void> {
        try {
            await this.adminService.disableCourierAccount(this.courier.id);
        } catch (err) {
            this.alertService.presentAlert(
                'Chyba',
                err.message
            );
        }
    }
}
