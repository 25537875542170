<app-navbar mode='admin'></app-navbar>
<main>
    <section class="section section--top background-primary">
        <div class="container">
            <div class='row'>
                <div class='col'>
                    <h1 class='secondary-color mb-3 font-normal ff-bangers'>Staňte se doručovatelem pro Nebalík!</h1>
                    <p class='mb-5 medium-dark-color'>
                        Chcete si přivydělat a máte vlastní automobil? Pak je tato práce vhodná právě pro vás!<br/> Staňte se nezávislým kurýrem naší společnosti. Stačí vyplnit registrační formulář, nastavit si vlastní časový rozvrh a průběžně sledovat
                        přivýdělek.
                    </p>
                    <p><a (click)='scrollTo(signUp)' class="btn btn--secondary">Chci se zaregistrovat</a></p>
                </div>
            </div>
        </div>
        <img id='bg-shape-1' src='assets/images/bg-shape-1--alt.png' />
    </section>
    <section class="section section--content">
        <div class="container">
            <div class='row'>
                <div class='col'>
                    <div class="text-center">
                        <h2 class='medium-darker-color font-normal'>Jak to funguje<span class='secondary-color h-enlarge ff-bangers'>?</span></h2>
                    </div>
                </div>
            </div>
            <div class="features row">
                <div class="col">
                    <div class="feature">
                        <img class='feature__img' src='assets/images/package-take-photo.png' />
                        <h5>1. Jednoduchá registrace</h5>
                        <p>Jednoduše zaregistrujte své osobní údaje a údaje o vozidle v naší aplikaci. Zvolte si oblast, ve které chcete doručovat.</p>
                    </div>
                    <img class='feature__path' src='assets/images/path-h-1.png' />
                </div>
                <div class="col">
                    <div class="feature">
                        <img class='feature__img' src='assets/images/package-pick-up.png' />
                        <h5>2. Schválení registrace</h5>
                        <p>Po schválení registrace budete kontaktován email a vyzván přihlášením do systému, kde vám při prvním přihlášení budou vysvětleny pracovní instrukce.</p>
                    </div>
                    <img class='feature__path' src='assets/images/path-h-2.png' />
                </div>
                <div class="col">
                    <div class="feature">
                        <img class='feature__img' src='assets/images/package-delivery.png' />
                        <h5>3. Rozvrhněte si práci</h5>
                        <p>Nastavte si časový rozvrh práce dle vlastních kapacit a vyčkejte na přidělení zakázek.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section id='app' class="section section--content background-primary">
        <div class="container">
            <img src='assets/images/mobile-app-kuryr.png' />
            <div class="row">
                <div class="col">
                    <h2 class='secondary-color font-normal'>Aplikace pro Kurýry</h2>
                    <p class="m-tb-40 medium-dark-color">Staňte se součástí Nebalik týmu. Staňte se naším kurýrem. Zaregistrujte se a přes aplikaci Nebalik dokončete registraci. Pak už jen stačí zadat si vlastní časový rozvrh práce a sledovat objednávky na přepravu.</p>
                    <div class="badges">
                        <a href='https://itunes.apple.com/app/id1550791507' target='_blank'>
                            <img src="assets/images/button-app-store.png" alt="">
                        </a>
                        <a href='https://play.google.com/store/apps/details?id=cz.nebalik.kuryr' target='_blank'>
                            <img src="assets/images/button-google-play.png" alt="">
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section #signUp id='section-signup' class="section section--content background-primary-lighter">
        <app-courier-sign-up></app-courier-sign-up>
        <app-courier-resend-verification-email></app-courier-resend-verification-email>
    </section>
    <section class="section section--content background-primary">
        <div class="container">
            <div class="text-center">
                <h3 class='medium-dark-color font-light'>
                    Jste zákazník a chcete zaslat zásilku?<br/> Pokračujte do <a routerLink='/send' class='link--alt'>Osobní sekce</a>
                </h3>
            </div>
        </div>
    </section>
</main>
<app-footer attribution='courier'></app-footer>
