import { Injectable } from '@angular/core';
import * as Lockr from 'lockr';

@Injectable({
    providedIn: 'root'
}) export class StorageService {

    public get(key: string): any {
        return Lockr.get(key);
    }

    public set(key: string, value: any): void {
        return Lockr.set(key, value);
    }

    public clear(): void {
        return Lockr.flush();
    }

}
