import { Component } from '@angular/core';
import { FormGroup, Validators, AbstractControl, FormBuilder } from '@angular/forms';
import { CourierSignUp } from 'src/app/models/courier-sign-up';
import { AlertService } from 'src/app/services/alert.service';
import { HelperService } from 'src/app/services/helper.service';
import { UserService } from 'src/app/services/user.service';

@Component({
    selector: 'app-courier-sign-up',
    templateUrl: './courier-sign-up.component.html',
    styleUrls: ['./courier-sign-up.component.scss']
})
export class CourierSignUpComponent {

    public form: FormGroup = this.fb.group({
        name: ['', [Validators.required]],
        email: ['', [Validators.required, this.helperService.emailValidator]],
        phone: ['', [Validators.required, Validators.pattern(/^\d{9}$/)]],
        password: ['', [Validators.required, Validators.minLength(6)]],
        passwordCheck: ['', [Validators.required]],
        maxVolume: ['', [Validators.required]],
        maxWeight: ['', [Validators.required]],
        distributionAreas: [{ value: 'Praha', disabled: true }, [Validators.required]],
        ic: ['', [Validators.required]],
        dic: [''],
    });
    public submitted: boolean = false;
    public error: undefined | string;
    public success: boolean = false;

    public get name(): AbstractControl { return this.form.get('name') as AbstractControl }
    public get email(): AbstractControl { return this.form.get('email') as AbstractControl }
    public get phone(): AbstractControl { return this.form.get('phone') as AbstractControl }
    public get password(): AbstractControl { return this.form.get('password') as AbstractControl }
    public get passwordCheck(): AbstractControl { return this.form.get('passwordCheck') as AbstractControl }
    public get maxVolume(): AbstractControl { return this.form.get('maxVolume') as AbstractControl }
    public get maxWeight(): AbstractControl { return this.form.get('maxWeight') as AbstractControl }
    public get distributionAreas(): AbstractControl { return this.form.get('distributionAreas') as AbstractControl }
    public get ic(): AbstractControl { return this.form.get('ic') as AbstractControl }
    public get dic(): AbstractControl { return this.form.get('dic') as AbstractControl }

    constructor(
        public userService: UserService,
        private fb: FormBuilder,
        private helperService: HelperService,
        private alertService: AlertService,
    ) {
    }

    public onSubmitClick(): void {
        this.error = undefined;
        this.submitted = true;
        if (!this.form.valid || this.form.value.password !== this.form.value.passwordCheck) {
            return;
        }
        this.signUp();
    }

    private async signUp(): Promise<void> {
        this.submitted = false;
        const signUp: CourierSignUp = {
            ...this.form.value,
            phone: '+420' + this.form.value.phone,
            distributionAreas: []
        };
        try {
            await this.userService.courierSignUp(signUp);
        } catch (err) {
            this.error = err.message;
            return;
        }
        this.success = true;
        setTimeout(() => {
            window.scrollTo({
                top: this.helperService.getElementOffset(document.getElementById('section-signup') as Element).top - 158,
                left: 0, behavior: 'smooth'
            });
        }, 0);
    }
}
