<nav class="navbar fixed-top background-primary">
    <div [class.container]="userIsCustomerOrAnonymous | async" [class.container-fluid]="userIsAdminOrDepo | async">
        <a routerLink='/' class="navbar-brand">
            <app-logo></app-logo>
            <ng-container *ngIf="userIsAdmin | async">
                <div class="text-uppercase">Admin</div>
            </ng-container>
            <ng-container *ngIf="userIsDepo | async">
                <div class="text-uppercase">Depo</div>
            </ng-container>
        </a>

        <!-- LOGGED ADMIN -->
        <ng-container *ngIf="userIsAdminOrDepo | async">
            <ul class="navbar-nav mr-auto">
                <li class="nav-item ">
                    <a class="nav-link" routerLink="/admin/packages" routerLinkActive="active">
                        Rozvozy zásilek
                    </a>
                </li>
                <li *ngIf='userIsAdmin | async' class="nav-item ">
                    <a class="nav-link" routerLink="/admin/deliverers" routerLinkActive="active">
                        Dopravci
                    </a>
                </li>
                <!-- neimplementovano -->
                <!-- <li class="nav-item ">
                    <a class="nav-link">
                        Mapa
                    </a>
                </li> -->
            </ul>
            <ul class="navbar-nav ml-auto">
                <li class="nav-item dropdown" dropdown>
                    <button class="nav-link dropdown-toggle" type="button" dropdownToggle aria-controls="dropdown-user">
                        <i class="fas fa-user" style="margin-right: 10px;"></i>
                        <span>
                            {{ userService.user?.username }}
                            <span>Nebalík.cz</span>
                        </span>
                        <i class="fas fa-angle-down" style="margin-left: 10px;"></i>
                    </button>
                    <ul id="dropdown-user" class="dropdown-menu" *dropdownMenu role="menu" aria-labelledby="button-basic">
                        <li role="menuitem"><a (click)='onSignOutClick()' class="dropdown-item">Odhlásit se</a></li>
                    </ul>
                </li>
            </ul>
        </ng-container>

        <!-- LOGGED USER -->
        <ng-container *ngIf="userIsCustomer | async">
            <ul class="navbar-nav mr-auto">
                <li class="nav-item">
                    <a class="nav-link" routerLink="/send" routerLinkActive='active'>Osobní</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" routerLink="/deliver" routerLinkActive='active'>Pro
                        dopravce</a>
                </li>
            </ul>
            <ul class="navbar-nav ml-auto">
                <li *ngIf='mode === "customer"' class="nav-item nav-item--small">
                    <a routerLink='/send' fragment='app' class="nav-link">
                        Mobilní aplikace
                    </a>
                </li>
                <li *ngIf='mode === "admin"' class="nav-item nav-item--small">
                    <a routerLink='/deliver' fragment='app' class="nav-link">
                        Aplikace pro kurýry
                    </a>
                </li>
                <li class="nav-item nav-item--small dropdown" dropdown>
                    <button class="nav-link dropdown-toggle" type="button" dropdownToggle aria-controls="dropdown-user">
                        <i class="fas fa-user" style="margin-right: 10px;"></i>
                        {{ userService.user?.username }}
                        <i class="fas fa-angle-down" style="margin-left: 10px;"></i>
                    </button>
                    <ul id="dropdown-user" class="dropdown-menu" *dropdownMenu role="menu" aria-labelledby="button-basic">
                        <li role="menuitem"><a class="dropdown-item" routerLink="/send/settings">Nastavení</a></li>
                        <li role="menuitem"><a (click)='onSignOutClick()' class="dropdown-item">Odhlásit se</a></li>
                    </ul>
                </li>
                <li role="nav-item"><a class="btn btn--secondary-outline" routerLink="/send/package-new">Poslat zásilku</a></li>
            </ul>
        </ng-container>

        <!-- NOT LOGGED USER -->
        <ng-container *ngIf="!userService.user">
            <ul class="navbar-nav mr-auto">
                <li class="nav-item">
                    <a class="nav-link" routerLink="/send" routerLinkActive='active'>Osobní</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" routerLink="/deliver" routerLinkActive='active'>Pro
                        dopravce</a>
                </li>
            </ul>
            <ul *ngIf='mode === "admin"' class="navbar-nav ml-auto">
                <li class="nav-item nav-item--small">
                    <a routerLink='/deliver' fragment='app' class="nav-link">
                        Aplikace pro kurýry
                    </a>
                </li>
                <li class="nav-item">
                    <a class="btn btn--secondary-outline" (click)="openModal('businessLogin')">
                        <i class="fas fa-user"></i> Business přihlášení
                    </a>
                </li>
            </ul>
            <ul *ngIf='mode === "customer"' class="navbar-nav ml-auto">
                <li class="nav-item nav-item--small">
                    <a routerLink='/send' fragment='app' class="nav-link">
                        Mobilní aplikace
                    </a>
                </li>
                <li class="nav-item nav-item--small">
                    <a class="nav-link" (click)="openModal('registration')">Registrovat se</a>
                </li>
                <li class="nav-item nav-item--small">
                    <a class="nav-link" (click)="openModal('userLogin')">Přihlásit se</a>
                </li>
                <li role="nav-item"><a class="btn btn--secondary-outline" routerLink="/send/package-new">Poslat zásilku</a></li>
            </ul>
        </ng-container>
    </div>
</nav>
