import { Component, Input } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserRole } from 'src/app/models/user-role';
import { UserService } from 'src/app/services/user.service';
import { TermsContentComponent } from '../terms-content/terms-content.component';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
    @Input() public mode: 'lite' | 'full' = 'full';
    @Input() public attribution: 'customer' | 'courier' = 'customer';

    public UserRole: typeof UserRole = UserRole;
    public userIsCustomerOrAnonymous: Observable<boolean> = this.userService.user$
        .pipe(map(user => !user || user.role === UserRole.CUSTOMER));
    public userIsAdmin: Observable<boolean> = this.userService.user$
        .pipe(map(user => !!user && (user.role === UserRole.ADMIN || user.role === UserRole.DEPO)));

    constructor(
        public userService: UserService,
        private bsModalService: BsModalService,
    ) {
    }

    public onSupportLinkClick(): void {
        this.bsModalService.show(TermsContentComponent, {
            initialState: {
                title: 'Technická podpora',
                html: 'Far far away, behind the word mountains, far from<br/>the countries Vokalia and Consonantia, there live the<br/>blind texts',
            }
        });
    }
    public onSignOutClick(): void {
        this.userService.signOut();
    }

    public onAboutUsClick(): void {
        this.bsModalService.show(TermsContentComponent, {
            initialState: {
                title: 'O nás',
                html: 'Chceme doručování zásilek dělat jinak, pohodlněji a proto je tu Nebalík. Naší vizí je ušetřit čas, který každý raději stráví jinak, než sháněním krabic a výplní, balením a chozením na poštu. Nyní vše potřebné uděláme za vás. A vy se klidně můžete věnovat jiným věcem.',
            }
        });
    }
}
