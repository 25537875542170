import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { EmailVerificationComponent } from '../components/email-verification/email-verification.component';
import { UserService } from '../services/user.service';

@Injectable({
    providedIn: 'root'
}) export class ActiveCustomerGuard implements CanActivate {


    constructor(
        public router: Router,
        private userService: UserService,
        private bsModalService: BsModalService,
    ) {
    }

    public canActivate(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): boolean {
        const unverifiedUser = this.userService.user && !this.userService.user.verified;
        if (unverifiedUser) {
            this.bsModalService.show(EmailVerificationComponent);
            this.router.navigateByUrl('/send');
            return false;
        }
        return true;
    }
}
