import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AlertService } from '../services/alert.service';
import { OrderService } from '../services/order.service';

@Injectable({
    providedIn: 'root'
}) export class OrderAvailableGuard implements CanActivate {

    constructor(
        private router: Router,
        private alertService: AlertService,
        private datePipe: DatePipe,
        private orderService: OrderService,
    ) {
    }

    public canActivate(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): boolean {
        const availableDates = this.orderService.computeAvailableDates();
        if (!availableDates.length) {
            this.alertOrderUnavailable(this.orderService.computeNextAvailableOrderDate());
            this.router.navigateByUrl('/send');
            return false;
        }
        return true;
    }

    private alertOrderUnavailable(nextAvailableDate: string): void {
        this.alertService.presentAlert(
            'Objednávka nedostupná',
            `Z důvodu provozní doby kurýrů nelze zásilku nyní zadat. Prosím, zadajte zásilku nejdříve ${this.datePipe.transform(nextAvailableDate, 'd. M. yyyy')}.`
        );
    }
}
