import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, Validators, AbstractControl, FormBuilder } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { CourierEditProfile } from 'src/app/models/courier-edit-profile';
import { User } from 'src/app/models/user';
import { AdminService } from 'src/app/services/admin.service';
import { HelperService } from 'src/app/services/helper.service';

@Component({
    selector: 'app-admin-courier-edit',
    templateUrl: './admin-courier-edit.component.html',
    styleUrls: ['./admin-courier-edit.component.scss']
})
export class AdminCourierEditComponent implements OnInit {

    @Input() public courier!: User;

    public form: FormGroup = this.fb.group({
        username: ['', [Validators.required]],
        email: [{ value: '', disabled: true }, [Validators.required, this.helperService.emailValidator]],
        phone: ['', [Validators.required, Validators.pattern(/^\d{9}$/)]],
        maxVolume: ['', [Validators.required]],
        maxWeight: ['', [Validators.required]],
        distributionAreas: [{ value: 'Praha', disabled: true }, [Validators.required]],
        ic: ['', [Validators.required]],
        dic: [''],
        company: [''],
        bankAccount: [''],
        address: [''],
        city: [''],
        zipCode: ['', [Validators.pattern(/^\d{5}$/)]],
    });
    public submitted: boolean = false;
    public error: undefined | string;

    public get username(): AbstractControl { return this.form.get('username') as AbstractControl }
    public get email(): AbstractControl { return this.form.get('email') as AbstractControl }
    public get phone(): AbstractControl { return this.form.get('phone') as AbstractControl }
    public get maxVolume(): AbstractControl { return this.form.get('maxVolume') as AbstractControl }
    public get maxWeight(): AbstractControl { return this.form.get('maxWeight') as AbstractControl }
    public get distributionAreas(): AbstractControl { return this.form.get('distributionAreas') as AbstractControl }
    public get ic(): AbstractControl { return this.form.get('ic') as AbstractControl }
    public get dic(): AbstractControl { return this.form.get('dic') as AbstractControl }
    public get company(): AbstractControl { return this.form.get('company') as AbstractControl }
    public get bankAccount(): AbstractControl { return this.form.get('bankAccount') as AbstractControl }
    public get address(): AbstractControl { return this.form.get('address') as AbstractControl }
    public get city(): AbstractControl { return this.form.get('city') as AbstractControl }
    public get zipCode(): AbstractControl { return this.form.get('zipCode') as AbstractControl }

    constructor(
        public adminService: AdminService,
        public modalRef: BsModalRef,
        private fb: FormBuilder,
        private helperService: HelperService,
    ) {
    }

    public ngOnInit(): void {
        this.form.patchValue({
            username: this.courier.username,
            email: this.courier.email,
            phone: this.courier.phone ? this.courier.phone.replace('+420', '') : '',
            maxVolume: this.courier.maxVolume,
            maxWeight: this.courier.maxWeight,
            ic: this.courier.ic,
            dic: this.courier.dic,
            company: this.courier.company,
            bankAccount: this.courier.bankAccount,
            address: this.courier.address.street,
            city: this.courier.address.city,
            zipCode: this.courier.address.zip,
        });
    }

    public onSubmitClick(): void {
        this.error = undefined;
        this.submitted = true;
        if (!this.form.valid) {
            return;
        }
        this.updateCourier();
    }

    private async updateCourier(): Promise<void> {
        this.submitted = false;
        const profileEdit: CourierEditProfile = {
            id: this.courier.id,
            email: this.courier.email,
            ...this.form.value,
            phone: '+420' + this.form.value.phone,
        };
        try {
            await this.adminService.updateCourier(profileEdit);
        } catch (err) {
            this.error = err.message;
            return;
        }
        this.modalRef.hide();
    }
}
