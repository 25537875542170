<td>
    <span class="underline">{{order.packageTrackId}}</span>
</td>
<td>
    {{ order.pickUpDate | date: "d.M. yyyy"}}
</td>
<td>
    {{order.title}}
</td>
<td>
    <ng-container *ngIf='!order.payment || order.payment.state !== GopayState.AUTHORIZED'>
        {{ order.finalDeliveryPrice ? order.finalDeliveryPrice + ' Kč' : 'Nezadáno' }}
    </ng-container>
    <ng-container *ngIf='order.payment && order.payment.state === GopayState.AUTHORIZED'>
        <div class='invisible-select__content'>
            <a (click)='onSetFinalPriceClick()' class="underline">{{ order.finalDeliveryPrice ? order.finalDeliveryPrice + ' Kč' : 'Nezadáno' }}</a>&nbsp;
            <i class="fas fa-pencil-alt"></i>
        </div>
    </ng-container>
</td>
<td class='invisible-select'>
    <select *ngIf='couriers' [ngModel]='order.courierId' (change)='onCourierChange($any($event).target.value)' class='invisible-select__select'>
        <option *ngFor='let courier of couriers' [value]="courier.id">{{courier.username}}</option>
    </select>
    <div class='invisible-select__content'>
        <a class="underline">{{courierName}}</a> &nbsp;
        <i class="fas fa-pencil-alt"></i>
    </div>
</td>
<td>
    <select [(ngModel)]='order.status' (change)='onPackageStatusChange($any($event).target.value)'>
        <option [value]="PackageStatus.WAITING">{{STRINGS.packageStatus[PackageStatus.WAITING]}}</option>
        <option [value]="PackageStatus.COURIER_PLANNED">{{STRINGS.packageStatus[PackageStatus.COURIER_PLANNED]}}</option>
        <option [value]="PackageStatus.COURIER_TAKEN">{{STRINGS.packageStatus[PackageStatus.COURIER_TAKEN]}}</option>
        <option [value]="PackageStatus.DEPO_TAKEN">{{STRINGS.packageStatus[PackageStatus.DEPO_TAKEN]}}</option>
        <option [value]="PackageStatus.EN_ROUTE_TO_CUSTOMER">{{STRINGS.packageStatus[PackageStatus.EN_ROUTE_TO_CUSTOMER]}}</option>
        <option [value]="PackageStatus.DELIVERED">{{STRINGS.packageStatus[PackageStatus.DELIVERED]}}</option>
        <option [value]="PackageStatus.DECLINED_OVERSIZED">{{STRINGS.packageStatus[PackageStatus.DECLINED_OVERSIZED]}}</option>
        <option [value]="PackageStatus.DECLINED_PROHIBITED">{{STRINGS.packageStatus[PackageStatus.DECLINED_PROHIBITED]}}</option>
        <option [value]="PackageStatus.DECLINED_SENDER_NOT_PRESENT">{{STRINGS.packageStatus[PackageStatus.DECLINED_SENDER_NOT_PRESENT]}}</option>
        <option [value]="PackageStatus.DECLINED">{{STRINGS.packageStatus[PackageStatus.DECLINED]}}</option>
        <option [value]="PackageStatus.CUSTOMER_DECLINED">{{STRINGS.packageStatus[PackageStatus.CUSTOMER_DECLINED]}}</option>
    </select>
</td>
<td>
    <ng-container *ngIf='order.payment'>
        {{$any(STRINGS.paymentStatus)[order.payment.state]}}
    </ng-container>
    <ng-container *ngIf='!order.payment'>
        Neznámý stav
    </ng-container>
</td>
<td>
    <button (click)='onDetailClick()' type="button" class="btn btn--primary btn--small">
        Detail
    </button>
</td>
