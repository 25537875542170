import { Pipe, PipeTransform } from '@angular/core';
@Pipe({ name: 'phone' })
export class PhonePipe implements PipeTransform {
    public transform(value: string): string {
        if (!value) {
            return value;
        }
        const match = value.match(/^(\+\d{3})(\d{3})(\d{3})(\d{3})$/);
        if (match) {
            return match.slice(1).join(' ');
        };
        return value
    }
}
