import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { FIREBASE_CONFIG } from 'src/config';
import { environment } from 'src/environments/environment';

declare var firebase: any;

@Injectable({
    providedIn: 'root',
})
export class AnalyticsService {

    constructor(
        private router: Router,
    ) {
    }

    public initialize(): void {
        if (!firebase?.analytics) {
            return;
        }
        if (!environment.production) {
            return;
        }
        firebase.initializeApp(FIREBASE_CONFIG);
        firebase.analytics();

        this.router.events.subscribe(async (evt) => {
            if (evt instanceof NavigationEnd) {
                const screen = evt.url.substring(1, evt.url.indexOf(';') > -1 ? evt.url.indexOf(';') : evt.url.length);
                if (screen) {
                    console.log('Analytics setCurrentScreen', screen);
                    try {
                        firebase.analytics().setCurrentScreen(screen);
                    } catch (e) {
                        console.log('Analytics setCurrentScreen error', e);
                    }
                }
            }
        });
    }

    public async setUserId(id: string): Promise<void> {
        console.log('Analytics setUserId', id);
        if (!firebase?.analytics) {
            return;
        }
        try {
            firebase.analytics().setUserId(id);
        } catch (e) {
            console.log('Analytics setUserId error', e);
        }
    }

    public async logEvent(event: {
        name: string;
        params?: { [key: string]: any };
    }): Promise<void> {
        console.log('Analytics logEvent', event);
        if (!firebase?.analytics) {
            return;
        }
        try {
            firebase.analytics().logEvent(event.name, event.params);
        } catch (e) {
            console.log('Analytics logEvent error', e);
        }
    }

    public async setUserProperty(name: string, value: string): Promise<void> {
        console.log('Analytics setUserProperty', name, value);
        if (!firebase?.analytics) {
            return;
        }
        try {
            firebase.analytics().setUserProperties({ [name]: value });
        } catch (e) {
            console.log('Analytics setUserProperty error', e);
        }
    }
}
