import { Component } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { HelperService } from 'src/app/services/helper.service';
import { UserService } from 'src/app/services/user.service';

@Component({
    selector: 'app-password-reset',
    templateUrl: './password-reset.component.html',
    styleUrls: ['./password-reset.component.scss']
})
export class PasswordResetComponent {

    public submitted: boolean = false;
    public success: boolean = false;
    public error: undefined | string;
    public form: FormGroup = this.fb.group({
        email: [{ value: '', disabled: this.success }, [Validators.required, this.helperService.emailValidator]],
    });

    public get email(): AbstractControl { return this.form.get('email') as AbstractControl }

    constructor(
        public userService: UserService,
        public modalRef: BsModalRef,
        private fb: FormBuilder,
        private helperService: HelperService,
    ) {
    }

    public onSubmitClick(): void {
        this.error = undefined;
        this.submitted = true;
        if (!this.form.valid) {
            return;
        }
        this.resetPassword();
    }

    private async resetPassword(): Promise<void> {
        this.submitted = false;
        try {
            await this.userService.userResetPassword(this.form.controls.email.value);
        } catch (err) {
            this.error = err.message;
            return;
        }
        this.success = true;
        this.form.controls.email.disable();
    }
}
