<app-navbar></app-navbar>
<main>
    <section class="section section--content p-t-150">
        <div class="container-fluid background-primary py-2 d-flex justify-content-between">
            <h2 class="font-normal">Dopravci</h2>
           <!-- Funkce přidání dopravce v administraci v první verzi nebude -->
           <!--  <button (click)='onAddCourierClick()' type="button" class="btn btn--secondary">
                Přidat dopravce
            </button> -->
        </div>
        <div class="container-fluid pt-3">
            <tabset>
                <tab [heading]="'Aktivní účty'" (selectTab)='onTabChange(UserStatus.COURIER_ACTIVE)'>
                    <ng-container *ngIf='couriers$ | async as items'>
                        <table (mfSortByChange)='onSortChange(mf)' (mfSortOrderChange)='onSortChange(mf)' [mfData]="items" #mf="mfDataTable" [mfRowsOnPage]="10" class="admin-table">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th colspan="5" class="text-center">Zásilek na následujících 5 dnů <i class="fas fa-info-circle"></i></th>
                                    <th colspan="4"></th>
                                </tr>
                                <tr>
                                    <th><app-table-sorter by="_username">Jméno</app-table-sorter></th>
                                    <th class="text-center" *ngFor='let isoDate of planPreviewDates'>{{isoDate | date: 'd.M.'}}</th>
                                    <!-- <th>Časový harmonogram</th> -->
                                    <th><app-table-sorter by="_phone">Telefon</app-table-sorter></th>
                                    <th><app-table-sorter by="_email">E-mail</app-table-sorter></th>
                                    <th><app-table-sorter by="_createdDate">Registrován</app-table-sorter></th>
                                    <th class="text-center">Akce</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngIf='items.length === 0'>
                                    <td colspan='10'>
                                        <div *ngIf='items.length === 0' class='list-empty-message'>Žádné výsledky</div>
                                    </td>
                                </tr>
                                <tr *ngFor='let item of mf.data' app-admin-courier-active [courier]='item'></tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td colspan="10">
                                        <mfBootstrapPaginator (click)="onPageChange(mf)" [rowsOnPageSet]="[5,10,25,50]"></mfBootstrapPaginator>
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </ng-container>
                </tab>
                <tab [heading]="'Čekající registrace'"  (selectTab)='onTabChange(UserStatus.COURIER_IN_REVIEW)'>
                    <ng-container *ngIf='couriers$ | async as items'>
                        <table (mfSortByChange)='onSortChange(mf)' (mfSortOrderChange)='onSortChange(mf)' [mfData]="items" #mf="mfDataTable" [mfRowsOnPage]="10" class="admin-table">
                            <thead>
                                <tr>
                                    <th><app-table-sorter by="_username">Jméno</app-table-sorter></th>
                                    <th><app-table-sorter by="_createdDate">Registrace</app-table-sorter></th>
                                    <!-- <th>Časový harmonogram</th> -->
                                    <th><app-table-sorter by="_phone">Telefon</app-table-sorter></th>
                                    <th><app-table-sorter by="_email">E-mail</app-table-sorter></th>
                                    <th class="text-right">Akce</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngIf='items.length === 0'>
                                    <td colspan='5'>
                                        <div *ngIf='items.length === 0' class='list-empty-message'>Žádné výsledky</div>
                                    </td>
                                </tr>
                                <tr *ngFor='let item of mf.data' app-admin-courier-pending [courier]='item'></tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td colspan="5">
                                        <mfBootstrapPaginator (click)="onPageChange(mf)" [rowsOnPageSet]="[5,10,25,50]"></mfBootstrapPaginator>
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </ng-container>
                </tab>
                <tab [heading]="'Deaktivované účty'"  (selectTab)='onTabChange(UserStatus.COURIER_DEACTIVATED)'>
                    <ng-container *ngIf='couriers$ | async as items'>
                        <table (mfSortByChange)='onSortChange(mf)' (mfSortOrderChange)='onSortChange(mf)' [mfData]="items" #mf="mfDataTable" [mfRowsOnPage]="10" class="admin-table">
                            <thead>
                                <tr>
                                    <th><app-table-sorter by="_username">Jméno</app-table-sorter></th>
                                    <th><app-table-sorter by="_createdDate">Registrace</app-table-sorter></th>
                                    <th><app-table-sorter by="_phone">Telefon</app-table-sorter></th>
                                    <th><app-table-sorter by="_email">E-mail</app-table-sorter></th>
                                    <th class="text-right">Akce</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngIf='items.length === 0'>
                                    <td colspan='6'>
                                        <div *ngIf='items.length === 0' class='list-empty-message'>Žádné výsledky</div>
                                    </td>
                                </tr>
                                <tr *ngFor='let item of mf.data' app-admin-courier-inactive [courier]='item'></tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td colspan="6">
                                        <mfBootstrapPaginator (click)="onPageChange(mf)" [rowsOnPageSet]="[5,10,25,50]"></mfBootstrapPaginator>
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </ng-container>
                </tab>
                <tab [heading]="'Zamítnuté registrace'"  (selectTab)='onTabChange(UserStatus.COURIER_DECLINED)'>
                    <ng-container *ngIf='couriers$ | async as items'>
                        <table (mfSortByChange)='onSortChange(mf)' (mfSortOrderChange)='onSortChange(mf)' [mfData]="items" #mf="mfDataTable" [mfRowsOnPage]="10" class="admin-table">
                            <thead>
                                <tr>
                                    <th><app-table-sorter by="_username">Jméno</app-table-sorter></th>
                                    <th><app-table-sorter by="_createdDate">Registrace</app-table-sorter></th>
                                    <th><app-table-sorter by="_phone">Telefon</app-table-sorter></th>
                                    <th><app-table-sorter by="_email">E-mail</app-table-sorter></th>
                                    <th class="text-right">Akce</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngIf='items.length === 0'>
                                    <td colspan='6'>
                                        <div *ngIf='items.length === 0' class='list-empty-message'>Žádné výsledky</div>
                                    </td>
                                </tr>
                                <tr *ngFor='let item of mf.data' app-admin-courier-declined [courier]='item'></tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td colspan="6">
                                        <mfBootstrapPaginator (click)="onPageChange(mf)" [rowsOnPageSet]="[5,10,25,50]"></mfBootstrapPaginator>
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </ng-container>
                </tab>
            </tabset>
        </div>
    </section>
</main>
<app-footer mode='lite'></app-footer>
