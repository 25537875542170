import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AlertService } from 'src/app/services/alert.service';
import { UserService } from 'src/app/services/user.service';

@Component({
    selector: 'app-email-verification',
    templateUrl: './email-verification.component.html',
    styleUrls: ['./email-verification.component.scss']
})
export class EmailVerificationComponent {

    public error: string = '';
    public userEmail: string | undefined = this.userService.user?.email;

    constructor(
        public userService: UserService,
        public modalRef: BsModalRef,
        private alertService: AlertService,
    ) {
    }

    public async onResendVerificationEmailClick(): Promise<void> {
        if (!this.userEmail) {
            return;
        }
        this.error = '';
        try {
            await this.userService.userResendVerificationEmail(this.userEmail, 'CUSTOMER');
        } catch (err) {
            this.error = err.message;
        }
    }

    public async onConfirmClick(): Promise<void> {
        this.error = '';
        try {
            await this.userService.customerCheckEmailVerified();
        } catch (err) {
            this.error = err.message;
            return;
        }
        if (!this.userService.user?.verified) {
            this.error = 'Chyba při ověření. Zkontrolujte zda jste klepnuli na odkaz, který jsme vám zaslali v e-mailu.';
        } else {
            // success
            this.alertService.presentToast('Děkujeme! Registrace proběhla úspěšně.');
            this.modalRef.hide();
        }
    }
}
