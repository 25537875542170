<form (submit)='onSubmitClick()' [formGroup]='form' [class.submitted]='submitted' novalidate>
    <fieldset>
        <img class='group-img group-img--info' src="assets/images/package-info.png" alt="">
        <h5 class='primary-color font-semibold'>Změna hesla</h5>
        <div class="form-group">
            <label>Současné heslo</label>
            <input formControlName='oldPassword' type="password" class="form-control" required>
            <div *ngIf='submitted && oldPassword.invalid' class="invalid-feedback">
                Zadejte prosím současné heslo.
            </div>
        </div>
        <div class="form-group">
            <label>Nové heslo</label>
            <input formControlName='newPassword' type="password" class="form-control" required>
            <div *ngIf='submitted && newPassword.invalid' class="invalid-feedback">
                Zadejte prosím nové heslo o alespoň 6 znacích.
            </div>
        </div>
        <div class="form-group">
            <label>Zopakovat nové heslo</label>
            <input formControlName='newPasswordControl' type="password" class="form-control" required>
            <div *ngIf='submitted && newPasswordControl.invalid' class="invalid-feedback">
                Zadejte prosím nové heslo ještě jednou pro kontrolu.
            </div>
            <div *ngIf='submitted && newPasswordControl.valid && newPassword.value !== newPasswordControl.value' class="invalid-feedback">
                Hesla se neshodují
            </div>
        </div>
        <div *ngIf='error' class='form-error-message'>
            {{error}}
        </div>
        <button class="btn btn--primary" type="submit">Změnit heslo</button>
    </fieldset>
</form>
