<div class="modal-header">
    <h4 class="modal-title pull-left primary-color">{{title}}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
</div>
<div class="modal-body  grey-color">
    <p>{{message}}</p>
    <div *ngIf='showConfirmButton' class='mt-5 d-flex justify-content-center'>
        <button (click)='onConfirmClick()' class='btn btn--primary'>OK</button>
    </div>
</div>
