import { Component, Input } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserRole } from 'src/app/models/user-role';
import { UserService } from 'src/app/services/user.service';
import { AdminSignInComponent } from '../admin-sign-in/admin-sign-in.component';
import { CustomerSignInComponent } from '../customer-sign-in/customer-sign-in.component';
import { CustomerSignUpComponent } from '../customer-sign-up/customer-sign-up.component';
@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent {
    @Input() public mode: string = 'customer';

    public userSignedIn: Observable<boolean> = this.userService.user$
        .pipe(map(user => !!user));
    public userIsCustomerOrAnonymous: Observable<boolean> = this.userService.user$
        .pipe(map(user => !user || user.role === UserRole.CUSTOMER));
    public userIsAdmin: Observable<boolean> = this.userService.user$
        .pipe(map(user => !!user && user.role === UserRole.ADMIN));
    public userIsDepo: Observable<boolean> = this.userService.user$
        .pipe(map(user => !!user && user.role === UserRole.DEPO));
    public userIsAdminOrDepo: Observable<boolean> = this.userService.user$
        .pipe(map(user => !!user && (user.role === UserRole.ADMIN || user.role === UserRole.DEPO)));
    public userIsCustomer: Observable<boolean> = this.userService.user$
        .pipe(map(user => !!user && user.role === UserRole.CUSTOMER));

    constructor(
        public userService: UserService,
        private bsModalService: BsModalService,
    ) {
    }

    public openModal(modalType: 'userLogin' | 'businessLogin' | 'registration'): void {
        const modalMap = {
            userLogin: CustomerSignInComponent,
            businessLogin: AdminSignInComponent,
            registration: CustomerSignUpComponent,
        };
        this.bsModalService.show(modalMap[modalType] as any);
    }

    public onSignOutClick(): void {
        this.userService.signOut();
    }
}
