import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { concatMap, map } from 'rxjs/operators';
import { BASE_URL } from 'src/config';
import { Order, orderToDTO } from '../models/order';
import { SignIn } from '../models/sign-in';
import { CustomerSignUp } from '../models/customer-sign-up';
import { StorageKeys } from '../models/storage-keys';
import { dtoToUser, User } from '../models/user';
import { CustomerEditProfile, customerEditProfileToDto } from '../models/customer-edit-profile';
import { StorageService } from './storage.service';
import { CourierSignUp } from '../models/courier-sign-up';
import { CustomerChangePassword } from '../models/customer-change-password';
import { dtoToPackage, Package } from '../models/package';
import { CourierEditProfile } from '../models/courier-edit-profile';
import { CourierSearch } from '../models/courier-search';
import { PackageSearch } from '../models/package-search';
import { PackageStatus } from '../models/package-status';
import { UserStatus } from '../models/user-status';
import { Payment } from '../models/payment';

@Injectable() export class ApiService {

    private token: string | undefined = undefined;

    constructor(
        private storage: StorageService,
        private http: HttpClient,
    ) {
    }

    public setTokenFromCache(token: string): void {
        this.token = token;
    }

    public reloadUser(): Observable<User> {
        return this.getUser();
    }

    public customerSignIn(input: SignIn): Observable<User> {
        const endpoint = `${BASE_URL}/auth/customer/sign-in`;
        return this.http
            .post<any>(
                endpoint,
                {
                    username: input.email,
                    password: input.password
                }
            ).pipe(
                map(resp => resp.access_token),
                concatMap(token => {
                    this.token = token;
                    this.storage.set(StorageKeys.API_TOKEN, token);
                    return this.getUser();
                })
            );
    }

    public adminSignIn(input: SignIn): Observable<User> {
        const endpoint = `${BASE_URL}/auth/admin/sign-in`; // TODO prihlaseni do administrace vs do depa
        return this.http
            .post<any>(endpoint,
                {
                    username: input.email,
                    password: input.password,
                }
            ).pipe(
                map(resp => resp.access_token),
                concatMap(token => {
                    this.token = token;
                    this.storage.set(StorageKeys.API_TOKEN, token);
                    return this.getUser();
                })
            );
    }

    public customerSignUp(input: CustomerSignUp): Observable<User> {
        const endpoint = `${BASE_URL}/auth/customer/sign-up`;
        return this.http
            .post<any>(
                endpoint,
                {
                    username: input.name,
                    password: input.password,
                    phone: input.phone,
                    email: input.email,
                }
            ).pipe(
                map(resp => resp.access_token),
                concatMap(token => {
                    this.token = token;
                    this.storage.set(StorageKeys.API_TOKEN, token);
                    return this.getUser();
                })
            );
    }

    public courierSignUp(input: CourierSignUp): Observable<void> {
        const endpoint = `${BASE_URL}/auth/courier/sign-up`;
        return this.http.post<any>(endpoint, {
            username: input.name,
            password: input.password,
            phone: input.phone,
            email: input.email,
            distributionAreas: input.distributionAreas,
            dic: input.dic,
            ic: input.ic,
            maxVolume: input.maxVolume,
            maxWeight: input.maxWeight,
        });
    }

    public customerGetEstimatedPrice(userId: string, order: Order): Observable<string> {
        const endpoint = `${BASE_URL}/package/price`;
        const body = {
            ...orderToDTO(order),
            userId,
        };
        return this.http.post<string>(endpoint, body, { headers: this.getAuthHeaders(), responseType: 'text' as any })
    }

    public userResetPassword(email: string): Observable<void> {
        const endpoint = `${BASE_URL}/auth/lost-password`;
        return this.http.post<void>(endpoint, { email, app: 'CUSTOMER' });
    }

    public userSetPassword(resetToken: string, newPassword: string): Observable<void> {
        const endpoint = `${BASE_URL}/auth/new-password/${resetToken}`;
        return this.http.post<void>(endpoint, { password: newPassword });
    }

    public userResendVerificationEmail(email: string, app: string): Observable<void> {
        const endpoint = `${BASE_URL}/auth/resend-verification-email`;
        return this.http.post<void>(endpoint, {
            email,
            app
        });
    }

    public userVerifyEmail(verificationToken: string): Observable<void> {
        const endpoint = `${BASE_URL}/auth/validate-email-token/${verificationToken}`;
        return this.http.get<void>(endpoint);
    }

    public customerGetPackage(id: number): Observable<Package> {
        const endpoint = `${BASE_URL}/package/${id}`;
        return this.http
            .get<any>(endpoint, { headers: this.getAuthHeaders() })
            .pipe(
                map(resp => dtoToPackage(resp))
            );
    }

    public customerGetPackages(offset: number, limit: number): Observable<{ data: Package[], total: number }> {
        const endpoint = `${BASE_URL}/package/customer`;
        return this.http
            .post<any>(
                endpoint,
                {
                    offset: '' + offset,
                    limit: '' + limit,
                    searchPattern: '',
                },
                {
                    headers: this.getAuthHeaders()
                }
            )
            .pipe(
                map(resp => ({
                    data: resp.data.map((item: any) => dtoToPackage(item)),
                    total: resp.total,
                }))
            );
    }

    public customerEditProfile(userId: string, input: CustomerEditProfile): Observable<User> {
        const endpoint = `${BASE_URL}/user`;
        const body = {
            id: userId,
            ...customerEditProfileToDto(input)
        };
        return this.http
            .put<any>(
                endpoint,
                body,
                {
                    headers: this.getAuthHeaders()
                }
            )
            .pipe(
                map(resp => dtoToUser(resp))
            );
    }

    public customerChangePassword(input: CustomerChangePassword): Observable<void> {
        const endpoint = `${BASE_URL}/user/change-password`;
        return this.http.put<void>(endpoint, input, { headers: this.getAuthHeaders() });
    }

    public customerDeleteAccount(): Observable<void> {
        const endpoint = `${BASE_URL}/user/cancel-account`;
        return this.http.get<void>(endpoint, { headers: this.getAuthHeaders() });
    }

    public customerPlaceOrder(user: User, order: Order, file: File): Observable<Package> {
        const form = new FormData();
        const jsonBody = {
            ...orderToDTO(order),
            userId: user.id,
            userEmail: user.email,
        };
        form.append('data', JSON.stringify(jsonBody));
        form.append('files', file);
        const endpoint = `${BASE_URL}/package`;
        return this.http.post<any>(endpoint, form, {
            headers: this.getAuthHeaders()
        });
    }

    public customerCreatePayment(packageId: number): Observable<Payment> {
        const endpoint = `${BASE_URL}/payments/payment`;
        return this.http
            .post<any>(
                endpoint,
                { packageId },
                {
                    headers: this.getAuthHeaders()
                }
            );
    }

    public customerRecreatePayment(packageId: number): Observable<Payment> {
        const endpoint = `${BASE_URL}/payments/payment-again`;
        return this.http
            .post<any>(
                endpoint,
                { packageId },
                {
                    headers: this.getAuthHeaders()
                }
            );
    }

    public checkPaymentStatus(paymentId: string): Observable<Payment> {
        const endpoint = `${BASE_URL}/payments/${paymentId}/state`;
        return this.http
            .get<any>(endpoint,
                {
                    headers: this.getAuthHeaders()
                }
            );
    }

    public adminCapturePayment(paymentId: string, amount: string): Observable<Payment> {
        const endpoint = `${BASE_URL}/payments/${paymentId}/capture`;
        return this.http
            .post<any>(
                endpoint,
                { amount },
                {
                    headers: this.getAuthHeaders()
                }
            );
    }

    public adminGetOrderList(search: PackageSearch): Observable<{ data: Package[], total: number }> {
        const endpoint = `${BASE_URL}/admin/packages`;
        const params = JSON.parse(JSON.stringify(search)); // remove undefined props
        return this.http
            .post<any>(endpoint,
                params,
                {
                    headers: this.getAuthHeaders()
                }
            ).pipe(
                map(resp => ({
                    total: resp.total,
                    data: resp.data.map((item: any) => dtoToPackage(item)),
                }))
            );
    }

    public adminUpdateOrder(orderId: number, stub: Partial<Package>): Observable<Package> {
        const endpoint = `${BASE_URL}/package`;
        return this.http.put<Package>(
            endpoint,
            {
                id: orderId,
                ...stub
            },
            {
                headers: this.getAuthHeaders()
            }
        ).pipe(
            map(resp => dtoToPackage(resp))
        );
    }

    public adminGetCourierList(search: CourierSearch): Observable<{ data: User[], total: number }> {
        const endpoint = `${BASE_URL}/admin/courier-list`;
        return this.http.post<any>(endpoint, search, { headers: this.getAuthHeaders() })
            .pipe(
                map(resp => ({
                    data: resp.data.map((item: any) => dtoToUser(item)),
                    total: resp.total,
                }))
            );
    }

    public adminUpdateCourier(profileEdit: CourierEditProfile): Observable<User> {
        const endpoint = `${BASE_URL}/user`;
        return this.http.put<User>(endpoint, profileEdit, { headers: this.getAuthHeaders() });
    }

    public adminDisableCourierAccount(userId: string): Observable<void> {
        const endpoint = `${BASE_URL}/admin/courier-status`;
        return this.http.put<void>(
            endpoint,
            {
                userId,
                status: UserStatus.COURIER_DEACTIVATED,
                message: '',
            },
            {
                headers: this.getAuthHeaders()
            });
    }

    public adminApproveCourier(userId: string): Observable<void> {
        const endpoint = `${BASE_URL}/admin/courier-status`;
        return this.http.put<void>(
            endpoint,
            {
                userId,
                status: UserStatus.COURIER_ACTIVE,
                message: '',
            },
            {
                headers: this.getAuthHeaders()
            });
    }

    public adminDeclineCourier(userId: string): Observable<void> {
        const endpoint = `${BASE_URL}/admin/courier-status`;
        return this.http.put<void>(
            endpoint,
            {
                userId,
                status: UserStatus.COURIER_DECLINED,
                message: '',
            },
            {
                headers: this.getAuthHeaders()
            });
    }

    public adminAddCourier(signUp: CustomerSignUp): Observable<void> {
        const endpoint = `${BASE_URL}/admin/courier/sign-up`;
        return this.http.post<void>(
            endpoint,
            {
                username: signUp.name,
                password: signUp.password,
                phone: signUp.phone,
                email: signUp.email,
            },
            {
                headers: this.getAuthHeaders()
            });
    }

    public adminSetPackageAcceptedInDepo(packageId: number): Observable<void> {
        const endpoint = `${BASE_URL}/package/${packageId}/take-over-depo`;
        return this.http.post<void>(
            endpoint,
            {},
            {
                headers: this.getAuthHeaders()
            });
    }

    public adminSetPackageEnRouteToCustomer(packageId: number): Observable<void> {
        const endpoint = `${BASE_URL}/package/${packageId}/on-the-way-depo`;
        return this.http.post<void>(
            endpoint,
            {},
            {
                headers: this.getAuthHeaders()
            });
    }

    public adminSetPackageDelivered(packageId: number): Observable<void> {
        const endpoint = `${BASE_URL}/package/${packageId}/deliver`;
        return this.http.post<void>(
            endpoint,
            {},
            {
                headers: this.getAuthHeaders()
            });
    }

    public adminSetPackageTakenByCourier(packageId: number): Observable<void> {
        const endpoint = `${BASE_URL}/package/${packageId}/take-over-courier`;
        return this.http.post<void>(
            endpoint,
            {},
            {
                headers: this.getAuthHeaders()
            });
    }

    public adminSetPackageDeclinedByCourier(packageId: number, status: PackageStatus): Observable<void> {
        const endpoint = `${BASE_URL}/package/${packageId}/decline-courier`;
        return this.http.post<void>(
            endpoint,
            {
                status
            },
            {
                headers: this.getAuthHeaders()
            });
    }

    public adminDeleteOrder(id: number): Observable<void> {
        const endpoint = `${BASE_URL}/package/${id}`;
        return this.http.delete<void>(
            endpoint,
            {
                headers: this.getAuthHeaders()
            });
    }

    private getUser(): Observable<User> {
        const endpoint = `${BASE_URL}/user`;
        return this.http
            .get<any>(endpoint, { headers: this.getAuthHeaders() })
            .pipe(
                map(resp => dtoToUser(resp))
            );
    }

    private getAuthHeaders(): { [key: string]: string } {
        return {
            Authorization: `Bearer ${this.token}`,
        };
    }
}
