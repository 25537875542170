<app-navbar></app-navbar>
<main>
    <section class="section section--top background-primary">
        <div class="container">
            <h2 class="font-normal">Objednávka přepravy</h2>
            <p>Nahrajte fotku předmětu, zadejte údaje a vyberte si čas doručení. Balení nechte na nás!</p>
            <img id='path-3' src='assets/images/path-3.png' />
        </div>
        <img id='bg-shape-1' src='assets/images/bg-shape-1.png' />
    </section>
    <section class="section section--content background-primary-lighter pb-0">
        <div class="container pb-0">
            <form (submit)='onSubmitClick()' [formGroup]='form' novalidate autocomplete="off" [class.submitted]='submitted'>
                <div class="row">
                    <div class="col-lg-6">
                        <fieldset>
                            <h5 class='primary-color font-semibold'>Detail zásilky</h5>
                            <img id='path-v' src='assets/images/path-v.png' />
                            <img class='group-img group-img--info' src="assets/images/package-info.png" alt="">
                            <div class="row">
                                <div class="col col-upload">
                                    <div class="upload grey-color">
                                        <input (change)='onPhotoFileInputChange($event)' formControlName='photo' type="file" accept="image/*" required>
                                        <img *ngIf='photoPreviewDataURL' [src]='photoPreviewDataURL' />
                                        <ng-container *ngIf='!photoPreviewDataURL'>
                                            <i class="fas fa-camera"></i> Nahrát fotku
                                        </ng-container>
                                    </div>
                                    <div *ngIf='submitted && photo.invalid' class="invalid-feedback">
                                        Nahrajte prosím fotku.
                                    </div>
                                </div>
                                <div class="col-xl-7 col-lg-6 pr-lg-4 pr-xl-3">
                                    <div class="form-group">
                                        <label>Pojmenování zásilky</label>
                                        <input formControlName='title' type="text" class="form-control" required>
                                        <div *ngIf='submitted && title.invalid' class="invalid-feedback">
                                            Zadejte prosím pojmenování zásilky.
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label>Datum převzetí</label>
                                        <ng-container *ngIf='!availableDates.length'>
                                            <div class="invalid-feedback">
                                                Z důvodu provozní doby kurýrů nelze zásilku nyní zadat.
                                            </div>
                                        </ng-container>
                                        <ng-container *ngIf='availableDates.length'>
                                            <select formControlName='pickUpDate' class="form-control" required>
                                                <option *ngFor='let date of availableDates' [value]="date">{{ date | date: 'E d. M. yyyy'}}</option>
                                            </select>
                                            <div *ngIf='submitted && pickUpDate.invalid' class="invalid-feedback">
                                                Zadejte prosím datum převzetí.
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                    </div>
                    <div class="col-lg-6 pr-5">
                        <fieldset class='mb-5'>
                            <h5 class='primary-color font-semibold'>&nbsp;</h5>
                            <div class="form-group">
                                <label>Váha zásilky</label>
                                <select formControlName='weight' class="form-control" required>
                                            <option [value]="PackageWeight.SMALL">{{STRINGS.packageWeight[PackageWeight.SMALL]}}</option>
                                            <option [value]="PackageWeight.LARGE">{{STRINGS.packageWeight[PackageWeight.LARGE]}}</option>
                                        </select>
                                <div *ngIf='submitted && weight.invalid' class="invalid-feedback">
                                    Zadejte prosím váhu zásilky.
                                </div>
                            </div>
                            <div class="form-group form-group--icon">
                                <label>Rozměry zásilky <i (click)='onDimensionsHelpClick()' class="fas fa-info-circle"></i></label>
                                <select formControlName='dimensions' class="form-control" required>
                                            <option [value]="PackageSize.SMALL">{{STRINGS.packageSize[PackageSize.SMALL]}}</option>
                                            <option [value]="PackageSize.LARGE">{{STRINGS.packageSize[PackageSize.LARGE]}}</option>
                                        </select>
                                <div *ngIf='submitted && dimensions.invalid' class="invalid-feedback">
                                    Zadejte prosím rozměry zásilky.
                                </div>
                            </div>
                        </fieldset>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-6 pr-5">
                        <fieldset class='mb-5'>
                            <h5 class='primary-color font-semibold'>Odesílatel</h5>
                            <img class='group-img group-img--from' src="assets/images/package-to.png" alt="">
                            <div class="form-group">
                                <label>E-mail</label>
                                <input formControlName='senderEmail' type="email" class="form-control" required>
                                <div *ngIf='submitted && senderEmail.invalid' class="invalid-feedback">
                                    Zadejte prosím platný email.
                                </div>
                                <p *ngIf='!userService.user'>Již máte účet? <a (click)='onSignInClick()' class='underline'>Přihlaste se</a></p>
                            </div>
                            <div class="form-group">
                                <label>Jméno a příjmení</label>
                                <input formControlName='senderName' type="text" class="form-control" required>
                                <div *ngIf='submitted && senderName.invalid' class="invalid-feedback">
                                    Zadejte prosím jméno a příjmení.
                                </div>
                            </div>
                            <div class="form-group form-group--phone">
                                <label>Kontaktní telefon pro přepravce</label>
                                <input formControlName='senderPhone' type="number" class="form-control" required>
                                <div *ngIf='submitted && senderPhone.invalid' class="invalid-feedback">
                                    Zadejte prosím platné telefonní číslo.
                                </div>
                            </div>
                            <div class="form-group">
                                <label>Ulice a číslo domu</label>
                                <input formControlName='senderStreet' type="text" class="form-control" required>
                                <div *ngIf='submitted && senderStreet.invalid' class="invalid-feedback">
                                    Zadejte prosím ulici a číslo domu.
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-9">

                                    <div class="form-group">
                                        <label>Město</label>
                                        <input formControlName='senderCity' type="text" class="form-control" required>
                                        <div *ngIf='submitted && senderCity.invalid' class="invalid-feedback">
                                            Zadejte prosím město.
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-3">

                                    <div class="form-group">
                                        <label>PSČ</label>
                                        <input formControlName='senderZip' type="number" class="form-control" required>
                                        <div *ngIf='submitted && senderZip.invalid' class="invalid-feedback">
                                            Zadejte prosím platné PSČ.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                    </div>
                    <div class="col-lg-6 pr-5">
                        <fieldset class='mb-5'>
                            <h5 class='primary-color font-semibold'>
                                Příjemce
                            </h5>
                            <img class='group-img group-img--from' src="assets/images/package-to.png" alt="">
                            <div class="form-group">
                                <label>Jméno a příjmení</label>
                                <input formControlName='recipientName' type="text" class="form-control" required>
                                <div *ngIf='submitted && recipientName.invalid' class="invalid-feedback">
                                    Zadejte prosím jméno a příjmení.
                                </div>
                            </div>
                            <div class="form-group form-group--phone">
                                <label>Kontaktní telefon pro přepravce</label>
                                <input formControlName='recipientPhone' type="number" class="form-control" required>
                                <div *ngIf='submitted && recipientPhone.invalid' class="invalid-feedback">
                                    Zadejte prosím platné telefonní číslo.
                                </div>
                            </div>
                            <div class="form-group">
                                <label>Ulice a číslo domu</label>
                                <input formControlName='recipientStreet' type="text" class="form-control" required>
                                <div *ngIf='submitted && recipientStreet.invalid' class="invalid-feedback">
                                    Zadejte prosím ulici a číslo domu.
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-9">

                                    <div class="form-group">
                                        <label>Město</label>
                                        <input formControlName='recipientCity' type="text" class="form-control" required>
                                        <div *ngIf='submitted && recipientCity.invalid' class="invalid-feedback">
                                            Zadejte prosím město.
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-3">
                                    <div class="form-group">
                                        <label>PSČ</label>
                                        <input formControlName='recipientZip' type="number" class="form-control" required>
                                        <div *ngIf='submitted && recipientZip.invalid' class="invalid-feedback">
                                            Zadejte prosím platné PSČ.
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </fieldset>
                    </div>
                </div>
            </form>
            <div *ngIf='error' class='form-error-message'>
                {{error}}
            </div>
            <div class="px-5 background-medium color-primary b-radius price-estimate">
                <div class="d-flex align-items-center justify-content-between py-3">
                    <div *ngIf='estimatedPrice'>
                        <h3 class="m-0 pt-3 font-light primary-color">Cena zásilky: {{estimatedPrice}}</h3>
                        <p class='pt-3'>Jedná se o přibližný rozsah ceny. Zablokujeme vám na kartě horní hranici a po zabalení zásilky a zjištění skutečných nákladů vám vrátíme rozdíl do 5 pracovních dní.</p>
                        <p>Souhlasím s <a href='/terms' target='_blank'>pravidly a podmínkami pro použití služby.</a></p>
                    </div>
                    <p *ngIf='estimatedPrice' class="m-0"><button (click)='onSubmitClick()' class="btn btn--primary" [disabled]='!availableDates.length'>Souhlasím a objednat</button></p>
                    <p *ngIf='!estimatedPrice' class="m-0 ml-auto"><button (click)='onEstimatePriceClick()' class="btn btn--primary">Vypočítat cenu</button></p>
                </div>
            </div>
        </div>
    </section>
    <section class="section background-primary-lighter pb-5">
        <div class="container">
            <div class="features features--horizontal row">
                <div class="col">
                    <div class="feature">
                        <img class='feature__img' src='assets/images/package-take-photo.png' />
                        <div>

                            <h5>Rychle</h5>
                            <p>Pokud stihnete zadat objednávku do 12:00, naši kurýři si u vás vyzvednou zásilku již následující den.</p>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="feature">
                        <img class='feature__img' src='assets/images/package-pick-up.png' />
                        <div>
                            <h5>Pohodlně</h5>
                            <p>Nemusíte nikam chodit ani čekat ve frontách na pobočkách nebo přepážkách. Kurýr se staví na vámi uvedenou adresu.</p>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="feature">
                        <img class='feature__img' src='assets/images/package-delivery.png' />
                        <div>
                            <h5>Bez starostí</h5>
                            <p>O balení se postaráme my tak, aby vaše zásilky byly v bezpečí. Po odeslání pak můžete sledovat stav své zásilky a mít tak dokonalý přehled.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</main>
<app-footer mode='lite'></app-footer>
