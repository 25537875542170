import { Component, Input, OnInit } from '@angular/core';
import { Package } from 'src/app/models/package';
import { PackageStatus } from 'src/app/models/package-status';
import { STRINGS } from 'src/assets/strings';
import { User } from 'src/app/models/user';
import { AdminService } from 'src/app/services/admin.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AdminSetPriceComponent } from '../admin-set-price/admin-set-price.component';
import { PackageImage } from 'src/app/models/package-image';
import { GopayState } from 'src/app/models/gopay-state';

@Component({
    selector: '[app-depo-order]',
    templateUrl: './depo-order.component.html',
    styleUrls: ['./depo-order.component.scss']
})
export class DepoOrderComponent implements OnInit {

    @Input() public order!: Package;
    @Input() public couriers!: User[];

    public PackageStatus: typeof PackageStatus = PackageStatus;
    public STRINGS: typeof STRINGS = STRINGS;
    public GopayState: typeof GopayState = GopayState;
    public courierName: string = '';

    constructor(
        private adminService: AdminService,
        private bsModalService: BsModalService,
    ) {
    }

    public ngOnInit(): void {
        if (!this.order.id) {
            return;
        }
        this.courierName = this.order.courierName || 'Nepřiřazen';
    }

    public onAcceptInDepoClick(): void {
        this.adminService.updateOrderStatus(this.order.id, PackageStatus.DEPO_TAKEN);
    }

    public onEnRouteToCustomerClick(): void {
        this.adminService.updateOrderStatus(this.order.id, PackageStatus.EN_ROUTE_TO_CUSTOMER);
    }

    public onDeliveredClick(): void {
        this.adminService.updateOrderStatus(this.order.id, PackageStatus.DELIVERED);
    }

    public onSetFinalPriceClick(): void {
        this.bsModalService.show(AdminSetPriceComponent, {
            initialState: {
                order: this.order
            }
        });
    }

    public onPhotoClick(photo: PackageImage): void {
        window.open(photo.url, '_blank');
    }
}
