import { Injectable } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AlertModalComponent } from '../components/alert-modal/alert-modal.component';
import { ConfirmModalComponent } from '../components/confirm-modal/confirm-modal.component';

@Injectable({
    providedIn: 'root',
})
export class AlertService {
    public alerts: any[] = [];

    constructor(
        private bsModalService: BsModalService,
    ) {

    }

    public presentAlert(title: string, message: string, showConfirmButton: boolean = true): void {
        this.bsModalService.show(AlertModalComponent, {
            initialState: {
                message,
                title,
                showConfirmButton,
            }
        });
    }

    public presentConfirm(options: {
        title: string,
        message: string,
        onConfirm: () => void,
        cancelText?: string,
        onCancel?: () => void
    }): void {
        const { message, title, onConfirm, cancelText, onCancel } = options;
        this.bsModalService.show(ConfirmModalComponent, {
            initialState: {
                message,
                title,
                cancelText,
                onSubmit: () => {
                    onConfirm();
                },
                onCancel
            },
            ignoreBackdropClick: true,
        });
    }

    public presentToast(text: string, type: string = 'success', timeout: number = 2500): void {
        this.alerts.push({
            type,
            msg: text,
            timeout
        });
    }
}
