import { Component, OnInit, ViewChild } from '@angular/core';
import { DataTable } from '@pascalhonegger/ng-datatable';
import { Observable } from 'rxjs';
import { Package } from 'src/app/models/package';
import { PackageSearch } from 'src/app/models/package-search';
import { User } from 'src/app/models/user';
import { UserRole } from 'src/app/models/user-role';
import { AdminService } from 'src/app/services/admin.service';
import { UserService } from 'src/app/services/user.service';

@Component({
    selector: 'app-package-list',
    templateUrl: './package-list.component.html',
    styleUrls: ['./package-list.component.scss']
})
export class PackageListComponent implements OnInit {

    @ViewChild('mf') public dataTable!: DataTable;
    public limit: number = 10;
    public page: number = 1;
    public sortBy: string = 'createdDate';
    public order: 'ASC' | 'DESC' = 'DESC';
    public orders$: Observable<Package[] | undefined> = this.adminService.orders$;
    public activeCouriers: User[] | undefined;
    public userIsAdmin: boolean = this.userService.user?.role === UserRole.ADMIN;
    public userIsDepo: boolean = this.userService.user?.role === UserRole.DEPO;

    constructor(
        public adminService: AdminService,
        private userService: UserService,
    ) {
    }

    public async ngOnInit(): Promise<void> {
        this.loadPackages();
        this.activeCouriers = await this.adminService.getAllActiveCouriers();
    }

    public onPageChange(table: DataTable): void {
        const activePage = table.activePage;
        const limit = table.rowsOnPage;
        if (this.page !== activePage || this.limit !== limit) {
            this.page = activePage;
            this.limit = limit;
            this.loadPackages();
        }
    }

    public onSortChange(table: DataTable): void {
        const sortBy = (table.sortBy as string).substr(1);
        const order = table.sortOrder.toUpperCase() as 'ASC' | 'DESC';
        if (this.sortBy !== sortBy || this.order !== order) {
            this.sortBy = sortBy;
            this.order = order;
            this.loadPackages();
        }
    }

    private async loadPackages(): Promise<void> {
        const search: PackageSearch = {
            limit: this.limit,
            offset: (this.page - 1) * this.limit,
            order: this.order,
            sort: this.sortBy,
            searchPattern: undefined,
            packageStatus: undefined,
        };
        this.adminService.loadOrders(search);
    }
}
