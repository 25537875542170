<app-navbar></app-navbar>
<main>
    <section class="section section--content p-t-150">
        <div class="container-fluid background-primary py-2">
            <h2 class="font-normal">Rozvozy zásilek</h2>
        </div>
        <div class="container-fluid">
            <ng-container *ngIf='activeCouriers && (orders$ | async) as items'>
                <table (mfSortByChange)='onSortChange(mf)' (mfSortOrderChange)='onSortChange(mf)' [mfData]="items" [mfRowsOnPage]="10" #mf="mfDataTable" class="admin-table">
                    <thead>
                        <tr>
                            <ng-container *ngIf='userIsDepo'>
                                <th><app-table-sorter by="_id">ID</app-table-sorter></th>
                                <th><app-table-sorter by="_pickUpDate">Datum převzetí</app-table-sorter></th>
                                <th><app-table-sorter by="_createdDate">Čas vytvoření</app-table-sorter></th>
                                <th><app-table-sorter by="_senderName">Odesílatel</app-table-sorter></th>
                                <th><app-table-sorter by="_receiverName">Příjemce</app-table-sorter></th>
                                <th class='text-center'>Foto zák.</th>
                                <th class='text-center'>Foto kur.</th>
                                <th><app-table-sorter by="_title">Název</app-table-sorter></th>
                                <th><app-table-sorter by="_packageWeight">Váha</app-table-sorter></th>
                                <th><app-table-sorter by="_packageSize">Rozměry</app-table-sorter></th>
                                <th><app-table-sorter by="_finalDeliveryPrice">Finální cena</app-table-sorter></th>
                                <th><app-table-sorter by="_courierName">Dopravce</app-table-sorter></th>
                                <th><app-table-sorter by="_status">Stav</app-table-sorter></th>
                                <th>Platba</th>
                                <th *ngIf='userIsDepo'>Akce</th>
                            </ng-container>
                            <ng-container *ngIf='userIsAdmin'>
                                <th><app-table-sorter by="_id">ID</app-table-sorter></th>
                                <th><app-table-sorter by="_pickUpDate">Datum převzetí</app-table-sorter></th>
                                <th><app-table-sorter by="_title">Název</app-table-sorter></th>
                                <th><app-table-sorter by="_finalDeliveryPrice">Finální cena</app-table-sorter></th>
                                <th><app-table-sorter by="_courierName">Dopravce</app-table-sorter></th>
                                <th><app-table-sorter by="_status">Stav</app-table-sorter></th>
                                <th>Platba</th>
                                <th>Akce</th>
                            </ng-container>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngIf='userIsAdmin'>
                            <tr *ngFor='let item of mf.data' app-admin-order [order]='item' [couriers]='activeCouriers'></tr>
                        </ng-container>
                        <ng-container *ngIf='userIsDepo'>
                            <tr *ngFor='let item of mf.data' app-depo-order [order]='item' [couriers]='activeCouriers'></tr>
                        </ng-container>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td [attr.colspan]="userIsAdmin ? 8 : 15">
                                <mfBootstrapPaginator (click)='onPageChange(mf)' [rowsOnPageSet]="[5,10,25,50]"></mfBootstrapPaginator>
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </ng-container>
        </div>
    </section>
</main>
<app-footer mode='lite'></app-footer>
