import { PackageImage } from './package-image';
import { PackageStatus } from './package-status';
import { BASE_URL } from './../../config';
import { PackageSizeEnum } from './package-size';
import { PackageWeightEnum } from './package-weight';
import { Payment } from './payment';
export interface Package {
    id: number;
    packageTrackId: string;
    courierId: string;
    courierName: string;
    userId: string;
    userEmail: string;
    senderName: string;
    senderPhone: string;
    receiverName: string;
    receiverPhone: string;
    title: string;
    packageSize: PackageSizeEnum;
    packageWeight: PackageWeightEnum;
    files: PackageImage[];
    estimatedDeliveryPrice: string;
    estimatedDeliveryMaxPrice: number;
    finalDeliveryPrice: number;
    addressFrom: string;
    addressFromLat: number;
    addressFromLng: number;
    cityFrom: string;
    zipCodeFrom: string;
    addressTo: string;
    addressToLat: number;
    addressToLng: number;
    cityTo: string;
    zipCodeTo: string;
    createdDate: string;
    lastModifiedDate: string;
    pickUpDate: string;
    pickUpTime: string;
    status: PackageStatus;
    statusMessage: string;
    payment: Payment;
}

const incomingFilePathToUrl = (path: string): string => {
    const result = `${BASE_URL.replace('/api', '')}/${path}`;
    return result;
}

export const dtoToPackage = (dto: any): Package => {
    return {
        ...dto,
        finalDeliveryPrice: typeof dto.finalDeliveryPrice === 'number' ? dto.finalDeliveryPrice / 100 : dto.finalDeliveryPrice,
        files: dto.files.map((file: any) => ({ ...file, url: incomingFilePathToUrl(file.url) }))
    };
}
