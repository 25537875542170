import { Component } from '@angular/core';
import { FormGroup, Validators, AbstractControl, FormBuilder } from '@angular/forms';
import { UserService } from 'src/app/services/user.service';

@Component({
    selector: 'app-customer-password-change',
    templateUrl: './customer-password-change.component.html',
    styleUrls: ['./customer-password-change.component.scss']
})
export class CustomerPasswordChangeComponent {

    public form: FormGroup = this.fb.group({
        oldPassword: ['', [Validators.required]],
        newPassword: ['', [Validators.required, Validators.minLength(6)]],
        newPasswordControl: ['', [Validators.required]],
    });
    public submitted: boolean = false;
    public error: undefined | string;

    public get oldPassword(): AbstractControl { return this.form.get('oldPassword') as AbstractControl }
    public get newPassword(): AbstractControl { return this.form.get('newPassword') as AbstractControl }
    public get newPasswordControl(): AbstractControl { return this.form.get('newPasswordControl') as AbstractControl }

    constructor(
        public userService: UserService,
        private fb: FormBuilder,
    ) {
    }

    public onSubmitClick(): void {
        this.error = undefined;
        this.submitted = true;
        if (!this.form.valid
            || this.newPassword.value !== this.newPasswordControl.value) {
            return;
        }
        this.signUp();
    }

    private async signUp(): Promise<void> {
        this.submitted = false;
        try {
            await this.userService.customerChangePassword(this.form.value);
        } catch (err) {
            this.error = err.message;
            return;
        }
        this.form.reset();
    }
}
