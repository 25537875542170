import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
}) export class LoadingService {

    public appInitialized: boolean = false;
    public loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

}
