<div class="modal-header">
    <h4 class="modal-title pull-left primary-color">Finální cena</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
</div>
<div class="modal-body grey-color">
    <form (submit)='onSubmitClick()' [formGroup]='form' [class.submitted]='submitted' novalidate>
        <div class="form-group">
            <label>Cena Kč</label>
            <input formControlName='price' type="number" class="form-control" required>
            <div *ngIf='submitted && price.invalid' class="invalid-feedback">
                Zadejte prosím platnou cenu.
            </div>
        </div>
        <div *ngIf='error' class='form-error-message'>
            {{error}}
        </div>
        <div class="d-flex">
            <input type="submit" class="btn btn--primary" value="Uložit">
        </div>
    </form>
</div>
