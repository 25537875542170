<div class="modal-header">
    <h4 class="modal-title pull-left primary-color">Přidat dopravce</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body grey-color">
    <form (submit)='onSubmitClick()' [formGroup]='form' [class.submitted]='submitted' novalidate class="form" >
        <div class="form-group">
            <label>Jméno a příjmení</label>
            <input formControlName='name' type="text" class="form-control">
            <div *ngIf='submitted && name.invalid' class="invalid-feedback">
                Zadejte prosím jméno a příjmení.
            </div>
        </div>
        <div class="form-group">
            <label>Telefon</label>
            <input formControlName='phone' type="number" class="form-control">
            <div *ngIf='submitted && phone.invalid' class="invalid-feedback">
                Zadejte prosím platné telefonní číslo.
            </div>
        </div>
        <div class="form-group">
            <label>E-mail</label>
            <input formControlName='email' type="email" class="form-control" autocomplete='new-password'>
            <div *ngIf='submitted && email.invalid' class="invalid-feedback">
                Zadejte prosím platný email.
            </div>
        </div>
        <div class="form-group">
            <label>Heslo</label>
            <input formControlName='password' type="password" class="form-control" autocomplete='new-password'>
            <div *ngIf='submitted && password.invalid' class="invalid-feedback">
                Zadejte heslo o délce alespoň 6 znaků.
            </div>
        </div>
        <div *ngIf='error' class='form-error-message'>
            {{error}}
        </div>
        <div class="d-flex">
            <input type="submit" class="btn btn--primary" value="Uložit" />
        </div>
    </form>
</div>
